

































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Pagination } from "@/utils/pagination"
import { UserV2 } from "@robocent/components"
import { UserDao } from "@/services/dao/userDao"

@Component({
  components: {}
})
export default class InternalUsers extends VueComponent {
  users: UserV2[] = []
  pagination = new Pagination(0, 1, 1000)

  created() {
    this.getInternalAgents()
  }

  async getInternalAgents() {
    await this.request(async () => {
      this.users = await UserDao.getInternalUsers()
    })
  }

  async changeAutoAssign(userId: string, autoAssign: boolean) {
    await this.request(async () => {
      await UserDao.updateUser(userId, { metadata: { autoAssign } })
      await this.getInternalAgents()
    }, "Successfully changed")
  }
}
