

























































import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import DownloadPhoneList from "@/components/uploads/DownloadFile.vue"
import {
  LookupModal,
  PhoneList,
  PhoneListDetailsModal,
  PhoneListUtilsMixin,
  ScrubListButton
} from "@robocent/components"
import InvoiceButton from "@/components/checkout/InvoiceButton.vue"
import { VueComponent } from "@/utils/VueComponent"
import UploadActions from "@/components/uploads/UploadActions.vue"
import GetQuoteModal from "@/components/modals/GetQuoteModal.vue"

@Component({
  components: {
    UploadActions,
    DownloadPhoneList,
    ScrubListButton,
    InvoiceButton
  }
})
export default class PhoneListButtons extends mixins(
  VueComponent,
  PhoneListUtilsMixin
) {
  @Prop() list!: PhoneList
  @Prop() orgId!: string
  @Prop() signedUrls!: object

  get invoice() {
    return this.list.meta.lookup?.invoice
  }

  openListDetails() {
    this.$buefy.modal.open({
      parent: this,
      component: PhoneListDetailsModal,
      props: {
        upload: this.list
      },
      events: {
        changed: () => this.$emit("change")
      },
      width: this.lookupExist(this.list) ? 740 : 420
    })
  }

  openLookup() {
    this.$buefy.modal.open({
      parent: this,
      component: LookupModal,
      props: {
        upload: this.list
      },
      width: 600,
      events: {
        changed: () => this.$emit("change"),
        pay: () => this.$emit("pay")
      }
    })
  }

  openQuoteModal() {
    this.$buefy.modal.open({
      parent: this,
      component: GetQuoteModal,
      props: {
        details: this.list.meta.details,
        orgId: this.orgId
      }
    })
  }
}
