














































import ChooseRow from "../elements/ChooseRow.vue"
import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { CampaignDao } from "@/services/dao/campaignDao"
import { CampaignType, Customer, Organization } from "@robocent/components"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import CampaignEstimatedCost from "@/components/scheduler/textElements/TextCampaignEstimatedCost.vue"

@Component({
  components: {
    CampaignEstimatedCost,
    ChooseItem,
    ChooseRow
  }
})
export default class TextCampaignCost extends TextSchedulerUtilsMixin {
  types = CampaignType
  isMergedFieldsEnabled = false
  @Prop() customer!: Customer

  created() {
    this.getData()
  }

  async getData() {
    this.checkIfMergedFieldsEnabled()
  }

  async checkIfMergedFieldsEnabled() {
    await this.request(async () => {
      this.isMergedFieldsEnabled = await CampaignDao.checkIfMergedFieldsEnabled(
        this.organizationId,
        this.campaign
      )
    })
  }

  @Watch("campaign.scripts", { deep: true })
  watchScripts() {
    this.checkIfMergedFieldsEnabled()
  }

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      this.getData()
    }
  }
}
