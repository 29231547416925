import { UserDao } from "./services/dao/userDao"
import Vue from "vue"
import "@/assets/styles/index.scss"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Buefy from "buefy"
import { Auth0Plugin } from "@/auth/plugin"
import { setupAxios } from "@/config/axios"
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"
import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"
import VueCompositionApi from "@vue/composition-api"
import { creditFilter } from "@/filters/creditFilter"
import VueCardFormat from "vue-credit-card-validation"
import "./plugins/vee-validate"
import { BuefyConfig } from "buefy/types/components"
import * as RoboCentComponents from "@robocent/components"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { ReportDao } from "@/services/dao/reportDao"
import VueClipboard from "vue-clipboard2"
import VueMq from "vue-mq"
import VueScreen from "vue-screen"
import LogRocket from "logrocket"
import VueScrollTo from "vue-scrollto"
import VueDebounce from "vue-debounce"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { SharedReportDao } from "@/services/dao/sharedReportDao"
import { usernameFilter } from "@/filters/usernameFilter"
import { campaignTypeFilter } from "@/filters/campaignTypeFilter"
import { statusTypeFilter } from "@/filters/statusTypeFilter"
import { round } from "@/filters/roundFilter"
import { fixed } from "@/filters/fixedFilter"
import { TosDao } from "@/services/dao/tosDao"
import { VueScreenObject } from "vue-screen/types/screen"

Vue.config.productionTip = false

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  authorizationParams: {
    screen_hint: "signup"
  },
  onRedirectCallback: (appState: { targetUrl: string }) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true })
  ],
  release: process.env.VUE_APP_COMMIT,
  environment: location.hostname,
  enabled: !location.hostname.includes("localhost")
})

Vue.use(Buefy, <BuefyConfig>{
  defaultIconPack: "fa",
  defaultToastPosition: "is-bottom-left",
  defaultNoticeQueue: false,
  defaultToastDuration: 4000
})

Vue.use(VueScreen, {
  sm: 768,
  md: 980,
  tablet: (screen: VueScreenObject) => !screen.desktop && !screen.lg,
  desktop: 1025,
  lg: Infinity
})

if (location.hostname.includes("app-old.robocent.com")) {
  LogRocket.init("vxlifz/sms-connect", {
    release: process.env.VUE_APP_COMMIT
  })
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL)
    })
  })
}

setupAxios()

Vue.use(VueCompositionApi)
Vue.filter("credit", creditFilter)
Vue.filter("username", usernameFilter)
Vue.filter("campaignType", campaignTypeFilter)
Vue.filter("statusType", statusTypeFilter)
Vue.filter("round", round)
Vue.filter("fixed", fixed)

Vue.use(VueClipboard)
Vue.use(VueScrollTo)
Vue.use(VueDebounce)

Vue.use(RoboCentComponents, {
  dao: {
    UploadsDao,
    UserDao,
    ReportDao,
    OrganizationDao,
    SharedReportDao,
    TosDao
  }
})

Vue.use(VueCardFormat)

Vue.use(VueMq, {
  breakpoints: {
    sm: 768,
    md: 980,
    lg: Infinity
  },
  defaultBreakpoint: "lg"
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
