
































import _ from "lodash"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import Vue from "vue"
import { SurveyOption } from "@robocent/components"

@Component({})
export default class SurveyOptionsInput extends Vue {
  @Prop() surveyOptions!: SurveyOption[]
  @Prop({ default: 2 }) minOptionsNumber!: number

  get options() {
    return this.optionsToStrings(this.surveyOptions)
  }

  set options(value) {
    this.$emit("update:surveyOptions", this.optionsToObjects(value))
  }

  optionsToStrings(options: SurveyOption[]): string[] {
    return _.map(options, (option) => option.text)
  }

  optionsToObjects(options: string[]): SurveyOption[] {
    return _.map(options, (option) => ({
      text: _.capitalize(option)
    })) as SurveyOption[]
  }
}
