














import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { Watch } from "vue-property-decorator"
import { DatasetFields } from "@/voterData/enums/datasetFields"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"
import _ from "lodash"

@Component({
  components: {
    StepTemplate
  }
})
export default class PartyCode extends VoterDataUtilsMixin {
  partyCodes: FieldInfo[] = []

  get affiliation() {
    return this.$store.getters["voterData/affiliationInfo/affiliation"]
  }
  set affiliation(value: string) {
    this.$store.commit("voterData/affiliationInfo/updateAffiliation", value)
  }

  mounted() {
    this.getPartyCodes()
  }

  async getPartyCodes() {
    await this.request(async () => {
      this.partyCodes = await this.getStepData(DatasetFields.partyCode)
    })
  }

  @Watch("affiliation")
  watchAffiliation(nextValue: string[], prevValue: string[]) {
    if (!_.isEqual(nextValue, prevValue)) {
      this.updateQuery()
    }
  }
}
