



































































































































































import Component, { mixins } from "vue-class-component"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import { Prop, Ref, Watch } from "vue-property-decorator"
import {
  CardTable,
  CardTableItem,
  Customer,
  ScrubListButton
} from "@robocent/components"
import { PhoneListMixin } from "@/mixins/campaign/PhoneListMixin"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import _ from "lodash"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { CampaignCostService } from "@/services/campaignCostService"
import CheckIcon from "@/components/elements/CheckIcon.vue"

@Component({
  components: {
    ChooseItem,
    ScrubListButton,
    CardTableItem,
    CardTable,
    CheckIcon
  }
})
export default class VoiceCampaignEstimatedCost extends mixins(
  VoiceSchedulerUtilsMixin,
  PhoneListMixin
) {
  @Ref("countdown") countdown: any
  leftTime = 15 * 1000
  @Prop() customer!: Customer
  estimatedCost: any = null
  eventBus = EventBus
  campaignCostService = new CampaignCostService()

  get isListEmpty() {
    return Boolean(this.list) && this.list!.meta.details?.landline === 0
  }

  get hasIssueDuringEstimate() {
    return Boolean(this.error)
  }

  get isEstimatedCostExists() {
    return Boolean(this.estimatedCost)
  }

  get typeSelected() {
    return Boolean(this.campaign.type)
  }

  get listSelected(): boolean {
    return Boolean(this.campaign.uploads.list)
  }

  get accountBalance() {
    return this.customer && this.customer?.balance < 0
      ? this.customer.balance * -1
      : 0
  }

  get newAccountBalance() {
    return this.accountBalance - this.appliedBalance
  }

  get appliedBalance() {
    return this.accountBalance >= this.cost ? this.cost : this.accountBalance
  }

  get cost() {
    return this.estimatedCost.total
  }

  get costWithAppliedBalance() {
    return this.cost - this.appliedBalance
  }

  created() {
    this.getData()
    EventBus.startScrubPhoneList$
      .pipe(whileComponentMounted(this))
      .subscribe(async () => this.getData())
  }

  async getData() {
    if (this.listSelected) {
      await this.getList()
    }

    if (this.isListReady(this.listSelected)) {
      await this.getEstimatedCost()
    } else {
      await this.refresh()
    }
  }

  async getList() {
    await this.request(async () => {
      const { list } = this.campaign.uploads

      const uploadListId = typeof list === "string" ? list : list._id

      this.list = (
        await UploadsDao.getUpload(this.organizationId, uploadListId)
      ).data
    })
  }

  async refresh() {
    if (!this.estimatedCost) {
      this.countdown?.startCountdown("restart")
    }
  }

  async getEstimatedCost() {
    if (this.isListEmpty) {
      return
    }
    try {
      this.error = null
      const data = await this.campaignCostService.getCostBasedOnCallObject(
        this.campaign
      )
      if (data.leads) {
        this.estimatedCost = this.campaignCostService.calculateInvoiceItemsCost(
          data,
          this.campaign.type
        )
      } else {
        this.estimatedCost = null
        this.error = "Error during estimate"
      }
    } catch (e) {
      this.error = _.get(e, "response.data.message")
      throw e
    } finally {
      this.refresh()
    }
  }

  @Watch("campaign.type")
  @Watch("campaign.uploads.list")
  @Watch("campaign.uploads.liveRecording")
  @Watch("campaign.uploads.vmRecording")
  @Watch("campaign.time")
  @Watch("campaign.meta.promoCode")
  async watchDependedField() {
    this.estimatedCost = null
    this.list = null

    this.emitWatchDependedFieldChange()
  }

  emitWatchDependedFieldChange = _.debounce(async () => this.getData(), 300)
}
