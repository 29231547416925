import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Campaign } from "@robocent/components"

@Component
export default class CampaignMixin extends VueComponent {
  range = 7

  campaignStartTime(campaign: Campaign) {
    return `${campaign.start} ${campaign.time.monday.start}`
  }
}
