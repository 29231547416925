


















































































































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Organization } from "@robocent/components"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import Card from "@/components/elements/Card.vue"

@Component({
  components: { Card }
})
export default class OrganizationNotificationsSettings extends VueComponent {
  organization: Organization = null!

  created() {
    this.getOrganization()
  }

  async getOrganization() {
    await this.request(async () => {
      this.organization = await OrganizationDao.getOrganization(
        this.$route.params.orgId
      )
    })
  }

  async updatePreferences() {
    await this.request(
      async () => {
        this.organization = await OrganizationDao.saveOrganization(
          this.organization
        )
      },
      "Notifications were successfully updated",
      "Failed to update notifications"
    )
  }
}
