import { RouteConfig } from "vue-router"
import OrganizationsCampaigns from "@/views/organization/OrganizationsCampaigns.vue"
import OrganizationSettings from "@/views/organization/OrganizationSettings.vue"
import OrganizationAgents from "@/views/organization/OrganizationAgents.vue"
import OrganizationBilling from "@/views/organization/OrganizationBilling.vue"
import { campaignRoutes } from "@/router/campaignRoutes"
import CampaignParent from "@/views/campaign/CampaignParent.vue"
import OrganizationScripts from "@/views/organization/OrganizationScripts.vue"
import OrganizationDND from "@/views/organization/OrganizationDND.vue"
import PrePay from "@/components/billing/PrePay.vue"
import { voiceCampaignRoutes } from "@/router/voiceCampaignRoutes"
import OrganizationUploads from "@/views/organization/OrganizationUploads.vue"
import OrganizationCIRL from "../views/organization/OrganizationCIRL.vue"
import Phones from "../helloInbox/views/Phones.vue"
import Phone from "../helloInbox/views/Phone.vue"
import { Resolvers } from "@/router/resolvers"
import PhoneMessages from "@/helloInbox/views/PhoneMessages.vue"
import PhoneSubscribers from "@/helloInbox/views/PhoneSubscribers.vue"

export const organizationRoutes: Array<RouteConfig> = [
  {
    path: "campaigns",
    name: "Org Campaigns",
    component: OrganizationsCampaigns
  },
  {
    path: "files",
    name: "Org Files",
    component: OrganizationUploads
  },
  {
    path: "agents",
    name: "Org Agents",
    component: OrganizationAgents
  },
  {
    path: "billing",
    name: "Org Billing",
    component: OrganizationBilling
  },
  {
    path: "settings",
    name: "Org Settings",
    component: OrganizationSettings
  },
  {
    path: "scripts",
    name: "Org Scripts",
    component: OrganizationScripts
  },
  {
    path: "dnd",
    name: "Org DND",
    component: OrganizationDND
  },
  {
    path: "pre-pay",
    name: "Org Pre Pay",
    component: PrePay
  },
  {
    path: "recording-line",
    name: "Org CIRL",
    component: OrganizationCIRL
  },
  {
    path: "phones",
    name: "Org Callback Numbers",
    component: Phones
  },
  {
    path: ":campaignUUID",
    component: CampaignParent,
    children: [...campaignRoutes, ...voiceCampaignRoutes]
  },
  {
    path: "phones/:phoneId",
    component: Phone,
    beforeEnter: Resolvers.getPhone,
    name: "Phone"
  },
  {
    path: "phones/:phoneId/messages",
    beforeEnter: Resolvers.getPhone,
    component: PhoneMessages,
    name: "PhoneMessages"
  },
  {
    path: "phones/:phoneId/subscribers",
    beforeEnter: Resolvers.getPhone,
    component: PhoneSubscribers,
    name: "PhoneSubscribers"
  }
]
