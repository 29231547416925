














import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"

@Component
export default class Card extends VueComponent {
  @Prop() title!: string
}
