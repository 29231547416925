






























import Avatar from "vue-avatar"
import { VueComponent } from "../../utils/VueComponent"
import Component from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { UserV2 } from "@robocent/components"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: {
    ChooseItem,
    Avatar
  }
})
export default class OrganizationPartnerPortalUsers extends VueComponent {
  @Prop() organizationPartnerId!: string

  users: UserV2[] = []

  created() {
    this.getUsers()
  }

  async getUsers() {
    await this.request(async () => {
      this.users = await OrganizationDao.getUsers(this.$route.params.orgId)
    })
  }

  @Emit()
  onSelect(userId: string) {
    return userId
  }
}
