




















































































import Component from "vue-class-component"
import Vue from "vue"
import { CampaignType } from "@robocent/components"
import { CampaignDirection } from "@/enums/campaignDirection"

@Component
export default class AdminNavBar extends Vue {
  types = CampaignType
  campaignDirection = CampaignDirection
}
