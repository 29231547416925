import { Campaign, CampaignStatus, CampaignType } from "@robocent/components"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"

export function isTextCampaignUpdate(campaign: Campaign) {
  return Boolean(campaign?.id) && campaign.status !== CampaignStatus.pending
}

@Component
export class TextSchedulerUtilsMixin extends SchedulerUtilsMixin {
  @Prop() campaign!: Campaign
  steps = TextSchedulerSteps

  get isManaged() {
    return this.campaign.type === CampaignType.managed
  }

  get isCampaignUpdate() {
    return isTextCampaignUpdate(this.campaign)
  }

  next(step: TextSchedulerSteps) {
    this.$store.commit("textScheduler/next", step)
  }

  remove(step: TextSchedulerSteps) {
    this.$store.commit("textScheduler/remove", step)
  }
}
