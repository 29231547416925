<template>
  <article class="card">
    <div v-if="label || icon">
      <h1>
        {{ label }}
        <b-icon class="is-pulled-right" :icon="icon"></b-icon>
      </h1>
      <div class="hr-line has-padding-tb-xs"></div>
    </div>
    <slot></slot>
  </article>
</template>

<script>
export default {
  name: "BigCard",
  props: ["icon", "type", "label"],
  watch: {},
  methods: {}
}
</script>
<style scoped>
.card {
  border-radius: 4px;
  padding: 1.5em 1.5em;
  margin-bottom: 1.5rem;
}
.custom-card {
  height: 100%;
  margin-bottom: 0;
}
.is-floating-label-right {
  right: 1em;
  left: unset !important;
}
.is-floating-label-right .label {
  left: unset !important;
  right: 1em;
}
</style>
