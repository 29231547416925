







import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { EventBus } from "@/services/eventBus"

@Component
export default class OrganizationEmptyUsers extends VueComponent {
  openInviteModal() {
    EventBus.inviteUserModal$.next()
  }
}
