



















import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { UnicodeUtil } from "@/utils/unicodeUtil"

@Component
export default class MessageCounter extends VueComponent {
  @Prop() value!: string
  @Prop({ default: 160 }) baseSegmentLength!: number
  @Prop() mms!: string
  @Prop() defaultScript!: string
  @Prop() segmentCost!: number
  @Prop() additionalSegmentCost!: number
  @Prop() mmsCost!: number
  @Prop() maxLength!: number

  unicodeUtil = new UnicodeUtil()

  get count() {
    if (!this.value) {
      return this.defaultScript ? this.defaultScript.length : 0
    }

    const script = this.value.toString().trim().length

    if (this.defaultScript) {
      return script + this.defaultScript.length
    }

    return script
  }

  get cost() {
    let totalCost = this.segmentCost

    if (this.count > this.baseSegmentLength) {
      totalCost += (this.segments - 1) * this.additionalSegmentCost
    }

    return totalCost
  }

  get segments() {
    return Math.ceil(this.count / this.baseSegmentLength)
  }

  get maxCounterValue() {
    if (this.mms) {
      return this.maxLength + this.defaultScript.length
    }

    let currentSegmentLength = this.baseSegmentLength

    if (this.count > currentSegmentLength) {
      currentSegmentLength += (this.segments - 1) * this.baseSegmentLength
    }

    if (this.value) {
      this.unicodeUtil.detectUnicode(this.value)

      if (this.unicodeUtil.unicodeChars) {
        currentSegmentLength /= 2
      }
    }

    return currentSegmentLength
  }
}
