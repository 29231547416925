





































import Component from "vue-class-component"
import { CampaignPricing, Script, ScriptStatus } from "@robocent/components"
import Scripts from "@/views/admin/Scripts.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import ApproveScriptModal from "@/components/modals/ApproveScriptModal.vue"
import RejectScriptModal from "@/components/modals/RejectScriptModal.vue"
import { Emit, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import ScriptItem from "@/components/scripts/ScriptItem.vue"

@Component({
  components: {
    ScriptItem,
    ChooseRow,
    ChooseItem,
    Scripts
  }
})
export default class CampaignAdminReview extends VueComponent {
  @Prop() scripts!: Script[]
  @Prop({ default: null }) pricing!: CampaignPricing

  isScriptApproved(script: Script) {
    return script.status === ScriptStatus.approved
  }

  approveScriptModal(script: Script) {
    this.$buefy.modal.open({
      parent: this,
      component: ApproveScriptModal,
      width: 600,
      props: {
        script
      },
      events: {
        changed: this.change
      }
    })
  }

  rejectScriptModal(script: Script) {
    this.$buefy.modal.open({
      parent: this,
      component: RejectScriptModal,
      width: 600,
      props: {
        script
      },
      events: {
        changed: this.change
      }
    })
  }

  @Emit()
  change() {}
}
