














































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import OrganizationDetails from "@/components/organization/OrganizationDetails.vue"
import OrganizationUsers from "@/views/organization/OrganizationUsers.vue"
import OrganizationNotificationsSettings from "@/views/organization/OrganizationNotificationsSettings.vue"
import OrganizationFeatures from "@/components/feature/OrganizationFeatures.vue"
import { Watch } from "vue-property-decorator"
import { OrganizationTab } from "@/enums/organizationTab"
import OrganizationPartnerPortal from "@/views/organization/OrganizationPartnerPortal.vue"
import OrganizationRates from "@/components/organization/OrganizationRates.vue"
import OrganizationSalesPortal from "@/views/organization/OrganizationSalesPortal.vue"
import router from "@/router"

@Component({
  components: {
    OrganizationSalesPortal,
    OrganizationRates,
    OrganizationPartnerPortal,
    OrganizationFeatures,
    OrganizationNotificationsSettings,
    OrganizationUsers,
    OrganizationDetails
  }
})
export default class OrganizationSettings extends VueComponent {
  activeTab = 0
  tabs = OrganizationTab
  mappedTabs = {
    [OrganizationTab.details]: 0,
    [OrganizationTab.notifications]: 1,
    [OrganizationTab.users]: 2,
    [OrganizationTab.features]: 3,
    [OrganizationTab.partnerStatus]: 4,
    [OrganizationTab.salesStatus]: 5,
    [OrganizationTab.rates]: 6
  }

  get isSuper() {
    return this.$store.getters.super
  }

  get isPartner() {
    return (
      this.$store.getters.organization.partnerId ===
      this.$store.getters.user.user_id
    )
  }

  selectTab() {
    const selectedTab: OrganizationTab = this.$route.query
      .tab as OrganizationTab
    this.activeTab = this.mappedTabs[selectedTab]
  }

  applyQuery(tab: OrganizationTab) {
    const query = Object.assign({}, this.$route.query, { tab })

    this.$router.push({ query })
  }

  goToSettings() {
    if (this.isSuper) {
      return router.push({
        name: `Org Settings`,
        params: { orgId: this.$route.params.orgId }
      })
    }
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/organizations/${this.$route.params.orgId}/settings`,
      "_self"
    )
  }

  @Watch("$route.query", { immediate: true })
  watchRouteQuery() {
    this.selectTab()
  }
}
