


























import Component from "vue-class-component"
import { CardTable, CardTableItem } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"
import InvoiceButton from "@/components/checkout/InvoiceButton.vue"
import VoiceCampaignShareableResultsModal from "@/components/modals/voiceCampaign/VoiceCampaignShareableResultsModal.vue"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import TestVoiceCampaignModal from "@/components/modals/voiceCampaign/TestVoiceCampaignModal.vue"

@Component({
  components: {
    InvoiceButton,
    CardTable,
    CardTableItem,
    Card
  }
})
export default class VoiceCampaignActions extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() invoice!: Invoice
  @Prop() waiting!: boolean

  get isTestCallAvailable() {
    return (
      this.voiceCampaign?.meta?.status === VoiceCampaignStatus.approved &&
      this.voiceCampaign?.meta?.straticsId &&
      (this.voiceCampaign?.type === VoiceCampaignType.robocall ||
        this.voiceCampaign?.type === VoiceCampaignType.survey ||
        this.voiceCampaign?.type === VoiceCampaignType.transfer)
    )
  }

  sharedResults() {
    this.$buefy.modal.open({
      parent: this,
      component: VoiceCampaignShareableResultsModal,
      props: { voiceCampaign: this.voiceCampaign },
      width: 800
    })
  }

  onTestOutboundMessageClick() {
    this.$buefy.modal.open({
      width: 500,
      props: {
        voiceCampaign: this.voiceCampaign
      },
      component: TestVoiceCampaignModal,
      trapFocus: true
    })
  }

  @Emit()
  changed() {}

  @Emit()
  invoiceChanged() {}
}
