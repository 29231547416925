












































import Component from "vue-class-component"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import { InputWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { Ref } from "vue-property-decorator"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"

@Component({
  components: { ChooseRow, InputWithValidation, ValidationObserver }
})
export default class VoiceCampaignSurveyScript extends VoiceSchedulerUtilsMixin {
  @Ref("survey-script-observer") observer!: any

  async confirm() {
    if (await this.observer.validate()) {
      this.next(VoiceSchedulerSteps.campaignDate)
    }
  }
}
