































































import Component, { mixins } from "vue-class-component"
import {
  FileUpload,
  GenericFilter,
  PhoneListType,
  PhoneListUtilsMixin
} from "@robocent/components"
import PhoneListItem from "@/components/uploads/list/PhoneListItem.vue"
import { VueComponent } from "@/utils/VueComponent"
import { PhoneListDao } from "@/services/dao/robocent/phoneListDao"
import { Watch } from "vue-property-decorator"
import ShowDeletedFilter from "@/components/elements/ShowDeletedFilter.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import OrganizationUploadsListMixin from "@/mixins/organization/OrganizationUploadsListMixin"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import { UploadType } from "@/enums/uploadType"
import OrganizationPhoneListMixin from "@/mixins/organization/OrganizationPhoneListMixin"

@Component({
  components: {
    SearchItem,
    PaginationItem,
    PhoneListItem,
    FileUpload,
    GenericFilter,
    ShowDeletedFilter
  }
})
export default class OrganizationPhoneLists extends mixins(
  VueComponent,
  PhoneListUtilsMixin,
  OrganizationUploadsListMixin,
  OrganizationPhoneListMixin
) {
  initialOption: string | null = ""
  defaultOptions = {
    type: [PhoneListType.list, PhoneListType.voterData],
    q: null,
    deleted: false
  } as FindManyFilter & { type: UploadType[] | PhoneListType[] }
  type: PhoneListType[] = Object.keys(PhoneListType) as PhoneListType[]

  async created() {
    this.orgId = this.$route.params.orgId
    this.initialOption = this.getTypeFromQuery(
      this.$route.query.type,
      this.defaultOptions.type
    )

    await this.getPhoneLists()
    EventBus.getUploads$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getPhoneLists)
  }

  async getPhoneLists() {
    const filter = Object.assign(
      this.defaultOptions,
      this.pagination.query,
      this.$route.query
    )

    await this.request(
      async () => {
        const response = await PhoneListDao.getPhoneLists(this.orgId, filter)
        this.pagination.total = response.total
        this.pagination.current = Number(filter.page)
        this.uploads = response.data

        await this.getSignedUrls()

        if (
          this.pagination.current !== 1 &&
          response.total <= this.pagination.perPage
        ) {
          this.applyQuery({ ...filter, page: 1 })
        }
      },
      undefined,
      "Cannot get phone lists"
    )
  }

  @Watch("$route.query")
  onRouteChange() {
    this.getPhoneLists()
  }
}
