import { Coupon } from "@/interfaces/coupon"

export const messages = {
  confirmPayWithCard: `Pay Invoice Using Credit balance or Credit Card on File.
        <br /> <br />
        <b>Note:</b> You cannot undo this action.`,
  pauseCampaign: (isPaused: boolean) => `This will ${
    isPaused ? "unpause" : "pause"
  } this Campaign and ${isPaused ? "open" : "close"} inbound responses and
          conversations with agents.`,
  archiveCampaign: (
    message: string
  ) => `Are you sure you want to archive this campaign? 
          You can safely archive a campaign when you do not anticipate anymore messaging. 
          ${message ? `<br/><br/> ${message}` : ""}
          <br/><br/>
          <strong>This cannot be undone.</strong>`,
  schedulerBeforeRouteLeave:
    'You have unsaved changes on this page, are you sure you want to leave? Be sure to select "Schedule Message" at the bottom of this page before leaving to ensure your message is successfully scheduled.',
  generateReport: (isPublic: boolean) =>
    `Generate Report? This may take a few moments.${
      !isPublic
        ? " You are able to visit other pages while report is generating."
        : ""
    }`,
  finalizeInvoice: (orgName: string) =>
    `This will finalize the invoice (if Draft) and attempt to collect or send an invoice, depending on AutoPay settings. If you would like to override AutoPay and force a charge, type the organization's name (${orgName}) below.`,
  deletePromoCode: (coupon: Coupon) =>
    `Are you sure you want to delete <b>[${
      coupon.name || coupon.id
    }]</b> promo code?`
}
