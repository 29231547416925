

















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { UserV2 } from "@robocent/components"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import OrganizationUserItem from "@/components/users/OrganizationUserItem.vue"
import OrganizationEmptyUsers from "@/views/organization/OrganizationEmptyUsers.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: {
    OrganizationEmptyUsers,
    OrganizationUserItem
  }
})
export default class OrganizationActiveUsers extends VueComponent {
  private users: UserV2[] = []

  created() {
    this.getUsers()
    EventBus.getUsers$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getUsers)
  }

  async getUsers() {
    await this.request(async () => {
      this.users = await OrganizationDao.getUsers(this.$route.params.orgId)
    })
  }

  openRemoveUserModal(userId: string) {
    const isCurrentUser = this.$store.getters.user.sub === userId
    const message = isCurrentUser
      ? "Attention: If you remove yourself, you will no longer be able to access this organization."
      : "Remove this user?"

    this.confirm(message, {
      message,
      title: isCurrentUser ? "Leave Organization" : "Remove User",
      type: "is-danger",
      hasIcon: true,
      icon: "times-circle",
      iconPack: "fa",
      confirmText: isCurrentUser ? "Leave Organization" : "Remove",
      onConfirm: () => this.removeUser(userId)
    })
  }

  async removeUser(userId: string) {
    await this.request(async () => {
      await OrganizationDao.removeUser(this.$route.params.orgId, userId)
      this.openToast("User Removed")
      await this.getUsers()
    })
  }
}
