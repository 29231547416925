


















































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import {
  Campaign,
  CampaignScheduledDate,
  CardTable,
  CardTableItem,
  UserV2
} from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"
import CampaignMixin from "@/mixins/campaign/CampaignMixin"

interface CampaignExtended extends Campaign {
  invoice: Invoice
  user: UserV2
}

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card,
    CampaignScheduledDate
  }
})
export default class CampaignDetails extends CampaignMixin {
  @Prop() campaign!: CampaignExtended
  @Prop() waiting!: boolean
}
