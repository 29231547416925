





















































































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Ref } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import _ from "lodash"
import { VoiceCampaignPublicDao } from "@/services/dao/robocent/voiceCampaignPublicDao"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { FileUtils } from "@/utils/fileUtils"
import moment from "moment"
import { BDialogConfig } from "buefy/types/components"
import GenerateReportButton from "@/components/report/voiceCampaign/GenerateReportButton.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { messages } from "@/data/messages"
import { ReportStatus } from "@/interfaces/campaignReports"

@Component({
  components: { GenerateReportButton }
})
export default class ReportsTable extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() isPublic!: boolean
  @Ref("countdown") countdown: any

  leftTime = 30 * 1000
  currentPage = 1
  perPage = 5
  orgId = ""
  reports = []
  statuses = ReportStatus

  get isEmpty() {
    return !this.reports.length
  }

  get isGenerateReportButtonDisabled() {
    return (
      _.some(this.reports, { status: ReportStatus.processing }) ||
      !this.voiceCampaign.meta?.straticsId
    )
  }

  async mounted() {
    this.orgId = this.$route.params.orgId
    await this.getReports()
    this.checkReportsStatus()
    EventBus.getReports$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getReports)
  }

  async getReports() {
    await this.request(
      async () => {
        let result

        if (this.isPublic) {
          result = await VoiceCampaignPublicDao.getVoiceCampaignReports(
            this.voiceCampaign._id!
          )
        } else {
          result = await VoiceCampaignDao.getVoiceCampaignReports(
            this.voiceCampaign._id!
          )
        }
        this.reports = result
      },
      undefined,
      "Failed loading reports."
    )
  }

  timeFromNow(date: Date) {
    return moment.utc(date).fromNow()
  }

  formattedDate(date: Date) {
    return moment.utc(date).format("dddd, MMMM Do YYYY, h:mm A")
  }

  async onDownloadClick(url: string, key: string, fileName: string) {
    if (!url || !key) {
      return this.openToast("Report can't be downloaded.")
    }

    const fileExt = FileUtils.getFileExtFromName(key)
    const name = `${fileName}.${fileExt}`
    await FileUtils.downloadFileByExternalUrl(url, name)
  }

  async onDeleteReport(reportId: number) {
    await this.confirm("Are you sure you want to remove this report?", {
      confirmText: "Yes",
      cancelText: "No"
    } as BDialogConfig)

    await this.request(
      async () => {
        if (this.isPublic) {
          await VoiceCampaignPublicDao.cancelReportDownloading(
            this.voiceCampaign._id!,
            reportId
          )
        } else {
          await VoiceCampaignDao.cancelReportDownloading(
            this.orgId,
            this.voiceCampaign._id!,
            reportId
          )
        }
        await this.getReports()
      },
      undefined,
      "Report can't be deleted."
    )
  }

  async generateReport() {
    await this.confirm(messages.generateReport(this.isPublic))

    await this.request(
      async () => {
        if (this.isPublic) {
          await VoiceCampaignPublicDao.generateVoiceCampaignReport(
            this.voiceCampaign._id!
          )
        } else {
          await VoiceCampaignDao.getVoiceCampaignReport(
            this.$route.params.orgId,
            this.voiceCampaign._id!
          )
        }
        EventBus.getReports$.next()
        this.countdown.startCountdown("restart")
      },
      undefined,
      "Report can't be generated."
    )
  }

  checkReportsStatus() {
    if (this.isGenerateReportButtonDisabled) {
      this.countdown.startCountdown("restart")
    }
  }

  updateReports() {
    this.getReports()
    this.checkReportsStatus()
  }

  getIcon(status: string) {
    return status === ReportStatus.processing ? "times" : "trash"
  }

  getLeftIcon(status: string) {
    return status === ReportStatus.failed ? "exclamation-triangle" : "download"
  }

  reportStatus(status: string, isRetry = false) {
    if (status === ReportStatus.failed) {
      return {
        status: "is-danger",
        disabled: true,
        button: "Failed"
      }
    }
    return {
      status: isRetry ? "is-warning" : "is-success",
      disabled: false,
      button: "Download"
    }
  }
}
