






























import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { ScheduleService } from "@/services/voice/scheduleService"
import { CampaignConfigureService } from "@/services/voice/campaignConfigureService"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import { Watch } from "vue-property-decorator"

@Component({
  components: { Card }
})
export default class OverviewTime extends OverviewMixin {
  scheduleService = new ScheduleService()
  campaignConfigureService = new CampaignConfigureService()
  schedule = null
  enabled: any[] = []

  get date() {
    return this.campaignConfigureService.formatTime(this.campaign!)
  }

  created() {
    this.prepareValues()
  }

  prepareValues() {
    const isScheduleReady =
      this.campaign.meta.stratics && this.campaign.meta.stratics.scheduleId
    isScheduleReady ? this.prepareSchedule() : this.populateValues()
  }

  async prepareSchedule() {
    await this.request(async () => {
      this.schedule = await CampaignConfigureDao.getSchedule(this.campaign._id!)
      this.enabled = this.scheduleService.filterNotActiveDays(
        this.scheduleService.returnActiveWeekDays(this.schedule)
      )
    })
  }

  populateValues() {
    this.schedule = this.scheduleService.populateSchedule(this.campaign)
    this.enabled = this.scheduleService.filterNotActiveDays(
      this.scheduleService.populateActiveWeekDay(this.campaign)
    )
  }

  @Watch("campaign")
  watchCampaignChange() {
    this.prepareValues()
  }
}
