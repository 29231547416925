







































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { Location } from "vue-router/types/router"
import CreateNewOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"

@Component
export default class DashboardAction extends VueComponent {
  @Prop() name!: string
  @Prop() guideLink!: string
  @Prop() icon!: string
  @Prop() link!: Location

  get orgId() {
    return this.$store.getters.lastOrg?._id
  }

  async openCreateOrganizationModalOrRedirect() {
    if (!this.orgId) {
      return this.$buefy.modal.open({
        width: 640,
        component: CreateNewOrganizationModal,
        parent: this,
        props: {
          redirect: this.link
        }
      })
    }
    await this.$router.push(this.link)
  }
}
