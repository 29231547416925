









import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import ImportExistingPhoneNumberModal from "@/components/modals/ImportExistingPhoneNumberModal.vue"

@Component
export default class PhoneNumbersEmptyMessage extends VueComponent {
  @Prop() orgId!: string

  openImportPhoneNumberModal() {
    this.$buefy.modal.open({
      parent: this,
      component: ImportExistingPhoneNumberModal,
      props: {
        orgId: this.orgId
      }
    })
  }
}
