






















import Component from "vue-class-component"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { DatasetFields } from "@/voterData/enums/datasetFields"
import { JurisdictionTypes } from "@/voterData/enums/jurisdictionTypes"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"
import Multiselect from "vue-multiselect"
import { AutocompleteWithValidation } from "@robocent/components"

@Component({
  components: {
    AutocompleteWithValidation,
    Multiselect
  }
})
export default class County extends VoterDataUtilsMixin {
  jurisdictionData: FieldInfo[] = []

  get county() {
    return this.$store.getters["voterData/locationInfo/county"]
  }
  set county(options: FieldInfo[]) {
    this.$store.commit("voterData/locationInfo/updateJurisdictionValue", {
      type: JurisdictionTypes.county,
      value: options
    })
  }

  async mounted() {
    await this.getData()
  }

  async getData() {
    if (!this.state) {
      return
    }

    await this.request(async () => {
      this.jurisdictionData = await this.getStepData(DatasetFields.county)
    })
  }
}
