







































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { Card, Customer } from "@robocent/components"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
@Component({
  components: { ChooseItem }
})
export default class CreditCard extends VueComponent {
  @Prop() card!: Card
  @Prop() customer!: Customer
  @Prop() orgId!: string

  get isActive() {
    return this.card.id === this.customer.default_source
  }

  async makeDefaultSource(cardId: string) {
    if (this.isActive) {
      return this.openToast("Card Already is default")
    }
    await this.request(
      async () => {
        const response = await BillingDao.makeCardDefault(this.orgId, cardId)
        this.$emit("change", response)
      },
      "The selected card made as default",
      "Failed updating the card"
    )
  }

  async deleteSource(cardId: string) {
    await this.confirm("Do you really want to delete a Credit Card?")
    await this.request(
      async () => {
        const response = await BillingDao.deleteCard(this.orgId, cardId)
        this.$emit("change", response)
      },
      "The selected card deleted",
      "Failed deleting the card"
    )
  }
}
