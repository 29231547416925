














import Component from "vue-class-component"
import { CreditNote } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"

@Component
export default class CampaignInvoice extends VueComponent {
  @Prop() creditNotes!: CreditNote

  onLinkClick() {
    if (!this.creditNotes.pdf) {
      return
    }
    window.open(this.creditNotes.pdf, "_blank")
  }
}
