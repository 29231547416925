import { Module } from "vuex"
import _ from "lodash"
import { SalesEarnings } from "@/interfaces/salesPortal/salesEarnings"
import { SalesManagerPayout } from "@/interfaces/salesPortal/salesManagerPayout"
import { SalesRate } from "@/interfaces/salesPortal/salesRate"
import { Organization } from "@robocent/components"

const initState = {
  selectedOrg: null,
  selectedDate: new Date(),
  payouts: [],
  salesEarnings: {
    totalSales: 0,
    totalEarnings: 0
  },
  salesRate: {
    threshold: 0,
    rateAfter: 0,
    rateBefore: 0,
    salesManagerId: ""
  },
  salesOrganizations: []
}

export const salesPortalStore = <Module<any, any>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    selectedOrg: (state) => state.selectedOrg,
    selectedDate: (state) => state.selectedDate,
    payouts: (state) => state.payouts,
    salesEarnings: (state) => state.salesEarnings,
    salesRate: (state) => state.salesRate,
    salesOrganizations: (state) => state.salesOrganizations
  },
  mutations: {
    setOrganization: (state, organization: Organization) => {
      state.selectedOrg = organization
    },
    setDate: (state, date: Date) => {
      state.selectedDate = date
    },
    setPayouts: (state, payouts: SalesManagerPayout[]) => {
      state.payouts = _.cloneDeep(payouts)
    },
    setSalesEarnings: (state, earnings: SalesEarnings) => {
      state.salesEarnings = _.cloneDeep(earnings)
    },
    setSalesRate: (state, salesRate: SalesRate) => {
      state.salesRate = _.cloneDeep(salesRate)
    },
    setSalesOrganizations: (state, salesOrganizations: Organization[]) => {
      state.salesOrganizations = _.cloneDeep(salesOrganizations)
    },
    clear: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
