export class SchedulerService {
  static instance: SchedulerService
  isDefaultScriptNeeded = true

  static getInstance() {
    if (!SchedulerService.instance) {
      SchedulerService.instance = new SchedulerService()
    }

    return SchedulerService.instance
  }

  static clear() {
    SchedulerService.instance = new SchedulerService()
  }
}
