import axios from "axios"
import { Coupon } from "@/interfaces/coupon"

export class CouponsDao {
  static async getCoupons(
    params: object
  ): Promise<{ data: Coupon[]; has_more: boolean }> {
    return (await axios.get("/coupons", { params })).data
  }

  static async createCoupon(data: object) {
    return await axios.post("/coupons", data)
  }

  static async deleteCoupon(id: string) {
    return await axios.delete(`/coupons/${id}`)
  }

  static async validateCoupon(name: string) {
    return await axios.get("/coupons/is-valid", { params: { name } })
  }
}
