























import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import PieChart from "../elements/PieChart"
import { CampaignStats } from "@/interfaces/campaignStats"

@Component({
  components: {
    PieChart,
    Card
  }
})
export default class MessagesDiagram extends VueComponent {
  @Prop() campaignStats!: CampaignStats
  @Prop() waiting!: boolean
}
