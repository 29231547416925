import { Module } from "vuex"
import _ from "lodash"
import { LegalCompliance } from "@/voterData/interfaces/LegalCompliance"
import { State } from "@/voterData/interfaces/state"

const initState = {
  intendedUse: "",
  authorizationToPurchase: true,
  tosAcceptance: true
}

export const legalCompliance = <Module<LegalCompliance, State>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    intendedUse: (state) => state.intendedUse,
    authorizationToPurchase: (state) => state.authorizationToPurchase,
    tosAcceptance: (state) => state.tosAcceptance
  },
  mutations: {
    updateIntendedUse: (state, value: string) => {
      state.intendedUse = value
    },
    updateAuthorizationToPurchase: (state, value: boolean) => {
      state.authorizationToPurchase = value
    },
    updateTosAcceptance: (state, value: boolean) => {
      state.tosAcceptance = value
    },
    clearState: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key as keyof LegalCompliance] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
