




































































import Component from "vue-class-component"
import { RequestedPayout, statuses } from "@robocent/components"
import { Emit, Prop, Ref } from "vue-property-decorator"
import Vue from "vue"
import { PayoutType } from "@/enums/payoutType"

@Component
export default class RequestedPayoutsTable extends Vue {
  @Ref("table") table: any
  @Prop() payouts!: RequestedPayout[]
  @Prop() loading!: boolean
  @Prop() payoutType!: PayoutType

  get isAgentPayout() {
    return this.payoutType === PayoutType.agent
  }

  actionsAvailable(status: string) {
    return status === statuses.open
  }

  getType(status: string) {
    if (status === statuses.open) {
      return "is-warning"
    }

    if (status === statuses.dismissed) {
      return "is-danger"
    }

    return "is-success"
  }

  @Emit()
  onDismiss() {}

  @Emit()
  onPay() {}
}
