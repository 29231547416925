import axios from "axios"
import {
  AgentPayout,
  CampaignPayout,
  RequestedPayout
} from "@robocent/components"

export class AgentPayoutDao {
  static async getAgentsPayout(params?: {
    paid: boolean
  }): Promise<AgentPayout[]> {
    return (await axios.get(`/agent/payouts`, { params })).data
  }

  static async getAgentPayout(userId: string): Promise<CampaignPayout[]> {
    return (await axios.get(`/agent/payouts/${userId}`)).data
  }

  static async markPayoutsAsPaid(data: object) {
    return await axios.post(`/agent/payouts/mark-as-paid`, { ...data })
  }

  static async getRequestedPayouts(): Promise<RequestedPayout[]> {
    return (await axios.get(`/agent/requested-payouts`)).data
  }

  static async dismissPayout(id: number) {
    return await axios.put(`/agent/requested-payouts/${id}/dismiss`)
  }
}
