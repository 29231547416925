








import Component from "vue-class-component"
import { Upload } from "@robocent/components"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import StraticsAudioFiles from "@/components/campaign/voiceAdminReview/configure/StraticsAudioFiles.vue"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { ExtendedUpload } from "@/interfaces/extendedUpload"

@Component({
  components: { StraticsAudioFiles }
})
export default class ConfigureAudio extends OverviewMixin {
  uploads: ExtendedUpload[] = []

  created() {
    this.prepareFiles()
  }

  async prepareFiles() {
    const uploads: {
      liveRecording: Upload
      vmRecording: Upload
    } = this.campaign.uploads as {
      liveRecording: Upload
      vmRecording: Upload
    }

    const orgId = this.$route.params.orgId

    if (uploads.liveRecording?._id) {
      const liveRecordingWithUrl = {
        ...uploads.liveRecording,
        url: await UploadsDao.getLinkForUpload(orgId, uploads.liveRecording._id)
      }

      this.uploads.push(liveRecordingWithUrl)
    }

    if (uploads.vmRecording?._id) {
      const vmRecordingWithUrl = {
        ...uploads.vmRecording,
        url: await UploadsDao.getLinkForUpload(orgId, uploads.vmRecording._id)
      }
      this.uploads.push(vmRecordingWithUrl)
    }
  }
}
