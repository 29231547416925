
















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import StepTemplate from "@/voterData/StepTemplate.vue"

@Component({
  components: {
    StepTemplate
  }
})
export default class TosAcceptance extends VueComponent {
  get tosAcceptance() {
    return this.$store.getters["voterData/legalCompliance/tosAcceptance"]
  }

  set tosAcceptance(value: boolean) {
    this.$store.commit("voterData/legalCompliance/updateTosAcceptance", value)
  }
}
