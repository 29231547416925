

















































import { UserDao } from "@/services/dao/userDao"
import Component from "vue-class-component"
import { Pagination } from "@/utils/pagination"
import UserItem from "@/components/users/UserItem.vue"
import { GenericFilter, User } from "@robocent/components"
import OrderingFilterMixin from "@/mixins/OrderingFilterMixin"
import { OrderBy } from "@/enums/orderBy"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import { BDialogConfig } from "buefy/types/components"

@Component({
  components: { SearchItem, GenericFilter, UserItem }
})
export default class AllUsers extends OrderingFilterMixin {
  pagination = new Pagination()
  users: User[] = []
  search = ""

  created() {
    this.getUsers()
  }

  async getUsers(searchQuery?: object) {
    this.params = {
      page: this.pagination.current,
      orderBy: this.orderBy,
      ...searchQuery
    }

    this.request(
      async () => {
        const {
          data: { data, total, limit }
        } = await UserDao.getAllUsers(this.params)
        this.users = data
        this.pagination.total = total
        this.pagination.perPage = limit
        this.updateQuery(this.params)
      },
      null,
      "Failed loading users"
    )
  }

  async verifyUser(id: string) {
    await this.confirm(
      "Verifying this user will unlock their account for full use of the RoboCent application",
      {
        title: "Are you sure?",
        confirmText: "Confirm",
        type: "is-warning",
        hasIcon: true
      } as BDialogConfig
    )
    await this.request(
      async () => {
        await UserDao.verifyUser(id)
        await this.getUsers()
      },
      null,
      "An error occurred."
    )
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getUsers()
  }

  onSearch(query: object) {
    this.pagination.current = 1
    this.getUsers(query)
  }

  onToggleFilter(filter: OrderBy) {
    this.orderBy = filter
    this.getUsers()
  }
}
