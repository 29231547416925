



































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import OrganizationActiveUsers from "@/views/organization/OrganizationActiveUsers.vue"
import OrganizationInvitedUsers from "@/views/organization/OrganizationInvitedUsers.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: {
    OrganizationInvitedUsers,
    OrganizationActiveUsers
  }
})
export default class OrganizationUsers extends VueComponent {
  created() {
    EventBus.inviteUserModal$
      .pipe(whileComponentMounted(this))
      .subscribe(this.openInviteUserModal)
  }

  openInviteUserModal() {
    this.$buefy.dialog.prompt({
      message: "What's their email address?",
      inputAttrs: {
        placeholder: "e.g. jsmith@example.com",
        type: "email",
        required: true
      },
      trapFocus: true,
      onConfirm: (email) => this.inviteUser(email)
    })
  }

  async inviteUser(email: string) {
    await this.request(async () => {
      await OrganizationDao.inviteUser(this.$route.params.orgId, email)
      this.openToast("User Invited")
      EventBus.getUsers$.next()
    })
  }
}
