

































































import SearchItem from "./SearchItem.vue"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { Organization } from "@robocent/components"
import Component from "vue-class-component"
import ChooseItem from "./ChooseItem.vue"
import ChooseRow from "./ChooseRow.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import CreateNewOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"
import OrganizationsEmptyMessage from "@/components/organization/OrganizationsEmptyMessage.vue"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: {
    OrganizationsEmptyMessage,
    PaginationItem,
    ChooseRow,
    ChooseItem,
    SearchItem
  }
})
export default class CampaignOrganization extends SchedulerUtilsMixin {
  autoSelectedOrg: Organization = null!
  organizations: Organization[] = []

  async created() {
    this.checkLastOrg()
    await this.getOrganizations()
    this.checkFirstOrganization()
    EventBus.getOrganizations$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getOrganizations)
    EventBus.organizationCreated$
      .pipe(whileComponentMounted(this))
      .subscribe((org) => {
        this.select(org as Organization)
      })
  }

  async getOrganizations() {
    const params = {
      q: this.search,
      limit: this.pagination.perPage,
      page: this.pagination.current
    }
    const getOrganizations = this.$store.getters.super
      ? OrganizationDao.getAllOrganizations.bind(this)
      : OrganizationDao.getOrganizations.bind(this)

    await this.request(async () => {
      const { data, total } = await getOrganizations(params)
      this.pagination.total = total
      this.organizations = data.filter(
        (org: Organization) => org._id !== this.autoSelectedOrg?._id
      )
    })
  }

  checkLastOrg() {
    const lastOrganization = this.$store.getters.lastOrg

    if (lastOrganization) {
      this.autoSelectedOrg = lastOrganization
      this.select(lastOrganization)
    }
  }

  checkFirstOrganization() {
    if (this.autoSelectedOrg) return
    const firstOrganization = this.organizations[0]

    if (firstOrganization) {
      this.select(firstOrganization)
    }
  }

  isActive(org: Organization) {
    return this.organizationId === org._id
  }

  select(org: Organization) {
    this.$set(this.campaign, "organization", org)
    this.$store.commit("setLastVisitedOrganization", org)
  }

  onSearch(search: string) {
    this.search = search
    this.pagination.current = 1
    this.getOrganizations()
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getOrganizations()
  }

  openCreateOrganizationModal() {
    this.$buefy.modal.open({
      width: 640,
      component: CreateNewOrganizationModal,
      parent: this
    })
  }
}
