export enum ReportType {
  detailedReport = "detailedReport",
  initialMessages = "initialMessages",
  conversations = "conversations",
  surveyResponses = "surveyResponses",
  list = "list",
  full = "full"
}

export enum ReportStatus {
  processing = "processing",
  done = "done",
  failed = "failed"
}

export interface CampaignReport {
  id: number
  createdAt: Date
  campaignTextUUID: number
  type: ReportType
  key?: string
  url?: string
  status: ReportStatus
}
