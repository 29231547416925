












import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Campaign } from "@robocent/components"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import DeleteCampaignModal from "../modals/DeleteCampaignModal.vue"
import { EventBus } from "@/services/eventBus"
import { VueComponent } from "@/utils/VueComponent"
import { CampaignDao } from "@/services/dao/campaignDao"

@Component({
  components: { DeleteCampaignModal }
})
export default class DeleteCampaignButton extends VueComponent {
  @Prop() campaign!: Campaign | VoiceCampaign

  async onDeleteCampaign() {
    await this.confirm("Are you sure you want to delete this campaign?")
    await this.deleteCampaign()
  }

  async deleteCampaign() {
    await this.request(async () => {
      await CampaignDao.deleteCampaign(
        this.campaign.organization._id,
        this.campaign.uuid
      )
      EventBus.getCampaigns$.next()
    }, "The campaign was successfully deleted")
  }
}
