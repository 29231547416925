import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { NavigationGuardNext, Route } from "vue-router/types/router"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"

export class Resolvers {
  static getOrganizations(to: Route, from: Route, next: NavigationGuardNext) {
    to.meta["organizations"] = OrganizationDao.getOrganizations.bind(null)

    next()
  }

  static getAllOrganizations(
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ) {
    to.meta["organizations"] = OrganizationDao.getAllOrganizations.bind(null)

    next()
  }

  static getPhone(to: Route, from: Route, next: NavigationGuardNext) {
    to.meta["phones"] = PhoneNumberDao.getPhone.bind(null, to.params.orgId)

    next()
  }
}
