


























































import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import RequestTable from "@/voterData/components/RequestTable.vue"
import { VoterDataQuery } from "@/voterData/interfaces/VoterDataQuery"
import { Prop, Ref, Watch } from "vue-property-decorator"
import { RequestStatus } from "@/voterData/enums/requestStatus"
import { VoterDataRequest } from "@/voterData/interfaces/VoterDataRequest"
import _ from "lodash"
import ConfirmPurchasingModal from "@/voterData/modals/ConfirmPurchasingModal.vue"
import VoterDataRequestMixin from "@/voterData/mixins/VoterDataRequestMixin"
import ViewCriteriaModal from "@/voterData/modals/ViewCriteriaModal.vue"
import { VoterDataDao } from "@/services/dao/voterDataDao"
import { statuses } from "@robocent/components"
import { VoterDataPricing } from "@/voterData/interfaces/VoterDataPricing"
import { EventBus } from "@/services/eventBus"

@Component({
  components: { RequestTable, StepTemplate }
})
export default class QueryItem extends VoterDataRequestMixin {
  @Ref("countdown") countdown: any
  @Prop({ default: () => ({}) }) query!: VoterDataQuery
  @Prop() pricing!: VoterDataPricing
  leftTime = 10 * 1000

  checkedRows: VoterDataRequest[] = []

  @Watch("query.requests")
  watchQueryRequests() {
    this.setCheckedRows()
    if (this.requestsToUpdate.length > 0) {
      this.countdown.startCountdown("restart")
    }
  }

  get isFeatureEnabled() {
    return this.query.organization?.feature?.enabled
  }

  get queryTitle() {
    return `#${this.query.id} ${this.query.organization?.name} - State ${this.query.state}`
  }

  get querySubtitle() {
    return this.$options.filters!.date(this.query.createdAt)
  }

  get requestsToUpdate() {
    return this.query.requests.filter((request) =>
      this.isRefreshAvailable(request)
    )
  }

  get descriptions() {
    return [
      { title: "Submitted: ", subtitle: this.querySubtitle },
      { title: "Intended Use: ", subtitle: this.query.intendedUse },
      {
        title: "Authorization to Purchase Voter Data: ",
        subtitle: this.query.authorizationToPurchase ? "I accept" : "I decline"
      },
      {
        title: "ToS Notification and Acceptance: ",
        subtitle: this.query.authorizationToPurchase ? "I agree" : "I decline"
      }
    ]
  }

  mounted() {
    this.setCheckedRows()
    this.updateRequests()
  }

  setCheckedRows() {
    this.checkedRows = this.isFeatureEnabled
      ? [
          ...this.query.requests.filter(
            (entry) =>
              entry.checkout.status === RequestStatus.completed &&
              !entry.invoice
          )
        ]
      : []
  }

  async confirmPurchasing() {
    this.$buefy.modal.open({
      width: 450,
      component: ConfirmPurchasingModal,
      parent: this,
      props: {
        items: this.checkedRows,
        pricing: this.pricing
      },
      events: {
        confirm: this.purchaseVoterData
      }
    })
  }

  async purchaseVoterData() {
    await this.request(async () => {
      const {
        id: invoiceId,
        status,
        hosted_invoice_url: invoiceUrl
      } = (
        await VoterDataDao.purchaseData(
          this.query.orgId,
          _.map(this.checkedRows, "id")
        )
      ).data
      if (status !== statuses.paid) {
        window.open(invoiceUrl, "_blank")
        console.warn(invoiceId, invoiceUrl)
      }
      EventBus.getVoterDataRequests$.next()
    }, "Invoice has been created successfully.")
  }

  updateRequests() {
    if (this.requestsToUpdate.length > 0) {
      EventBus.getVoterDataRequests$.next()
    }
  }

  viewCriteria() {
    this.$buefy.modal.open({
      width: 600,
      component: ViewCriteriaModal,
      parent: this,
      props: {
        query: this.query
      }
    })
  }
}
