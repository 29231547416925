














import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { Watch } from "vue-property-decorator"
import { DatasetFields } from "@/voterData/enums/datasetFields"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"
import _ from "lodash"

@Component({
  components: {
    StepTemplate
  }
})
export default class Ethnicity extends VoterDataUtilsMixin {
  ethnicities: FieldInfo[] = []

  get ethnicity() {
    return this.$store.getters["voterData/demographicsInfo/ethnicity"]
  }
  set ethnicity(value: string[]) {
    this.$store.commit("voterData/demographicsInfo/updateEthnicity", value)
  }

  mounted() {
    this.getEthnicities()
  }

  async getEthnicities() {
    await this.request(async () => {
      this.ethnicities = await this.getStepData(DatasetFields.ethnicity)

      if (!this.ethnicity.length) {
        this.ethnicity = _.map(this.ethnicities, "value")
      }
    })
  }

  @Watch("ethnicity")
  watchEthnicity(nextValue: string[], prevValue: string[]) {
    if (!_.isEqual(nextValue, prevValue)) {
      this.updateQuery()
    }
  }
}
