import { RouteConfig } from "vue-router"
import SharedCampaignResults from "@/views/shared/SharedCampaignResults.vue"

export const sharedRoutes: Array<RouteConfig> = [
  {
    path: "campaign/:campaignUUID",
    name: "Shared Campaign",
    component: SharedCampaignResults
  }
]
