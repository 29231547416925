











import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import MarkPayoutsAsPaid from "@/components/payouts/MarkPayoutsAsPaid.vue"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"
import SalesPayoutMixin from "@/mixins/salesPortal/SalesPayoutMixin"

@Component({
  components: {
    MarkPayoutsAsPaid
  }
})
export default class MarkSalesPayoutsAsPaid extends SalesPayoutMixin {
  @Prop() userId!: string
  @Prop() name!: number
  amount = 0

  created() {
    this.getSalesPayout()
  }

  async getSalesPayout() {
    const params = {
      date: this.selectedDate.toDateString()
    }

    await this.request(async () => {
      this.amount = (
        await SalesPortalDao.getSalesEarningsByUserId(this.userId, params)
      ).totalEarnings
    })
  }

  async onConfirm(check: string) {
    await this.request(async () => {
      const data = {
        date: this.selectedDate.toDateString(),
        check
      }

      await SalesPortalDao.markPayoutsAsPaid(this.userId, data)

      this.$parent.close()
      EventBus.getSalesPayouts$.next({
        status: PartnerPayoutStatus.open,
        date: this.selectedDate.toDateString()
      })
    }, "The Payouts were paid successfully")
  }
}
