





































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import FeatureRequestItem from "@/components/feature/FeatureRequestItem.vue"
import { FeatureDao } from "@/services/dao/featureDao"
import { OrganizationFeatureRequest } from "@/interfaces/feature/organizationFeatureRequest"
import { Pagination } from "@/utils/pagination"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import { GenericFilter } from "@robocent/components"
import { FeatureRequestStatus } from "@/interfaces/feature/featureRequestStatus"
import _ from "lodash"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: { PaginationItem, FeatureRequestItem, GenericFilter }
})
export default class FeatureRequestList extends VueComponent {
  featureRequests: OrganizationFeatureRequest[] = []
  pagination = new Pagination(0, 1, 9)
  filters = [
    {
      label: "All",
      option: "all",
      values: Object.keys(FeatureRequestStatus)
    },
    {
      label: "Pending",
      option: FeatureRequestStatus.pending,
      values: [FeatureRequestStatus.pending]
    },
    {
      label: "Approved",
      option: FeatureRequestStatus.approved,
      values: [FeatureRequestStatus.approved]
    },
    {
      label: "Rejected",
      option: FeatureRequestStatus.rejected,
      values: [FeatureRequestStatus.rejected]
    }
  ]
  filter = [FeatureRequestStatus.pending]

  get filterLabel() {
    return this.filters.find((filter) => _.isEqual(filter.values, this.filter))
      ?.label
  }

  created() {
    this.getFeatureRequests()
    EventBus.getFeatures$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getFeatureRequests)
  }

  async getFeatureRequests() {
    const options = {
      page: this.pagination.current,
      limit: this.pagination.perPage,
      status: this.filter
    }
    await this.request(async () => {
      const { data, total } = await FeatureDao.getAllRequests(options)
      this.featureRequests = data
      this.pagination.total = total
    })
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getFeatureRequests()
  }

  onToggleFilter(filter: FeatureRequestStatus[]) {
    this.filter = filter
    this.pagination.current = 1
    this.getFeatureRequests()
  }
}
