






import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import ReportsTable from "@/components/report/voiceCampaign/ReportsTable.vue"

@Component({
  components: {
    ReportsTable,
    Card
  }
})
export default class VoiceCampaignReports extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() isPublic!: boolean
}
