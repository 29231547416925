















import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import CampaignMixin from "@/mixins/campaign/CampaignMixin"
import CampaignEstimatedCost from "@/components/scheduler/textElements/TextCampaignEstimatedCost.vue"
import TextCampaignCheckList from "@/components/campaign/textAdminReview/TextCampaignCheckList.vue"
import TextOverviewMixin from "@/components/campaign/textAdminReview/TextOverviewMixin"

@Component({
  components: {
    TextCampaignCheckList,
    CampaignEstimatedCost
  }
})
export default class ReviewTextCampaign extends mixins(
  CampaignMixin,
  TextOverviewMixin
) {
  @Prop() isMergedFieldsEnabled!: boolean
}
