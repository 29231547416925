











import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { ValidationObserver } from "vee-validate"
import { UserDao } from "@/services/dao/userDao"
import UserLevel from "@/components/modals/UserLevelModal.vue"
import { UserLevel as IUserLevel } from "@/enums/userLevel"
import { Prop } from "vue-property-decorator"
import { User } from "@robocent/components"

@Component({
  components: {
    UserLevel,
    ValidationObserver
  }
})
export default class AdminUserLevelModal extends VueComponent {
  @Prop() user!: User

  get level() {
    return this.user.app_metadata?.level
  }

  get isInternalAgent() {
    return this.user.app_metadata?.internal
  }

  async onConfirm(data: { level: IUserLevel; internalAgent: boolean }) {
    await this.request(async () => {
      await UserDao.updateUserLevelById(this.user.user_id, data)
      this.$parent.close()
      this.$emit("onConfirm")
    }, "Successfully saved role")
  }
}
