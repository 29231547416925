import { Module } from "vuex"
import _ from "lodash"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { CampaignDao } from "@/services/dao/campaignDao"
import { CampaignStatus, IdentityStatus } from "@robocent/components"

const initState = {
  campaign: null,
  list: null,
  loading: false,
  status: {}
}

export const textConfiguratorStore = <Module<any, any>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    list: (state) => state.list,
    campaign: (state) => state.campaign,
    status: (state) => state.status,
    loading: (state) => state.loading
  },
  mutations: {
    setCampaign: (state, campaign) => {
      state.campaign = _.cloneDeep(campaign)
    },
    setList: (state, list) => {
      state.list = _.cloneDeep(list)
    },
    setStatus: (state, status) => {
      state.status = _.cloneDeep(status)
    },
    setLoading: (state, loading) => {
      state.loading = loading
    },
    clearCampaign: (state) => {
      state.campaign = null
    }
  },
  actions: {
    updateCampaign: async (context, { orgId, campaignUUID }) => {
      try {
        context.commit("setLoading", true)
        const campaign = await CampaignDao.getCampaign(orgId, campaignUUID)

        if (campaign.listId) {
          const list = (
            await UploadsDao.getUpload(orgId, campaign.listId as string)
          ).data
          context.commit("setList", list)
        }

        context.commit("setCampaign", campaign)
      } finally {
        context.commit("setLoading", false)
      }
      context.dispatch("calculateStatus")
    },
    calculateStatus: async (context) => {
      try {
        context.commit("setLoading", true)
        const campaign = context.getters.campaign
        const status: any = {}

        status.isScheduleReady = Boolean(campaign.metadata.scheduleId)
        status.isIdentityCreated = Boolean(campaign.organization?.identity.link)
        status.isAccountRegistered = Boolean(
          campaign.organization.identity?.status === IdentityStatus.submitted
        )
        status.isPhoneListReady = Boolean(campaign.metadata.listId)

        status.isJobReady = Boolean(campaign?.metadata?.jobId)
        status.isCampaignApproved = campaign.status !== CampaignStatus.pending

        context.commit("setStatus", status)
      } finally {
        context.commit("setLoading", false)
      }
    }
  }
}
