

























import { links } from "@/data/links"
import { VueComponent } from "@/utils/VueComponent"
import { Component } from "vue-property-decorator"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: {
    ChooseItem
  }
})
export default class SupportGuides extends VueComponent {
  docsLinks = links
}
