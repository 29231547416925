











import { CampaignConfigureService } from "@/services/voice/campaignConfigureService"
import Component from "vue-class-component"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import { ApproveVoiceCampaign } from "@/interfaces/voiceCampaign/approveVoiceCampaign"
import ApproveCampaignModal from "@/components/modals/ApproveCampaignModal.vue"
import { Feature } from "@/interfaces/feature/feature"

@Component({
  components: {
    ApproveCampaignModal
  }
})
export default class ConfirmAndApproveModal extends OverviewMixin {
  campaignConfigureService = new CampaignConfigureService()

  data: ApproveVoiceCampaign = null!
  features = Feature

  async created() {
    this.setInitialData()
    await this.initCallCost()
  }

  setInitialData() {
    this.data = {
      straticsId: "",
      leads: null,
      multis: [],
      fixedDiscounts: [],
      percentDiscounts: [],
      fees: [],
      coupon: null,
      pureCost: 0
    } as ApproveVoiceCampaign
  }

  async initCallCost() {
    await this.request(
      async () => {
        const data = await BillingDao.getCallCost(this.campaign._id!)
        const maxPorts = this.campaignConfigureService.maxPorts
        const transferMaxPorts =
          this.campaignConfigureService.getTransferMaxPorts(this.campaign)
        this.data = Object.assign(this.data, data, {
          maxPorts,
          transferMaxPorts
        })
        this.data.active =
          this.campaign.organization?.meta?.postPayEnabled &&
          this.campaign.organization?.meta?.autoPayEnabled
        this.data.straticsId = this.campaign.meta?.straticsId
      },
      undefined,
      "Failed getting call cost"
    )
  }
}
