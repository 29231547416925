


























import { VueComponent } from "@/utils/VueComponent"
import { CampaignType } from "@robocent/components"
import { Component, Prop } from "vue-property-decorator"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: {
    ChooseItem
  }
})
export default class TextCampaignSendingPreference extends VueComponent {
  @Prop() campaignType!: CampaignType

  get isCampaignTypeSelf() {
    return this.campaignType === CampaignType.self
  }

  get isCampaignTypeManaged() {
    return this.campaignType === CampaignType.managed
  }
}
