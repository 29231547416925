import Vue from "vue"
import Vuex, { Payload } from "vuex"
import VuexPersistence from "vuex-persist"
import { baseStore } from "@/store/modules/base"
import { textSchedulerStore } from "@/store/modules/textScheduler"
import { requestsStore } from "@/store/modules/requests"
import LogRocket from "logrocket"
import createPlugin from "logrocket-vuex"
import { voiceSchedulerStore } from "@/store/modules/voiceScheduler"
import { voiceConfiguratorStore } from "@/store/modules/voiceConfigurator"
import voterDataStore from "@/voterData/store"
import { partnerPortalStore } from "@/store/modules/partnerPortal"
import { textConfiguratorStore } from "@/store/modules/textConfigurator"
import { salesPortalStore } from "@/store/modules/salesPortal"

Vue.use(Vuex)

const logrocketPlugin = createPlugin(LogRocket)

const syncState = [
  "setUser",
  "setLevel",
  "setLastVisitedOrganization",
  "clearOrganization",
  "clearLastVisitedOrganization"
]

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  filter: (mutation: Payload) => syncState.includes(mutation.type),
  modules: ["base"],
  restoreState: (key, storage: any) => {
    let value = storage?.getItem(key)
    if (typeof value === "string") {
      value = JSON.parse(value || "{}")
    }
    if (value?.base?.auth) {
      value.base.auth = false
    }
    return value || {}
  }
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin, logrocketPlugin],
  modules: {
    base: baseStore,
    textScheduler: textSchedulerStore,
    voiceScheduler: voiceSchedulerStore,
    requests: requestsStore,
    voiceConfigurator: voiceConfiguratorStore,
    textConfigurator: textConfiguratorStore,
    voterData: voterDataStore,
    partnerPortal: partnerPortalStore,
    salesPortal: salesPortalStore
  }
})
