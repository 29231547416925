





































































































































import Component from "vue-class-component"
import {
  Campaign,
  CampaignService,
  CampaignType,
  InputWithValidation
} from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import ApproveCoupon from "@/components/campaign/voiceAdminReview/elements/approve/ApproveCoupon.vue"
import ApproveFee from "@/components/campaign/voiceAdminReview/elements/approve/ApproveFee.vue"
import ApproveDiscount from "@/components/campaign/voiceAdminReview/elements/approve/ApproveDiscount.vue"
import ApproveMultipliers from "@/components/campaign/voiceAdminReview/elements/approve/ApproveMultipliers.vue"
import ApprovePercentDiscount from "@/components/campaign/voiceAdminReview/elements/approve/ApprovePercentDiscount.vue"
import CommissionTag from "@/components/campaign/voiceAdminReview/elements/approve/CommissionTag.vue"
import LeadsDiscountTag from "@/components/campaign/voiceAdminReview/elements/approve/LeadsDiscountTag.vue"
import Card from "@/components/elements/Card.vue"
import ApproveMixin from "@/components/campaign/ApproveMixin"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import { Emit, Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import { PartnerType } from "@/enums/partnerPortal/partnerType"
import { ApproveTextCampaign } from "@/interfaces/approveTextCampaign"
import { ApproveVoiceCampaign } from "@/interfaces/voiceCampaign/approveVoiceCampaign"
import { PartnerPayout } from "@/interfaces/partnerPortal/partnerPayout"
import { UnicodeUtil } from "@/utils/unicodeUtil"
import UnicodeInfo from "../elements/UnicodeInfo.vue"

@Component({
  components: {
    Card,
    InputWithValidation,
    ValidationObserver,
    CommissionTag,
    LeadsDiscountTag,
    ApproveCoupon,
    ApproveFee,
    ApproveDiscount,
    ApprovePercentDiscount,
    ApproveMultipliers,
    UnicodeInfo
  }
})
export default class ApproveCampaignModal extends ApproveMixin {
  @Prop() loading!: boolean
  unicodeUtil = new UnicodeUtil()

  get isPartnerTypeCommission() {
    return this.partnerService?.service?.type === PartnerType.commission
  }

  get isSms() {
    return [CampaignType.managed, CampaignType.self].includes(
      this.campaign.type as unknown as CampaignType
    )
  }

  get isTransfer() {
    return this.campaign.type === VoiceCampaignType.transfer
  }

  get isSurvey() {
    return this.campaign.type === VoiceCampaignType.survey
  }

  async created() {
    await this.setPartnerRate()
    this.calculate()
    this.detectUnicodeInScripts()
  }

  onConfirm() {
    const campaignData = {
      ...this.data
    } as (ApproveTextCampaign | ApproveVoiceCampaign) & {
      partnerPayout: PartnerPayout
    }

    if (this.partnerService?.service) {
      campaignData.partnerPayout = this.getPartnerPayout()
    }
    EventBus.approveCampaign$.next(campaignData)
    this.clear()
    this.$parent.$parent.close()
  }

  getPartnerPayout() {
    return {
      userId: this.partnerService?.partner?.id,
      feature: this.partnerService?.service?.feature,
      type: this.partnerService?.service?.type,
      rate: this.partnerService?.service?.rate,
      amount:
        this.partnerService?.service?.type === PartnerType.discount
          ? Math.round(this.totalDiscounts)
          : 0
    }
  }

  detectUnicodeInScripts() {
    const { service, scripts } = this.campaign as Campaign

    if (service === CampaignService.sms) {
      for (const script of scripts) {
        if (script.mms) {
          this.unicodeUtil.clearUnicodeChars()
          continue
        }

        this.unicodeUtil.detectUnicode(script.text)
      }
    }
  }

  @Emit()
  clear() {}
}
