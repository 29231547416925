






















import Component from "vue-class-component"
import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { Organization, User } from "@robocent/components"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { Prop, Watch } from "vue-property-decorator"

@Component({
  components: {
    ChooseItem
  }
})
export default class TextCampaignDefaultAgents extends TextSchedulerUtilsMixin {
  @Prop() orgId!: string
  users: User[] = []

  formatName(user: User) {
    return `${user.user_metadata?.firstName} ${user.user_metadata?.lastName}`
  }

  created() {
    this.getUsers()
  }

  async getUsers() {
    await this.request(async () => {
      const { users } = (
        await OrganizationDao.getOrganizationWithUsers(this.orgId)
      ).data
      this.users = users
      this.$set(this.campaign, "defaultAgents", users)
    })
  }

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      this.getUsers()
    }
  }
}
