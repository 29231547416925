import moment from "moment"
import _ from "lodash"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { timezones } from "@robocent/components"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"

export class ScheduleService {
  async createSchedule(campaign: VoiceCampaign) {
    const enabled = this.populateActiveWeekDay(campaign)
    const schedule = this.populateSchedule(campaign)
    const data = this.prepareRequestObject(schedule, enabled)

    return await CampaignConfigureDao.createSchedule(campaign._id!, data)
  }

  populateSchedule(campaign: VoiceCampaign) {
    return Object.assign(
      {
        schedule_name: campaign.humanId,
        schedule_timezone: this.convertTimeZone(campaign.timezone),
        is_global: 1
      },
      this.setDayValues(this.formatStartTime(campaign), this.formatEndTime())
    )
  }

  setDayValues(start: Date, end: Date) {
    const schedule: any = {}
    schedule.mon_start = start
    schedule.mon_end = end

    schedule.tue_start = start
    schedule.tue_end = end

    schedule.wed_start = start
    schedule.wed_end = end

    schedule.thu_start = start
    schedule.thu_end = end

    schedule.fri_start = start
    schedule.fri_end = end

    schedule.sat_start = start
    schedule.sat_end = end

    schedule.sun_start = start
    schedule.sun_end = end

    return schedule
  }

  formatStartTime(campaign: VoiceCampaign) {
    return new Date(
      moment.utc(campaign.time).tz(campaign.timezone).format("lll")
    )
  }

  formatEndTime() {
    const date = new Date()
    date.setHours(21)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }

  convertTimeZone(locale: string) {
    return `US/${
      timezones.find((timezone: any) => timezone.locale === locale)!.name
    }`
  }

  populateActiveWeekDay(campaign: VoiceCampaign) {
    const enabled: any = {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false
    }

    const dayNumber = moment(this.formatStartTime(campaign)).isoWeekday() - 1
    const day = Object.keys(enabled)[dayNumber]
    enabled[day] = true

    return enabled
  }

  returnActiveWeekDays(data: any) {
    const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
    const enabled: any = {}

    days.forEach(
      (day) => (enabled[day] = data[`${day}_start`] !== data[`${day}_end`])
    )

    return enabled
  }

  prepareRequestObject(data: any, activeWeekDay: string) {
    const schedule = _.cloneDeep(data)
    this.prepareDayDate(schedule, "mon", activeWeekDay)
    this.prepareDayDate(schedule, "tue", activeWeekDay)
    this.prepareDayDate(schedule, "wed", activeWeekDay)
    this.prepareDayDate(schedule, "thu", activeWeekDay)
    this.prepareDayDate(schedule, "fri", activeWeekDay)
    this.prepareDayDate(schedule, "sat", activeWeekDay)
    this.prepareDayDate(schedule, "sun", activeWeekDay)
    return schedule
  }

  prepareDayDate(source: any, key: string, activeWeekDay: any) {
    const startKey = `${key}_start`
    const endKey = `${key}_end`

    source[startKey] = moment(source[startKey]).format("HH:mm:00")

    if (activeWeekDay[key]) {
      source[endKey] = moment(source[endKey]).format("HH:mm:00")
    } else {
      source[endKey] = source[startKey]
    }
  }

  convertTime(time: string) {
    return moment(time, "HH:mm:ss").format("hh:mm A")
  }

  filterNotActiveDays(days: any): any[] {
    return Object.entries(days)
      .filter(([, value]) => value)
      .map(([key]) => key)
  }

  getTime(key: string, schedule: any) {
    const start = this.convertTime(schedule[`${key}_start`])
    const end = this.convertTime(schedule[`${key}_end`])

    return `${start} - ${end}`
  }
}
