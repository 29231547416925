import { Module } from "vuex"
import _ from "lodash"
import { State } from "@/voterData/interfaces/state"
import { SubmitRequestStep } from "@/voterData/interfaces/SubmitRequestStep"
import { CountTypes } from "@/voterData/enums/countTypes"
import { VoterDataDao } from "@/services/dao/voterDataDao"

const initState = {
  fileName: "",
  recordsAmount: {
    [CountTypes.phone]: 0,
    [CountTypes.mobile]: 0,
    [CountTypes.email]: 0,
    [CountTypes.individual]: 0
  }
}

export const submitRequestStep = <Module<SubmitRequestStep, State>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    fileName: (state) => state.fileName,
    recordsAmount: (state) => state.recordsAmount
  },
  actions: {
    async submitDataRequest(context, data) {
      return await VoterDataDao.submitDataRequest(data)
    }
  },
  mutations: {
    updateFileName: (state, value: string) => {
      state.fileName = value
    },
    updateRecordsAmount: (
      state,
      { type, value }: { type: CountTypes; value: number }
    ) => {
      state.recordsAmount[type] = value
    },
    clearState: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key as keyof SubmitRequestStep] = _.cloneDeep(initState as any)[
          key
        ]
      })
    }
  }
}
