



































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { OrganizationFeature } from "@/interfaces/feature/organizationFeature"
import { Prop } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { Feature } from "@/interfaces/feature/feature"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { FeatureDao } from "@/services/dao/featureDao"
import { EventBus } from "@/services/eventBus"

@Component({
  components: { ChooseItem, Card }
})
export default class OrganizationFeatureItem extends VueComponent {
  @Prop() feature!: OrganizationFeature

  get type() {
    return this.feature.enabled ? "is-success" : "is-danger"
  }

  get title() {
    if (this.feature.feature === Feature.voterData) {
      return "Buy Voter Data"
    }

    return this.feature.feature
  }

  get icon() {
    if (this.feature.feature === Feature.smsCampaign) {
      return "comment-dots"
    }

    if (this.feature.feature === Feature.voterData) {
      return "address-book"
    }

    return "wrench"
  }

  async enable() {
    await this.confirm(
      `Do you really want to enable ${this.feature.feature} for this organization?`
    )
    await this.request(async () => {
      await FeatureDao.enableOrganizationFeature(
        this.feature.organizationId,
        this.feature.feature
      )
      EventBus.getFeatures$.next()
    }, "Feature Enabled")
  }

  async disable() {
    await this.confirm(
      `Do you really want to disable ${this.feature.feature} for this organization?`
    )
    await this.request(async () => {
      await FeatureDao.disableOrganizationFeature(
        this.feature.organizationId,
        this.feature.feature
      )
      EventBus.getFeatures$.next()
    }, "Feature Disabled")
  }
}
