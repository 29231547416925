













































































































import { FileUtils } from "@/utils/fileUtils"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { CardTable, CardTableItem } from "@robocent/components"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { ExtendedUpload } from "@/interfaces/extendedUpload"
import Card from "@/components/elements/Card.vue"
import _ from "lodash"
import { AudioService } from "@/services/voice/audioService"
import { StraticsAudio } from "@/interfaces/voiceCampaign/straticsAudio"

interface CallUpload {
  name: string
  isUploaded: boolean
  file: ExtendedUpload
}

@Component({
  components: {
    Card,
    CardTable,
    CardTableItem
  }
})
export default class StraticsAudioFiles extends VueComponent {
  @Prop() campaign!: VoiceCampaign
  @Prop() uploads!: ExtendedUpload[]
  audioFiles: StraticsAudio[] = []
  callUploads: CallUpload[] = []
  audioService = new AudioService()

  created() {
    this.getUploadedFiles()
  }

  prepareUploads() {
    const callUploads = this.uploads.map((file) => {
      const name = FileUtils.normalizeAudioFileName(
        file,
        this.campaign.organization.name
      )
      const isUploaded = Boolean(
        this.audioFiles.find((audioFile) => audioFile.Name === name)
      )
      return {
        name,
        isUploaded,
        file
      }
    })

    this.callUploads = _.uniqBy(callUploads, "name")
  }

  async getUploadedFiles() {
    await this.request(async () => {
      this.audioFiles = await this.audioService.getUploadedAudioFiles(
        this.campaign
      )
      this.prepareUploads()
    })
  }

  async uploadFile(upload: CallUpload) {
    await this.request(
      async () => {
        await CampaignConfigureDao.uploadAudioFile(
          this.campaign._id as string,
          upload.file._id
        )
        this.getUploadedFiles()
      },
      undefined,
      "Error Uploading File to dialer"
    )
  }

  async deleteFile(upload: CallUpload) {
    await this.request(
      async () => {
        await CampaignConfigureDao.deleteAudioFile(
          this.campaign._id as string,
          upload.name
        )
        this.getUploadedFiles()
      },
      undefined,
      "Error Deleting Uploaded File from dialer"
    )
  }

  async downloadFile(url: string, fileKey: string, humanId: number) {
    const type = FileUtils.getFileExtFromName(fileKey)
    const name = `${humanId} ${this.campaign.organization.name}.${type}`

    await FileUtils.downloadFileByExternalUrl(url, name)
  }
}
