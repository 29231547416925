






import PublicPageHeader from "../../components/shared/PublicPageHeader.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { PublicTosAgreement, TosUtils } from "@robocent/components"
import { LocationService } from "@/services/locationService"
import { TosDao } from "@/services/dao/tosDao"
import { setSharedToken } from "@/config/axios"

@Component({
  components: { PublicPageHeader }
})
export default class SharedPage extends VueComponent {
  tosAgreement = false

  async created() {
    setSharedToken(this.$route.params.token)
    await this.checkTos()
  }

  async checkTos() {
    const tosUtils = new TosUtils()

    try {
      const publicTos: PublicTosAgreement | null =
        await TosDao.getPublicTosAgreement()
      const agreeTos = await tosUtils.checkIfPublicTosDeclined(publicTos)

      if (!publicTos?.agreeTOS && !agreeTos) {
        await tosUtils.publicTOSAgreement()
      }

      this.tosAgreement = true
    } catch (e) {
      console.error(e)
      new LocationService().refreshPage()
    }
  }
}
