



























































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { DndDao } from "../../services/dao/dndDao"
import { Campaign, DoNotDisturb } from "@robocent/components"
import { parsePhoneNumber } from "libphonenumber-js"
import AddDndModal from "../modals/AddDndModal.vue"
import { Pagination } from "@/utils/pagination"
import _ from "lodash"

@Component
export default class DndList extends VueComponent {
  pagination = new Pagination()
  data: DoNotDisturb[] = []
  q: string | null = null

  get isPaginated() {
    return this.pagination.total > this.pagination.perPage
  }

  created() {
    this.getDNDList()
  }

  campaignName(campaign: Campaign | null) {
    return campaign?.name || "-"
  }

  async getDNDList() {
    await this.request(async () => {
      const filter = {
        page: this.pagination.current,
        limit: this.pagination.perPage,
        q: this.q
      }
      const { data, total } = await DndDao.getDndList(
        this.$route.params.orgId,
        filter
      )
      this.pagination.total = total
      data.forEach((element: DoNotDisturb) => {
        const phoneNumber = parsePhoneNumber(element.phone, "US")
        element.phone = phoneNumber.formatNational()
      })
      this.data = data
    })
  }

  async removeDND(dndId: number) {
    await this.confirm("Do you really want to remove from Do Not Disturb List?")
    await this.request(async () => {
      await DndDao.removeDnd(this.$route.params.orgId, dndId)
      this.openToast(
        "Phone number successfully removed from Do Not Disturb List"
      )
      await this.getDNDList()
    })
  }

  async addDNDModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddDndModal,
      width: 600,
      props: {
        orgId: this.$route.params.orgId
      },
      events: {
        added: this.getDNDList
      }
    })
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getDNDList()
  }

  onFilterChange({ phone }: { phone: string }) {
    this.q = phone
    this.emitChange()
  }

  emitChange = _.debounce(() => this.getDNDList(), 300)
}
