




























import Component from "vue-class-component"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { AgentDao } from "@/services/dao/agentDao"
import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { Agent, Organization } from "@robocent/components"
import { Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import AgentsEmptyMessage from "@/components/agent/AgentsEmptyMessage.vue"

@Component({
  components: {
    ChooseItem,
    PaginationItem,
    AgentsEmptyMessage
  }
})
export default class TextCampaignActiveAgents extends TextSchedulerUtilsMixin {
  @Prop() orgId!: string
  agents: Agent[] = []

  formatName(agent: Agent) {
    return `${agent.user?.user_metadata?.firstName} ${agent.user?.user_metadata?.lastName}`
  }

  created() {
    this.getAgents()
    EventBus.getAgents$
      .pipe(whileComponentMounted(this))
      .subscribe(async () => {
        this.reset()
        await this.getAgents()
      })
  }

  async getAgents() {
    await this.request(async () => {
      const { data, total } = await AgentDao.getAgents(this.orgId, {
        limit: this.pagination.perPage,
        page: this.pagination.current
      })
      this.agents = data
      this.pagination.total = total
    })
  }

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      this.reset()
      this.getAgents()
    }
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getAgents()
  }

  onAgentSelect(agent: Agent) {
    const index = this.agentIndex(agent)
    if (index > -1) {
      return this.campaign.agents.splice(index, 1)
    }
    if (agent.deleted) {
      return
    }
    this.campaign.agents = [...this.campaign.agents, agent]
  }

  agentIndex(agent: Agent) {
    return this.campaign.agents.findIndex((item) => item.id === agent.id)
  }

  isActive(agent: Agent) {
    return Boolean(_.find(this.campaign.agents, { id: agent.id }))
  }
}
