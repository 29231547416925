


































import Component from "vue-class-component"
import AgentItem from "@/components/agent/AgentItem.vue"
import AgentActions from "@/components/agent/AgentActions.vue"
import InvitedAgentItem from "@/components/agent/InvitedAgentItem.vue"
import AgentsList from "@/components/agent/AgentsList.vue"
import InvitedAgentsList from "@/components/agent/InvitedAgentsList.vue"
import { VueComponent } from "@/utils/VueComponent"
import DeletedAgentsList from "@/components/agent/DeletedAgentsList.vue"

@Component({
  components: {
    DeletedAgentsList,
    InvitedAgentsList,
    AgentsList,
    InvitedAgentItem,
    AgentActions,
    AgentItem
  }
})
export default class OrganizationAgents extends VueComponent {}
