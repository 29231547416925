















































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import {
  CampaignReport,
  ReportStatus,
  ReportType
} from "@/interfaces/campaignReports"
import { EventBus } from "@/services/eventBus"
import { ReportDao } from "@/services/dao/reportDao"
import { FileUtils } from "@/utils/fileUtils"
import { SharedReportDao } from "@/services/dao/sharedReportDao"
import { CardTableItem } from "@robocent/components"

@Component({
  components: {
    CardTableItem
  }
})
export default class CampaignReportItem extends VueComponent {
  @Prop() label!: string
  @Prop() type!: ReportType
  @Prop() report!: CampaignReport | null
  @Prop() shared!: boolean
  @Prop() disabledGenerate!: boolean
  @Prop() campaignArchived!: boolean

  get isLoading() {
    return this.report?.status === ReportStatus.processing
  }

  get isDone() {
    return this.report?.status === ReportStatus.done
  }

  get isFailed() {
    return this.report?.status === ReportStatus.failed
  }

  async generate() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(async () => {
      this.shared
        ? await SharedReportDao.generateCampaignReport(
            orgId,
            campaignUUID,
            this.type
          )
        : await ReportDao.generateCampaignReport(orgId, campaignUUID, this.type)
      EventBus.getReports$.next()
    })
  }

  exportFile() {
    FileUtils.downloadFileByExternalUrl(
      this.report!.url as string,
      `${this.report?.campaignTextUUID}_${
        this.report?.type
      }.${FileUtils.getFileExtFromName(this.report!.key as string)}`
    )
  }
}
