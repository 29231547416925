import axios from "axios"

export class PhoneNumberDao {
  static async getPhones(orgId: string, params: any) {
    return (await axios.get(`/organizations/${orgId}/phones`, { params })).data
  }

  static async createPhone(orgId: string, data: any) {
    return await axios.post(`/organizations/${orgId}/phones/`, data)
  }

  static async enableNotify(orgId: string, numberId: string) {
    return await axios.post(
      `/organizations/${orgId}/phones/${numberId}/enable-notify`
    )
  }

  static async getPhone(orgId: string, numberId: string) {
    return await axios.get(`/organizations/${orgId}/phones/${numberId}`)
  }

  static async savePhone(orgId: string, numberId: string, data: any) {
    return await axios.put(`/organizations/${orgId}/phones/${numberId}`, data)
  }

  static async saveVoice(orgId: string, data: any) {
    return await axios.put(
      `/organizations/${orgId}/phones/${data.uuid}/voice/${data.voice.id}`,
      data.voice
    )
  }

  static async saveSms(orgId: string, data: any) {
    return await axios.put(
      `/organizations/${orgId}/phones/${data.uuid}/sms-tree/${data.smsTree.id}`,
      data.smsTree
    )
  }

  static async restorePhone(orgId: string, numberId: string) {
    return await axios.put(`/organizations/${orgId}/phones/${numberId}/restore`)
  }

  static async deletePhone(orgId: string, numberId: string) {
    return await axios.put(`/organizations/${orgId}/phones/${numberId}/delete`)
  }

  static async sendNotify(orgId: string, numberId: string, data: any) {
    return await axios.post(
      `/organizations/${orgId}/phones/${numberId}/notification`,
      data
    )
  }

  static async getSubscribers(orgId: string, numberId: string) {
    return (
      await axios.get(`/organizations/${orgId}/phones/${numberId}/subscribers`)
    ).data
  }
}
