




import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import Vue from "vue"

@Component
export default class ShowDeletedFilter extends Vue {
  @Prop({ default: false }) show!: boolean
  showDeleted = this.show

  @Watch("show")
  onShowChange() {
    this.showDeleted = this.show
  }

  submit() {
    this.$emit("changed", this.showDeleted)
  }
}
