import { RouteConfig } from "vue-router"
import CampaignAgentsSettings from "@/views/campaign/CampaignAgentsSettings.vue"
import CampaignResults from "@/views/campaign/CampaignResults.vue"
import EditCampaign from "@/views/campaign/EditCampaign.vue"
import CampaignScriptsSettings from "@/views/campaign/CampaignScriptsSettings.vue"
import TextCampaignAdminReview from "@/components/campaign/textAdminReview/TextCampaignAdminReview.vue"
import { isSuper } from "@/router/guards"
import RerunTextCampaign from "@/components/campaign/textCampaign/RerunTextCampaign.vue"

export const campaignRoutes: Array<RouteConfig> = [
  {
    path: "agents",
    name: "Campaign Agents",
    component: CampaignAgentsSettings
  },
  {
    path: "scripts",
    name: "Campaign Scripts",
    component: CampaignScriptsSettings
  },
  {
    path: "settings",
    name: "Campaign Settings",
    component: EditCampaign
  },
  {
    path: "report",
    name: "Campaign Report",
    component: CampaignResults
  },
  {
    path: "text-admin-review",
    beforeEnter: isSuper,
    name: "Text Campaign Admin Review",
    component: TextCampaignAdminReview
  },
  {
    path: "re-run",
    name: "ReRun Text Campaign",
    component: RerunTextCampaign
  }
]
