




































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: { Card, ValidationObserver }
})
export default class DeleteInvoiceModal extends VueComponent {
  async deleteInvoice() {
    this.$emit("deleted")
    this.$parent.close()
  }
}
