






























































































import Component from "vue-class-component"
import { Watch } from "vue-property-decorator"
import OrganizationPhoneLists from "./OrganizationPhoneLists.vue"
import { UploadType } from "@/enums/uploadType"
import OrganizationUploadsListMixin from "@/mixins/organization/OrganizationUploadsListMixin"
import OrganizationAudioFiles from "@/components/organization/OrganizationAudioFiles.vue"
import { EventBus } from "@/services/eventBus"
import { FileUpload, PhoneListType } from "@robocent/components"
import OrganizationImages from "@/components/organization/OrganizationImages.vue"
import OrganizationAllFiles from "@/views/organization/OrganizationAllFiles.vue"

@Component({
  components: {
    OrganizationImages,
    FileUpload,
    OrganizationAudioFiles,
    OrganizationPhoneLists,
    OrganizationAllFiles
  }
})
export default class OrganizationUploads extends OrganizationUploadsListMixin {
  uploadTypes = UploadType
  phoneListTypes = PhoneListType
  activeTab = 0
  orgId!: string

  created() {
    this.orgId = this.$route.params.orgId
  }

  selectTab() {
    this.activeTab = 0

    if (
      this.$route.query.type?.includes(PhoneListType.list) ||
      this.$route.query.type?.includes(PhoneListType.voterData)
    ) {
      this.activeTab = 1
    }

    if (this.$route.query.type?.includes(UploadType.audio)) {
      this.activeTab = 2
    }

    if (this.$route.query.type?.includes(UploadType.image)) {
      this.activeTab = 3
    }
  }

  openVoterDataPage() {
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/voter-data/request-data`,
      "_self"
    )
  }

  onUploaded() {
    EventBus.getUploads$.next()
  }

  @Watch("$route.query", { immediate: true })
  watchRouteQuery() {
    this.selectTab()
  }
}
