













import Component from "vue-class-component"
import SalesPayoutsTable from "@/components/salesPortal/admin/SalesPayoutsTable.vue"
import SalesPayoutMixin from "@/mixins/salesPortal/SalesPayoutMixin"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import MonthPicker from "@/components/salesPortal/MonthPicker.vue"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({
  components: {
    MonthPicker,
    SalesPayoutsTable
  }
})
export default class PaidSalesPayouts extends SalesPayoutMixin {
  async created() {
    await this.getAllSalesPayouts({
      ...this.defaultFilter,
      status: PartnerPayoutStatus.paid
    })

    EventBus.getSalesPayouts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getAllSalesPayouts as any)
  }
}
