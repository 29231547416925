































import Component from "vue-class-component"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import Card from "@/components/elements/Card.vue"
import CheckListTableItem from "@/components/elements/CheckListTableItem.vue"

@Component({
  components: { Card, CheckListTableItem }
})
export default class OverviewChecklist extends OverviewMixin {}
