import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { mapGetters } from "vuex"
import { CountTypes } from "@/voterData/enums/countTypes"
import { DatasetFields } from "@/voterData/enums/datasetFields"
import _ from "lodash"
import { getStateAbbr } from "@/voterData/utils/state"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  computed: {
    ...mapGetters("voterData/generalInfo", ["office", "organization"]),
    ...mapGetters("voterData/locationInfo", ["county", "zipCode"]),
    ...mapGetters("voterData/demographicsInfo", ["gender", "age", "ethnicity"]),
    ...mapGetters("voterData/affiliationInfo", [
      "affiliation",
      "historyRequirements",
      "votingHistory"
    ]),
    ...mapGetters("voterData/legalCompliance", [
      "intendedUse",
      "authorizationToPurchase",
      "tosAcceptance"
    ])
  }
})
export default class VoterDataUtilsMixin extends VueComponent {
  @Prop() voterData!: any
  blankValue = " "

  get historyReqsNotExist() {
    return (
      !(this as any).historyRequirements ||
      (this as any).historyRequirements === "all"
    )
  }

  get state() {
    return this.$store.getters["voterData/locationInfo/state"]
  }

  get stateAbbr() {
    if (!this.state) return ""

    return getStateAbbr(this.state)
  }

  async getStepData(field: string) {
    if (!this.stateAbbr) {
      return
    }
    return await this.$store.dispatch("voterData/getStepData", {
      state: this.stateAbbr,
      field
    })
  }

  async updateQuery() {
    try {
      if (!this.stateAbbr) {
        return
      }
      await this.$store.dispatch("cancelPendingRequests")
      this.$store.commit("voterData/updateLoading", {
        type: "query",
        value: true
      })
      const fields = this.configureFields()

      const queryId = await this.$store.dispatch("voterData/submitQuery", {
        state: this.stateAbbr,
        fields
      })
      this.updateLiveCounts(queryId, Object.keys(fields).join(","))
    } catch (e) {
      console.warn(e)
    } finally {
      this.$store.commit("voterData/updateLoading", {
        type: "query",
        value: false
      })
    }
  }

  updateLiveCounts(queryId: string, fields: string) {
    this.$store.dispatch("voterData/updateLiveCounts", {
      queryId,
      type: CountTypes.phone,
      fields
    })
    this.$store.dispatch("voterData/updateLiveCounts", {
      queryId,
      type: CountTypes.mobile,
      fields
    })
    this.$store.dispatch("voterData/updateLiveCounts", {
      queryId,
      type: CountTypes.email,
      fields
    })
    this.$store.dispatch("voterData/updateLiveCounts", {
      queryId,
      type: CountTypes.individual,
      fields
    })
  }

  configureFields() {
    return {
      [DatasetFields.gender]: this.genderValues(),
      [DatasetFields.age]: this.getFieldsQuery(
        DatasetFields.age,
        (this as any).age
      ),
      [DatasetFields.ethnicity]: this.getFieldsQuery(
        DatasetFields.ethnicity,
        (this as any).ethnicity
      ),
      [DatasetFields.partyCode]: this.getFieldsQuery(
        DatasetFields.partyCode,
        (this as any).affiliation
      ),
      [DatasetFields.votingHistory]: this.votingHistoryValues(),
      [DatasetFields.historyReqs]: this.historyRequirementsValues(),
      [DatasetFields.county]: this.getFieldsQuery(
        DatasetFields.county,
        _.map((this as any).county, "value")
      ),
      [DatasetFields.zipCode]: this.getFieldsQuery(
        DatasetFields.zipCode,
        _.map((this as any).zipCode, "value")
      )
    }
  }

  genderValues() {
    const values = (this as any).gender

    const filter = values.length ? [...values, this.blankValue] : values
    return this.getFieldsQuery(DatasetFields.gender, filter)
  }

  votingHistoryValues() {
    const joinValue = this.historyReqsNotExist ? " AND " : " OR "

    const result = (this as any).votingHistory.map(
      (entry: string) => `${entry} > ' '`
    )
    return result.join(joinValue)
  }

  historyRequirementsValues() {
    const votingHistory = (this as any).votingHistory.join("+")

    if (!votingHistory.length || this.historyReqsNotExist) {
      return ""
    }

    return `len(replace(${votingHistory},' ',''))>=${
      (this as any).historyRequirements
    }`
  }

  getFieldsQuery(field: DatasetFields, values: string[] = []) {
    if (!values?.length) {
      return ""
    }
    const arr = values.map((val) => {
      return `'${val}'`
    })

    return `${field} in (${arr.join(",")})`
  }

  clearState() {
    this.$store.commit("voterData/locationInfo/clear")
    this.$store.commit("voterData/demographicsInfo/clearState")
    this.$store.commit("voterData/affiliationInfo/clearState")
  }

  getFullQuery() {
    return {
      officeSought: (this as any).office,
      orgId: (this as any).organization._id,
      state: this.state,
      county: _.map((this as any).county, "value"),
      zipCode: _.map((this as any).zipCode, "value"),
      gender: (this as any).gender,
      age: (this as any).age,
      ethnicity: (this as any).ethnicity,
      partyCode: (this as any).affiliation,
      votingHistory: (this as any).votingHistory,
      historyReqs: (this as any).historyRequirements,
      intendedUse: (this as any).intendedUse,
      authorizationToPurchase: (this as any).authorizationToPurchase,
      agreeTOS: (this as any).tosAcceptance
    }
  }
}
