





























import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import SelectUsers from "@/components/users/SelectUsers.vue"
import { UserDao } from "@/services/dao/userDao"
import { Pagination } from "@/utils/pagination"
import { Organization, StringObject, User } from "@robocent/components"
import { AgentDao } from "@/services/dao/agentDao"
import { Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"

@Component({
  components: { SelectUsers }
})
export default class AddAgentModal extends VueComponent {
  @Prop() orgId!: string
  users: User[] = []
  selectedUsers: User[] = []

  async getUsers(pagination: Pagination, search?: StringObject) {
    const params = {
      page: pagination.current,
      ...search
    }

    await this.request(
      async () => {
        const {
          data: { data, total, limit, start }
        } = await UserDao.getAllUsers(params)
        this.users = data.map(
          ({ user }: { user: User; organizations: Organization[] }) => user
        )
        pagination.total = total
        pagination.perPage = limit
        pagination.current = start / limit + 1
      },
      null,
      "Failed loading users"
    )
  }

  onUserCheck(users: User[]) {
    this.selectedUsers = users
  }

  async addAgents() {
    const userIds = this.selectedUsers.map((user) => user.user_id)
    await this.request(async () => {
      await AgentDao.addAgents(this.orgId, userIds)
      this.$parent.close()
      EventBus.getAgents$.next()
    }, "Users Successfully added")
  }
}
