<template>
  <b-message
    :title="`Step ${index}: Play Text-to-Speech`"
    @close="$emit('remove')"
  >
    <b-input
      size="is-large"
      v-model="value"
      placeholder="Text to Transcribe"
      @input="$emit('update', value)"
    ></b-input>
  </b-message>
</template>

<script>
export default {
  name: "VoicePlay",
  props: ["index", "tts"],
  data() {
    return {
      value: this.tts
    }
  }
}
</script>
