



























































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { CardTable, CardTableItem, RequestedPayout } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import { PartnerPortalTotals } from "@/interfaces/partnerPortal/partnerPortalTotals"
import RequestedPartnerPayout from "@/components/partnerPortal/partner/RequestedPartnerPayout.vue"

@Component({
  components: {
    RequestedPartnerPayout,
    StepTemplate,
    Card,
    CardTable,
    CardTableItem
  }
})
export default class PartnerPortalTotalEarnings extends VueComponent {
  totals: PartnerPortalTotals = {
    commission: { paid: 0, notPaid: 0 },
    discounts: 0
  }
  requestedPartnerPayout: RequestedPayout | null = null

  get openCommission() {
    return this.totals.commission?.notPaid
  }

  created() {
    this.getPartnerTotalEarnings()
    this.getRequestedPartnerPayout()
  }

  async getPartnerTotalEarnings() {
    await this.request(
      async () => {
        this.totals = await PartnerPortalDao.getPartnerTotalEarnings()
      },
      undefined,
      undefined,
      "partner-totals"
    )
  }

  async requestPartnerPayout() {
    if (!this.openCommission) {
      return
    }

    const amount = this.$options.filters!.toCurrency(this.openCommission)
    await this.request(
      async () => {
        await PartnerPortalDao.requestPartnerPayout(amount)
        await this.getRequestedPartnerPayout()
      },
      "Your request has been sent!",
      "An error occurred",
      "payout-request"
    )
  }

  async getRequestedPartnerPayout() {
    await this.request(
      async () => {
        this.requestedPartnerPayout =
          await PartnerPortalDao.getRequestedPartnerPayout()
      },
      null,
      "Cannot get the data"
    )
  }
}
