




















import Component from "vue-class-component"
import Vue from "vue"

@Component
export default class ScheduleMessageWrapper extends Vue {
  get isNotDesktop() {
    return this.$mq !== "lg"
  }
}
