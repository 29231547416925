











































































import Component from "vue-class-component"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import Card from "@/components/elements/Card.vue"
import { Campaign, ScriptStatus } from "@robocent/components"
import { ScriptDao } from "@/services/dao/scriptDao"
import ScriptsMixin from "@/mixins/ScriptsMixin"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import ScriptsEmptyMessage from "@/components/scripts/ScriptsEmptyMessage.vue"
import ScriptItem from "@/components/scripts/ScriptItem.vue"
import { CampaignUtils } from "@/utils/campaignUtils"

@Component({
  components: {
    ScriptItem,
    SearchItem,
    Card,
    PaginationItem,
    ChooseItem,
    ScriptsEmptyMessage
  }
})
export default class CampaignScriptsSettings extends ScriptsMixin {
  campaign: Campaign = null!
  status: ScriptStatus[] = Object.keys(ScriptStatus) as ScriptStatus[]
  campaignUtils = new CampaignUtils()

  async created() {
    await this.getCampaign()
    await this.getScripts()
  }

  get orgId() {
    return this.$route.params.orgId
  }

  async getCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.campaign = await CampaignDao.getCampaign(orgId, campaignUUID)
      },
      undefined,
      "Cannot get campaign"
    )
  }

  async getScripts() {
    await this.request(async () => {
      const { data, total, pricing } = (
        await ScriptDao.getScripts(this.$route.params.orgId, {
          q: this.search,
          page: this.pagination.current,
          limit: this.pagination.perPage,
          status: this.status
        })
      ).data
      this.pagination.total = total
      this.scripts = data
      this.pricing = pricing
    })
  }

  async saveScripts() {
    const { orgId, campaignUUID } = this.$route.params
    const hasIntroScript = this.campaignUtils.hasIntroScript(
      this.campaign.scripts
    )

    if (!hasIntroScript) {
      return this.openToast("Please select at least one INTRO script")
    }

    await this.request(async () => {
      await CampaignDao.updateCampaignScripts(
        orgId,
        campaignUUID,
        this.campaign.scripts
      )
      await this.$router.push({
        name: "Org Campaigns",
        params: { orgId: this.$route.params.orgId }
      })
    }, "Scripts Successfully Saved")
  }
}
