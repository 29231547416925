














import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import VoiceSchedulerElements from "@/components/scheduler/VoiceSchedulerElements.vue"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { CampaignCostService } from "@/services/campaignCostService"
import { User } from "@robocent/components"
import { CampaignDirection as ECampaignDirection } from "@/enums/campaignDirection"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"

@Component({
  components: {
    VoiceSchedulerElements
  }
})
export default class ReSubmitVoiceCampaign extends VueComponent {
  voiceCampaign: VoiceCampaign = null!
  campaignCostService = new CampaignCostService()

  get user(): User {
    return this.$store.getters.user
  }

  async created() {
    await this.getVoiceCampaign()
    this.$store.commit("voiceScheduler/fillAllSteps", this.voiceCampaign)
  }

  async getVoiceCampaign() {
    const { orgId, campaignId } = this.$route.params
    await this.request(
      async () => {
        const voiceCampaign: VoiceCampaign =
          await VoiceCampaignDao.getVoiceCampaign(orgId, campaignId)
        voiceCampaign.uploads.vmRecording =
          voiceCampaign.uploads.vmRecording ?? null

        this.voiceCampaign = voiceCampaign
      },
      undefined,
      "Cannot get campaign"
    )
  }

  async resubmit() {
    await this.request(async () => {
      this.campaignCostService.setScriptField(this.voiceCampaign)
      this.voiceCampaign.meta.status = VoiceCampaignStatus.pending
      this.voiceCampaign.meta.reject = null
      await VoiceCampaignDao.editCampaign(this.voiceCampaign)
      await this.$router.push({
        name: "Org Campaigns",
        params: {
          orgId: this.voiceCampaign.organization._id
        },
        query: {
          direction: ECampaignDirection.voice,
          created: "true"
        }
      })
    }, "Campaign successfully resubmitted!")
  }
}
