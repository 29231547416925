import { RouteConfig } from "vue-router"
import { Resolvers } from "@/router/resolvers"
import Organizations from "@/views/Organizations.vue"
import Users from "@/views/admin/Users.vue"

export const salesRoutes: Array<RouteConfig> = [
  {
    path: "organizations",
    component: Organizations,
    beforeEnter: Resolvers.getAllOrganizations,
    name: "Sales Organizations"
  },
  {
    path: "users",
    component: Users,
    name: "Sales Users"
  }
]
