







import Component from "vue-class-component"
import { Invoice, statuses } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"

@Component
export default class CampaignInvoice extends VueComponent {
  @Prop({ default: true }) showPaidTitle!: boolean
  @Prop() invoice!: Invoice
  statuses = statuses
}
