

























import Card from "@/components/elements/Card.vue"
import Component from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: { Card }
})
export default class DeleteCampaignModal extends VueComponent {
  @Prop() confirmLoader!: boolean
  @Prop() campaignName!: string

  @Emit()
  onConfirm() {}
}
