




















import Component from "vue-class-component"
import PartnerServiceCategories from "@/components/partnerPortal/PartnerServiceCategories.vue"
import PartnerPayouts from "@/components/partnerPortal/PartnerPayouts.vue"
import { Prop } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { Feature } from "@/interfaces/feature/feature"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import PartnerPortalMixin from "@/mixins/partnerPortal/PartnerPortalMixin"

@Component({
  components: {
    Card,
    PartnerPayouts,
    PartnerServiceCategories
  }
})
export default class PartnerDetailsViewModal extends PartnerPortalMixin {
  @Prop() userId!: string
  @Prop() title!: string
  @Prop() orgId!: string

  created() {
    this.$store.commit("partnerPortal/clear")
  }

  mounted() {
    this.selectedOrg = this.orgId
  }

  async getPartnerPayouts(params: {
    type: Feature | null
    status: PartnerPayoutStatus
  }) {
    this.payouts = []

    await this.request(
      async () => {
        this.payouts = await PartnerPortalDao.getPartnerPayoutsByUser(
          this.userId,
          this.selectedOrg,
          params
        )
      },
      undefined,
      undefined,
      "payouts"
    )
  }

  async getServiceTotals() {
    await this.request(
      async () => {
        this.servicesTotals =
          await PartnerPortalDao.getTotalEarningsByServiceAndUser(
            this.userId,
            this.selectedOrg
          )
      },
      undefined,
      undefined,
      "totals"
    )
  }
}
