


























































import { Prop } from "vue-property-decorator"
import Component, { mixins } from "vue-class-component"
import PaymentMixin from "@/mixins/PaymentMixin"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"
import { statuses } from "@robocent/components"
import CampaignInvoiceMixin from "@/mixins/campaign/CampaignInvoiceMixin"

@Component
export default class InvoiceButton extends mixins(
  PaymentMixin,
  CampaignInvoiceMixin
) {
  @Prop() invoice!: Invoice
  statuses = statuses
}
