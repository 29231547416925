import axios from "axios"
import { Upload } from "@robocent/components"

export class UploadPublicDao {
  static async getUpload(uploadId: string): Promise<Upload> {
    return (await axios.get(`/public/uploads/${uploadId}`)).data
  }

  static async getUploadLink(uploadId: string) {
    return (await axios.get(`/public/uploads/${uploadId}/link`)).data
  }
}
