































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { AgentDao } from "@/services/dao/agentDao"
import { Prop, Ref, Watch } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { Agent, InputWithValidation } from "@robocent/components"
import { ValidationContext } from "vee-validate/dist/types/components/common"
import { InvitedAgent } from "@robocent/components"
import { EventBus } from "@/services/eventBus"
import Card from "@/components/elements/Card.vue"

@Component({
  components: {
    InputWithValidation,
    ValidationObserver,
    Card
  }
})
export default class InviteAgentModal extends VueComponent {
  @Ref("observer") observer!: ValidationContext
  @Prop() orgId!: string
  newAgent: InvitedAgent = {
    email: ""
  } as InvitedAgent

  async inviteAgent() {
    try {
      this.loading = true
      const agent: Agent | InvitedAgent = (
        await AgentDao.inviteAgent(this.orgId, this.newAgent.email)
      ).data
      this.$parent.close()
      this.$emit("invited", agent)
      EventBus.getInvitedAgents$.next()
      EventBus.getAgents$.next()
      this.showMessage(Boolean((agent as Agent).userId))
    } catch (e) {
      this.openFailedToast("Agent can't be added.")
    } finally {
      this.loading = false
    }
  }

  showMessage(isAgentExist: boolean) {
    if (isAgentExist) {
      return this.openToast(
        "The Agent was Successfully Added to the Organization"
      )
    }
    return this.openToast("The Agent was Successfully Invited")
  }

  @Watch("newAgent.email")
  emailToLowerCase(email: string) {
    this.newAgent.email = email.toLowerCase()
  }
}
