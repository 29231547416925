import { render, staticRenderFns } from "./DndKeywords.vue?vue&type=template&id=89a0fb98&scoped=true&"
import script from "./DndKeywords.vue?vue&type=script&lang=ts&"
export * from "./DndKeywords.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89a0fb98",
  null
  
)

export default component.exports