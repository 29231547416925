










import Component from "vue-class-component"
import PartnerPayoutsTable from "@/components/partnerPortal/admin/PartnerPayoutsTable.vue"
import PartnerPayoutMixin from "@/mixins/partnerPortal/PartnerPayoutMixin"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: {
    PartnerPayoutsTable
  }
})
export default class OpenPartnersPayouts extends PartnerPayoutMixin {
  async created() {
    await this.getPartnersPayouts({ status: PartnerPayoutStatus.open })
    EventBus.getPartnersPayouts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getPartnersPayouts as any)
  }
}
