import { DoNotDisturb, DoNotDisturbAction } from "@robocent/components"
import axios from "axios"
import { FindManyFilter } from "@/interfaces/findManyFilter"

export class DndDao {
  static async getDndList(
    orgId: string,
    params: FindManyFilter
  ): Promise<{ data: DoNotDisturb[]; total: number }> {
    return (await axios.get(`/text/organizations/${orgId}/dnd`, { params }))
      .data
  }

  static async removeDnd(orgId: string, dndId: number) {
    return axios.delete(`/text/organizations/${orgId}/dnd/${dndId}`)
  }

  static async addToDnd(orgId: string, phone: string) {
    return axios.post(`/text/organizations/${orgId}/dnd`, { phone })
  }

  static async getDndKeywords(orgId: string) {
    return axios.get(`/text/organizations/${orgId}/dnd-keywords`)
  }

  static async addDndKeyword(
    orgId: string,
    data: { caseSensitive: boolean; text: string; action: DoNotDisturbAction }
  ) {
    return axios.post(`/text/organizations/${orgId}/dnd-keywords`, data)
  }

  static async removeDndKeyword(orgId: string, id: number) {
    return axios.delete(`/text/organizations/${orgId}/dnd-keywords/${id}`)
  }
}
