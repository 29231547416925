






























































































import Component from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"
import { CountTypeNames } from "@/voterData/data/countTypeNames"
import { RequestStatus } from "@/voterData/enums/requestStatus"
import { VoterDataRequest } from "@/voterData/interfaces/VoterDataRequest"
import { InvoiceStatus } from "@/voterData/enums/invoiceStatus"
import { VoterDataInvoice } from "@/voterData/interfaces/VoterDataInvoice"
import { BDialogConfig } from "buefy/types/components"
import VoterDataRequestMixin from "@/voterData/mixins/VoterDataRequestMixin"
import { VoterDataDao } from "@/services/dao/voterDataDao"
import { PhoneListType, StringObject } from "@robocent/components"
import { EventBus } from "@/services/eventBus"

@Component({})
export default class RequestTable extends VoterDataRequestMixin {
  @Ref("table") table: any
  @Prop() data!: VoterDataRequest[]
  @Prop() isFeatureEnabled!: boolean
  @Prop() orgId!: string
  @Prop() recordsToPurchase!: VoterDataRequest[]

  countTypeNames = CountTypeNames

  get checkedRows() {
    return this.recordsToPurchase
  }

  set checkedRows(rows: VoterDataRequest[]) {
    this.$emit("update:recordsToPurchase", rows)
  }

  get invoiceExists() {
    return this.data.some((request) => request.invoice)
  }

  get listIdExists() {
    return this.data.some((request) => request.listId)
  }

  isPurchasingDisabled(row: VoterDataRequest) {
    return (
      row.checkout.status !== RequestStatus.completed ||
      !this.isFeatureEnabled ||
      row.invoice
    )
  }

  statusToType(status: RequestStatus) {
    const statusToType: StringObject = {
      [RequestStatus.pending]: "is-warning",
      [RequestStatus.started]: "is-warning",
      [RequestStatus.failed]: "is-danger",
      [RequestStatus.completed]: "is-success",
      [RequestStatus.purchased]: "is-primary",
      [RequestStatus.unknown]: "is-primary"
    }
    return statusToType[status]
  }

  getStatusName(status: RequestStatus) {
    if (status === RequestStatus.completed) {
      return "Ready to Buy"
    }
    return status
  }

  invoiceStatusToType(status: InvoiceStatus) {
    const statusToType: StringObject = {
      [InvoiceStatus.open]: "is-danger",
      [InvoiceStatus.paid]: "is-success"
    }
    return statusToType[status]
  }

  async confirmDeleting(id: number) {
    const message = "Are you sure you want to delete this request?"
    await this.confirm(message, {
      message,
      onConfirm: async () => await this.onDeleteRequest(id),
      cancelText: "Cancel",
      confirmText: "Delete",
      type: "is-danger"
    } as BDialogConfig)
  }

  async onDeleteRequest(id: number) {
    await this.request(async () => {
      await VoterDataDao.deleteRequest(this.orgId, id)
      EventBus.getVoterDataRequests$.next()
    }, "Request has been deleted successfully.")
  }

  onInvoiceClick(invoice: VoterDataInvoice) {
    window.open(invoice.hosted_invoice_url, "_blank")
  }

  goToPhoneLists(orgId: string) {
    this.$router.push({
      name: "Org Files",
      params: { orgId },
      query: { type: [PhoneListType.list, PhoneListType.voterData] }
    })
  }
}
