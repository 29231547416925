import { OrganizationStatus } from "@robocent/components"
import LogRocket from "logrocket"
import { UserLevel } from "@/enums/userLevel"
import { Module } from "vuex"

export const baseStore = <Module<any, any>>{
  state: {
    user: {
      email: "",
      password: ""
    },
    auth: false,
    super: false,
    level: null,
    organization: null,
    lastOrganization: null
  },

  getters: {
    user: (state) => state.user,
    auth: (state) => state.auth,
    super: (state) => state.super,
    level: (state) => state.level,
    organization: (state) => state.organization,
    lastOrg: (state) => state.lastOrganization,
    isOrganizationPending: (state: any) => {
      return state.organization?.meta.status === OrganizationStatus.pending
    },
    isOrganizationActive: (state: any) => {
      return state.organization?.meta.status === OrganizationStatus.active
    }
  },

  mutations: {
    setUser: (state, item) => {
      state.user = item
      LogRocket.identify(item.sub, {
        name: item.name,
        email: item.email
      })
    },
    setAuth: (state, value) => {
      state.auth = value
    },
    setLevel: (state, level) => {
      state.level = level
      state.super = level === UserLevel.super
    },
    setOrganization: (state, item) => {
      state.organization = item
    },
    clearOrganization: (state) => {
      state.organization = null
    },
    clearLastVisitedOrganization: (state) => {
      state.lastOrganization = null
    },
    setLastVisitedOrganization: (state, item) => {
      state.lastOrganization = item
    }
  },

  actions: {
    setUser(context, item) {
      context.commit("setUser", item)
    },
    setLevel(context, item) {
      context.commit("setLevel", item)
    }
  }
}
