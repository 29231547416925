import { Campaign, CampaignType, PhoneList, Upload } from "@robocent/components"
import { ToastProgrammatic as Toast } from "buefy"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { StatsConfig } from "@/interfaces/voiceCampaign/statsConfig"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import { FileUtils } from "@/utils/fileUtils"
import { StatsPhoneListStatus } from "@/interfaces/statsPhoneListStatus"

export abstract class PhoneListConfigureService {
  abstract getPhoneListData(
    campaign: Campaign | VoiceCampaign
  ): Promise<PhoneList>
  abstract uploadPhoneList(
    campaign: Campaign | VoiceCampaign,
    config: any,
    mapping: any
  ): void
  abstract checkPhoneListStatus(
    campaignId: string | number
  ): Promise<StatsPhoneListStatus>

  async processPhoneListUploading(campaign: Campaign | VoiceCampaign) {
    const phoneList = await this.getPhoneListData(campaign)
    const mapping = this.getMapping(phoneList.meta.list)
    const config = this.getConfig(campaign, phoneList)

    await this.uploadPhoneList(campaign, config, mapping)
  }

  getMapping(mapping: any) {
    const mappedArray = mapping.filter((item: any) => {
      if (!item.mapping) {
        return true
      }
      return !item.mapping.lead_phone.mappedColumnInd && !item.skip
    })

    if (mappedArray.length !== 0) {
      Toast.open({
        message: "Please map columns manually",
        type: "is-danger",
        duration: 4000
      })
      throw new Error("Columns must be mapped")
    }
    return mapping
  }

  getConfig(
    campaign: VoiceCampaign | Campaign,
    phoneList: Upload
  ): StatsConfig {
    const config: StatsConfig = {
      split_states: false,
      split_timezones: false,
      suppress_cell_phones: 1,
      use_nat_dnc: false,
      use_state_dnc: false,
      list_name: this.getPhoneListName(campaign, phoneList.humanId)
    } as StatsConfig

    if (campaign.type === VoiceCampaignType.rvm) {
      config.suppress_cell_phones = 2
    }

    if (
      [CampaignType.self, CampaignType.managed].includes(
        campaign.type as CampaignType
      )
    ) {
      config.suppress_cell_phones = 4
      config.identity_id = campaign.organization?.identity.id
    }

    return config
  }

  async waitWhenPhoneListWillBeUploaded(campaignId: string) {
    const intervalTime = 1000 * 5

    const check = async (resolve: Function) => {
      try {
        const { list_id } = await this.checkPhoneListStatus(campaignId)
        list_id ? resolve() : setTimeout(() => check(resolve), intervalTime)
      } catch (e) {
        setTimeout(() => check(resolve), intervalTime)
      }
    }

    return new Promise((resolve) =>
      setTimeout(() => check(resolve), intervalTime)
    )
  }

  getPhoneListName(campaign: any, humanId: number) {
    const name = FileUtils.getNormalizedName(campaign.organization.name)
    return `${humanId} ${name}`
  }
}
