import Component from "vue-class-component"
import MarkPartnerPayoutsAsPaidModal from "@/components/modals/partnerPortal/MarkPartnerPayoutsAsPaidModal.vue"
import Vue from "vue"

@Component({})
export default class PartnerPaymentMixin extends Vue {
  markPayoutsAsPaid({ userId, name }: { userId: string; name: string }) {
    this.$buefy.modal.open({
      parent: this,
      component: MarkPartnerPayoutsAsPaidModal,
      width: 400,
      props: {
        userId,
        name
      }
    })
  }
}
