




























































































import Component from "vue-class-component"
import { CampaignType } from "@robocent/components"
import { Watch } from "vue-property-decorator"
import { CampaignDirection } from "@/enums/campaignDirection"
import CampaignsListMixin from "@/mixins/campaign/CampaignsListMixin"
import CampaignsList from "@/components/campaign/CampaignsList.vue"

@Component({
  components: {
    CampaignsList
  }
})
export default class Campaigns extends CampaignsListMixin {
  types = CampaignType
  campaignDirection = CampaignDirection
  activeTab = 0

  selectTab() {
    if (!this.$route.query.direction) {
      this.activeTab = 0
    }

    if (this.$route.query.type?.includes(this.types.managed)) {
      this.activeTab = 2
    }

    if (this.$route.query.type?.includes(this.types.self)) {
      this.activeTab = 3
    }

    if (
      this.$route.query.type?.includes(this.types.managed) &&
      this.$route.query.type?.includes(this.types.self)
    ) {
      this.activeTab = 1
    }

    if (this.$route.query.direction === CampaignDirection.voice) {
      this.activeTab = 4
    }
  }

  @Watch("$route.query", { immediate: true })
  watchRouteQuery() {
    this.selectTab()
  }
}
