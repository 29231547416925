















































import Component, { mixins } from "vue-class-component"
import InvoiceItemMixin from "@/mixins/InvoiceItemMixin"

@Component
export default class FixedDiscounts extends mixins(InvoiceItemMixin) {}
