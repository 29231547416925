import axios, { AxiosResponse } from "axios"
import { Organization } from "@robocent/components"

export class OrganizationDao {
  static async getOrganizations(params: object) {
    return (await axios.get("/organizations", { params })).data
  }

  static async getAllOrganizations(params: object) {
    return (await axios.get("/admin/organizations", { params })).data
  }

  static async getOrganization(orgId: string): Promise<Organization> {
    return (await axios.get(`organizations/${orgId}`)).data
  }

  static async getOrganizationWithUsers(orgId: string) {
    return await axios.get(`organizations/${orgId}/with-users`)
  }

  static async createOrganization(data: Organization): Promise<Organization> {
    return (await axios.post("organizations", data)).data
  }

  static async saveOrganization(data: Organization): Promise<Organization> {
    return (await axios.put(`organizations/${data._id}`, data)).data
  }

  static async organizationAutoPay(
    id: string,
    autoPayEnabled: boolean
  ): Promise<boolean> {
    return (await axios.put(`organizations/${id}/auto-pay`, { autoPayEnabled }))
      .data
  }

  static async deleteOrganization(orgId: string) {
    return await axios.put(`organizations/${orgId}/delete`)
  }

  static async restoreOrganization(orgId: string) {
    return await axios.put(`organizations/${orgId}/restore`)
  }

  static async getUsers(orgId: string): Promise<any[]> {
    return ((await axios.get(`/organizations/${orgId}/users`)) as AxiosResponse)
      .data
  }

  static async inviteUser(orgId: string, email: string) {
    return await axios.post(`organizations/${orgId}/users`, {
      email
    })
  }

  static async removeUser(orgId: string, userId: string) {
    return await axios.delete(`organizations/${orgId}/users/${userId}`)
  }

  static async removeInvitedUser(orgId: string, email: string) {
    return await axios.put(`organizations/${orgId}/remove-invitation`, {
      email
    })
  }

  static async retrieveInvitedUsers(orgId: string) {
    return (await axios.get(`organizations/${orgId}/invited-users`)).data
  }

  static async createCallerId(
    orgId: string,
    phoneNumber: string
  ): Promise<{ phoneNumber: string }> {
    return (
      await axios.post(`organizations/${orgId}/caller-id`, {
        phoneNumber
      })
    ).data
  }
}
