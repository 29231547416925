





























































































import CampaignReports from "../../components/report/CampaignReports.vue"
import CampaignShare from "../../components/report/CampaignShare.vue"
import SurveyDiagrams from "../../components/report/SurveyDiagrams.vue"
import MessagesDiagram from "../../components/report/MessagesDiagram.vue"
import MessagesStats from "../../components/report/MessagesStats.vue"
import { VueComponent } from "@/utils/VueComponent"
import Component, { mixins } from "vue-class-component"
import { ReportDao } from "@/services/dao/reportDao"
import { CampaignStats } from "@/interfaces/campaignStats"
import { CampaignSurveyStats } from "@/interfaces/campaignSurveyStats"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import AgentsDiagram from "@/components/report/AgentsDiagram.vue"
import CampaignDetails from "@/components/report/CampaignDetails.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import {
  CampaignPricing,
  CampaignStatus,
  CampaignUtilsMixin,
  PhoneList
} from "@robocent/components"
import CampaignAutoRefresh from "@/components/report/CampaignAutoRefresh.vue"
import moment from "moment"
import Card from "@/components/elements/Card.vue"
import CampaignDetailsElements from "@/components/report/CampaignDetailsElements.vue"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import CampaignLiveProgress from "@/views/campaign/CampaignLiveProgress.vue"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import TextCampaignResultsMixin from "@/mixins/campaign/TextCampaignResultsMixin"
import TextCampaignCostBasis from "@/components/report/textCampaign/TextCampaignCostBasis.vue"
import { CampaignAgentsStats } from "@/interfaces/campaignAgentsStats"
import AgentPortalInfo from "@/components/agent/AgentPortalInfo.vue"

@Component({
  components: {
    TextCampaignCostBasis,
    CampaignLiveProgress,
    CampaignDetailsElements,
    Card,
    CampaignAutoRefresh,
    CampaignDetails,
    AgentsDiagram,
    MessagesStats,
    MessagesDiagram,
    SurveyDiagrams,
    CampaignShare,
    CampaignReports,
    AgentPortalInfo
  }
})
export default class CampaignResults extends mixins(
  CampaignUtilsMixin,
  TextCampaignResultsMixin,
  VueComponent
) {
  list: PhoneList | null = null
  stats: CampaignStats | null = null
  surveyStats: CampaignSurveyStats[] | null = null
  campaignReports: any[] = []
  agentsStats: CampaignAgentsStats[] = []
  updatedAt: string = null!
  pricing: CampaignPricing | null = null

  async created() {
    await this.getAllStats()
    EventBus.getReports$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getCampaignReports)
    EventBus.startScrubPhoneList$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getList)
  }

  async getAllStats() {
    await this.getCampaign()
    this.getPricing()
    this.getCampaignReports()
    this.updatedAt = moment().format("h:mm:ss A").toString()

    if (this.campaign?.metadata?.jobId) {
      await this.getDetailedStats()
    } else {
      await this.getCampaignStats()
      this.getCampaignSurveyStats()
      this.getCampaignAgentsStats()
    }
  }

  get isActive() {
    return (
      this.campaign?.status === CampaignStatus.active ||
      this.campaign?.status === CampaignStatus.open
    )
  }

  async getCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.campaign = await CampaignDao.getCampaign(orgId, campaignUUID)
        if (
          this.campaign.status === CampaignStatus.open &&
          (this as any).checkIfSendingAvailable()
        ) {
          this.campaign.status = CampaignStatus.active
        }
        this.getList()
      },
      undefined,
      undefined,
      "getCampaign"
    )
  }

  async getCampaignStats() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.stats = (
          await ReportDao.getCampaignStats(orgId, campaignUUID)
        ).data
      },
      undefined,
      undefined,
      "getCampaignStats"
    )
  }

  async getCampaignSurveyStats() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.surveyStats = (
          await ReportDao.getCampaignSurveyStats(orgId, campaignUUID)
        ).data
      },
      undefined,
      undefined,
      "getCampaignSurveyStats"
    )
  }

  async getCampaignReports() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.campaignReports = (
          await ReportDao.getCampaignReports(orgId, campaignUUID)
        ).data
      },
      undefined,
      undefined,
      "getCampaignReports"
    )
  }

  async getCampaignAgentsStats() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.agentsStats = (
          await ReportDao.getCampaignAgentsStats(orgId, campaignUUID)
        ).data
      },
      undefined,
      undefined,
      "getCampaignAgentsStats"
    )
  }

  async getList() {
    await this.request(
      async () => {
        this.list = (
          await UploadsDao.getUpload(
            this.campaign!.organizationId,
            this.campaign!.listId
          )
        ).data
      },
      undefined,
      undefined,
      "getList"
    )
  }

  async getPricing() {
    await this.request(async () => {
      this.pricing = await SmsBillingDao.getPricing(this.$route.params.orgId)
    })
  }

  async getDetailedStats() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.stats = await ReportDao.getDetailedCampaignStats(
          orgId,
          campaignUUID
        )
        this.agentsStats = this.stats?.agentStats as CampaignAgentsStats[]
      },
      undefined,
      undefined,
      "getCampaignStats"
    )
  }
}
