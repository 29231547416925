





















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { AgentDao } from "@/services/dao/agentDao"
import { InvitedAgent, ListCard } from "@robocent/components"
import { EventBus } from "@/services/eventBus"

@Component({
  components: {
    ListCard
  }
})
export default class InvitedAgentItem extends VueComponent {
  @Prop() invitedAgent!: InvitedAgent

  async deleteInvitedAgent() {
    await this.confirm(
      "Do you really want to delete this Agent from the Invited List?"
    )
    await this.request(async () => {
      await AgentDao.deleteInvitedAgent(
        this.$route.params.orgId,
        this.invitedAgent.id
      )
      EventBus.getInvitedAgents$.next()
    }, "Agent was successfully deleted from the Invited List")
  }
}
