























































































import Component from "vue-class-component"
import { states } from "@/data/states"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { ValidationObserver } from "vee-validate"
import { Prop, Ref, Watch } from "vue-property-decorator"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import _ from "lodash"
import {
  AutocompleteWithValidation,
  InputWithValidation,
  Organization,
  PhoneNumberWithValidation,
  StringObject,
  User
} from "@robocent/components"
import { UserLevel } from "@/enums/userLevel"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import CreateNewOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: {
    StepTemplate,
    AutocompleteWithValidation,
    PhoneNumberWithValidation,
    InputWithValidation,
    ValidationObserver
  }
})
export default class GeneralInfoStep extends VoterDataUtilsMixin {
  @Ref("general-info-observer") observer!: any
  @Prop() user!: User

  states = states
  organizations: Organization[] = []

  get email() {
    return this.$store.getters["voterData/generalInfo/email"]
  }
  set email(value: string) {
    this.$store.commit("voterData/generalInfo/updateEmail", value)
  }

  get name() {
    return this.$store.getters["voterData/generalInfo/name"]
  }
  set name(value: string) {
    this.$store.commit("voterData/generalInfo/updateName", value)
  }

  get phone() {
    return this.$store.getters["voterData/generalInfo/phone"]
  }
  set phone(value: string) {
    this.$store.commit("voterData/generalInfo/updatePhone", value)
  }

  get office() {
    return this.$store.getters["voterData/generalInfo/office"]
  }
  set office(value: string) {
    this.$store.commit("voterData/generalInfo/updateOffice", value)
  }

  get state() {
    return this.$store.getters["voterData/generalInfo/state"]
  }
  set state(option: string) {
    this.$store.commit("voterData/generalInfo/updateState", option)
  }

  get organization() {
    return this.$store.getters["voterData/generalInfo/organization"]
  }
  set organization(option: Organization) {
    this.$store.commit("voterData/generalInfo/updateOrganization", option)
  }

  mounted() {
    this.getOrganizations()
    this.setUser()
    this.setOrg()
    EventBus.organizationCreated$
      .pipe(whileComponentMounted(this))
      .subscribe(this.onOrgCreated)
  }

  debounceTypingUpdate = _.debounce((name) => {
    this.getOrganizations(name)
  }, 300)

  async getOrganizations(name = "") {
    const params = {
      q: name
    }
    await this.request(() => {
      if (this.user?.app_metadata?.level === UserLevel.super) {
        return this.getAllOrganizations(params)
      }
      return this.getUserOrganizations(params)
    })
  }

  async getAllOrganizations(params: StringObject) {
    this.organizations = (
      await OrganizationDao.getAllOrganizations(params)
    ).data
  }

  async getUserOrganizations(params: StringObject) {
    this.organizations = (await OrganizationDao.getOrganizations(params)).data
  }

  setUser() {
    const { email, phone, firstName, lastName } = this.user?.user_metadata
    this.email = email || ""
    this.phone = phone || ""
    this.name = `${firstName} ${lastName}` || ""
  }

  setOrg() {
    this.organization = this.$store.getters.lastOrg
  }

  openCreateOrgModal() {
    this.$buefy.modal.open({
      width: 640,
      component: CreateNewOrganizationModal,
      parent: this
    })
  }

  async onOrgCreated() {
    await this.getOrganizations()
    this.organization = this.organizations[0]
  }

  @Watch("organization")
  watchOrganization(newVal: Organization) {
    this.state = newVal?.state
  }
}
