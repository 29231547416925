import axios from "axios"
import { PublicTosAgreement } from "@robocent/components"

export class TosDao {
  static async getPublicTosAgreement(): Promise<PublicTosAgreement | null> {
    return (await axios.get("/tos-agreement")).data
  }

  static async updatePublicTosAgreement(data: PublicTosAgreement) {
    return await axios.put("/tos-agreement", data)
  }
}
