
















import NavSide from "@/components/nav/NavSide.vue"
import Vue from "vue"

const hideNavRoutes = [
  "Shared Campaign",
  "Shared Voice Campaign",
  "Shared Upload",
  "404"
]

export default Vue.extend({
  name: "App",
  components: {
    NavSide
  },
  data() {
    return {
      hideNav: hideNavRoutes.includes(this.$router.currentRoute.name as string),
      isNavOpen: false
    }
  },
  watch: {
    $route(to) {
      this.hideNav = hideNavRoutes.includes(to.name)
    }
  },
  methods: {
    handleNavActive(isActive: boolean) {
      this.isNavOpen = isActive
    }
  },
  computed: {
    isNavbarReady(): boolean {
      return this.$store.getters.auth
    }
  }
})
