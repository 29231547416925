import Component from "vue-class-component"
import { VoterDataRequest } from "@/voterData/interfaces/VoterDataRequest"
import { RequestStatus } from "@/voterData/enums/requestStatus"
import { InvoiceStatus } from "@/voterData/enums/invoiceStatus"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { VoterDataPricing } from "@/voterData/interfaces/VoterDataPricing"

@Component
export default class VoterDataRequestMixin extends VueComponent {
  @Prop() pricing!: VoterDataPricing
  minCost = 3000

  isRefreshAvailable(row: VoterDataRequest) {
    return (
      row.checkout.status === RequestStatus.pending ||
      row.checkout.status === RequestStatus.started ||
      (row.checkout.status === RequestStatus.completed &&
        row.invoice?.status === InvoiceStatus.paid)
    )
  }

  getSingleItemPrice(item: VoterDataRequest) {
    const dataTypePricing = this.pricing[item.type]
    const cost = dataTypePricing?.costPerRecord * item.amount || 0
    const minimumOrderCost = dataTypePricing?.minimumOrderCost || this.minCost
    return cost > minimumOrderCost ? cost : minimumOrderCost
  }
}
