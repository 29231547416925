






















import Component from "vue-class-component"
import { AutocompleteWithValidation } from "@robocent/components"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { Watch } from "vue-property-decorator"
import { usStates } from "@robocent/components"

@Component({
  components: {
    StepTemplate,
    AutocompleteWithValidation
  }
})
export default class State extends VoterDataUtilsMixin {
  states = usStates

  get state() {
    return this.$store.getters["voterData/locationInfo/state"]
  }
  set state(option: string) {
    this.$store.commit("voterData/locationInfo/updateState", option)
  }

  @Watch("state")
  watchState(nextValue: string, prevValue: string) {
    if (nextValue !== prevValue) {
      this.clearState()
      this.updateQuery()
    }
  }
}
