






































































































import Component from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { ValidationObserver } from "vee-validate"
import { InputWithValidation, Upload } from "@robocent/components"
import { NotificationsDao } from "@/services/dao/robocent/notificationsDao"
import { ValidationContext } from "vee-validate/dist/types/components/common"
import { EventBus } from "@/services/eventBus"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import ShareablePageMixin from "@/mixins/ShareablePageMixin"

@Component({
  components: {
    Card,
    ValidationObserver,
    InputWithValidation
  }
})
export default class UploadShareableModal extends ShareablePageMixin {
  @Prop() upload!: Upload
  @Ref("emailObserver") emailObserver!: ValidationContext
  @Ref("smsObserver") smsObserver!: ValidationContext

  email = ""
  phone = ""

  get sharedURL() {
    return `${location.protocol}//${location.host}/shared/uploads/${this.upload._id}`
  }

  async toggleShareFile() {
    this.upload.meta = {
      ...this.upload.meta,
      public: !this.upload.meta.public
    }

    await this.request(async () => {
      await UploadsDao.setShared(
        this.upload.organization?._id,
        this.upload._id,
        this.upload.meta.public
      )
      EventBus.getUploads$.next()
    }, `File link is now ${this.upload.meta?.public ? "public" : "private"}`)
  }

  async sendEmail() {
    await this.request(
      async () => {
        await NotificationsDao.shareUploadByEmail(this.email, this.upload._id)
        this.email = ""
        this.$nextTick(() => this.emailObserver.reset())
      },
      "Email successfully sent",
      "Sending Email failed. Please try again"
    )
  }

  async sendSms() {
    await this.request(
      async () => {
        await NotificationsDao.shareUploadBySMS(this.phone, this.upload._id)
        this.phone = ""
        this.$nextTick(() => this.smsObserver.reset())
      },
      "SMS successfully sent",
      "Sending SMS failed. Please try again"
    )
  }

  goToSharedFile() {
    this.$router.push({
      name: "Shared Upload",
      params: { uploadId: this.upload._id }
    })
    this.$parent.close()
  }
}
