import { Module } from "vuex"
import _ from "lodash"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { Campaign } from "@robocent/components"
import { isTextCampaignUpdate } from "@/mixins/campaign/TextSchedulerUtilsMixin"

const initState = {
  steps: [],
  step: null
}

export const textSchedulerStore = <Module<any, any>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    step: (state) => state.step,
    steps: (state) => state.steps
  },
  mutations: {
    next: (state, step: TextSchedulerSteps) => {
      if (!state.steps.includes(step)) {
        state.steps.push(step)
      }

      if (state.step !== step) {
        state.step = step
      }
    },
    remove: (state, step: TextSchedulerSteps) => {
      state.steps = state.steps.filter(
        (filledStep: TextSchedulerSteps) => filledStep !== step
      )
    },
    fillAllSteps(state, campaign: Campaign) {
      const isUpdate = isTextCampaignUpdate(campaign)
      let steps = Object.values(TextSchedulerSteps)
      if (isUpdate) {
        const exclude = [
          TextSchedulerSteps.campaignCard,
          TextSchedulerSteps.campaignCost
        ]
        steps = steps.filter(
          (step: TextSchedulerSteps) => !exclude.includes(step)
        )
      }
      state.steps = steps
    },
    clear: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
