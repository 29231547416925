












































































import Component from "vue-class-component"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import { VoiceCampaignType as EVoiceCampaignType } from "@/enums/voiceCampaignType"

@Component({
  components: { ChooseItem, ChooseRow }
})
export default class VoiceCampaignType extends VoiceSchedulerUtilsMixin {
  types = EVoiceCampaignType

  get filledSteps() {
    return this.$store.getters["voiceScheduler/steps"]
  }

  isActive(type: EVoiceCampaignType) {
    return this.campaign.type === type
  }

  nextStep(type: EVoiceCampaignType) {
    this.campaign.type = type
    if (type === EVoiceCampaignType.robocall) {
      this.removeDependentStepsIfExistAndGoToNext(
        VoiceSchedulerSteps.campaignDate,
        [
          VoiceSchedulerSteps.campaignTransferOptions,
          VoiceSchedulerSteps.campaignSurveyScript
        ]
      )
    }

    if (type === EVoiceCampaignType.transfer) {
      this.removeDependentStepsIfExistAndGoToNext(
        VoiceSchedulerSteps.campaignTransferOptions,
        [VoiceSchedulerSteps.campaignSurveyScript]
      )
    }

    if (type === EVoiceCampaignType.survey) {
      this.removeDependentStepsIfExistAndGoToNext(
        VoiceSchedulerSteps.campaignSurveyScript,
        [VoiceSchedulerSteps.campaignTransferOptions]
      )
    }
  }

  removeDependentStepsIfExistAndGoToNext(
    step: VoiceSchedulerSteps,
    dependent: VoiceSchedulerSteps[]
  ) {
    const filledStepsToRemove = this.filledSteps.filter(
      (step: VoiceSchedulerSteps) => dependent.includes(step)
    )
    if (filledStepsToRemove.length) {
      filledStepsToRemove.forEach((step: VoiceSchedulerSteps) =>
        this.remove(step)
      )
      setTimeout(() => {
        this.next(step)
      }, 400)
    } else {
      this.next(step)
    }
  }
}
