










































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { ImageFile } from "@robocent/components"

@Component
export default class ImageItem extends VueComponent {
  @Prop() image!: ImageFile
  @Prop() active!: boolean
  @Prop() imageFileSizeLimit!: number

  get isSelectingDisabled() {
    return Number(this.image?.meta?.size) > this.imageFileSizeLimit
  }
}
