





































import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import CheckListTableItem from "@/components/elements/CheckListTableItem.vue"
import TextOverviewMixin from "@/components/campaign/textAdminReview/TextOverviewMixin"

@Component({
  components: { CheckListTableItem, Card }
})
export default class TextCampaignCheckList extends TextOverviewMixin {}
