














import Component from "vue-class-component"
import { InputWithValidation } from "@robocent/components"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { FileUtils } from "@/utils/fileUtils"

@Component({
  components: {
    StepTemplate,
    InputWithValidation
  }
})
export default class FileName extends VoterDataUtilsMixin {
  get fileName() {
    return this.$store.getters["voterData/submitRequestStep/fileName"]
  }
  set fileName(value: string) {
    this.$store.commit("voterData/submitRequestStep/updateFileName", value)
  }

  mounted() {
    this.fileName = `RoboCent Voter Data - ${
      this.state
    } - ${FileUtils.removeAllSpecialCharacters(
      (this as any).organization?.name
    )}`
  }
}
