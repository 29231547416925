import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import { Customer } from "@robocent/components"
import AddNewCardModal from "@/components/modals/AddNewCardModal.vue"
import _ from "lodash"
import { EventBus } from "@/services/eventBus"

@Component
export default class OrganizationBillingMixin extends VueComponent {
  customer: Customer = null!
  creditBalance = 0

  get hasCard() {
    return this.customer?.default_source?.includes("card")
  }

  get ccSources() {
    const sources =
      this.customer?.sources.data.filter(function (element: any) {
        return element.object === "card"
      }) || []
    return _.sortBy(sources, "id")
  }

  async getCustomer(orgId: string) {
    await this.request(
      async () => {
        this.setCustomer(await SmsBillingDao.getCustomer(orgId))
      },
      undefined,
      undefined,
      "customer"
    )
  }

  setCustomer(customer: Customer) {
    this.customer = customer
    this.creditBalance =
      this.customer.balance < 0 ? this.customer.balance * -1 : 0
  }

  async openAddNewCardModal(orgId: string) {
    this.$buefy.modal.open({
      parent: this,
      component: AddNewCardModal,
      width: 500,
      props: {
        orgId
      },
      events: {
        added: (customer: Customer) => {
          this.setCustomer(customer)
          EventBus.organizationChanged$.next()
        }
      }
    })
  }
}
