import { baseStore } from "./modules/base"
import { generalInfo } from "./modules/generalInfo"
import { locationInfo } from "@/voterData/store/modules/locationInfo"
import { demographicsInfo } from "@/voterData/store/modules/demographicsInfo"
import { affiliationInfo } from "@/voterData/store/modules/affiliationInfo"
import { legalCompliance } from "@/voterData/store/modules/legalCompliance"
import { submitRequestStep } from "@/voterData/store/modules/submitRequestStep"

export default {
  namespaced: true,
  modules: {
    baseStore,
    generalInfo,
    locationInfo,
    demographicsInfo,
    affiliationInfo,
    legalCompliance,
    submitRequestStep
  }
}
