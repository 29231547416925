import { render, staticRenderFns } from "./ChooseRow.vue?vue&type=template&id=ffe826d8&scoped=true&"
import script from "./ChooseRow.vue?vue&type=script&lang=ts&"
export * from "./ChooseRow.vue?vue&type=script&lang=ts&"
import style0 from "./ChooseRow.vue?vue&type=style&index=0&id=ffe826d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffe826d8",
  null
  
)

export default component.exports