import { render, staticRenderFns } from "./OpenPartnersPayouts.vue?vue&type=template&id=1f631fb4&scoped=true&"
import script from "./OpenPartnersPayouts.vue?vue&type=script&lang=ts&"
export * from "./OpenPartnersPayouts.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f631fb4",
  null
  
)

export default component.exports