



















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop } from "vue-property-decorator"
import { Pagination } from "@/utils/pagination"

@Component
export default class PaginationItem extends VueComponent {
  @Prop() pagination!: Pagination

  @Emit()
  onChange(page: number) {
    return page
  }
}
