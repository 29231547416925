























import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { CardTable, CardTableItem } from "@robocent/components"
import { Criteria } from "@/voterData/interfaces/Criteria"

@Component({
  components: { CardTableItem, CardTable }
})
export default class SelectedOptionsTable extends Vue {
  @Prop() data!: Criteria[]
  @Prop() loading!: boolean
}
