

















import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { Watch } from "vue-property-decorator"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { historyReqs } from "@/voterData/data/votingHistory"

@Component({
  components: {
    StepTemplate
  }
})
export default class VotingHistoryRequirements extends VoterDataUtilsMixin {
  historyReqs = historyReqs

  get historyRequirements() {
    return this.$store.getters["voterData/affiliationInfo/historyRequirements"]
  }
  set historyRequirements(value: string) {
    this.$store.commit(
      "voterData/affiliationInfo/updateHistoryRequirements",
      value
    )
  }

  mounted() {
    this.historyRequirements = this.historyReqs[0].value
  }

  @Watch("historyRequirements")
  watchHistoryRequirements(nextValue: string, prevValue: string) {
    if (nextValue !== prevValue) {
      this.updateQuery()
    }
  }
}
