








import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Campaign } from "@robocent/components"
import { CampaignDao } from "@/services/dao/campaignDao"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import DeleteCampaignModal from "../modals/DeleteCampaignModal.vue"

@Component({
  components: { DeleteCampaignModal }
})
export default class DeleteCampaign extends VueComponent {
  @Prop() campaign!: Campaign | VoiceCampaign

  async onConfirmClick() {
    await this.request(async () => {
      await CampaignDao.deleteCampaignPermanently(this.campaign.uuid)
      this.$emit("onConfirm")
      this.$parent.close()
    })
  }
}
