









import Component from "vue-class-component"
import MonthPicker from "@/components/salesPortal/MonthPicker.vue"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    StepTemplate,
    MonthPicker
  }
})
export default class SalesManagerFilter extends VueComponent {}
