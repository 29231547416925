






import Component from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { CampaignDirection } from "@/enums/campaignDirection"
import CampaignsListMixin from "@/mixins/campaign/CampaignsListMixin"
import CampaignsList from "@/components/campaign/CampaignsList.vue"

@Component({
  components: {
    CampaignsList
  }
})
export default class OrganizationsCampaigns extends CampaignsListMixin {
  campaignDirection = CampaignDirection
  activeTab = 0

  selectTab() {
    if (this.$route.query.direction === CampaignDirection.voice) {
      this.activeTab = 2
    }

    if (this.$route.query.direction === CampaignDirection.text) {
      this.activeTab = 1
    }

    if (!this.$route.query.direction) {
      this.activeTab = 0
    }
  }

  @Watch("$route.query", { immediate: true })
  watchRouteQuery() {
    this.selectTab()
  }
}
