import Component, { mixins } from "vue-class-component"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { UploadType } from "@/enums/uploadType"
import { FilterMixin, PhoneListType } from "@robocent/components"
import _ from "lodash"

@Component
export default class OrganizationUploadsListMixin extends mixins<FilterMixin>(
  FilterMixin
) {
  get isDeletedFilterEnabled() {
    if (this.$route.query?.deleted) {
      return JSON.parse(this.$route.query?.deleted as string)
    }
    return false
  }

  applyQuery(
    filter: FindManyFilter & { type?: UploadType[] | PhoneListType[] }
  ) {
    delete this.$route.query.listType

    const query = Object.assign({}, this.$route.query, filter)

    if (filter.type && !_.isEqual(filter.type, this.$route.query.type)) {
      query.page = 1
    }

    this.$router.push({ query })
  }

  getTypeFromQuery(
    type: string | (string | null)[],
    defaultFilters: UploadType[] | PhoneListType[]
  ) {
    const preparedType: UploadType[] =
      (this.prepareStatusFromQuery(type) as UploadType[]) || defaultFilters

    if (_.isEqual(preparedType, defaultFilters)) {
      return "all"
    }

    return preparedType[0]
  }
}
