











import Component, { mixins } from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { AgentTotalsMixin, CampaignPayout } from "@robocent/components"
import { AgentPayoutDao } from "@/services/dao/agentPayoutDao"
import { EventBus } from "@/services/eventBus"
import _ from "lodash"
import MarkPayoutsAsPaid from "@/components/payouts/MarkPayoutsAsPaid.vue"
import { Prop } from "vue-property-decorator"

@Component({
  components: {
    MarkPayoutsAsPaid
  }
})
export default class MarkAgentPayoutsAsPaidModal extends mixins(
  VueComponent,
  AgentTotalsMixin
) {
  @Prop() userId!: string
  @Prop() name!: number
  totals = { messages: 0, earned: 0 }
  campaignPayouts: CampaignPayout[] = []

  created() {
    this.getAgentPayout()
  }

  async getAgentPayout() {
    await this.request(async () => {
      this.campaignPayouts = await AgentPayoutDao.getAgentPayout(this.userId)
      this.totals = this.getTotals(this.campaignPayouts)
    })
  }

  async onConfirm(check: string) {
    await this.request(async () => {
      const data = {
        campaigns: _(this.campaignPayouts)
          .filter((payout: CampaignPayout) => payout.earned > 0)
          .map((payout: CampaignPayout) => payout.campaignId)
          .value(),
        userId: this.userId,
        check: check,
        amount: this.$options.filters!.toCurrency(this.totals.earned)
      }

      await AgentPayoutDao.markPayoutsAsPaid(data)

      this.$parent.close()
      EventBus.getAgentsPayouts$.next({ paid: false })
    }, "The Payouts were paid successfully")
  }
}
