































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { CardTable, CardTableItem, RequestedPayout } from "@robocent/components"
import Vue from "vue"

@Component({
  components: {
    CardTable,
    CardTableItem
  }
})
export default class RequestedPartnerPayout extends Vue {
  @Prop() requestedPartnerPayout!: RequestedPayout
  @Prop() amount!: number
  @Prop() loading!: boolean
}
