import { Module } from "vuex"
import _ from "lodash"
import { AffiliationInfo } from "@/voterData/interfaces/AffiliationInfo"
import { State } from "@/voterData/interfaces/state"

const initState = {
  affiliation: [],
  votingHistory: [],
  historyRequirements: null
}

export const affiliationInfo = <Module<AffiliationInfo, State>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    affiliation: (state: AffiliationInfo) => state.affiliation,
    votingHistory: (state: AffiliationInfo) => state.votingHistory,
    historyRequirements: (state: AffiliationInfo) => state.historyRequirements
  },
  mutations: {
    updateAffiliation: (state: AffiliationInfo, value: string[]) => {
      state.affiliation = value
    },
    updateVotingHistory: (state: AffiliationInfo, value: string[]) => {
      state.votingHistory = value
    },
    updateHistoryRequirements: (state: AffiliationInfo, value: string) => {
      state.historyRequirements = value
    },
    clearState: (state: AffiliationInfo) => {
      Object.keys(initState).forEach((key: string) => {
        state[key as keyof AffiliationInfo] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
