




















import Component from "vue-class-component"
import DatePicker from "vue2-datepicker"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import moment from "moment"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"

@Component({
  components: {
    ChooseRow,
    DatePicker
  }
})
export default class MonthPicker extends SalesPortalMixin {
  format = "MMMM, YYYY"

  setDate(date: Date) {
    this.selectedDate = date
  }

  validateBasedOnUnitOfTime(date: string) {
    const unitOfTime = "month"

    return moment(date).isAfter(moment(), unitOfTime)
  }
}
