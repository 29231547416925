
































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop } from "vue-property-decorator"

@Component
export default class CardItem extends VueComponent {
  @Prop() title!: string
  @Prop({ default: true }) canDeleted!: boolean
  @Prop({ default: false }) canEdit!: boolean
  @Prop() type!: string

  @Emit()
  onDelete() {}

  @Emit()
  onEdit() {}
}
