





























































































































































import Component, { mixins } from "vue-class-component"
import {
  Campaign,
  CampaignStatus,
  CampaignType,
  CampaignUtilsMixin,
  statuses
} from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import { messages } from "@/data/messages"
import { CampaignDao } from "@/services/dao/campaignDao"
import TextCampaignTestMessageModal from "@/components/modals/TextCampaignTestMessageModal.vue"
import { BDialogConfig } from "buefy/types/components"
import moment from "moment"
import { VueComponent } from "@/utils/VueComponent"
import DeleteCampaignButton from "@/components/campaign/DeleteCampaignButton.vue"
import { InvoiceStatus } from "@/voterData/enums/invoiceStatus"

@Component({
  components: { DeleteCampaignButton }
})
export default class CampaignActionsDropdown extends mixins(
  VueComponent,
  CampaignUtilsMixin
) {
  @Prop() campaign!: Campaign

  get isCampaignPaused() {
    return this.campaign.status === CampaignStatus.closed
  }

  get pauseTitle() {
    return this.isCampaignPaused ? "Unpause" : "Pause"
  }

  get pauseIcon() {
    return this.isCampaignPaused ? "play" : "pause"
  }

  get isSuper() {
    return this.$store.getters.super
  }

  get isAgentsPageAvailable() {
    return this.campaign.type === CampaignType.self || this.isSuper
  }

  get isArchivingAvailable() {
    return [
      CampaignStatus.pending,
      CampaignStatus.rejected,
      CampaignStatus.closed,
      CampaignStatus.canceled
    ].includes(this.campaign.status)
  }

  get isRequestCancelationAvailable() {
    const invoiceStatus = this.campaign?.invoice?.status
    const campaignStatus = this.campaign?.status

    const startedHourAgo = this.checkIfHourPassed()
    return (
      [statuses.draft, statuses.open, statuses.paid].includes(invoiceStatus) &&
      [
        CampaignStatus.pendingActivation,
        CampaignStatus.open,
        CampaignStatus.active,
        CampaignStatus.closed
      ].includes(campaignStatus) &&
      (startedHourAgo || this.isSuper)
    )
  }

  get isDeleteAvailable() {
    return (
      this.campaign.status === CampaignStatus.waitingAccountRegistration ||
      (this.isSuper &&
        this.campaign.invoice?.status !== InvoiceStatus.paid &&
        !this.campaign.deleted)
    )
  }

  async archiveCampaign() {
    await this.request(async () => {
      await CampaignDao.archiveCampaign(
        this.campaign.organizationId,
        this.campaign.uuid
      )
      EventBus.getCampaigns$.next()
    }, "The campaign was successfully archived")
  }

  async pauseCampaign() {
    await this.request(async () => {
      if (this.campaign.status === CampaignStatus.closed) {
        await CampaignDao.unpauseCampaign(
          this.campaign.organizationId,
          this.campaign.uuid
        )
      }
      if (
        [CampaignStatus.open, CampaignStatus.active].includes(
          this.campaign.status
        )
      ) {
        await CampaignDao.pauseCampaign(
          this.campaign.organizationId,
          this.campaign.uuid
        )
      }
      EventBus.getCampaigns$.next()
    }, `The campaign was successfully updated`)
  }

  async onArchiveCampaign() {
    const refundMessage =
      this.campaign.status === CampaignStatus.pendingActivation
        ? "100% of the cost will be refunded to your Credit Balance."
        : ""
    await this.confirm(messages.archiveCampaign(refundMessage))
    await this.archiveCampaign()
  }

  async onPauseCampaign() {
    await this.confirm(messages.pauseCampaign(this.isCampaignPaused), {
      hasIcon: true,
      icon: "exclamation-triangle"
    } as BDialogConfig)
    await this.pauseCampaign()
  }

  sendTestMessageModal() {
    this.$buefy.modal.open({
      component: TextCampaignTestMessageModal,
      width: 500,
      props: {
        campaign: this.campaign
      }
    })
  }

  checkIfHourPassed() {
    const { start: startDate, time, timezone } = this.campaign
    const dayOfWeek = moment(startDate).format("dddd").toLowerCase()
    const startTime = time[dayOfWeek]?.start
    return (
      moment
        .duration(
          moment
            .tz(timezone)
            .diff(moment.tz(`${startDate} ${startTime}`, timezone))
        )
        .asHours() < 1
    )
  }

  async onRequestCancelation() {
    await this.confirm("Are you sure you want to cancel this campaign?")
    await this.request(async () => {
      await CampaignDao.requestCampaignCancelation(
        this.campaign.organizationId,
        this.campaign.uuid
      )
      EventBus.getCampaigns$.next()
    }, "The cancelation request was sent.")
  }
}
