































import { Component, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { links } from "@/data/links"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import TextCampaignStatusSubmitted from "@/components/campaign/textCampaign/statuses/TextCampaignStatusSubmitted.vue"

@Component({
  components: {
    TextCampaignStatusSubmitted,
    ChooseItem
  }
})
export default class NonPoliticalStatusIncomplete extends VueComponent {
  @Prop() start!: string
  @Prop() timezone!: string
  @Prop() time!: any
  docsLinks = links
}
