import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import { OrderBy } from "@/enums/orderBy"

@Component
export default class OrderingFilterMixin extends VueComponent {
  filters = [
    {
      label: "Newest",
      option: "Newest",
      values: OrderBy.desc
    },
    {
      label: "Oldest",
      option: "Oldest",
      values: OrderBy.asc
    }
  ]
  orderBy: OrderBy = OrderBy.desc
}
