









import Component from "vue-class-component"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import CampaignCallerIds from "@/components/scheduler/elements/CampaignCallerIds.vue"

@Component({
  components: {
    CampaignCallerIds
  }
})
export default class VoiceCampaignPhoneNumbers extends VoiceSchedulerUtilsMixin {
  select(phoneNumber: string) {
    this.campaign.callerId = phoneNumber
    this.next(VoiceSchedulerSteps.campaignCard)
  }
}
