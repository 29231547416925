

































import Component from "vue-class-component"
import { Upload } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { FileUtils } from "@/utils/fileUtils"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component({
  components: {}
})
export default class FileRow extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() file!: Upload & {
    url: { original: string; converted: string }
  }
  @Prop() waiting!: boolean
  @Prop() label!: string

  async downloadFile(url: string, fileKey: string) {
    const type = FileUtils.getFileExtFromName(fileKey)
    const name = `${this.file.humanId} ${this.file.organization?.name}.${type}`

    await FileUtils.downloadFileByExternalUrl(url, name)
  }
}
