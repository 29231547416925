import { render, staticRenderFns } from "./SalesCommissionRates.vue?vue&type=template&id=14b26644&scoped=true&"
import script from "./SalesCommissionRates.vue?vue&type=script&lang=ts&"
export * from "./SalesCommissionRates.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b26644",
  null
  
)

export default component.exports