































































import Component from "vue-class-component"
import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import { UserLevel } from "@/enums/userLevel"
import { Emit, Prop } from "vue-property-decorator"

@Component({
  components: {
    ValidationObserver
  }
})
export default class UserLevelModal extends Vue {
  @Prop() userName!: string
  @Prop() userLevel!: UserLevel
  @Prop({ default: false }) isSuperOptionAvailable!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) isInternalAgent!: boolean

  UserLevel = UserLevel
  level: UserLevel = UserLevel.user
  internalAgent!: boolean

  created() {
    if (this.userLevel) {
      this.level = this.userLevel
    }
    this.internalAgent = this.isInternalAgent
  }

  @Emit()
  async onConfirm() {
    return { level: this.level, internalAgent: this.internalAgent }
  }
}
