<template>
  <div class="container mt-md">
    <b-message v-if="orgId && phone" class="is-primary is-borderless">
      <div class="columns is-vcentered">
        <div class="column">
          <p>
            <strong class="is-size-5"
              >Settings for {{ phone.name ? phone.name + " - " : "" }}
              {{ phone.number | phone }}
            </strong>
          </p>
          <p class="is-size-6 has-text-grey">
            Configure inbound actions for both voice calls and SMS
          </p>
        </div>
        <div class="buttons has-text-right">
          <router-link
            class="button"
            :to="`/organizations/${orgId}/phones/${phone.uuid}/messages`"
          >
            View Text & Voice Messages
          </router-link>
          <router-link
            class="button"
            :to="`/organizations/${orgId}/phones/${phone.uuid}/subscribers`"
          >
            Subscribers
          </router-link>
        </div>
      </div>
    </b-message>

    <voice-settings v-if="phone" :phone="phone" />
    <sms-settings v-if="phone" :phone="phone" />
  </div>
</template>

<script>
import VoiceSettings from "../VoiceSettings.vue"
import SmsSettings from "../SmsSettings.vue"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"

export default {
  name: "Phone",
  components: { VoiceSettings, SmsSettings },
  data() {
    return {
      orgId: this.$route.params.orgId,
      phoneId: this.$route.params.phoneId,
      phone: null,
      subscribers: 0
    }
  },
  computed: {},
  created() {
    if (this.$route.params.phoneId) {
      this.get()
    }
  },
  methods: {
    get() {
      PhoneNumberDao.getPhone(this.orgId, this.phoneId).then(
        ({ data }) => (this.phone = data)
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";

@include mobile {
  ::v-deep .media-content {
    display: contents;
  }
}
</style>
