




















































import SearchItem from "./SearchItem.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import Component from "vue-class-component"
import { Organization, PhoneList, PhoneListType } from "@robocent/components"
import { PhoneListDao } from "@/services/dao/robocent/phoneListDao"
import { Emit, Prop, Watch } from "vue-property-decorator"
import ListsEmptyMessage from "@/components/lists/ListsEmptyMessage.vue"
import UploadModal from "@/components/modals/UploadModal.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import CampaignPhoneList from "@/components/scheduler/elements/CampaignPhoneList.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { get, set } from "lodash"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"

@Component({
  components: {
    CampaignPhoneList,
    ListsEmptyMessage,
    ChooseRow,
    ChooseItem,
    PaginationItem,
    SearchItem
  }
})
export default class CampaignPhoneLists extends SchedulerUtilsMixin {
  lists: PhoneList[] = []
  @Prop() listPath!: string
  @Prop() isCampaignUpdate!: boolean
  @Prop({ default: false }) calculateLandline!: boolean

  created() {
    this.getPhoneLists()
    EventBus.startScrubPhoneList$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getPhoneLists)
    EventBus.getUploads$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getPhoneLists)
  }

  async getPhoneLists() {
    const params = {
      q: this.search,
      page: this.pagination.current,
      type: [PhoneListType.list, PhoneListType.voterData],
      limit: this.pagination.perPage
    }
    await this.request(async () => {
      const lists = await PhoneListDao.getPhoneLists(
        this.organizationId,
        params
      )
      this.setPhoneLists(lists)
    })
  }

  setPhoneLists(listsData: { data: PhoneList[]; total: number }) {
    this.pagination.total = listsData.total
    this.lists = listsData.data
  }

  isSelected(list: PhoneList) {
    return get(this.campaign, this.listPath) === list._id
  }

  select(list: PhoneList) {
    set(this.campaign, this.listPath, list._id)
    this.$forceUpdate()
  }

  @Watch("campaign.listId")
  @Watch("campaign.uploads.list")
  watchNextStepTrigger(currentValue: string, prevValue: string) {
    if (!prevValue && currentValue) {
      this.nextStep()
    }
  }

  @Emit()
  nextStep() {}

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      set(this.campaign, this.listPath, null)
      this.reset()
      this.getPhoneLists()
    }
  }

  openUploadPhoneListModal() {
    this.$buefy.modal.open({
      width: 800,
      component: UploadModal,
      parent: this,
      props: {
        orgId: this.organizationId,
        title: "Upload Phone List"
      }
    })
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getPhoneLists()
  }

  onSearch(search: string) {
    this.search = search
    this.pagination.current = 1
    this.getPhoneLists()
  }
}
