





















































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"

@Component
export default class ChooseItem extends VueComponent {
  @Prop() active!: boolean
  @Prop() rejected!: boolean
  @Prop() disabled!: boolean
  @Prop() hideHover!: boolean
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop() icon!: string
  @Prop() pack!: string
  @Prop() multiselectIndex!: number
  @Prop() tooltipText!: string
  @Prop({ default: "is-large" }) size!: string

  get classes() {
    const classes = []

    if (this.active) {
      classes.push("active")
    }

    if (this.rejected) {
      classes.push("rejected")
    }

    if (this.disabled) {
      classes.push("disabled")
    }

    if (!this.hasListener("on-click") || this.hideHover || this.disabled) {
      classes.push("hide-hover")
    }

    return classes.join(" ")
  }

  get index() {
    return this.multiselectIndex + 1
  }

  onClick() {
    if (!this.disabled) {
      this.$emit("on-click")
    }
  }
}
