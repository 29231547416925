import { Module } from "vuex"
import _ from "lodash"
import { BaseStore } from "@/voterData/interfaces/BaseStore"
import { State } from "@/voterData/interfaces/state"
import { VoterDataDao } from "@/services/dao/voterDataDao"
import { CountTypes } from "@/voterData/enums/countTypes"

const initState = {
  loading: {
    query: false,
    [CountTypes.individual]: false,
    [CountTypes.mobile]: false,
    [CountTypes.phone]: false,
    [CountTypes.email]: false
  },
  activeStep: 0,
  [CountTypes.individual]: 0,
  [CountTypes.mobile]: 0,
  [CountTypes.phone]: 0,
  [CountTypes.email]: 0,
  queryId: ""
}

export const baseStore = <Module<BaseStore, State>>{
  state: _.cloneDeep(initState),
  getters: {
    activeStep: (state) => state.activeStep,
    individual: (state) => state.individual,
    mobile: (state) => state.mobile,
    phone: (state) => state.phone,
    email: (state) => state.email,
    loading: (state) => state.loading,
    queryId: (state) => state.queryId
  },
  mutations: {
    setStep: (state: BaseStore, step: number) => {
      state.activeStep = step
    },
    updateLoading: (state: BaseStore, { type, value }) => {
      state.loading[type as CountTypes] = value
    },
    setLiveCounts: (state: BaseStore, { type, counts }) => {
      state[type as CountTypes] = counts
    },
    updateQueryId: (state: BaseStore, value: string) => {
      state.queryId = value
    },
    clearState(state: any) {
      Object.keys(initState).forEach((key: string) => {
        state[key] = _.cloneDeep(initState as any)[key]
      })
    }
  },
  actions: {
    async submitQuery(context, { state, fields }) {
      try {
        const queryId = (await VoterDataDao.submitQuery(state, fields)).data
        context.commit("updateQueryId", queryId)

        return queryId
      } catch (e) {
        if (e.request === undefined) {
          console.warn("Request cancelled")
        }
      }
    },
    async updateLiveCounts(context, { queryId, type, fields }) {
      try {
        if (!queryId) {
          return
        }
        context.commit("updateLoading", { type, value: true })
        const counts = await VoterDataDao.queryBreakout(queryId, type, fields)
        context.commit("setLiveCounts", { type, counts })
        context.commit("submitRequestStep/updateRecordsAmount", {
          type,
          value: counts
        })
        context.commit("updateLoading", { type, value: false })
      } catch (e) {
        if (e.request === undefined) {
          console.warn("Request cancelled")
        }
      }
    },
    async getStepData(context, { state, field }) {
      return await VoterDataDao.getStepData(state, field)
    },
    clearAll(context) {
      context.commit("clearState")
      context.commit("generalInfo/clearState")
      context.commit("locationInfo/clearState")
      context.commit("demographicsInfo/clearState")
      context.commit("affiliationInfo/clearState")
      context.commit("legalCompliance/clearState")
      context.commit("submitRequestStep/clearState")
    }
  }
}
