





































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { OrganizationAccountDao } from "@/services/dao/organizationAccountDao"
import { IdentityStatus, Organization } from "@robocent/components"
import { Pagination } from "@/utils/pagination"
import _ from "lodash"

@Component({
  components: {}
})
export default class RegisteredAccounts extends VueComponent {
  data: Organization[] = []
  pagination = new Pagination()
  orgName = ""

  get isPaginated() {
    return this.pagination.total > this.pagination.perPage
  }

  created() {
    this.getAccounts()
  }

  async getAccounts() {
    this.params = {
      page: this.pagination.current,
      limit: this.pagination.perPage
    }

    if (this.orgName) {
      this.params.q = this.orgName
    }

    await this.request(async () => {
      const { data, total } = await OrganizationAccountDao.getAllAccounts(
        this.params
      )

      this.data = data
      this.pagination.total = total
    })
  }

  isCompleted(row: Organization) {
    return Boolean(row.identity?.status === IdentityStatus.submitted)
  }

  openAccountPage(row: Organization) {
    window.open(row.identity?.link, "_blank")
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getAccounts()
  }

  setOrgName(name: string) {
    this.orgName = name
  }

  debounceOnFilterChange = _.debounce(({ name }) => {
    this.setOrgName(name)
    this.getAccounts()
  }, 300)
}
