













import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import GenericSearch from "@/components/GenericSearch.vue"
import AllUsers from "@/views/admin/AllUsers.vue"
import InternalUsers from "@/views/admin/InternalUsers.vue"

@Component({
  components: { InternalUsers, AllUsers, GenericSearch }
})
export default class Users extends VueComponent {
  get isSuper() {
    return this.$store.getters.super
  }
}
