
































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"

@Component({
  components: { Card }
})
export default class OrganizationCIRL extends VueComponent {
  get organization() {
    return this.$store.getters.organization || {}
  }
}
