




























































import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import CampaignAutoRefresh from "@/components/report/CampaignAutoRefresh.vue"
import moment from "moment"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import VoiceCampaignDetails from "@/components/scheduler/voiceElements/VoiceCampaignDetails.vue"
import VoiceCampaignGeneralInfo from "@/components/report/voiceCampaign/VoiceCampaignGeneralInfo.vue"
import VoiceCampaignAdditionalInfo from "@/components/report/voiceCampaign/VoiceCampaignAdditionalInfo.vue"
import VoiceCampaignScheduledBy from "@/components/report/voiceCampaign/VoiceCampaignScheduledBy.vue"
import { User } from "@robocent/components"
import { VoiceCampaignStatsDetail } from "@/interfaces/voiceCampaign/voiceCampaignStatsDetail"
import { get } from "lodash"
import VoiceCampaignReports from "@/components/report/voiceCampaign/VoiceCampaignReports.vue"
import VoiceCampaignTotal from "@/components/report/voiceCampaign/VoiceCampaignTotal.vue"
import { VoiceCampaignStatsLive } from "@/interfaces/voiceCampaign/voiceCampaignStatsLive"
import VoiceCampaignSurvey from "@/components/report/voiceCampaign/VoiceCampaignSurvey.vue"
import VoiceCampaignScript from "@/components/report/voiceCampaign/VoiceCampaignScript.vue"
import { VoiceCampaignPublicDao } from "@/services/dao/robocent/voiceCampaignPublicDao"

@Component({
  components: {
    VoiceCampaignScript,
    VoiceCampaignSurvey,
    VoiceCampaignTotal,
    VoiceCampaignReports,
    VoiceCampaignScheduledBy,
    VoiceCampaignAdditionalInfo,
    VoiceCampaignGeneralInfo,
    VoiceCampaignDetails,
    CampaignAutoRefresh
  }
})
export default class VoiceCampaignResults extends VueComponent {
  voiceCampaign: VoiceCampaign | null = null
  updatedAt: string = null!
  scheduledBy: User | null = null
  statsDetail: VoiceCampaignStatsDetail | null = null
  stats: VoiceCampaignStatsLive | null = null

  async created() {
    await this.getStats()
    this.getUser()
  }

  async getStats() {
    await this.getVoiceCampaign()
    if (this.$route.meta["call"].meta.straticsId) {
      this.getLiveStats()
      this.getStatsDetailed()
    }
    this.updatedAt = moment().format("h:mm:ss A").toString()
  }

  get isActive() {
    const endDate = get(this.statsDetail, "end_date")

    if (!endDate) {
      return true
    }

    const now = moment().utc()
    return now.isBefore(moment(endDate))
  }

  async getVoiceCampaign() {
    const { campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.voiceCampaign = await VoiceCampaignPublicDao.getVoiceCampaign(
          campaignUUID
        )
      },
      undefined,
      "Cannot get campaign",
      "getCampaign"
    )
  }

  async getUser() {
    const { campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.scheduledBy = await VoiceCampaignPublicDao.getUser(campaignUUID)
      },
      undefined,
      "Cannot get user",
      "getUser"
    )
  }

  async getLiveStats() {
    const { campaignUUID } = this.$route.params

    await this.request(
      async () => {
        this.stats = await VoiceCampaignPublicDao.getVoiceCampaignLiveStats(
          campaignUUID
        )
      },
      undefined,
      "Cannot get stats",
      "getLiveStats"
    )
  }

  async getStatsDetailed() {
    const { campaignUUID } = this.$route.params

    await this.request(
      async () => {
        this.statsDetail =
          await VoiceCampaignPublicDao.getVoiceCampaignStatsDetail(campaignUUID)
      },
      undefined,
      "Cannot get stats",
      "getStatsDetailed"
    )
  }
}
