import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"

@Component
export default class ShareablePageMixin extends VueComponent {
  onCopy({ text }: { text: string }) {
    this.openToast("You just copied: " + text)
  }

  onError() {
    this.openToast("Failed to copy link")
  }
}
