



















import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { JobAgentDao } from "@/services/dao/p2p/jobAgentDao"

@Component({
  components: {
    Card
  }
})
export default class AgentPortalInfo extends VueComponent {
  async goToAgentPortal() {
    await this.request(
      async () => {
        const { url } = await JobAgentDao.getAgentLoginLink()

        location.replace(url)
      },
      undefined,
      "Sorry, the link doesn't work. Please try again later."
    )
  }
}
