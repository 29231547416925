<template>
  <b-message
    :title="`Step ${index}: Dial Numbers`"
    @close="$emit('remove')"
    class="custom-block"
  >
    <article class="panel">
      <p class="panel-heading">Numbers will be dialed simultaneously</p>
      <div v-if="!values.length" class="panel-block is-flex-between is-block">
        <div class="has-text-grey">Add a number below</div>
      </div>
      <div
        v-for="(item, i) in values"
        :key="item.number"
        class="panel-block is-flex-between"
      >
        <div>{{ item.name }} - {{ item.number }}</div>
        <b-button
          class="button is-danger"
          native-type="button"
          @click="values.splice(i, 1)"
        >
          Remove
        </b-button>
      </div>
      <div class="panel-block no-border is-flex-between">
        <b-field label="Name" label-position="inside" class="mr-xs">
          <b-input
            placeholder="John Smith"
            v-model="newSimulring.name"
          ></b-input>
        </b-field>
        <b-field
          v-mask="'###-###-####'"
          label="Phone Number"
          label-position="inside"
          grouped
        >
          <b-input
            placeholder="555-213-4356"
            v-model="newSimulring.number"
          ></b-input>
          <p class="control">
            <b-button
              @click="
                values.push(newSimulring)
                newSimulring = {}
              "
              type="is-primary"
              :disabled="!newSimulring.name || !newSimulring.number"
            >
              Add Number
            </b-button>
          </p>
        </b-field>
      </div>
    </article>
    <div>
      <b-field :label="`${timeoutLabel} (${configuration.timeout})`">
        <b-slider
          size="is-large"
          :max="60"
          @input="update()"
          v-model="configuration.timeout"
        >
        </b-slider>
      </b-field>
    </div>
  </b-message>
</template>

<script>
export default {
  name: "VoicePlay",
  props: ["index", "numbers", "config"],
  data() {
    return {
      values: this.numbers,
      configuration: this.config,
      newSimulring: { name: null, number: null },
      timeoutLabel: "Time in Seconds to Wait for Answer"
    }
  },
  watch: {
    values: function (val) {
      this.$emit("update", val)
    }
  },
  methods: {
    update() {
      this.$emit("updateConfig", this.configuration)
    }
  }
}
</script>

<style scoped lang="scss">
.custom-block ::v-deep .media-content {
  display: flex;
  flex-direction: column;
}
</style>
