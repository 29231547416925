import Component, { mixins } from "vue-class-component"
import { PhoneList, PhoneListUtilsMixin } from "@robocent/components"
import _ from "lodash"

@Component
export class PhoneListMixin extends mixins(PhoneListUtilsMixin) {
  list: PhoneList | null = null

  get isEstimatedMobileExist() {
    return _.isNumber(this.list!.meta.details?.mobile)
  }

  get isListShouldScrubbed() {
    return (
      Boolean(this.list) &&
      !this.isEstimatedMobileExist &&
      this.scrubAvailable(this.list) &&
      !this.isListInScrubProcess
    )
  }

  get isListInScrubProcess() {
    return Boolean(this.list) && this.refreshIsAvailable(this.list)
  }

  get isListEmpty() {
    return Boolean(this.list) && this.list!.meta.details?.mobile === 0
  }

  isListReady(listSelected: boolean) {
    return (
      listSelected && !this.isListShouldScrubbed && !this.isListInScrubProcess
    )
  }
}
