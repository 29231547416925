



























import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Upload } from "@robocent/components"
import { Emit, Prop } from "vue-property-decorator"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { FileUtils } from "@/utils/fileUtils"

@Component({
  components: {
    ChooseItem
  }
})
export default class VoiceCampaignAudioItem extends VueComponent {
  @Prop() audio!: Upload
  @Prop() active!: boolean
  @Prop() disabled!: boolean
  @Prop() url!: string

  recordingBetween(sourceDuration: number) {
    return FileUtils.recordingBetween(sourceDuration)
  }

  @Emit()
  onClick() {}
}
