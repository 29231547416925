












import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import SalesManagerOrganizations from "@/components/salesPortal/SalesManagerOrganizations.vue"
import { Prop } from "vue-property-decorator"

@Component({
  components: {
    SalesManagerOrganizations,
    StepTemplate
  }
})
export default class OrganizationEarnings extends SalesPortalMixin {
  @Prop() userId!: string
}
