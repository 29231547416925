































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import {
  Campaign,
  CampaignProgress,
  CampaignProgressMixin,
  CardTable,
  CardTableItem
} from "@robocent/components"
import Card from "@/components/elements/Card.vue"

@Component({
  components: {
    CampaignProgress,
    Card,
    CardTable,
    CardTableItem
  }
})
export default class CampaignLiveProgress extends CampaignProgressMixin {
  @Prop() campaign!: Campaign
  @Prop() waiting!: boolean
  @Prop({ default: false }) shared!: boolean
  progress = null

  get inProgress() {
    return (
      !this.progress && this.checkIfStatusHasProgress(this.campaign?.status)
    )
  }

  onProgressUpdate(progress: any) {
    this.progress = progress
  }
}
