
































































































































import Component from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"
import { SalesPayout } from "@/interfaces/salesPortal/salesPayout"
import SalesDetailsViewModal from "@/components/modals/salesPortal/SalesDetailsViewModal.vue"
import { statuses } from "@robocent/components"
import MarkSalesPayoutsAsPaid from "@/components/modals/salesPortal/MarkSalesPayoutsAsPaid.vue"
import { PartnerPayoutTotals } from "@/interfaces/partnerPortal/partnerPayoutTotals"
import { OrganizationTab } from "@/enums/organizationTab"
import PayoutsTableMixin from "@/mixins/PayoutsTableMixin"

@Component({})
export default class SalesPayoutsTable extends PayoutsTableMixin {
  @Ref("table") table: any
  @Prop() data!: SalesPayout[]
  @Prop() type!: string
  @Prop() isLoading!: boolean
  @Prop({
    default: () => ({
      totalSales: 0,
      totalEarnings: 0
    })
  })
  totals!: {
    totalSales: number
    totalEarnings: number
  }
  statuses = statuses
  selectedRow = ""
  tabs = OrganizationTab

  get totalSalesSubheading() {
    return this.getValueInCurrency(this.totals?.totalSales)
  }

  get totalCommissionSubheading() {
    return this.getValueInCurrency(this.totals?.totalEarnings)
  }

  getClass(row: PartnerPayoutTotals) {
    return row.id === this.selectedRow && "selected-row"
  }

  onDetailsOpen(row: SalesPayout) {
    this.selectedRow = row.id
  }

  toggle(row: SalesPayout) {
    this.table.toggleDetails(row)
  }

  onViewDetails(row: SalesPayout) {
    this.$buefy.modal.open({
      component: SalesDetailsViewModal,
      parent: this,
      width: 1600,
      props: {
        userId: row.id,
        title: `${row.firstName} ${row.lastName}`
      }
    })
  }

  markPayoutsAsPaid({ userId, name }: { userId: string; name: string }) {
    this.$buefy.modal.open({
      parent: this,
      component: MarkSalesPayoutsAsPaid,
      width: 400,
      props: {
        userId,
        name
      }
    })
  }
}
