export const links = {
  main: "https://docs.robocent.com",
  registration10DLC:
    "https://docs.robocent.com/outreach/text-messaging/compliance/10dlc",
  campaignVerify: "https://www.campaignverify.org/get-started",
  p2p: "https://docs.robocent.com/services/text-messaging",
  humanVsAutomatedTexting:
    "https://docs.robocent.com/services/text-messaging/human-vs-automated-texting#who-sends-the-texts",
  scriptContentStandards: {
    main: "https://docs.robocent.com/script-content-standards",
    mergedFields:
      "https://docs.robocent.com/script-content-standards#merge-fields"
  },
  howToScheduleTextMessage:
    "https://docs.robocent.com/guides-and-how-to/how-to-schedule-text-message",
  howToScheduleVoiceCampaign:
    "https://docs.robocent.com/guides-and-how-to/how-to-schedule-voice-campaign",
  howToScrubAList:
    "https://docs.robocent.com/guides-and-how-to/how-to-scrub-a-list",
  voterData: "https://docs.robocent.com/services/voter-data"
}
