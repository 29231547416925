import Component from "vue-class-component"
import DeleteCampaign from "@/components/campaign/DeleteCampaign.vue"
import { VueComponent } from "@/utils/VueComponent"
import { EventBus } from "@/services/eventBus"
import { Campaign } from "@robocent/components"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component({})
export default class DeleteCampaignMixin extends VueComponent {
  onCampaignDelete(campaign: Campaign | VoiceCampaign) {
    this.$buefy.modal.open({
      component: DeleteCampaign,
      width: 500,
      props: { campaign },
      events: {
        onConfirm: () => EventBus.getCampaigns$.next()
      }
    })
  }
}
