









































































import Component, { mixins } from "vue-class-component"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import { InputWithValidation, SwitchWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { Ref } from "vue-property-decorator"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import { PromoCodeMixin } from "@/mixins/campaign/PromoCodeMixin"

@Component({
  components: {
    ChooseRow,
    ValidationObserver,
    SwitchWithValidation,
    InputWithValidation
  }
})
export default class VoiceCampaignDetails extends mixins(
  VoiceSchedulerUtilsMixin,
  PromoCodeMixin
) {
  @Ref("observer") observer!: any

  async nextStep() {
    if (this.promoCodeMessage()) {
      return this.openFailedToast(this.promoCodeMessage())
    }

    if (await this.observer.validate()) {
      this.next(VoiceSchedulerSteps.campaignCost)
    }
  }
}
