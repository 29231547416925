

















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import OpenPayouts from "@/components/agent/OpenPayouts.vue"
import CompletedPayouts from "@/components/agent/CompletedPayouts.vue"
import AgentRequestedPayoutsTable from "@/components/agent/AgentRequestedPayoutsTable.vue"

@Component({
  components: {
    AgentRequestedPayoutsTable,
    CompletedPayouts,
    OpenPayouts
  }
})
export default class AgentsPayout extends VueComponent {}
