








import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import TextCampaignRates from "@/components/organization/rates/TextCampaignRates.vue"

@Component({
  components: { TextCampaignRates }
})
export default class OrganizationRates extends VueComponent {}
