import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { Campaign } from "@robocent/components"
import { TextCampaignConfigureDao } from "@/services/dao/p2p/textCampaignConfigureDao"
import { PhoneListConfigureService } from "@/services/phoneListConfigureService"

export class TextPhoneListService extends PhoneListConfigureService {
  async checkPhoneListStatus(campaignUUID: string) {
    return await TextCampaignConfigureDao.checkPhoneListStatus(campaignUUID)
  }

  async getPhoneListData(campaign: Campaign) {
    return await UploadsDao.getFileData(
      campaign.organizationId,
      campaign.listId
    )
  }

  async uploadPhoneList(campaign: Campaign, config: any, mapping: any) {
    if (!campaign?.metadata?.phoneListToken) {
      await TextCampaignConfigureDao.uploadPhoneList(campaign.uuid, {
        config,
        mapping
      })
    }

    await this.waitWhenPhoneListWillBeUploaded(campaign.uuid)
  }
}
