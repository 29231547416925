






















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { PrePayItem } from "@/interfaces/prePayItem"

@Component({
  components: { Card }
})
export default class PrePayAmount extends VueComponent {
  @Prop() prePayItem!: PrePayItem

  @Emit()
  onClick() {}
}
