



































import Component from "vue-class-component"
import PartnerCampaignsTable from "@/components/partnerPortal/PartnerCampaignsTable.vue"
import Card from "@/components/elements/Card.vue"
import { PartnerType } from "@/enums/partnerPortal/partnerType"
import PartnerPortalMixin from "@/mixins/partnerPortal/PartnerPortalMixin"
import { Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({
  components: {
    Card,
    PartnerCampaignsTable
  }
})
export default class PartnerPayouts extends PartnerPortalMixin {
  @Prop() isLoading!: boolean
  partnerPayoutStatuses = PartnerPayoutStatus
  activeTab = 0
  mappedTabs: { [key: number]: PartnerPayoutStatus } = {
    0: PartnerPayoutStatus.open,
    1: PartnerPayoutStatus.paid
  }

  get partnerType() {
    return _.find(
      this.partnerSettings,
      (entry) => entry.feature === this.selectedService
    )?.type
  }

  get isCommission() {
    return this.partnerType === PartnerType.commission
  }

  get isDiscount() {
    return this.partnerType === PartnerType.discount
  }

  @Watch("selectedService")
  async watchSelectedService() {
    this.activeTab = 0
    await this.onChange()
  }

  @Watch("activeTab")
  async watchActiveTab() {
    await this.onChange()
  }

  onChange() {
    this.$emit("on-change", {
      type: this.selectedService,
      status: this.mappedTabs[this.activeTab]
    })
  }
}
