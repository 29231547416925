



















import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { Watch } from "vue-property-decorator"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { votingHistories } from "@/voterData/data/votingHistory"
import _ from "lodash"

@Component({
  components: {
    StepTemplate
  }
})
export default class VotingHistory extends VoterDataUtilsMixin {
  votingHistories = votingHistories

  get votingHistory() {
    return this.$store.getters["voterData/affiliationInfo/votingHistory"]
  }
  set votingHistory(value: string) {
    this.$store.commit("voterData/affiliationInfo/updateVotingHistory", value)
  }

  @Watch("votingHistory")
  watchVotingHistory(nextValue: string[], prevValue: string[]) {
    if (!_.isEqual(nextValue, prevValue)) {
      this.updateQuery()
    }
  }
}
