import Component, { mixins } from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { PhoneList, PhoneListUtilsMixin, Upload } from "@robocent/components"
import { Ref, Watch } from "vue-property-decorator"
import { Pagination } from "@/utils/pagination"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"
import PaymentMixin from "@/mixins/PaymentMixin"
import { EventBus } from "@/services/eventBus"

@Component({})
export default class OrganizationPhoneListMixin extends mixins(
  VueComponent,
  PhoneListUtilsMixin,
  PaymentMixin
) {
  uploads: Upload[] | PhoneList[] = []
  signedUrls: string[] = []
  orgId!: string
  pagination = new Pagination(0, 1, 10)
  leftTime = 15 * 1000

  @Ref("countdown") countdown: any

  get search(): string | null {
    return this.$route.query.q as string | null
  }

  async onPay(invoice: Invoice) {
    await this.confirmPayWithBalanceOrCreditCard(invoice)
    await this.updateLists()
  }

  async getSignedUrls() {
    const ids = this.uploads?.map((item) => item._id)
    this.signedUrls = await UploadsDao.getLinksForUploads(ids)
  }

  getListsToRefresh() {
    return this.uploads?.filter(
      (list) =>
        this.refreshIsAvailable(list) || this.isLookupOpenOrProcessing(list)
    )
  }

  async updateLists() {
    const listsToRefresh = this.getListsToRefresh()

    if (listsToRefresh?.length) {
      await EventBus.getUploads$.next()
      this.countdown.startCountdown("restart")
    }
  }

  @Watch("uploads", { deep: true })
  async onUploadsChange() {
    const listsToRefresh = this.getListsToRefresh()
    if (listsToRefresh?.length) {
      this.countdown.startCountdown("restart")
    }
  }
}
