<!-- eslint-disable vue/no-mutating-props -->
<template>
  <big-card v-if="phone">
    <section>
      <div class="columns">
        <div class="column">
          <p>
            <strong class="is-size-5">Inbound SMS AutoResponder</strong>
          </p>
          <p class="is-size-6 has-text-grey">
            This action will trigger when your number receives an inbound text
            message.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="columns">
        <div class="column">
          <b-message type="is-info">
            <p>
              An SMS AutoResponder allows you to define simple or complex
              autoresponses to keywords. For example, when you receive an SMS
              that says
              <em>"hello"</em>, the autoresponder can reply with
              <em
                >"Thank you for contacting us! Please call
                {{ phone.number | phone }} to speak to a representative."</em
              >
            </p>
          </b-message>
          <b-collapse :open="false" class="card" animation="slide">
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header has-no-shadow"
              role="button"
            >
              <p class="card-header-title">
                View Complex SMS AutoResponder Example
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'caret-down' : 'caret-up'">
                </b-icon>
              </a>
            </div>
            <div class="card-content">
              <div class="content">
                <p>
                  A complex tree can also be created by directing users to
                  follow a path of keywords. For example:
                </p>
                <blockquote>
                  <p>Q: <em>"Where can I vote"</em></p>
                  <p>
                    A:
                    <em
                      >"What district do you live in? Available options: '1st',
                      '2nd', '3rd', '4th'"</em
                    >
                  </p>
                  <p>Q: <em>"4th"</em></p>
                  <p>
                    A:
                    <em
                      >"Your polling location is 123 Falling Springs Drive."</em
                    >
                  </p>
                </blockquote>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Default Response" label-position="inside">
            <b-input size="is-large" v-model="phone.smsTree.intro"></b-input>
          </b-field>
          <div>
            <article class="panel is-primary users-panel">
              <p class="panel-heading">AutoResponder Q&A</p>
              <div
                class="panel-block is-flex-between"
                v-if="!phone.smsTree.conversations.length"
              >
                <div class="has-text-grey">Add a question and answer below</div>
              </div>
              <div
                v-for="(item, index) in phone.smsTree.conversations"
                :key="index"
              >
                <div class="panel-block no-border">
                  <span class="panel-icon">
                    <i class="fas fa-question" aria-hidden="true"></i>
                  </span>
                  <b-taglist>
                    <b-tag
                      v-for="q in item.question.split(',')"
                      :key="q"
                      type="is-success"
                      size="is-medium"
                      >{{ q }}</b-tag
                    >
                  </b-taglist>
                </div>
                <div class="panel-block is-flex-between">
                  <span>
                    <span class="panel-icon">
                      <i class="fas fa-reply" aria-hidden="true"></i>
                    </span>

                    "{{ item.answer }}"
                  </span>
                  <b-button
                    class="button is-danger"
                    native-type="button"
                    @click="phone.smsTree.conversations.splice(index, 1)"
                  >
                    Remove
                  </b-button>
                </div>
              </div>
              <div class="panel-block no-border is-flex-between">
                <b-field
                  expanded
                  label="Question"
                  label-position="inside"
                  class="mr-xs"
                >
                  <b-input
                    placeholder="help"
                    v-model="newConversation.question"
                  ></b-input>
                </b-field>
                <b-field
                  expanded
                  label="Answer"
                  label-position="inside"
                  grouped
                >
                  <b-input
                    placeholder="You can visit our website at www.RoboCent.com"
                    v-model="newConversation.answer"
                  ></b-input>
                  <p class="control">
                    <b-button
                      @click="addQa()"
                      type="is-primary"
                      :disabled="
                        !newConversation.question || !newConversation.answer
                      "
                    >
                      Add QA
                    </b-button>
                  </p>
                </b-field>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-flex-end">
          <b-button @click="onSave" size="is-medium" type="is-success"
            >Save SMS Tree</b-button
          >
        </div>
      </div>
    </section>
  </big-card>
</template>

<script>
import BigCard from "./elements/BigCard.vue"
import { PhoneNumberDao } from "../services/dao/robocent/phoneNumberDao"

export default {
  name: "SmsSettings",
  components: { BigCard },
  props: ["phone"],
  data() {
    return {
      newConversation: { name: "", number: "" },
      selectedVoiceType: null,
      orgId: this.$route.params.orgId,
      phoneId: this.$route.params.phoneId
    }
  },
  created() {
    if (this.$route.params.phoneId) {
      this.init()
    }
  },
  methods: {
    addQa() {
      // eslint-disable-next-line vue/no-mutating-props
      this.phone.smsTree.conversations.push(this.newConversation)
      this.newConversation = { name: "", number: "" }
    },
    async onSave() {
      await PhoneNumberDao.saveSms(this.orgId, this.phone)
      this.$buefy.toast.open("Successfully Saved!")
    },
    init() {}
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";

.panel-icon {
  vertical-align: middle;
}
.panel-block:first-child {
  border-top: none !important;
}

@include mobile {
  .panel-block {
    display: block;
  }
}
</style>
