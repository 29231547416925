















































































import Component, { mixins } from "vue-class-component"
import TextCampaignItem from "@/components/campaign/TextCampaignItem.vue"
import {
  Campaign,
  CampaignFilters,
  CampaignStatus,
  TextCampaignFilterMixin
} from "@robocent/components"
import { Prop, Ref, Watch } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import { Pagination } from "@/utils/pagination"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import CampaignsListMixin from "@/mixins/campaign/CampaignsListMixin"
import VoiceCampaignItem from "./voiceCampaign/VoiceCampaignItem.vue"
import { CampaignDirection } from "@/enums/campaignDirection"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"

@Component({
  components: {
    SearchItem,
    PaginationItem,
    CampaignFilters,
    TextCampaignItem,
    VoiceCampaignItem
  }
})
export default class CampaignsList extends mixins(
  CampaignsListMixin,
  TextCampaignFilterMixin
) {
  @Ref("campaignsCountdown") countdown: any
  @Prop() showArchived!: boolean
  @Prop() campaignDir!: CampaignDirection
  leftTime = 15 * 1000
  campaigns: Campaign[] = []
  initialOption: string | null = ""
  defaultOptions = {
    status: this.filters,
    q: null
  } as FindManyFilter
  pagination = new Pagination(0, 1, 10)
  campaignDirection = CampaignDirection

  get filters() {
    const generalFilters = [
      CampaignStatus.pending,
      CampaignStatus.pendingCancelation,
      CampaignStatus.open,
      CampaignStatus.closed,
      CampaignStatus.pendingActivation,
      CampaignStatus.rejected,
      CampaignStatus.underReview,
      CampaignStatus.canceled,
      CampaignStatus.completed,
      VoiceCampaignStatus.approved
    ]

    return this.showArchived
      ? [...generalFilters, CampaignStatus.archived, CampaignStatus.deleted]
      : generalFilters
  }

  get search(): string | null {
    return this.$route.query.q as string | null
  }

  get isArchivedFilterEnabled() {
    return Boolean(
      this.$route.query.status?.includes(CampaignStatus.archived) ||
        this.showArchived
    )
  }

  async created() {
    this.initialOption = this.getStatusFromQuery(
      this.$route.query.status,
      this.filters
    )
    await this.getCampaigns()
    EventBus.getCampaigns$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getCampaigns)
  }

  async getCampaigns() {
    const filter = Object.assign(
      this.defaultOptions,
      this.pagination.query,
      this.$route.query,
      {
        status:
          this.prepareStatusFromQuery(this.$route.query.status) || this.filters
      }
    )

    await this.request(
      async () => {
        let response
        if (this.$route.name?.includes("Admin")) {
          switch (this.campaignDir) {
            case CampaignDirection.text:
              response = await CampaignDao.getAllTextCampaigns(filter)
              break
            case CampaignDirection.voice:
              response = await VoiceCampaignDao.getAllVoiceCampaigns(filter)
              break
            default:
              response = await CampaignDao.getAllAdminCampaigns(filter)
          }
        } else {
          switch (this.campaignDir) {
            case CampaignDirection.text:
              response = await CampaignDao.getCampaigns(
                this.$route.params.orgId,
                filter
              )
              break
            case CampaignDirection.voice:
              response = await VoiceCampaignDao.getVoiceCampaigns(
                this.$route.params.orgId,
                filter
              )
              break
            default:
              response = await CampaignDao.getAllCampaigns(
                this.$route.params.orgId,
                filter
              )
          }
        }

        this.campaigns = response.data
        this.pagination.total = response.total
        this.pagination.current = Number(filter.page)

        if (
          this.pagination.current !== 1 &&
          response.total <= this.pagination.perPage
        ) {
          this.applyQuery({ ...filter, page: 1 })
        }
      },
      null,
      "Cannot get campaigns"
    )
  }

  async updateCampaigns() {
    const campaignsToRefresh = this.campaigns.filter((campaign) =>
      [
        CampaignStatus.pending,
        CampaignStatus.pendingActivation,
        CampaignStatus.underReview,
        CampaignStatus.closed
      ].includes(campaign.status)
    )

    if (campaignsToRefresh.length > 0) {
      await this.getCampaigns()
      this.countdown.startCountdown("restart")
    }
  }

  @Watch("$route.query")
  onRouteChange() {
    this.getCampaigns()
  }
}
