















import Component from "vue-class-component"
import { CardTable, CardTableItem, User } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  }
})
export default class VoiceCampaignScheduledBy extends VueComponent {
  @Prop() user!: User
  @Prop() waiting!: boolean
}
