// @ts-nocheck
import _ from "lodash"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import {
  VoiceCampaignType as EVoiceCampaignType,
  VoiceCampaignType
} from "@/enums/voiceCampaignType"
import { TextUtils } from "@/utils/textUtils"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

export class CampaignCostService {
  additionalPrice = 0.01
  durationStep = 15
  minimumOrder = {
    robocall: 30,
    rvm: 50,
    pushpoll: 50,
    survey: 75,
    transfer: 30,
    self: 30,
    managed: 30
  }
  minLeads = 100000
  middleLeads = 500000
  maxLeads = 1000000

  checkMinimalCost(message) {
    if (message.total < message.minimalCost) {
      return message.minimalCost
    }
    return message.total
  }

  calculateCostForAudio(audioDuration, basePrice) {
    const baseDuration = 15
    const durationToCalculate = Math.round(audioDuration)
    return durationToCalculate > baseDuration
      ? Math.ceil((durationToCalculate - baseDuration) / this.durationStep) *
          this.additionalPrice +
          basePrice
      : basePrice
  }

  calculateRobocall(message, audioDuration) {
    const basePrice = 0.01
    const audioCost = this.calculateCostForAudio(audioDuration, basePrice)
    const modifiedObj = { ...message }
    modifiedObj.cost = audioCost
    modifiedObj.total = message.leads * audioCost
    return modifiedObj
  }

  calculateBasedOnRobocall(message, audioDuration) {
    const baseCost = message.cost
    const modifiedObj = {
      ...this.calculateRobocall(message, audioDuration)
    }
    modifiedObj.cost += baseCost
    modifiedObj.total += message.leads * baseCost
    return modifiedObj
  }

  calculateSimpleMessage(message) {
    const modifiedObj = { ...message }
    modifiedObj.total = modifiedObj.cost * message.leads
    return modifiedObj
  }

  calculateTeleTownHall(message) {
    const modifiedObj = { ...message }
    _.map(modifiedObj.connects, (item) => {
      if (message.leads >= item.from && message.leads <= item.to) {
        modifiedObj.total = item.cost
      }
    })
    return modifiedObj
  }

  applyLeadsDiscount(price, percent) {
    return price - (price * parseInt(percent)) / 100
  }

  checkLeadsDiscount(message) {
    const minDiscount = 10
    const maxDiscount = 20
    if (message.leads >= this.minLeads && message.leads < this.middleLeads) {
      const appliedDiscountToTotal = this.applyLeadsDiscount(
        message.total,
        minDiscount
      )
      return {
        ...message,
        discount: `${minDiscount}% Bulk Discount`,
        discountRate: minDiscount,
        discountAmount: message.total - appliedDiscountToTotal,
        total: appliedDiscountToTotal
      }
    }
    if (message.leads >= this.middleLeads && message.leads < this.maxLeads) {
      const appliedDiscountToTotal = this.applyLeadsDiscount(
        message.total,
        maxDiscount
      )
      return {
        ...message,
        discount: `${maxDiscount}% Bulk Discount`,
        discountRate: maxDiscount,
        discountAmount: message.total - appliedDiscountToTotal,
        total: appliedDiscountToTotal
      }
    }
    return message
  }

  calculateInvoiceItemsCost(data, callType) {
    let totalMulti = 0
    let totalDiscounts = 0
    data.multis.forEach((element) => {
      totalMulti += element.amount
    })

    const subtotal = totalMulti * data.leads

    const pureCost = Math.round(subtotal * 100)

    // Calculate minimum cost before add fee
    let totalCents = this.getMinimumCost(subtotal * 100, callType)
    const minimalCost = totalCents

    let totalFees = 0
    data.fees.forEach((element) => {
      totalFees += parseFloat(element.amount)
    })

    totalCents = totalCents + totalFees * 100

    const priceBeforeDiscount = totalCents

    if (data.percentDiscounts) {
      data.percentDiscounts.forEach((element) => {
        const discount = (totalCents * parseInt(element.amount)) / 100
        totalCents -= discount
        totalDiscounts += discount
      })
    }

    let totalFixedDiscount = 0
    if (data.fixedDiscounts) {
      data.fixedDiscounts.forEach((element) => {
        totalFixedDiscount += parseInt(element.amount * 100)
      })
    }
    totalCents = totalCents - totalFixedDiscount
    totalDiscounts += totalFixedDiscount
    data.total = totalCents
    const { discount, discountRate, discountAmount } =
      this.checkLeadsDiscount(data)

    const {
      total,
      discount: couponDiscount,
      discountAmount: couponDiscountAmount
    } = this.applyCouponIfExist(totalCents, data)
    totalDiscounts += couponDiscountAmount || 0

    return {
      multis: data.multis,
      fees: data.fees,
      basedOnCount: data.leads,
      costPerMessage: Math.round(totalMulti * 100) / 100,
      discount,
      discountRate,
      discountAmount,
      couponDiscount,
      couponDiscountAmount,
      total,
      priceBeforeDiscount,
      subtotal: totalCents,
      minimalCost,
      pureCost,
      totalDiscounts
    }
  }

  getMinimumCost(cost, type) {
    let minimumCost = 0

    if (Object.keys(this.minimumOrder).includes(type)) {
      minimumCost = this.minimumOrder[type] * 100
    }

    return cost < minimumCost ? minimumCost : cost
  }

  applyCouponIfExist(total, data) {
    if (data.coupon) {
      let totalWithDiscount = total
      const amountOff = data.coupon.amount_off
      const percentOff = data.coupon.percent_off

      if (amountOff) {
        totalWithDiscount -= amountOff
      } else {
        const amountOffPercent = total * (percentOff / 100)
        totalWithDiscount -= amountOffPercent
      }

      return {
        total: totalWithDiscount,
        discount: amountOff ? "$" + amountOff / 100 : percentOff + "%",
        discountAmount: total - totalWithDiscount
      }
    }

    return { total, discount: null, discountAmount: null }
  }

  async getCostBasedOnCallObject(call) {
    const campaign = {
      ...call,
      uploads: {
        list: call.uploads.list._id,
        liveRecording: call.uploads.liveRecording._id,
        vmRecording: call.uploads.vmRecording._id
      }
    }

    const { data } = await BillingDao.getCostBasedOnCallObject(campaign)
    return data
  }

  async checkRushAndGetMessage(call) {
    const data = await this.getCostBasedOnCallObject(call)
    const rushMessage = (text, hours, amount) =>
      `This ${text}message has been scheduled to send within ${hours} hours from now.
        A Rush Fee of $${amount} will be added to your invoice.`

    if (data.fees[0]) {
      call.meta.rush = true
      const amount = data.fees[0].amount
      const notSmsHours = call.type === VoiceCampaignType.survey ? "4" : "two"
      return rushMessage("", notSmsHours, amount)
    }
    call.meta.rush = false

    return null
  }

  setScriptField(voiceCampaign: VoiceCampaign) {
    if (voiceCampaign.type !== EVoiceCampaignType.survey) {
      voiceCampaign.script = ""
      return
    }
    voiceCampaign.script = TextUtils.removeSpaces(voiceCampaign.script)
  }
}
