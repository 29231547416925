


























import Component from "vue-class-component"
import Multiselect from "vue-multiselect"
import _ from "lodash"
import { Prop } from "vue-property-decorator"
import { JurisdictionType } from "@/voterData/interfaces/JurisdictionType"
import County from "@/voterData/components/jurisdiction/County.vue"
import ZipCode from "@/voterData/components/jurisdiction/ZipCode.vue"
import { JurisdictionTypes } from "@/voterData/enums/jurisdictionTypes"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"

interface JurisdictionComponent {
  name: string
  component: string
  slug: JurisdictionTypes
}

@Component({
  components: {
    Multiselect,
    County,
    ZipCode
  }
})
export default class JurisdictionRow extends VoterDataUtilsMixin {
  @Prop() jurisdiction!: JurisdictionType
  @Prop() allTypes!: JurisdictionComponent[]

  get jurisdictionTypes() {
    return this.$store.getters["voterData/locationInfo/jurisdictionTypes"]
  }

  get filteredTypes() {
    const usedTypes = _(this.jurisdictionTypes)
      .map("type.name")
      .filter((entry) => entry !== this.jurisdiction.type.name)
      .value()
    return this.allTypes.filter(
      (entry: JurisdictionComponent) => !usedTypes.includes(entry.name)
    )
  }

  onSelect(selectedOption: {
    name: string
    component: string
    slug: JurisdictionTypes
  }) {
    this.$store.commit(
      "voterData/locationInfo/clearField",
      this.jurisdiction.type.slug
    )
    this.jurisdiction.type = selectedOption
  }
}
