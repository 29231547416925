








































































import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { ListCard, PhoneList } from "@robocent/components"
import PhoneListButtons from "@/components/uploads/list/PhoneListButtons.vue"
import { VueComponent } from "@/utils/VueComponent"
import PhoneListItemMixin from "@/mixins/PhoneListItemMixin"
import SharedUploadTag from "@/components/uploads/SharedUploadTag.vue"

@Component({
  components: {
    SharedUploadTag,
    ListCard,
    PhoneListButtons
  }
})
export default class PhoneListItem extends mixins<
  VueComponent,
  PhoneListItemMixin
>(VueComponent, PhoneListItemMixin) {
  @Prop() list!: PhoneList
  @Prop() signedUrls!: object
  @Prop() orgId!: string

  get status() {
    return this.getStatus(this.list)
  }

  get isDisabled() {
    return this.list.meta.deleted ? "disabled" : ""
  }
}
