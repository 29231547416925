import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { CampaignStatus, Invoice, statuses } from "@robocent/components"

@Component
export default class CampaignInvoiceMixin extends VueComponent {
  @Prop() invoice!: Invoice
  @Prop({ required: false }) campaignStatus!: CampaignStatus

  get isLinkAvailable(): boolean {
    return this.invoice?.status !== statuses.draft
  }

  get isInvoiceAvailable(): boolean {
    const { pendingCancelation, canceled } = CampaignStatus

    return ![pendingCancelation, canceled].includes(this.campaignStatus)
  }

  onInvoiceClick() {
    if (!this.isLinkAvailable) {
      return
    }

    window.open(this.invoice.hosted_invoice_url, "_blank")
  }
}
