










































import Component, { mixins } from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { Prop } from "vue-property-decorator"
import moment from "moment"
import CampaignDateMixin from "@/mixins/campaign/CampaignDateMixin"

@Component({
  components: { Card }
})
export default class VoiceCampaignMessageConfirmationModal extends mixins(
  CampaignDateMixin
) {
  @Prop() time!: string
  @Prop() timezone!: string

  get campaignTime() {
    return `${this.timeInTimezone(this.timezone, this.time).format(
      "dddd, MMMM Do, YYYY h:mm A"
    )}`
  }

  timeInTimezone(timezone: string, time: string) {
    return moment(
      new Date(this.formattedTimeJS(moment.utc(time).toDate(), timezone))
    )
  }
}
