import moment from "moment"
import { Campaign } from "@robocent/components"
import { ScheduleService } from "@/services/voice/scheduleService"
import { TextCampaignConfigureDao } from "@/services/dao/p2p/textCampaignConfigureDao"

export class TextScheduleService {
  scheduleService = new ScheduleService()

  async createTextSchedule(campaign: Campaign) {
    const defaultTimezone = "LOCAL"
    const { schedule, timezone } = this.prepareRequestObject(campaign)

    const data = Object.assign(
      {
        schedule_name: campaign.id,
        schedule_timezone: !timezone
          ? defaultTimezone
          : this.scheduleService.convertTimeZone(campaign.timezone),
        is_global: 1
      },
      schedule
    )

    return await TextCampaignConfigureDao.createSchedule(campaign.uuid, data)
  }

  prepareRequestObject(campaign: Campaign) {
    const activeDay: string = moment(campaign.start).format("ddd").toLowerCase()
    const days: string[] = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
    const schedule = {}

    days.forEach((day: string) => {
      const startTime =
        day === activeDay
          ? this.formatStartTime(campaign)
          : this.getDefaultStartTime()
      this.prepareDayDate(schedule, day, startTime)
    })

    const timezone = this.getTimezone(campaign)

    return { schedule, timezone }
  }

  prepareDayDate(source: any, key: string, startTime: moment.MomentInput) {
    const startKey = `${key}_start`
    const endKey = `${key}_end`

    source[startKey] = moment(startTime).format("HH:mm:00")
    source[endKey] = moment(this.scheduleService.formatEndTime()).format(
      "HH:mm:00"
    )
  }

  formatStartTime(campaign: Campaign) {
    const activeDay = moment(campaign.start).format("dddd").toLowerCase()
    return moment(`${campaign.start} ${campaign.time[activeDay].start}`)
  }

  getDefaultStartTime() {
    const date = new Date()
    date.setHours(8)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
  }

  getTimezone(campaign: Campaign) {
    return (
      moment(campaign.time.monday.start, "HH:mm").isAfter(
        moment("08:59", "HH:mm")
      ) && campaign.timezone
    )
  }
}
