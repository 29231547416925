


































import Component, { mixins } from "vue-class-component"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import CardItem from "@/components/elements/CardItem.vue"
import { CampaignScheduledDate } from "@robocent/components"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import CampaignPhoneList from "@/components/scheduler/elements/CampaignPhoneList.vue"
import CampaignMixin from "@/mixins/campaign/CampaignMixin"
import CampaignCost from "@/components/scheduler/textElements/TextCampaignCost.vue"
import TextOverviewMixin from "@/components/campaign/textAdminReview/TextOverviewMixin"

@Component({
  components: {
    CampaignCost,
    CampaignPhoneList,
    ChooseItem,
    CardItem,
    ChooseRow,
    CampaignScheduledDate
  }
})
export default class TextCampaignDetails extends mixins(
  CampaignMixin,
  TextOverviewMixin
) {}
