import moment from "moment/moment"
import {
  Agent,
  InvitedAgent,
  ScheduleDays,
  Script,
  timezones,
  User
} from "@robocent/components"

export function buildTextCampaign() {
  return {
    ...buildTextCampaignTime(),
    organization: {},
    listId: "",
    service: null as any,
    timezone: timezones[0].locale,
    scripts: [] as Script[],
    agents: [] as Agent[],
    invitedAgents: [] as InvitedAgent[],
    defaultAgents: [] as User[],
    phone: ""
  }
}

export function buildTextCampaignTime() {
  const defaultTime = {
    start: null,
    end: null
  }
  const range = 7

  return {
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).add(range, "days").format("YYYY-MM-DD"),
    time: {
      [ScheduleDays.sunday]: { ...defaultTime },
      [ScheduleDays.monday]: { ...defaultTime },
      [ScheduleDays.tuesday]: { ...defaultTime },
      [ScheduleDays.wednesday]: { ...defaultTime },
      [ScheduleDays.thursday]: { ...defaultTime },
      [ScheduleDays.friday]: { ...defaultTime },
      [ScheduleDays.saturday]: { ...defaultTime }
    }
  }
}
