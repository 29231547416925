import { ReportType } from "@/interfaces/campaignReports"
import axios from "axios"

export class ReportDao {
  static async getCampaignReports(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports`
    )
  }

  static async generateCampaignReport(
    orgId: string,
    campaignUUID: string | number,
    type: ReportType
  ) {
    return axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports`,
      { type }
    )
  }

  static async getCampaignProgress(
    orgId: string,
    campaignUUID: string | number
  ) {
    return axios.get(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports/campaign-progress`
    )
  }

  static async getCampaignStats(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports/campaign-stats`
    )
  }

  static async getCampaignSurveyStats(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports/campaign-survey-stats`
    )
  }

  static async getCampaignSurveyResponses(
    orgId: string,
    campaignId: string | number,
    scriptId: string | number
  ) {
    return (
      await axios.get(
        `/text/organizations/${orgId}/campaigns/${campaignId}/reports/campaign-survey-responses/${scriptId}`
      )
    ).data
  }

  static async getCampaignAgentsStats(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports/campaign-agents-stats`
    )
  }

  static async getDetailedCampaignStats(orgId: string, campaignUUID: string) {
    return (
      await axios.get(
        `/text/organizations/${orgId}/campaigns/${campaignUUID}/reports/campaign-detailed-stats`
      )
    ).data
  }
}
