


















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { AgentDao } from "@/services/dao/agentDao"
import { EventBus } from "@/services/eventBus"
import { Agent } from "@robocent/components"
import DeletedAgentItem from "@/components/agent/DeletedAgentItem.vue"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: { DeletedAgentItem }
})
export default class DeletedAgentsList extends VueComponent {
  deletedAgents: Agent[] = []

  async created() {
    EventBus.getDeletedAgents$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getDeletedAgents)
    await this.getDeletedAgents()
  }

  async getDeletedAgents() {
    await this.request(async () => {
      this.deletedAgents = (
        await AgentDao.getAgents(this.$route.params.orgId, {
          limit: 1000,
          deleted: true
        })
      ).data
    })
  }
}
