






import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import FeatureRequestList from "@/components/feature/FeatureRequestList.vue"

@Component({
  components: { FeatureRequestList }
})
export default class Features extends VueComponent {}
