


























































import Component, { mixins } from "vue-class-component"
import { Campaign, CampaignStatus } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import CampaignAdminActionsDropdown from "@/components/campaign/CampaignAdminActionsDropdown.vue"
import RefundTextCampaign from "@/components/campaign/RefundTextCampaign.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import DeleteCampaignMixin from "@/mixins/campaign/DeleteCampaignMixin"

@Component({
  components: { CampaignAdminActionsDropdown }
})
export default class TextAdminActionsDropdown extends mixins(
  DeleteCampaignMixin
) {
  @Prop() campaign!: Campaign
  campaignStatus = CampaignStatus

  get isCancelationAvailable() {
    return this.campaign.status === CampaignStatus.pendingCancelation
  }

  onCancelClick() {
    this.$buefy.modal.open({
      component: RefundTextCampaign,
      width: 500,
      props: { campaign: this.campaign },
      events: {
        onConfirm: () => EventBus.getCampaigns$.next()
      }
    })
  }

  async onDeleteInvoice() {
    await this.request(async () => {
      await SmsBillingDao.deleteTextCampaignInvoice(
        this.campaign.organizationId,
        this.campaign.invoice?.id
      )
    })

    await this.updateCampaignStatus()
    EventBus.getCampaigns$.next()
  }

  async updateCampaignStatus() {
    await this.request(async () => {
      await CampaignDao.updateCampaignStatus(
        this.campaign.uuid,
        CampaignStatus.pending
      )
    })
  }
}
