import { UploadPublicDao } from "@/services/dao/robocent/uploadPublicDao"
import { Upload } from "@robocent/components"
import { NavigationGuardNext, Route } from "vue-router/types/router"

export class UploadService {
  static async isPublic(to: Route, from: Route, next: NavigationGuardNext) {
    const uploadId = to.params.uploadId
    let upload: Upload | null = null

    if (!uploadId) {
      next("/404")
      return
    }

    try {
      upload = await UploadService.getPublicUploadWithUrl(uploadId)
    } catch (e) {
      console.error(e)
      next("/404")
      return
    }

    if (!upload.meta.public) {
      next("/404")
      return
    }

    to.meta["upload"] = upload

    next()
  }

  static async getPublicUploadWithUrl(uploadId: string) {
    const upload: Upload & {
      url: { original: string; converted: string }
    } = (await UploadPublicDao.getUpload(uploadId)) as Upload & {
      url: { original: string; converted: string }
    }
    upload.url = await UploadPublicDao.getUploadLink(upload._id)
    return upload
  }
}
