import Component from "vue-class-component"
import {
  Campaign,
  CampaignPricing,
  CampaignService,
  Script,
  ScriptStatus
} from "@robocent/components"
import { Pagination } from "@/utils/pagination"
import _ from "lodash"
import { CampaignDao } from "@/services/dao/campaignDao"
import AddNewScriptModal from "@/components/modals/AddNewScriptModal.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { VueComponent } from "@/utils/VueComponent"
import { isTextCampaignUpdate } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { CampaignUtils } from "@/utils/campaignUtils"

@Component
export default class ScriptsMixin extends VueComponent {
  campaign!: Campaign
  scripts: Script[] = []
  pagination = new Pagination(0, 1, 12)
  search: string | null = null
  status: ScriptStatus[] = [ScriptStatus.pending] as ScriptStatus[]
  campaignUtils = new CampaignUtils()
  filters = [
    {
      label: "All",
      option: "all",
      values: Object.keys(ScriptStatus)
    },
    {
      label: "Draft",
      option: ScriptStatus.draft,
      values: [ScriptStatus.draft]
    },
    {
      label: "Pending",
      option: ScriptStatus.pending,
      values: [ScriptStatus.pending]
    },
    {
      label: "Approved",
      option: ScriptStatus.approved,
      values: [ScriptStatus.approved]
    },
    {
      label: "Rejected",
      option: ScriptStatus.rejected,
      values: [ScriptStatus.rejected]
    }
  ]
  pricing: CampaignPricing | null = null

  mounted() {
    EventBus.getScripts$
      .pipe(whileComponentMounted(this))
      .subscribe((script) => this.onScriptAdded(script as Script | null))
  }

  get scriptsCount() {
    return this.campaign.scripts.filter((script) => !script.deleted).length
  }

  get atLeastOnePage() {
    return this.pagination.total > this.pagination.perPage || this.search
  }

  get filterLabel() {
    return this.filters.find((filter) => _.isEqual(filter.values, this.status))
      ?.label
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getScripts()
  }

  onSearch(search: string) {
    this.search = search
    this.pagination.current = 1
    this.getScripts()
  }

  onFilterChange(values: ScriptStatus[]) {
    this.status = values
    this.pagination.current = 1
    this.getScripts()
  }

  async getScripts(): Promise<void> {
    await Promise.resolve()
  }

  isActive(script: Script) {
    return Boolean(_.find(this.campaign.scripts, { id: script.id }))
  }

  onScriptSelect(script: Script) {
    const index = this.scriptIndex(script)
    if (index > -1) {
      this.campaign.scripts.splice(index, 1)
      return this.calculateServiceType()
    }
    if (script.deleted) {
      return
    }
    this.campaign.scripts = [...this.campaign.scripts, script]
    this.calculateServiceType()
  }

  scriptIndex(script: Script) {
    return this.campaign.scripts.findIndex((item) => item.id === script.id)
  }

  async getCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.campaign = await CampaignDao.getCampaign(orgId, campaignUUID)
      },
      undefined,
      "Cannot get campaign"
    )
  }

  isSelectDisabled(script: Script) {
    if (this.isActive(script)) {
      return false
    }

    if (!script.intro) {
      return false
    }

    if (script.status === ScriptStatus.rejected) {
      return true
    }

    const isServiceDifferent =
      (this.campaign.service === CampaignService.sms &&
        script.intro &&
        Boolean(script.mms)) ||
      (this.campaign.service === CampaignService.mms &&
        script.intro &&
        !script.mms)

    return this.hasIntroScript || isServiceDifferent
  }

  async onScriptAdded(script: Script | null) {
    await this.getScripts()

    if (!script || !this.campaign) {
      return
    }

    if (this.isSelectDisabled(script)) {
      return
    }
    this.onScriptSelect(script)
  }

  selectScriptTooltip(script: Script) {
    if (script.status === ScriptStatus.rejected) {
      return "You cannot select the rejected script, please read reject reason and add changes"
    }

    if (!script.intro) return ""

    if (this.isSelectDisabled(script) && this.hasIntroScript) {
      return "You can select only one Intro Script."
    }

    if (
      this.campaign &&
      this.isSelectDisabled(script) &&
      isTextCampaignUpdate(this.campaign)
    ) {
      return "You cannot select another intro script type after the campaign activation"
    }

    return ""
  }

  get hasIntroScript() {
    return this.campaignUtils.hasIntroScript(this.campaign.scripts)
  }

  calculateServiceType() {
    if (isTextCampaignUpdate(this.campaign)) {
      return
    }

    const introScript: Script = this.campaign.scripts.find(
      (script: Script) => script.intro
    )

    if (!introScript) {
      return (this.campaign.service = null!)
    }

    this.campaign.service = introScript.mms
      ? CampaignService.mms
      : CampaignService.sms
  }

  openCreateScriptModal() {
    this.$buefy.modal.open({
      width: 950,
      component: AddNewScriptModal,
      parent: this,
      props: {
        organizationId:
          this.campaign.organization?._id || this.campaign.organizationId
      },
      events: {
        created: this.onScriptAdded
      }
    })
  }

  onScriptChange(script: Script) {
    const ind = _.findIndex(
      this.campaign.scripts,
      (entry) => entry.id === script.id
    )

    if (ind === -1) {
      return
    }

    if (script.deleted) {
      this.$delete(this.campaign.scripts, ind)
      this.$set(this.campaign, "service", null)
    } else {
      this.$set(this.campaign.scripts, ind, _.cloneDeep(script))
    }

    EventBus.getEstimatedCost$.next()
  }
}
