











































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import {
  ListCard,
  Organization,
  OrganizationElectionType,
  OrganizationLegalEntityType,
  OrganizationType
} from "@robocent/components"
import { Prop, Ref } from "vue-property-decorator"
import { ValidationContext } from "vee-validate/dist/types/components/common"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import OrganizationForm from "@/components/organization/OrganizationForm.vue"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"
import _ from "lodash"
import { EventBus } from "@/services/eventBus"
import { Location } from "vue-router/types/router"

@Component({
  components: {
    Card,
    OrganizationForm,
    ListCard,
    ValidationObserver
  }
})
export default class CreateOrganizationModal extends VueComponent {
  @Prop() redirect!: Location
  @Ref("create-org-observer") observer!: ValidationContext
  types = OrganizationType
  organization: Organization = {
    type: OrganizationType.political,
    electionType: OrganizationElectionType.state,
    legalEntityType: OrganizationLegalEntityType.nonProfit,
    meta: {} as any
  } as Organization

  async save() {
    await this.request(
      async () => {
        const preparedOrganization = this.prepareOrganization(this.organization)
        const organization = await OrganizationDao.createOrganization(
          preparedOrganization
        )
        this.$store.commit("setLastVisitedOrganization", organization)
        EventBus.getOrganizations$.next(this.redirect)
        EventBus.organizationCreated$.next(organization)
        this.$parent.close()
      },
      "Organization successfully created",
      "Organization can't be created"
    )
  }

  prepareOrganization(organization: Organization): Organization {
    const clonedOrganization = _.cloneDeep(organization)

    if (organization.type === OrganizationType.political) {
      clonedOrganization.legalEntityType = OrganizationLegalEntityType.nonProfit
    }

    if (organization.type === OrganizationType.nonPolitical) {
      delete clonedOrganization.electionType
    }

    return clonedOrganization
  }
}
