import _ from "lodash"
import { ToastProgrammatic as Toast } from "buefy"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { Upload } from "@robocent/components"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { FileUtils } from "@/utils/fileUtils"
import { StraticsAudio } from "@/interfaces/voiceCampaign/straticsAudio"

export class AudioService {
  async uploadAudioFiles(campaign: VoiceCampaign) {
    const audioFiles = await this.getUploadedAudioFiles(campaign)
    const audioUploads = this.getAudioFiles(campaign.uploads)

    for (const audio of audioUploads) {
      if (
        !audioFiles.find((straticsAudio: StraticsAudio) =>
          this.compareAudioFile(
            audio,
            straticsAudio,
            campaign.organization.name
          )
        )
      ) {
        if (audio._id) {
          await this.uploadAudioFile(campaign._id!, audio._id)
        }
      }
    }
  }

  async uploadAudioFile(campaignId: string, uploadId: string) {
    return await CampaignConfigureDao.uploadAudioFile(campaignId, uploadId)
  }

  async audioFilesUploaded(campaign: VoiceCampaign) {
    const audioFiles = await this.getUploadedAudioFiles(campaign)
    const audioUploads = this.getAudioFiles(campaign.uploads)

    return audioUploads.every((audio) =>
      audioFiles.find((straticsAudio: any) =>
        this.compareAudioFile(audio, straticsAudio, campaign.organization.name)
      )
    )
  }

  compareAudioFile(
    upload: Upload,
    straticsFile: StraticsAudio,
    orgName: string
  ) {
    return (
      straticsFile.Name === FileUtils.normalizeAudioFileName(upload, orgName)
    )
  }

  async getUploadedAudioFiles(campaign: VoiceCampaign) {
    try {
      return await CampaignConfigureDao.getAudioFiles(campaign._id!)
    } catch (e) {
      Toast.open({
        type: "is-danger",
        message: "Error during load audio files from dialer"
      })
      return []
    }
  }

  getAudioFiles(uploads: any) {
    const audioFiles = []

    if (uploads.liveRecording?._id) {
      audioFiles.push(uploads.liveRecording)
    }

    if (uploads.vmRecording?._id) {
      audioFiles.push(uploads.vmRecording)
    }

    return _.unionBy(audioFiles, "humanId")
  }
}
