
































import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { CampaignStats } from "@/interfaces/campaignStats"
import { CardTable, CardTableItem } from "@robocent/components"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  }
})
export default class MessagesStats extends VueComponent {
  @Prop() campaignStats!: CampaignStats
  @Prop() waiting!: boolean

  get totalReplies() {
    const totalReplies =
      this.campaignStats.messages.received - this.campaignStats.messages.dnd
    return totalReplies || 0
  }

  get replyRate() {
    const replyRate = Math.round(
      (this.totalReplies / this.campaignStats.messages.outbound) * 100
    )
    return replyRate || 0
  }
}
