

























import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import AddAgentModal from "@/components/modals/AddAgentModal.vue"
import InviteAgentModal from "@/components/modals/InviteAgentModal.vue"
@Component({})
export default class AgentsActions extends VueComponent {
  addAgentModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddAgentModal,
      width: 1280,
      props: {
        orgId: this.$route.params.orgId
      }
    })
  }

  inviteAgentModal() {
    this.$buefy.modal.open({
      parent: this,
      component: InviteAgentModal,
      width: 400,
      props: {
        orgId: this.$route.params.orgId
      }
    })
  }
}
