
















































































































































import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { Coupon } from "@/interfaces/coupon"
import { CouponDuration } from "@/enums/couponDuration"
import {
  DateTimePickerWithValidation,
  InputWithValidation,
  NumberInputWithValidation
} from "@robocent/components"
import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { ValidationObserver } from "vee-validate"

enum Types {
  percentage = "percentage",
  fixedAmount = "fixedAmount"
}

@Component({
  components: {
    Card,
    DateTimePickerWithValidation,
    NumberInputWithValidation,
    InputWithValidation,
    ValidationObserver
  }
})
export default class PromoCodeModal extends VueComponent {
  @Prop({
    default: () => ({
      name: null,
      currency: "USD",
      amount_off: null,
      percent_off: null,
      duration: null,
      max_redemptions: null,
      duration_in_months: null,
      redeem_by: null
    })
  })
  couponData!: Coupon
  duration = [
    { value: CouponDuration.forever, displayValue: "Forever" },
    { value: CouponDuration.once, displayValue: "Once" },
    { value: CouponDuration.repeating, displayValue: "Multiple months" }
  ]
  durations = CouponDuration
  dateLimit = false
  timesLimit = false
  datetime = null
  minDateTime = new Date()
  datetimeError = ""
  type = ""
  redeemBy: Date | null = null
  durationDefault = CouponDuration.forever
  types = Types

  get isPercentageTypeCoupon() {
    return this.type === Types.percentage
  }
  get isFixedAmountTypeCoupon() {
    return this.type === Types.fixedAmount
  }
  get isDisabled() {
    return Boolean(this.couponData.created)
  }

  mounted() {
    this.init()
    this.couponData.duration = this.durationDefault
  }

  async submit() {
    const data = Object.assign({}, this.couponData, {
      amount_off: this.isFixedAmountTypeCoupon
        ? this.couponData.amount_off! * 100
        : null,
      percent_off: this.isPercentageTypeCoupon
        ? this.couponData.percent_off
        : null,
      redeem_by: this.redeemBy
        ? Math.floor(this.redeemBy.getTime() / 1000)
        : null
    })

    this.$emit("onCreate", data)
    this.$parent.close()
  }

  init() {
    this.type =
      this.couponData.amount_off! > 0 ? Types.fixedAmount : Types.percentage
    this.couponData.amount_off =
      this.couponData.amount_off! > 0 ? this.couponData.amount_off! / 100 : null
    this.dateLimit = Boolean(this.couponData.redeem_by)
    this.timesLimit = Boolean(this.couponData.max_redemptions)
    this.redeemBy = this.couponData.redeem_by
      ? new Date(this.couponData.redeem_by * 1000)
      : null
  }

  onDurationChange(value: string) {
    const isOnceSelected = value === CouponDuration.once
    this.timesLimit = isOnceSelected
    this.onTimeLimitChange(isOnceSelected)
  }

  onTimeLimitChange(isSelected: boolean) {
    this.couponData.max_redemptions = isSelected ? 1 : null
  }

  onDateLimitChange(isSelected: boolean) {
    this.redeemBy = isSelected ? new Date() : null
  }
}
