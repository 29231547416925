











































































import SearchItem from "../elements/SearchItem.vue"
import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { ScriptDao } from "@/services/dao/scriptDao"
import { Campaign, Organization } from "@robocent/components"
import Component, { mixins } from "vue-class-component"
import ChooseItem from "../elements/ChooseItem.vue"
import ChooseRow from "../elements/ChooseRow.vue"
import ScriptsEmptyMessage from "@/components/scripts/ScriptsEmptyMessage.vue"
import { Prop, Watch } from "vue-property-decorator"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import ScriptsMixin from "@/mixins/ScriptsMixin"
import { Pagination } from "@/utils/pagination"
import ScriptItem from "@/components/scripts/ScriptItem.vue"

@Component({
  components: {
    ScriptItem,
    ScriptsEmptyMessage,
    ChooseRow,
    ChooseItem,
    SearchItem,
    PaginationItem
  }
})
export default class TextCampaignScripts extends mixins(
  TextSchedulerUtilsMixin,
  ScriptsMixin
) {
  @Prop({ default: null }) campaign!: Campaign
  pagination = new Pagination(0, 1, 9)

  created() {
    this.getScripts()
  }

  async getScripts() {
    await this.request(async () => {
      const { data, total, pricing } = (
        await ScriptDao.getScripts(this.organizationId, {
          q: this.search,
          page: this.pagination.current,
          limit: this.pagination.perPage
        })
      ).data
      this.pagination.total = total
      this.scripts = data
      this.pricing = pricing
    })
  }

  nextStep() {
    this.next(TextSchedulerSteps.campaignType)
  }

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      this.campaign.scripts = []
      this.reset()
      this.getScripts()
    }
  }
}
