









import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import UploadModal from "@/components/modals/UploadModal.vue"
import { Prop } from "vue-property-decorator"

@Component
export default class ListsEmptyMessage extends VueComponent {
  @Prop() orgId!: string

  openUploadPhoneListModal() {
    this.$buefy.modal.open({
      width: 800,
      component: UploadModal,
      parent: this,
      props: {
        orgId: this.orgId,
        title: "Upload Phone List"
      },
      events: {
        uploaded: () => this.$emit("uploaded")
      }
    })
  }
}
