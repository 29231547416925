











import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { ListCard, Upload } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import DownloadFile from "@/components/uploads/DownloadFile.vue"
import UploadActions from "@/components/uploads/UploadActions.vue"

@Component({
  components: {
    UploadActions,
    DownloadFile,
    ListCard
  }
})
export default class SharedUploadTag extends VueComponent {
  @Prop() upload!: Upload
}
