









import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Campaign } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import RefundAmountCard from "@/components/elements/RefundAmountCard.vue"
import RefundCampaignCostModal from "@/components/modals/RefundCampaignCostModal.vue"
import { RefundTypes } from "@/enums/refundTypes"
import { RefundAmount } from "@/enums/refundAmount"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: { RefundCampaignCostModal, RefundAmountCard, Card }
})
export default class RefundTextCampaign extends VueComponent {
  @Prop() campaign!: Campaign

  async onConfirmClick(refundType: RefundTypes, refundAmount: RefundAmount) {
    await this.request(async () => {
      await CampaignDao.cancelCampaign(
        this.campaign.organizationId,
        this.campaign.uuid,
        refundType,
        refundAmount
      )
      this.$emit("onConfirm")
      this.$parent.close()
    })
  }
}
