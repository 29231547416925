import { render, staticRenderFns } from "./AddDndModal.vue?vue&type=template&id=61ff0530&scoped=true&"
import script from "./AddDndModal.vue?vue&type=script&lang=ts&"
export * from "./AddDndModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ff0530",
  null
  
)

export default component.exports