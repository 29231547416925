import axios from "axios"
import { Upload } from "@robocent/components"
import { UploadType } from "@/enums/uploadType"

export class AudioDao {
  static async getAudios(
    orgId: string,
    params: object
  ): Promise<{ data: Upload[]; total: number }> {
    return (
      await axios.get(`/organizations/${orgId}/uploads`, {
        params: {
          type: [UploadType.audio],
          ...params
        }
      })
    ).data
  }
}
