








import Component from "vue-class-component"
import PartyCode from "@/voterData/components/PartyCode.vue"
import VotingHistory from "@/voterData/components/VotingHistory.vue"
import VotingHistoryRequirements from "@/voterData/components/VotingHistoryRequirements.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    VotingHistoryRequirements,
    VotingHistory,
    PartyCode
  }
})
export default class PoliticalAffiliationStep extends VueComponent {}
