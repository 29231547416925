
























import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"

@Component
export default class AssignAgentsActions extends VueComponent {
  @Prop({ default: false }) disabled!: boolean
  assignAgents() {
    this.$emit("save")
  }

  notifyAgents() {
    this.$emit("notify")
  }
}
