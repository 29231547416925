






























































import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { CardTable, CardTableItem } from "@robocent/components"
import { UploadType } from "@/enums/uploadType"
import FileRow from "@/components/elements/voiceCampaign/FileRow.vue"
import MmsPreview from "@/components/elements/MmsPreview.vue"

@Component({
  components: { MmsPreview, FileRow, Card, CardTable, CardTableItem }
})
export default class SharedUpload extends VueComponent {
  uploadTypes = UploadType

  get upload() {
    return this.$route.meta["upload"]
  }
}
