

























































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import DownloadPhoneList from "@/components/uploads/DownloadFile.vue"
import { ScrubListButton, Upload } from "@robocent/components"
import InvoiceButton from "@/components/checkout/InvoiceButton.vue"
import { VueComponent } from "@/utils/VueComponent"
import { UploadsDao } from "../../services/dao/robocent/uploadDao"
import { EventBus } from "../../services/eventBus"
import UploadShareableModal from "@/components/modals/UploadShareableModal.vue"

@Component({
  components: { DownloadPhoneList, ScrubListButton, InvoiceButton }
})
export default class UploadActions extends VueComponent {
  @Prop() upload!: Upload
  @Prop() orgId!: string

  renameUpload() {
    this.$buefy.dialog.prompt({
      message: "Rename Upload",
      inputAttrs: {
        value: this.upload.name
      },
      trapFocus: true,
      onConfirm: (name) => this.rename(name)
    })
  }

  async rename(name: string) {
    await this.request(
      async () => {
        await UploadsDao.renameUpload(this.orgId, this.upload._id, name)
        EventBus.getUploads$.next()
      },
      "The File Renamed.",
      "File can't be renamed."
    )
  }

  async deleteUpload() {
    await this.confirm(`Do you really want to archive ${this.upload.name}?`)
    await this.request(
      async () => {
        await UploadsDao.deleteUpload(this.orgId, this.upload._id)
        EventBus.getUploads$.next()
      },
      "The file successfully archived!",
      "File can't be archived."
    )
  }

  async restoreUpload() {
    await this.confirm(`Do you really want to restore ${this.upload.name}?`)
    await this.request(
      async () => {
        await UploadsDao.restoreUpload(this.orgId, this.upload._id)
        EventBus.getUploads$.next()
      },
      "The file successfully restored!",
      "File can't be restored."
    )
  }

  sharedResults() {
    this.$buefy.modal.open({
      parent: this,
      component: UploadShareableModal,
      props: { upload: this.upload },
      width: 800
    })
  }
}
