













import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { InvoiceCoupon } from "@/interfaces/invoiceCoupon"

@Component
export default class ApproveCoupon extends VueComponent {
  @Prop() coupon!: InvoiceCoupon

  get couponValue() {
    const amountOff = this.coupon.amount_off
    const percentOff = this.coupon.percent_off

    if (amountOff) {
      return `$${parseFloat(amountOff) / 100}`
    } else {
      return `${percentOff}%`
    }
  }
}
