






























import Component from "vue-class-component"
import { Feature } from "@/interfaces/feature/feature"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import OrganizationPartnerService from "@/components/partnerPortal/OrganizationPartnerService.vue"
import PartnerPortalMixin from "@/mixins/partnerPortal/PartnerPortalMixin"
import { Emit, Prop, Watch } from "vue-property-decorator"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"

@Component({
  components: {
    OrganizationPartnerService,
    ChooseRow
  }
})
export default class PartnerServiceCategories extends PartnerPortalMixin {
  @Prop() isLoading!: boolean
  onSelectService(service: Feature) {
    this.selectedService = service
  }

  async getOrgSettings() {
    await this.request(
      async () => {
        this.partnerSettings = await PartnerPortalDao.getPartnerSettings(
          this.selectedOrg
        )
      },
      undefined,
      undefined,
      "settings"
    )
  }

  @Watch("selectedOrg")
  async watchOrg() {
    this.selectedService = null
    await this.getOrgSettings()
    this.selectedService = this.partnerSettings[0]?.feature
    this.onChange()
  }

  @Emit()
  onChange() {}
}
