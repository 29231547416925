
































import Component, { mixins } from "vue-class-component"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import moment from "moment"
import { statuses } from "@robocent/components"
import CampaignDateMixin from "@/mixins/campaign/CampaignDateMixin"
import { voiceCampaignTypes } from "@/data/voiceCampaign/voiceCampaignTypes"
import { Prop } from "vue-property-decorator"

@Component
export default class VoiceCampaignScheduleButton extends mixins(
  VoiceSchedulerUtilsMixin,
  CampaignDateMixin
) {
  @Prop() isLoading!: boolean

  get message() {
    let message = this.campaign._id ? "Update" : "Schedule"
    let type = "",
      suffix = ""

    if (this.campaign.meta.status === statuses.rejected) {
      message = "Resubmit"
    }

    if (this.$route.name?.includes("ReRun")) {
      message = "Schedule"
    }

    if (this.campaign.type) {
      type = voiceCampaignTypes[this.campaign.type]
    }

    if (this.campaign.time) {
      suffix = "for"
    }

    return `${message} ${type} ${suffix}`.trim()
  }

  timeInTimezone(timezone: string) {
    return moment(
      new Date(
        this.formattedTimeJS(moment.utc(this.campaign.time).toDate(), timezone)
      )
    )
  }

  get campaignTime() {
    return `${this.timeInTimezone(this.campaign.timezone).format(
      "dddd, MMMM Do, YYYY h:mm A"
    )}`
  }

  get timeEastern() {
    if (this.campaign.timezone === "America/New_York") {
      return ""
    }

    return `(${moment(this.timeInTimezone("America/New_York")).format(
      "h:mm A"
    )} Eastern)`
  }

  goBack() {
    this.$router.back()
  }
}
