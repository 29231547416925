












































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import { Emit, Prop } from "vue-property-decorator"
import { Coupon } from "@/interfaces/coupon"
import PromoCodeModal from "@/components/modals/PromoCodeModal.vue"

@Component({
  components: { PaginationItem }
})
export default class PromoCodes extends VueComponent {
  @Prop() promoCodes!: Coupon[]

  onCopy({ text }: { text: string }) {
    this.openToast(`You just copied: ${text}`)
  }

  onError() {
    this.openToast("Failed to copy coupon")
  }

  getTypeForDiscount(coupon: Coupon) {
    return coupon.percent_off ? "is-success" : "is-info"
  }

  getCouponNameOrId(coupon: Coupon) {
    return coupon?.name || coupon?.id
  }

  openPromoCodeModal(couponData: Coupon) {
    this.$buefy.modal.open({
      parent: this,
      component: PromoCodeModal,
      width: 500,
      props: {
        couponData
      }
    })
  }

  @Emit()
  onDelete() {}
}
