













import Component from "vue-class-component"
import { CardTable, CardTableItem } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  }
})
export default class GenerateReportButton extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() isPublic!: boolean
  @Prop() disabled!: boolean

  @Emit()
  generate() {}
}
