











import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"

@Component
export default class SalesManagerParent extends VueComponent {}
