















import Component from "vue-class-component"
import { Campaign, Script } from "@robocent/components"
import { CampaignDao } from "@/services/dao/campaignDao"
import { Prop } from "vue-property-decorator"
import TestTextMessage from "@/components/campaign/TestTextMessage.vue"
import { get } from "lodash"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    TestTextMessage
  }
})
export default class TextCampaignTestMessageModal extends VueComponent {
  @Prop() campaign!: Campaign

  get introScriptsLength() {
    return Boolean(
      this.campaign.scripts.filter((script: Script) => script.intro).length
    )
  }

  async sendMessage(phone: string) {
    try {
      this.loading = true
      await CampaignDao.sendTestMessage(
        this.campaign.organizationId,
        this.campaign.uuid,
        phone
      )
      this.openToast("Text Message successfully sent")
      this.$parent.close()
    } catch (e) {
      const message =
        get(e, "response.data.message") || "The message can't be sent."
      this.openToast(message)
    } finally {
      this.loading = false
    }
  }
}
