import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"

export function isVoiceCampaignUpdate(campaign: VoiceCampaign) {
  return (
    Boolean(campaign?._id) &&
    !(
      campaign?.meta.status === VoiceCampaignStatus.pending ||
      campaign?.meta.status === VoiceCampaignStatus.rejected
    )
  )
}

@Component
export class VoiceSchedulerUtilsMixin extends SchedulerUtilsMixin {
  @Prop() campaign!: VoiceCampaign
  steps = VoiceSchedulerSteps

  get organizationId() {
    return this.campaign.organization?._id
  }

  get isCampaignUpdate() {
    return isVoiceCampaignUpdate(this.campaign)
  }

  get isUnderReview() {
    return (
      (this.campaign.meta.status === VoiceCampaignStatus.underReview ||
        (this.campaign.meta.status === VoiceCampaignStatus.approved &&
          !this.$route?.name?.includes("ReRun"))) &&
      !this.isSuper
    )
  }

  get isSuper() {
    return this.$store.getters.super
  }

  next(step: VoiceSchedulerSteps) {
    this.$store.commit("voiceScheduler/next", step)
  }

  remove(step: VoiceSchedulerSteps) {
    this.$store.commit("voiceScheduler/remove", step)
  }
}
