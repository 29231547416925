



























































import Component from "vue-class-component"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import { Organization, Upload } from "@robocent/components"
import { Emit, Prop, Watch } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { get, set } from "lodash"
import UploadModal from "@/components/modals/UploadModal.vue"
import { AudioDao } from "@/services/dao/robocent/audioDao"
import VoiceCampaignAudioItem from "@/components/scheduler/voiceElements/VoiceCampaignAudioItem.vue"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import VoiceCampaignAudiosEmptyMessage from "@/components/scheduler/voiceElements/VoiceCampaignAudiosEmptyMessage.vue"

@Component({
  components: {
    VoiceCampaignAudiosEmptyMessage,
    VoiceCampaignAudioItem,
    ChooseRow,
    ChooseItem,
    PaginationItem,
    SearchItem
  }
})
export default class VoiceCampaignAudios extends VoiceSchedulerUtilsMixin {
  audios: Upload[] = []
  @Prop() path!: string
  @Prop() title!: string
  @Prop({ default: false }) hasNull!: boolean
  signedUrls = {}

  created() {
    this.getAudios()
    EventBus.getUploads$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getAudios)
  }

  async getAudios() {
    const params = {
      q: this.search,
      page: this.pagination.current,
      limit: this.pagination.perPage
    }
    await this.request(async () => {
      const audios = await AudioDao.getAudios(this.organizationId, params)
      await this.getSignedUrls(audios.data)
      this.pagination.total = audios.total
      this.audios = audios.data
    })
  }

  async getSignedUrls(audios: Upload[]) {
    await this.request(async () => {
      const uploadIds: string[] = audios.map((audio) => audio._id)
      this.signedUrls = await UploadsDao.getLinksForUploads(uploadIds)
    })
  }

  isSelected(audio: Upload | null) {
    return get(this.campaign, this.path) === (audio?._id || null)
  }

  select(audio: Upload | null) {
    if (get(this.campaign, this.path) === (audio?._id || null)) {
      return
    }

    set(this.campaign, this.path, audio?._id || null)
    this.$forceUpdate()
    this.nextStep()
  }

  @Emit()
  nextStep() {}

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      set(this.campaign, this.path, null)
      this.reset()
      this.getAudios()
    }
  }

  openUploadPhoneListModal() {
    this.$buefy.modal.open({
      width: 800,
      component: UploadModal,
      parent: this,
      props: {
        orgId: this.organizationId,
        title: "Upload Audio File"
      }
    })
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getAudios()
  }

  onSearch(search: string) {
    this.search = search
    this.pagination.current = 1
    this.getAudios()
  }
}
