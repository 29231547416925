





















import Component from "vue-class-component"
import {
  InputWithValidation,
  MappedColumns,
  MergedTagsValues,
  MessageCounter,
  Script
} from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import SurveyOptionsInput from "@/components/scripts/SurveyOptionsInput.vue"
import { Prop } from "vue-property-decorator"
import mergeFieldsValues from "@/data/mergeFieldsValues"

@Component({
  components: {
    SurveyOptionsInput,
    InputWithValidation,
    MessageCounter
  }
})
export default class ScriptPreview extends VueComponent {
  @Prop() script!: Script
  @Prop() defaultText!: string

  get finalScript() {
    const script = this.script.isDefaultScriptNeeded
      ? this.script.text + this.defaultText
      : this.script.text

    return this.replaceMergedFields(script)
  }

  get user() {
    return this.$store.getters.user.user_metadata
  }

  replaceMergedFields(script: string) {
    const values = {
      ...mergeFieldsValues,
      [MergedTagsValues.agentFirstName]: this.user.firstName,
      [MergedTagsValues.agentLastName]: this.user.lastName
    }

    return script?.replace(
      /{(.*?)}/g,
      (math, group: MappedColumns) => values[group] || ""
    )
  }
}
