import { cloneDeep } from "lodash"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { CampaignCostService } from "@/services/campaignCostService"
import { Campaign } from "@robocent/components"
import { InvoiceItem } from "@/interfaces/invoiceItem"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { ApproveTextCampaign } from "@/interfaces/approveTextCampaign"
import { ApproveVoiceCampaign } from "@/interfaces/voiceCampaign/approveVoiceCampaign"
import Vue from "vue"
import { PartnerType } from "@/enums/partnerPortal/partnerType"
import { OrganizationPartnerService } from "@/interfaces/partnerPortal/organizationPartnerService"
import { Feature } from "@/interfaces/feature/feature"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"

@Component
export default class ApproveMixin extends Vue {
  @Prop() orgId!: string
  @Prop() campaign!: Campaign | VoiceCampaign
  @Prop() orgFeature!: Feature
  @Prop({
    default: () => ({
      straticsId: "",
      leads: null,
      multis: [],
      fixedDiscounts: [],
      percentDiscounts: [],
      fees: [],
      coupon: null,
      minimalCost: 0,
      pureCost: 0
    })
  })
  data!: ApproveTextCampaign | ApproveVoiceCampaign

  tags = [
    { description: "Guided Setup", amount: 50 },
    { description: "RoboCent Voice Talent 00-60 Seconds", amount: 75 },
    { description: "RoboCent Voice Talent Over 60 Seconds", amount: 150 },
    { description: "Data Management", amount: 50 },
    { description: "Rush RoboCall (under 2hrs)", amount: 30 },
    { description: "Rush Transfer Call (under 2hrs)", amount: 30 },
    { description: "Rush RVM (under 2hrs)", amount: 50 },
    { description: "Rush SMS Connect (under 4hrs)", amount: 100 },
    { description: "Rush Survey (under 4hrs)", amount: 100 },
    { description: "Other", amount: 0, custom: true }
  ]
  campaignCostService = new CampaignCostService()
  totalPriceDiscount = "RoboCent Discount"
  leadsBetweenDiscount = {
    description: "10% Bulk Discount",
    amount: 10
  }
  leadsOverDiscount = {
    description: "20% Bulk Discount",
    amount: 20
  }
  discount = ""
  priceBeforeDiscount = 0
  total_cents = 0
  partnerService: OrganizationPartnerService = null!
  totalDiscounts = 0

  get isTotalPriceDiscountAdded() {
    const result = this.data.percentDiscounts.filter(
      (discount: InvoiceItem) =>
        discount.description === this.totalPriceDiscount
    )
    return result.length !== 0
  }

  get isLeadsDiscountAdded() {
    const result = this.data.percentDiscounts.filter((discount: InvoiceItem) =>
      discount.description.includes("Bulk Discount")
    )
    return result.length !== 0
  }

  get isFreeButtonDisabled() {
    return (
      !this.data.leads ||
      !this.data.multis.length ||
      this.isTotalPriceDiscountAdded
    )
  }

  @Watch("data.leads")
  watchLeads() {
    this.calculate()
    this.leadsPercentDiscount()
  }

  addToArr(arr: InvoiceItem[], item: InvoiceItem) {
    arr.push(cloneDeep(item))
    this.calculate()
  }

  removeFromArr(arr: InvoiceItem[], index: number) {
    arr.splice(index, 1)
    this.calculate()
  }

  async removeCoupon() {
    this.data.coupon = null
    this.calculate()
  }

  calculate() {
    const {
      total,
      priceBeforeDiscount,
      minimalCost,
      pureCost,
      totalDiscounts
    } = this.campaignCostService.calculateInvoiceItemsCost(
      this.data,
      this.campaign.type
    )
    this.data.minimalCost = minimalCost
    this.data.pureCost = pureCost
    this.total_cents = total
    this.priceBeforeDiscount = priceBeforeDiscount
    this.totalDiscounts = totalDiscounts

    if (!this.isLeadsDiscountAdded) {
      this.discount = ""
    }
  }

  addTotalPriceDiscount() {
    const tmpPercentDiscount = {
      description: this.totalPriceDiscount,
      amount: 100
    }
    this.addToArr(this.data.percentDiscounts, tmpPercentDiscount)
    this.$buefy.toast.open({
      message: `${this.totalPriceDiscount} was added to Discount % items`,
      position: "is-top",
      type: "is-success",
      container: "#approve-call-modal"
    })
  }

  leadsPercentDiscount() {
    if (
      this.data.leads &&
      this.data.leads >= this.campaignCostService.minLeads &&
      this.data.leads < this.campaignCostService.middleLeads
    ) {
      this.discount = this.leadsBetweenDiscount.description
      return this.addToArr(
        this.data.percentDiscounts,
        this.leadsBetweenDiscount
      )
    }
    if (
      this.data.leads &&
      this.data.leads >= this.campaignCostService.middleLeads &&
      this.data.leads < this.campaignCostService.maxLeads
    ) {
      this.discount = this.leadsOverDiscount.description
      return this.addToArr(this.data.percentDiscounts, this.leadsOverDiscount)
    }
  }

  async setPartnerRate() {
    this.partnerService = await this.getPartnerPortalSettings()

    if (!this.partnerService?.service) return

    const { service } = this.partnerService

    if (service.type === PartnerType.discount) {
      return this.setPartnerDiscount(service.rate)
    }
  }

  setPartnerDiscount(rate: number) {
    this.addToArr(this.data.percentDiscounts, {
      description: `Discount: ${rate}%`,
      amount: rate
    })
  }

  async getPartnerPortalSettings() {
    const params = {
      type: this.orgFeature
    }

    return await PartnerPortalDao.getOrganizationPartnerService(
      this.orgId,
      params
    )
  }
}
