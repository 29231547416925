<!-- eslint-disable vue/no-mutating-props -->
<template>
  <big-card v-if="phone">
    <section>
      <div class="columns">
        <div class="column">
          <p>
            <strong class="is-size-5">Inbound Voice Actions</strong>
          </p>
          <p class="is-size-6 has-text-grey">
            This action will trigger when your number receives an inbound phone
            call.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="columns">
        <div class="column">
          <!-- {{ phone.voice.actions }} -->
          <draggable
            v-model="phone.voice.actions"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <div v-for="(item, index) in phone.voice.actions" :key="index">
              <div class="mt-sm" v-if="item.type === 'play'">
                <voice-play
                  :index="index + 1"
                  :audio="item.audio"
                  :uploads="uploads"
                  @update="updateItem($event, item, 'audio')"
                  @remove="removeStep(index)"
                ></voice-play>
              </div>
              <div class="mt-sm" v-if="item.type === 'say'">
                <voice-say
                  :index="index + 1"
                  :tts="item.tts"
                  @update="updateItem($event, item, 'tts')"
                  @remove="removeStep(index)"
                ></voice-say>
              </div>
              <div class="mt-sm" v-if="item.type === 'dial'">
                <voice-dial
                  :index="index + 1"
                  :numbers="item.numbers"
                  :config="item.config"
                  @update="updateItem($event, item, 'numbers')"
                  @updateConfig="updateItemConfig($event, item, 'config')"
                  @remove="removeStep(index)"
                ></voice-dial>
              </div>
              <div class="mt-sm" v-if="item.type === 'record'">
                <voice-record
                  :index="index + 1"
                  :config="item.config || {}"
                  @updateConfig="updateItemConfig($event, item, 'config')"
                  @remove="removeStep(index)"
                ></voice-record>
              </div></div
          ></draggable>
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-centered">
          <h1 class="subtitle">Add Step to Phone Actions</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-centered">
          <div class="buttons is-centered">
            <b-button
              v-for="item in voiceTypes"
              @click="
                phone.voice.actions.push({
                  type: item.id,
                  numbers: [],
                  config: {}
                })
              "
              :key="item.id"
              :icon-left="item.icon"
              type="is-primary"
              size="is-medium"
              >{{ item.name }}</b-button
            >
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-centered">
          <b-button @click="onSave" size="is-medium" type="is-success"
            >Save Voice Settings</b-button
          >
        </div>
      </div>
    </section>
  </big-card>
</template>

<script>
import BigCard from "./elements/BigCard.vue"
import VoicePlay from "./components/VoicePlay.vue"
import VoiceSay from "./components/VoiceSay.vue"
import VoiceDial from "./components/VoiceDial.vue"
import VoiceRecord from "./components/VoiceRecord.vue"
import draggable from "vuedraggable"
import { PhoneNumberDao } from "../services/dao/robocent/phoneNumberDao"
import { UploadsDao } from "../services/dao/robocent/uploadDao"

export default {
  name: "VoiceSettings",
  components: {
    BigCard,
    VoicePlay,
    VoiceSay,
    VoiceDial,
    VoiceRecord,
    draggable
  },
  props: ["phone"],
  computed: {
    // a computed getter
    voiceActions: function () {
      // `this` points to the vm instance
      return this.phone.voice.actions
    }
  },
  data() {
    return {
      voiceTypes: [
        {
          id: "dial",
          name: "Forward to Numbers",
          icon: "phone",
          description:
            "Calls placed to this number will be directly forwarded to the numbers entered here."
        },
        {
          id: "play",
          name: "Play Audio File",
          icon: "music",
          description: ""
        },
        {
          id: "say",
          name: "Play Text-To-Speech",
          icon: "comment-alt",
          description: ""
        },
        {
          id: "record",
          name: "Send to Voicemail",
          icon: "voicemail",
          description:
            "The caller will have the option to leave a message that you will be able to view from this page."
        }
      ],
      orgId: this.$route.params.orgId,
      phoneId: this.$route.params.phoneId,
      uploads: []
    }
  },
  created() {
    if (this.$route.params.phoneId) {
      this.getUploads()
      this.init()
    }
  },
  methods: {
    updateItem(value, item, field) {
      item[field] = value
    },
    updateItemConfig(value, item) {
      item.config = value
    },
    removeStep(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.phone.voice.actions.splice(index, 1)
    },
    async onSave() {
      await PhoneNumberDao.saveVoice(this.orgId, this.phone)
      this.$buefy.toast.open("Successfully Saved!")
    },
    selectType(option) {
      this.selectedVoiceType = option
      // eslint-disable-next-line vue/no-mutating-props
      this.phone.voice.type = option.id
    },
    getUploads() {
      UploadsDao.getUploads(this.orgId, { type: ["audio"] })
        .then((response) => {
          this.uploads = response.data
        })
        .catch((e) => {
          console.warn(e)
        })
    },
    init() {
      this.selectedVoiceType = this.voiceTypes.find(
        ({ id }) => id === this.phone.voice.type
      )
    }
  }
}
</script>
<style scoped>
.panel-block:first-child {
  border-top: none !important;
}

.column.is-centered {
  justify-content: center;
}
</style>
