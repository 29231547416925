import { render, staticRenderFns } from "./SurveyResponsesModal.vue?vue&type=template&id=2e6a7a36&scoped=true&"
import script from "./SurveyResponsesModal.vue?vue&type=script&lang=ts&"
export * from "./SurveyResponsesModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6a7a36",
  null
  
)

export default component.exports