import { Module } from "vuex"
import _ from "lodash"
import { GeneralInfo } from "@/voterData/interfaces/GeneralInfo"
import { State } from "@/voterData/interfaces/state"
import { Organization } from "@robocent/components"

const initState = {
  email: "",
  name: "",
  phone: "",
  office: "",
  organization: null,
  state: ""
}

export const generalInfo = <Module<GeneralInfo, State>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    email: (state) => state.email,
    name: (state) => state.name,
    phone: (state) => state.phone,
    office: (state) => state.office,
    organization: (state) => state.organization,
    state: (state) => state.state
  },
  mutations: {
    updateEmail: (state, value: string) => {
      state.email = value
    },
    updateName: (state, value: string) => {
      state.name = value
    },
    updatePhone: (state, value: string) => {
      state.phone = value
    },
    updateOffice: (state, value: string) => {
      state.office = value
    },
    updateOrganization: (state, value: Organization) => {
      state.organization = value
    },
    updateState: (state, value: string) => {
      state.state = value
    },
    clearState: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key as keyof GeneralInfo] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
