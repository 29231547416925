export enum VoiceCampaignStatus {
  pending = "pending",
  allPending = "allPending",
  archived = "archived",
  underReview = "underReview",
  approved = "approved",
  rejected = "rejected",
  pendingCancel = "pendingCancelation",
  canceled = "canceled",
  outstanding = "outstanding",
  deleted = "deleted"
}
