























import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { AgentDao } from "@/services/dao/agentDao"
import { Agent, ListCard } from "@robocent/components"
import { EventBus } from "@/services/eventBus"
import AgentMixin from "@/mixins/AgentMixin"

@Component({
  components: {
    ListCard
  }
})
export default class DeletedAgentItem extends mixins(AgentMixin) {
  @Prop() deletedAgent!: Agent

  async restoreAgent() {
    await this.confirm("Do you want to restore this Agent?")
    await this.request(async () => {
      await AgentDao.restoreAgent(
        this.$route.params.orgId,
        this.deletedAgent.id
      )
      EventBus.getDeletedAgents$.next()
    }, "Agent was successfully restored")
  }
}
