import { render, staticRenderFns } from "./SupportGuides.vue?vue&type=template&id=4099d472&scoped=true&"
import script from "./SupportGuides.vue?vue&type=script&lang=ts&"
export * from "./SupportGuides.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4099d472",
  null
  
)

export default component.exports