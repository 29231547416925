import { User } from "@robocent/components"
import { AgentDao } from "@/services/dao/agentDao"
import { UserDao } from "@/services/dao/userDao"

export class InviteUtil {
  static async inviteAgentHandler(user: User) {
    try {
      return (await AgentDao.acceptInvites(user.email)).data
    } catch (e) {
      console.error(e)
    }
  }

  static async inviteUserHandler(user: User) {
    try {
      return (await UserDao.acceptInvites(user.email)).data
    } catch (e) {
      console.error(e)
    }
  }
}
