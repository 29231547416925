

















































import Component, { mixins } from "vue-class-component"
import { Upload } from "@robocent/components"
import { Pagination } from "@/utils/pagination"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import ShowDeletedFilter from "@/components/elements/ShowDeletedFilter.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import OrganizationUploadsListMixin from "@/mixins/organization/OrganizationUploadsListMixin"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { Watch } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import ImageFileItem from "@/components/uploads/images/ImageFileItem.vue"
import { UploadType } from "@/enums/uploadType"

@Component({
  components: {
    ImageFileItem,
    SearchItem,
    PaginationItem,
    ShowDeletedFilter
  }
})
export default class OrganizationImages extends mixins(
  VueComponent,
  OrganizationUploadsListMixin
) {
  pagination = new Pagination(0, 1, 10)
  images: Upload[] = []
  signedUrls: string[] = []
  orgId!: string
  defaultOptions = {
    q: null,
    deleted: false
  } as FindManyFilter

  get search(): string | null {
    return this.$route.query.q as string | null
  }

  created() {
    this.orgId = this.$route.params.orgId
    this.getImages()
    EventBus.getUploads$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getImages)
  }

  async getImages() {
    const filter = Object.assign(
      this.defaultOptions,
      this.pagination.query,
      this.$route.query,
      { type: [UploadType.image] }
    )

    await this.request(
      async () => {
        const response = await UploadsDao.getUploads(this.orgId, filter)
        this.pagination.total = response.total
        this.pagination.current = Number(filter.page)
        this.images = response.data
        this.getSignedUrls()

        if (
          this.pagination.current !== 1 &&
          response.total <= this.pagination.perPage
        ) {
          this.applyQuery({ ...filter, page: 1 })
        }
      },
      undefined,
      "Cannot get images"
    )
  }

  async getSignedUrls() {
    const ids = this.images.map((item) => item._id)
    this.signedUrls = await UploadsDao.getLinksForUploads(ids)
  }

  @Watch("$route.query")
  onRouteChange() {
    this.getImages()
  }
}
