import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"

@Component
export default class OverviewMixin extends VueComponent {
  get globalLoading() {
    return this.$store.getters["voiceConfigurator/loading"]
  }

  get status() {
    return this.$store.getters["voiceConfigurator/status"]
  }

  get campaign(): VoiceCampaign {
    return this.$store.getters["voiceConfigurator/campaign"]
  }

  get isRoboCall() {
    return this.campaign.type === VoiceCampaignType.robocall
  }

  get isTransfer() {
    return this.campaign.type === VoiceCampaignType.transfer
  }

  get isSurvey() {
    return this.campaign.type === VoiceCampaignType.survey
  }

  get isDeleted() {
    return this.campaign.meta.deleted
  }

  async updateCampaign() {
    await this.$store.dispatch("voiceConfigurator/updateCampaign", {
      orgId: this.campaign.organization._id,
      callId: this.campaign._id
    })
  }
}
