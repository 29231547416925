










import Component from "vue-class-component"
import PartnerPayoutsTable from "@/components/partnerPortal/admin/PartnerPayoutsTable.vue"
import PartnerPayoutMixin from "@/mixins/partnerPortal/PartnerPayoutMixin"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({
  components: {
    PartnerPayoutsTable
  }
})
export default class CompletedPartnerPayouts extends PartnerPayoutMixin {
  async created() {
    await this.getPartnersPayouts({ status: PartnerPayoutStatus.paid })
  }
}
