import axios from "axios"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { PhoneList, Upload } from "@robocent/components"

export class UploadsDao {
  static async uploadPhoneList(orgId: string, uploadId: string, data: any) {
    return await axios.post(
      `/organizations/${orgId}/uploads/${uploadId}/phone-list`,
      data
    )
  }

  static async getFileData(
    orgId: string,
    uploadId: string
  ): Promise<PhoneList> {
    return (
      await axios.get(`/organizations/${orgId}/uploads/${uploadId}/parse`)
    ).data
  }

  static async createUpload(orgId: string, data: any, config: any) {
    return await axios.post(`/organizations/${orgId}/uploads/`, data, config)
  }

  static async getUpload(orgId: string, uploadUUID: string) {
    return await axios.get(`/organizations/${orgId}/uploads/${uploadUUID}`)
  }

  static async lookupDetails(orgId: string, uploadId: string) {
    return await axios.get(
      `/organizations/${orgId}/uploads/${uploadId}/lookup-details`
    )
  }

  static async lookupInvoice(orgId: string, uploadId: string) {
    return await axios.get(
      `/organizations/${orgId}/uploads/${uploadId}/lookup-invoice`
    )
  }

  static async startLookup(orgId: string, uploadId: string) {
    return await axios.get(`/organizations/${orgId}/uploads/${uploadId}/lookup`)
  }

  static async reRunLookupInvoice(orgId: string, uploadId: string) {
    return await axios.get(
      `/organizations/${orgId}/uploads/${uploadId}/re-run-lookup`
    )
  }

  static async getLinksForUploads(uploadIds: string[]) {
    return (await axios.post("/uploads/links", { ids: uploadIds })).data
  }

  static async getLinkForUpload(orgId: string, uploadId: string) {
    return (await axios.get(`/organizations/${orgId}/uploads/${uploadId}/link`))
      .data
  }

  static async deleteUpload(orgId: string, uploadId: string) {
    return await axios.delete(`/organizations/${orgId}/uploads/${uploadId}`)
  }

  static async renameUpload(orgId: string, uploadId: string, name: string) {
    return await axios.put(`/organizations/${orgId}/uploads/${uploadId}`, {
      name
    })
  }

  static async restoreUpload(orgId: string, uploadId: string) {
    return await axios.put(
      `/organizations/${orgId}/uploads/${uploadId}/restore`
    )
  }

  static async setShared(orgId: string, uploadId: string, shared: boolean) {
    return await axios.put(
      `/organizations/${orgId}/uploads/${uploadId}/sharing-settings`,
      { shared }
    )
  }

  static async getUploads(
    orgId: string,
    params: FindManyFilter
  ): Promise<{ data: Upload[]; total: number }> {
    return (await axios.get(`/organizations/${orgId}/uploads`, { params })).data
  }
}
