









import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import CheckIcon from "@/components/elements/CheckIcon.vue"

@Component({
  components: {
    CheckIcon
  }
})
export default class CheckListTableItem extends VueComponent {
  @Prop() label!: string
  @Prop() value!: string
  @Prop() successIcon!: string
}
