const unicodePattern =
  "^[A-Za-z0-9 \\r\\n@\xa3$\xa5\xe8\xe9\xf9\xec\xf2\xc7\xd8\xf8\xc5\xe5\xa4\u0394_\u03a6\u0393\u039b\u03a9\u03a0\u03a8\u03a3\u0398\u039e\xc6\xe6\xdf\xc9!\"#$%&'()*+,\\-./:;<=>?\xa1\xc4\xd6\xd1\xdc\xa7\xbf\xe4\xf6\xf1\xfc]*$"
const showMaxUnicodeChars = 5
const charsToIgnore = ["{", "}"]
const apostropheRegex = /([`’])/g

export class UnicodeUtil {
  unicodeChars = ""

  replaceUnicode(str: string) {
    return str.replace(apostropheRegex, "'")
  }

  detectUnicode(str = "") {
    const regexUnicode = new RegExp(unicodePattern)
    const unicodeCharList = []

    for (let i = 0; i < str.length; i++) {
      if (!regexUnicode.test(str[i])) {
        unicodeCharList.push(str[i])
      }
    }

    if (unicodeCharList.length) {
      this.unicodeChars = unicodeCharList
        .filter((char) => !charsToIgnore.includes(char))
        .map((char) => `"${char}"`)
        .slice(0, showMaxUnicodeChars)
        .join(", ")

      return
    }

    this.clearUnicodeChars()
  }

  clearUnicodeChars() {
    this.unicodeChars = ""
  }
}
