















































































































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { Organization } from "@robocent/components"
import { User, ListCard } from "@robocent/components"
import AdminUserLevelModal from "@/components/modals/AdminUserLevelModal.vue"
import { verify } from "sinon"

@Component({
  methods: { verify },
  components: {
    ListCard
  }
})
export default class UserItem extends VueComponent {
  @Prop() user!: User
  @Prop() organizations!: Organization[]

  stripeDashboard = "https://dashboard.stripe.com/customers"

  openUserLevelModal() {
    this.$buefy.modal.open({
      component: AdminUserLevelModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        user: this.user
      },
      events: {
        onConfirm: () => this.$emit("change")
      }
    })
  }
}
