import { Pie } from "vue-chartjs"
import Vue from "vue"

export default Vue.extend({
  extends: Pie,
  props: ["chartData", "options"],
  watch: {
    chartData: function () {
      this.calculate()
    }
  },
  mounted() {
    this.calculate()
  },
  methods: {
    calculate() {
      ;(this as any).renderChart(this.chartData, this.options)
    }
  }
})
