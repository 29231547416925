










































import Component, { mixins } from "vue-class-component"
import { Agent, ListCard } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { AgentDao } from "@/services/dao/agentDao"
import { EventBus } from "@/services/eventBus"
import AgentMixin from "@/mixins/AgentMixin"

@Component({
  components: {
    ListCard
  }
})
export default class AgentItem extends mixins(AgentMixin) {
  @Prop() agent!: Agent

  async deleteAgent() {
    await this.confirm("Do you really want to delete Agent?")
    await this.request(async () => {
      await AgentDao.deleteAgents(this.$route.params.orgId, this.agent.id)
      EventBus.getAgents$.next()
    }, "Agent successfully deleted")
  }
}
