var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container pt-sm"},[_c('business-not-registered',{staticClass:"has-text-left is-size-6 is-paddingless"})],1),(!_vm.isOrganizationInfoCompleted)?_c('div',{staticClass:"container pt-sm"},[_c('b-message',{attrs:{"type":"is-warning","has-icon":""}},[_c('span',{staticClass:"has-text-left is-size-6 is-paddingless"},[_vm._v(" Your profile is incomplete. Update your profile to complete sign-up: "),_c('router-link',{staticClass:"is-underlined",attrs:{"to":{
            name: 'Org Settings',
            params: {
              orgId: _vm.orgId
            },
            query: {
              tab: _vm.tabs.details
            }
          }}},[_vm._v("Update Organization Info")])],1)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }