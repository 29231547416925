



















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import AgentItem from "@/components/agent/AgentItem.vue"
import { Agent } from "@robocent/components"
import { EventBus } from "@/services/eventBus"
import { AgentDao } from "@/services/dao/agentDao"
import { whileComponentMounted } from "@/utils/operators"
import AddAgentModal from "@/components/modals/AddAgentModal.vue"

@Component({
  components: { AgentItem }
})
export default class AgentsList extends VueComponent {
  agents: Agent[] = []

  async created() {
    EventBus.getAgents$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getAgents)
    await this.getAgents()
  }

  async getAgents() {
    await this.request(async () => {
      this.agents = (
        await AgentDao.getAgents(this.$route.params.orgId, { limit: 1000 })
      ).data
    })
  }

  addAgentModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddAgentModal,
      width: 1280,
      props: {
        orgId: this.$route.params.orgId
      }
    })
  }
}
