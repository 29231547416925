












































import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { CampaignPricing, CampaignType } from "@robocent/components"
import Component from "vue-class-component"
import ChooseItem from "../elements/ChooseItem.vue"
import ChooseRow from "../elements/ChooseRow.vue"
import { Prop } from "vue-property-decorator"
import SelfServiceAgreementModal from "@/components/modals/SelfServiceAgreementModal.vue"

@Component({
  components: { ChooseRow, ChooseItem }
})
export default class TextCampaignTypeStep extends TextSchedulerUtilsMixin {
  @Prop() pricing!: CampaignPricing
  types = CampaignType

  get subtitle() {
    return `+${this.$options.filters!.toCurrency(
      this.pricing?.managedCost
    )} Per Message`
  }

  isActive(type: CampaignType) {
    return this.campaign.type === type
  }

  select(type: CampaignType) {
    if (this.campaign.type === type) {
      return
    }
    this.$set(this.campaign, "type", type)
    this.$set(this.campaign, "agents", [])
    this.$set(this.campaign, "invitedAgents", [])

    if (this.$store.getters.super) {
      return this.next(TextSchedulerSteps.campaignAgents)
    }

    if (type === CampaignType.self) {
      this.openSelfServiceAgreementModal()
      this.next(TextSchedulerSteps.campaignAgents)
    } else {
      this.remove(TextSchedulerSteps.campaignAgents)
      setTimeout(() => {
        this.next(TextSchedulerSteps.campaignPhoneNumbersInfo)
      }, 400)
    }
  }

  openSelfServiceAgreementModal() {
    this.$buefy.modal.open({
      parent: this,
      component: SelfServiceAgreementModal,
      width: 500,
      events: {
        onDecline: () => this.select(CampaignType.managed)
      }
    })
  }
}
