







































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"

@Component
export default class NotifyAgentModal extends VueComponent {
  message = ""

  onConfirm() {
    this.$emit("confirm", this.message)
    this.$parent.close()
  }
}
