<template>
  <div>
    <public-page-header></public-page-header>
    <div class="container box">
      <b-message class="is-borderless" :type="'is-danger'">
        <div class="columns">
          <div class="column">
            <p>
              <strong class="is-size-1">404</strong>
            </p>
            <p class="is-size-5 has-text-grey">Page Not Found</p>
          </div>
        </div>
      </b-message>
      <div class="level-left buttons">
        <b-button
          size="is-medium"
          icon-left="chevron-left"
          class="is-va-middle"
          @click="$router.go(-1)"
        >
          <span class="align-icon">Back</span>
        </b-button>
        <router-link class="button is-medium" :to="{ name: 'Dashboard' }">
          Dashboard
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PublicPageHeader from "@/components/shared/PublicPageHeader.vue"
import Component from "vue-class-component"
import Vue from "vue"

@Component({ components: { PublicPageHeader } })
export default class NotFound extends Vue {}
</script>

<style scoped lang="scss">
::v-deep {
  .box {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .media-content {
    overflow: unset;
  }
}
</style>
