import { StringObject } from "@robocent/components"
import axios from "axios"

export class ImageDao {
  static async getImages(orgId: string, params: StringObject) {
    return await axios.get(`/organizations/${orgId}/uploads`, {
      params: {
        type: "image",
        ...params
      }
    })
  }

  static async deleteImage(orgId: string, uploadId: string) {
    return await axios.put(`/organizations/${orgId}/uploads/${uploadId}/delete`)
  }
}
