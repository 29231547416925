














































import Component, { mixins } from "vue-class-component"
import ScriptItemBody from "@/components/scripts/ScriptItemBody.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { Emit, Prop } from "vue-property-decorator"
import DeleteScriptMixin from "@/mixins/DeleteScriptMixin"
import { CampaignPricing, Script, ScriptStatus } from "@robocent/components"
import EditScriptModal from "@/components/modals/EditScriptModal.vue"
import TestScriptModal from "@/components/modals/TestScriptModal.vue"

@Component({
  components: {
    ScriptItemBody,
    ChooseItem
  }
})
export default class ScriptItem extends mixins(DeleteScriptMixin) {
  @Prop() active!: boolean
  @Prop() script!: Script
  @Prop({ default: null }) pricing!: CampaignPricing
  @Prop() viewMode!: boolean

  @Prop() disabled!: boolean
  @Prop() multiselectIndex!: number
  @Prop() tooltipText!: string
  @Prop({ default: true }) testMessageAvailable!: boolean

  get isEditEnabled() {
    return (
      this.script.status !== ScriptStatus.approved || this.$store.getters.super
    )
  }

  editScriptModal(script: Script) {
    this.$buefy.modal.open({
      parent: this,
      component: EditScriptModal,
      width: 950,
      props: {
        organizationId: script.organizationId,
        scriptId: script.id
      },
      events: {
        onChange: this.onChange
      }
    })
  }

  async handleDelete(script: Script) {
    const deletedScript = await this.onScriptDelete(script)
    this.onChange(deletedScript)
  }

  sendTestScript(script: Script) {
    this.$buefy.modal.open({
      component: TestScriptModal,
      width: 500,
      props: {
        script
      }
    })
  }

  @Emit()
  onChange(script: Script) {
    return script
  }
}
