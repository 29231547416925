import {
  Campaign,
  Organization,
  Script,
  User,
  UserV2
} from "@robocent/components"
import _ from "lodash"

interface CampaignExtended extends Campaign {
  usedCost: number
  user: UserV2
  userId: string
  organization: Organization
  defaultAgents: User[]
}

export class CampaignUtils {
  hasIntroScript(scripts: Script[]) {
    return Boolean(_.some(scripts, { intro: true, deleted: false }))
  }

  prepareCampaign(campaign: CampaignExtended | Campaign): Campaign {
    return {
      agents: campaign.agents,
      areaCode: campaign.areaCode,
      birthday: campaign.birthday,
      end: campaign.end,
      invitedAgents: campaign.invitedAgents,
      listId: campaign.listId,
      name: campaign.name,
      organizationId: campaign.organization?._id || campaign.organizationId,
      phone: campaign.phone,
      scripts: campaign.scripts,
      service: campaign.service,
      start: campaign.start,
      state: campaign.state,
      time: campaign.time,
      timezone: campaign.timezone,
      type: campaign.type,
      promoCode: campaign.promoCode
    } as unknown as Campaign
  }
}
