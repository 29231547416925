import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import _ from "lodash"
import { CouponsDao } from "@/services/dao/robocent/couponsDao"

@Component
export class PromoCodeMixin extends VueComponent {
  statuses = {
    valid: "valid",
    invalid: "invalid",
    pending: "pending"
  }
  promoCodeStatus = ""

  promoCodeChanged = _.debounce((promoCode) => {
    this.validatePromoCode(promoCode)
  }, 300)

  promoCodeClassType() {
    if (
      this.promoCodeStatus !== this.statuses.valid &&
      this.promoCodeStatus !== this.statuses.invalid
    ) {
      return ""
    }
    return this.promoCodeStatus === this.statuses.valid
      ? "is-success"
      : "is-danger"
  }

  promoCodeMessage() {
    return this.promoCodeStatus === this.statuses.invalid
      ? "The coupon is invalid. Please try another one"
      : ""
  }

  async validatePromoCode(promoCode: string) {
    await this.request(async () => {
      this.promoCodeStatus = ""
      if (!promoCode) return
      this.promoCodeStatus = this.statuses.pending
      const isPromoCodeValid = (await CouponsDao.validateCoupon(promoCode)).data
        .result
      this.promoCodeStatus = isPromoCodeValid
        ? this.statuses.valid
        : this.statuses.invalid
    })
  }
}
