









import { VueComponent } from "@/utils/VueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class LeadsDiscountTag extends VueComponent {
  @Prop() priceBeforeDiscount!: number
  @Prop() discount!: string
  @Prop() rounded!: boolean
}
