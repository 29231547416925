import { Module } from "vuex"
import _ from "lodash"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { isVoiceCampaignUpdate } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"

const initState = {
  steps: [],
  step: null
}

export const voiceSchedulerStore = <Module<any, any>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    step: (state) => state.step,
    steps: (state) => state.steps
  },
  mutations: {
    next: (state, step: VoiceSchedulerSteps) => {
      if (!state.steps.includes(step)) {
        state.steps.push(step)
      }

      if (state.step !== step) {
        state.step = step
      }
    },
    remove: (state, step: VoiceSchedulerSteps) => {
      state.steps = state.steps.filter(
        (filledStep: VoiceSchedulerSteps) => filledStep !== step
      )
    },
    fillAllSteps(state, campaign: VoiceCampaign) {
      let steps = Object.values(VoiceSchedulerSteps)
      const isUpdate = isVoiceCampaignUpdate(campaign)
      const exclude: VoiceSchedulerSteps[] = []

      if (isUpdate) {
        exclude.push(VoiceSchedulerSteps.campaignCard)
        exclude.push(VoiceSchedulerSteps.campaignCost)
      }

      if (campaign.type !== VoiceCampaignType.transfer) {
        exclude.push(VoiceSchedulerSteps.campaignTransferOptions)
      }

      if (campaign.type !== VoiceCampaignType.survey) {
        exclude.push(VoiceSchedulerSteps.campaignSurveyScript)
      }

      state.steps = steps = steps.filter(
        (step: VoiceSchedulerSteps) => !exclude.includes(step)
      )
    },
    clear: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
