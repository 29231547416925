



























import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { CardTable, CardTableItem } from "@robocent/components"
import { VoterDataQuery } from "@/voterData/interfaces/VoterDataQuery"
import { getStateAbbr } from "@/voterData/utils/state"
import _ from "lodash"
import { historyReqs, votingHistories } from "@/voterData/data/votingHistory"
import { VueComponent } from "@/utils/VueComponent"
import { DatasetFields } from "@/voterData/enums/datasetFields"
import SelectedOptionsTable from "@/voterData/components/SelectedOptionsTable.vue"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"
import { CriteriaNames } from "@/voterData/enums/criteriaNames"
import { Criteria } from "@/voterData/interfaces/Criteria"

@Component({
  components: { SelectedOptionsTable, CardTableItem, CardTable }
})
export default class ViewCriteriaModal extends VueComponent {
  @Prop() query!: VoterDataQuery

  data: {
    [key in CriteriaNames]: Criteria
  } = {
    county: {
      criteria: "County",
      options: []
    },
    zipCode: {
      criteria: "Zip Code",
      options: []
    },
    municipality: {
      criteria: "Municipality",
      options: []
    },
    congDist: {
      criteria: "Congressional District",
      options: []
    },
    precinct: {
      criteria: "Precinct",
      options: []
    },
    gender: {
      criteria: "Gender",
      options: []
    },
    age: {
      criteria: "Age",
      options: []
    },
    ethnicity: {
      criteria: "Ethnicity",
      options: []
    },
    partyCode: {
      criteria: "Party Code",
      options: []
    },
    votingHistory: {
      criteria: "Voting History",
      options: votingHistories
    },
    historyReqs: {
      criteria: "Voting History Requirements",
      options: historyReqs
    }
  }

  mounted() {
    this.getData()
  }

  async getData() {
    await this.request(async () => {
      return Promise.all(
        _.map(Object.keys(this.data), async (field: CriteriaNames) => {
          if (field === CriteriaNames.precinct && this.query.precinct?.length) {
            this.data.precinct.options = this.query.precinct.map((prec) => ({
              name: prec.value,
              value: prec.value
            }))
          } else if (!this.data[field]?.options?.length) {
            this.data[field].options = await this.getStepData(
              DatasetFields[field]
            )
          }

          return this.mapOptions(field)
        })
      )
    })
  }

  async getStepData(field: DatasetFields) {
    return await this.$store.dispatch("voterData/getStepData", {
      state: getStateAbbr(this.query.state),
      field
    })
  }

  mapOptions(field: CriteriaNames) {
    if (field === CriteriaNames.precinct) {
      return
    }
    this.data[field].options = _.cloneDeep(
      this.data[field].options.filter((option: FieldInfo) =>
        this.query[field]?.includes(option?.value as any)
      )
    )
  }
}
