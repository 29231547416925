






























import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import OrganizationEmptyUsers from "@/views/organization/OrganizationEmptyUsers.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"

@Component({
  components: {
    OrganizationEmptyUsers
  }
})
export default class OrganizationInvitedUsers extends VueComponent {
  users = []

  created() {
    this.getUsers()
    EventBus.getUsers$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getUsers)
  }

  async getUsers() {
    await this.request(async () => {
      this.users = await OrganizationDao.retrieveInvitedUsers(
        this.$route.params.orgId
      )
    })
  }

  openRemoveInvitedUserModal(email: string) {
    const message = "Remove this user from the invite list?"
    this.confirm(message, {
      title: "Uninvite User",
      message,
      type: "is-danger",
      hasIcon: true,
      icon: "times-circle",
      iconPack: "fa",
      onConfirm: () => this.removeInvitedUser(email)
    })
  }

  async removeInvitedUser(email: string) {
    await this.request(async () => {
      await OrganizationDao.removeInvitedUser(this.$route.params.orgId, email)
      this.openToast("User was removed from invite list")
      await this.getUsers()
    })
  }
}
