<template>
  <div class="container mt-md">
    <b-loading :active.sync="loading" :can-cancel="true" />
    <b-message v-if="orgId" class="is-primary is-borderless">
      <div class="columns is-vcentered phone-manage">
        <div class="column">
          <p>
            <span class="is-size-5">Callback Numbers</span>
          </p>
          <p class="is-size-6 has-text-grey">
            <span>Purchase & Manage Callback Numbers</span>
          </p>
        </div>
        <div class="column has-text-right">
          <b-field class="buttons-wrapper">
            <b-button @click="open = true" type="is-success">
              Purchase Callback Number ($50/mo)
            </b-button>
            <b-button @click="addCallerId" type="is-success" class="ml-10">
              Import Existing Number for Caller ID
            </b-button>
          </b-field>
        </div>
      </div>
    </b-message>

    <search-item
      class="is-12"
      :value="$route.query.q"
      @on-change="getPhones({ q: $event })"
    />

    <section v-if="phones.length">
      <list-card
        class="mt-md"
        v-for="phone in phones"
        :key="phone.uuid"
        :title="getTitle(phone)"
      >
        <template #left>
          <div class="links">
            <p>
              <small>{{ phone.organization.name }} </small>
            </p>
            <p>
              <small>
                Created on
                {{ phone.createdAt | moment("MM/DD/YY") }}
              </small>
            </p>
          </div>
        </template>
        <template #right>
          <b-field v-if="phone.type == 'imported'" class="buttons is-just-end">
            <button class="button has-text-success">Manually Imported</button>
            <button class="button" @click="edit(phone)">Rename</button>
          </b-field>
          <b-field v-else class="buttons is-just-end">
            <router-link
              class="button"
              :to="'/organizations/' + orgId + '/phones/' + phone.uuid"
              >Settings</router-link
            >
            <router-link
              class="button"
              :to="`/organizations/${orgId}/phones/${phone.uuid}/messages`"
              >View Text & Voice Messages</router-link
            >
            <router-link
              class="button"
              :to="`/organizations/${orgId}/phones/${phone.uuid}/subscribers`"
              >View Subscribers</router-link
            >
            <button class="button" @click="edit(phone)">Rename</button>
            <button
              v-if="!phone.metadata.deleted"
              class="button has-text-danger"
              @click="releaseConfirm(phone)"
            >
              Deactivate
            </button>
            <button v-else class="button has-text-info">Inactive</button>
          </b-field>
          <b-field v-only-super class="buttons is-just-end">
            <b-dropdown
              v-if="phone.metadata.subscriptionId"
              position="is-bottom-left"
              class="button"
            >
              <div slot="trigger" class="is-flex">
                <div>Admin</div>
                <b-icon icon="caret-down"></b-icon>
              </div>
              <b-dropdown-item has-link aria-role="menuitem">
                <a
                  :href="`https://dashboard.stripe.com/subscriptions/${phone.metadata.subscriptionId}`"
                  target="_blank"
                >
                  View Subscription
                </a>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </template>
      </list-card>
      <div class="has-padding mt-md">
        <b-pagination
          :total="total"
          :current="current"
          :range-before="rangeBefore"
          :range-after="rangeAfter"
          :per-page="perPage"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          order="is-centered"
          @change="onPageChange"
        >
        </b-pagination>
      </div>
    </section>
    <section v-else>
      <b-message type="is-info" class="mt-sm">
        <p>No Callback Numbers Found.</p>
        <small>
          Click the "Purchase Callback Number" button above to get your first
          number.
        </small>
      </b-message>
    </section>
    <b-modal :active.sync="open" has-modal-card trap-focus>
      <purchase-phone-number :org="orgId" @on-purchase="getPhones" />
    </b-modal>
  </div>
</template>

<script>
import PurchasePhoneNumber from "../elements/PurchasePhoneNumber.vue"
import axios from "axios"
import { get } from "lodash"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"
import ImportExistingPhoneNumberModal from "@/components/modals/ImportExistingPhoneNumberModal.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import SearchItem from "@/components/scheduler/elements/SearchItem"

export default {
  name: "Phones",
  components: { SearchItem, PurchasePhoneNumber },
  data() {
    return {
      orgId: this.$route.params.orgId,
      activeTab: null,
      holdingId: "",
      search: "",
      loading: false,
      phones: [],
      view_deleted: false,
      view_all: false,
      open: false,
      total: null,
      current: 1,
      perPage: 20,
      rangeBefore: 3,
      rangeAfter: 3,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right"
    }
  },
  created() {
    this.getPhones()
    EventBus.getPhoneNumbers$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getPhones)
  },
  methods: {
    getTitle(phone) {
      const number = this.$options.filters.phone(phone.number)
      return `${number} - ${phone.name}`
    },
    releaseConfirm(phone) {
      this.$buefy.dialog.confirm({
        message: `Deactivate ${phone.number} and Cancel Subscription?`,
        trapFocus: true,
        onConfirm: () => this.release(phone)
      })
    },
    async release(phone) {
      await axios
        .delete(`organizations/${this.orgId}/phones/${phone.uuid}`)
        .then(() => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "Callback Number Subscription Canceled",
            position: "is-bottom",
            type: "is-success"
          })
          this.getPhones()
        })
        .catch((e) => {
          this.$buefy.toast.open({
            duration: 3000,
            message:
              get(e, "response.data.message") || "Error during release phone",
            position: "is-bottom",
            type: "is-danger"
          })
        })
    },
    edit(item) {
      this.$buefy.dialog.prompt({
        message: "Rename Callback Number",
        inputAttrs: {
          value: item.name
        },
        trapFocus: true,
        onConfirm: (name) => this.rename(item, name)
      })
    },
    async rename(phone, name) {
      try {
        await PhoneNumberDao.savePhone(phone.organizationId, phone.uuid, {
          name
        })
        this.$buefy.toast.open({
          duration: 3000,
          message: "Renamed Callback Number",
          position: "is-bottom",
          type: "is-success"
        })
        this.getPhones()
      } catch (e) {
        console.warn(e)
      }
    },
    onPageChange(page) {
      this.current = page
      this.getPhones()
    },
    async getPhones(searchQuery) {
      const params = {
        page: this.current,
        ...searchQuery
      }

      if (this.search) {
        params.q = this.search
      }
      try {
        this.loading = true
        const { data, total } = await PhoneNumberDao.getPhones(
          this.$route.params.orgId,
          params
        )
        this.phones = data
        this.total = total
      } catch (e) {
        this.$buefy.toast.open({
          message: "Failed loading phones.",
          type: "is-danger"
        })
      } finally {
        this.loading = false
      }
    },
    addCallerId() {
      this.$buefy.modal.open({
        parent: this,
        component: ImportExistingPhoneNumberModal,
        props: {
          orgId: this.$route.params.orgId
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";

::v-deep .title.is-5 {
  margin-bottom: 0;
}

.ml-10 {
  margin-left: 10px;
}

.buttons-wrapper {
  flex-wrap: wrap;
  justify-content: flex-end;

  .button {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@include mobile {
  .phone-manage {
    text-align: center;
  }

  .buttons-wrapper {
    justify-content: center;
    margin-bottom: 0;

    .button {
      white-space: normal;
      height: auto;
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  ::v-deep .media-content {
    display: contents;
  }
}
</style>
