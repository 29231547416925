import axios from "axios"
import { ReportType } from "@/interfaces/campaignReports"
import { Campaign, Organization } from "@robocent/components"

export class SharedReportDao {
  static async getCampaign(
    orgId: string,
    campaignUUID: string
  ): Promise<Campaign & { organization: Organization }> {
    return (await axios.get(`/text/shared/campaigns/${campaignUUID}`)).data
  }

  static async getCampaignStats(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/shared/campaigns/${campaignUUID}/reports/campaign-stats`
    )
  }

  static async getDetailedCampaignStats(orgId: string, campaignUUID: string) {
    return (
      await axios.get(
        `/text/shared/campaigns/${campaignUUID}/reports/campaign-detailed-stats`
      )
    ).data
  }

  static async getCampaignProgress(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/shared/campaigns/${campaignUUID}/reports/campaign-progress`
    )
  }

  static async getCampaignReports(orgId: string, campaignUUID: string) {
    return axios.get(`/text/shared/campaigns/${campaignUUID}/reports`)
  }

  static async generateCampaignReport(
    orgId: string,
    campaignUUID: string,
    type: ReportType
  ) {
    return axios.post(`/text/shared/campaigns/${campaignUUID}/reports`, {
      type
    })
  }

  static async getCampaignSurveyStats(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/shared/campaigns/${campaignUUID}/reports/campaign-survey-stats`
    )
  }

  static async getCampaignSurveyResponses(
    orgId: string,
    campaignUUID: string,
    scriptId: number
  ) {
    return (
      await axios.get(
        `/text/shared/campaigns/${campaignUUID}/reports/campaign-survey-responses/${scriptId}`
      )
    ).data
  }

  static async getCampaignAgentsStats(orgId: string, campaignUUID: string) {
    return axios.get(
      `/text/shared/campaigns/${campaignUUID}/reports/campaign-agents-stats`
    )
  }

  static async getPhoneList(
    orgId: string,
    campaignUUID: string,
    listId: string
  ) {
    return axios.get(
      `/text/shared/campaigns/${campaignUUID}/phone-lists/${listId}`
    )
  }
}
