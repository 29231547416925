












import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { FeatureDao } from "@/services/dao/featureDao"
import { OrganizationFeature } from "@/interfaces/feature/organizationFeature"
import OrganizationFeatureItem from "@/components/feature/OrganizationFeatureItem.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: { OrganizationFeatureItem }
})
export default class OrganizationFeatures extends VueComponent {
  features: OrganizationFeature[] = []

  created() {
    this.getFeatures()
    EventBus.getFeatures$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getFeatures)
  }

  async getFeatures() {
    await this.request(async () => {
      this.features = await FeatureDao.getOrganizationFeatures(
        this.$route.params.orgId
      )
    })
  }
}
