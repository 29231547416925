











import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import TextSchedulerElements from "../../components/scheduler/TextSchedulerElements.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import { Campaign, CampaignType } from "@robocent/components"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"

@Component({
  components: { SchedulerElements: TextSchedulerElements }
})
export default class EditCampaign extends VueComponent {
  campaign?: Campaign | null = null

  async created() {
    await this.getCampaign()
    this.$store.commit("textScheduler/fillAllSteps", this.campaign)

    if (
      this.campaign!.type === CampaignType.managed &&
      !this.$store.getters.super
    ) {
      this.$store.commit(
        "textScheduler/remove",
        TextSchedulerSteps.campaignAgents
      )
    }
  }

  async getCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.campaign = await CampaignDao.getCampaign(orgId, campaignUUID)
      },
      undefined,
      "Cannot get campaign"
    )
  }
}
