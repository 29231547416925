import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Agent } from "@robocent/components"
import { UserLevel } from "@/enums/userLevel"

@Component({})
export default class AgentMixin extends VueComponent {
  isInternalAgent(agent: Agent) {
    return (
      agent.user?.app_metadata?.internal ||
      agent.user?.app_metadata?.level === UserLevel.super
    )
  }

  agentType(agent: Agent) {
    return this.isInternalAgent(agent) ? "RoboCent Agent" : null
  }
}
