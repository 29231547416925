import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import { Campaign, PhoneList } from "@robocent/components"

@Component
export default class TextOverviewMixin extends VueComponent {
  get globalLoading() {
    return this.$store.getters["textConfigurator/loading"]
  }

  get status() {
    return this.$store.getters["textConfigurator/status"]
  }

  get list(): PhoneList {
    return this.$store.getters["textConfigurator/list"]
  }

  get campaign(): Campaign {
    return this.$store.getters["textConfigurator/campaign"]
  }

  async updateCampaign() {
    await this.$store.dispatch("textConfigurator/updateCampaign", {
      orgId: this.campaign.organizationId,
      campaignUUID: this.campaign.uuid
    })
  }
}
