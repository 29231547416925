








































import Component from "vue-class-component"
import { CardTable, CardTableItem } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { Prop } from "vue-property-decorator"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"

@Component({
  components: {
    StepTemplate,
    Card,
    CardTable,
    CardTableItem
  }
})
export default class SalesCommissionRates extends SalesPortalMixin {
  @Prop() isLoading!: boolean

  get rateBeforeType() {
    return this.usedRate === this.salesRate.rateBefore
      ? "is-success"
      : "is-primary"
  }

  get rateAfterType() {
    return this.usedRate === this.salesRate.rateAfter
      ? "is-success"
      : "is-primary"
  }

  get usedRate() {
    const { threshold, rateAfter, rateBefore } = this.salesRate
    return this.salesEarnings.totalSales < threshold ? rateBefore : rateAfter
  }
}
