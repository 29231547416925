import Component, { mixins } from "vue-class-component"
import { PhoneList, PhoneListUtilsMixin } from "@robocent/components"
import { PhoneListStatuses } from "@/enums/phoneListStatuses"
import _ from "lodash"

@Component
export default class PhoneListItemMixin extends mixins<PhoneListUtilsMixin>(
  PhoneListUtilsMixin
) {
  getStatus(list: PhoneList) {
    if (list.meta?.status) {
      return {
        type: "is-info",
        name: list.meta.status?.state,
        value: list.meta.status?.value,
        outside: true
      }
    }

    if (!list.stratics?.id && !list.stratics?.rvm?.id) {
      return {
        type: "is-warning",
        name: PhoneListStatuses.pendingScrub
      }
    }

    return {
      type: "is-success",
      name: PhoneListStatuses.approved
    }
  }

  getMobiles(list: PhoneList) {
    if (_.isNumber(list.meta.lookup?.mobile)) {
      return list.meta.lookup?.mobile
    }

    if (_.isNumber(list.meta.details?.mobile)) {
      return list.meta.details?.mobile
    }

    return "-"
  }

  getLandlines(list: PhoneList) {
    if (_.isNumber(list.meta.lookup?.landline)) {
      return list.meta.lookup?.landline
    }

    if (_.isNumber(list.meta.details?.landline)) {
      return list.meta.details?.landline
    }

    return "-"
  }
}
