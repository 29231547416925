import axios from "axios"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { Agent } from "@robocent/components"

export class AgentDao {
  static async getAgents(orgId: string, params: FindManyFilter) {
    return (
      await axios.get(`text/organizations/${orgId}/agents`, {
        params
      })
    ).data
  }

  static async addAgents(orgId: string, userIds: string[]): Promise<Agent[]> {
    return (await axios.post(`text/organizations/${orgId}/agents`, { userIds }))
      .data
  }

  static async deleteAgents(orgId: string, agentId: number) {
    return await axios.delete(`text/organizations/${orgId}/agents/${agentId}`)
  }

  static async getInvitedAgents(orgId: string, params: FindManyFilter) {
    return (
      await axios.get(`text/organizations/${orgId}/invited-agents`, {
        params
      })
    ).data
  }

  static async restoreAgent(orgId: string, agentId: number) {
    return await axios.post(`text/organizations/${orgId}/agents/restore`, {
      agentId
    })
  }

  static async inviteAgent(orgId: string, email: string) {
    return await axios.post(`text/organizations/${orgId}/invited-agents`, {
      email
    })
  }

  static async deleteInvitedAgent(orgId: string, invitedAgentId: number) {
    return await axios.delete(
      `text/organizations/${orgId}/invited-agents/${invitedAgentId}`
    )
  }

  static async acceptInvites(email: string) {
    return await axios.post("text/invited-agents/accept-invites", {
      email
    })
  }
}
