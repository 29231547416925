

















































































import Component from "vue-class-component"
import { Emit, Prop, Watch } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import RefundCampaignCostMixin from "@/mixins/campaign/RefundCampaignCostMixin"
import RefundAmountCard from "@/components/elements/RefundAmountCard.vue"
import { RefundAmount } from "@/enums/refundAmount"
import { RefundTypes } from "@/enums/refundTypes"

@Component({
  components: { RefundAmountCard, Card }
})
export default class RefundCampaignCostModal extends RefundCampaignCostMixin {
  @Prop() confirmLoader!: boolean

  get refundAmounts() {
    return [
      {
        title: "Amount Paid via Stripe",
        amount: this.invoice?.amount_paid,
        value: RefundAmount.amountPaid
      },
      {
        title: "Total Amount on Invoice",
        amount: this.invoice?.total,
        value: RefundAmount.amountTotal
      },
      {
        title: "90% of the Total Amount",
        subtitle:
          this.refundType?.id === RefundTypes.outOfBandAmount
            ? "Cannot be selected due to the Stripe limitation."
            : "",
        amount: Math.round((Number(this.invoice?.total) * 90) / 100),
        value: RefundAmount.amount90Percent
      }
    ]
  }

  get icon() {
    if (this.refundType?.id === RefundTypes.creditAmount) {
      return "file-invoice-dollar"
    }
    if (this.refundType?.id === RefundTypes.refundAmount) {
      return "credit-card"
    }
    return "wallet"
  }

  mounted() {
    this.setRefundType()
    this.checkCreditCard(this.organizationId)
  }

  onSelect(type: { title: string; amount: number; value: RefundAmount }) {
    if (!type.amount) {
      return
    }
    this.refundAmount = type.value
  }

  isTypeDisabled(value: RefundAmount) {
    if (
      value === RefundAmount.amount90Percent &&
      this.refundType?.id === RefundTypes.outOfBandAmount
    ) {
      return true
    }

    return false
  }

  @Watch("refundType.id")
  watchType(nextVal: RefundTypes, prevVal: RefundTypes) {
    if (prevVal && nextVal !== RefundTypes.outOfBandAmount) {
      return
    }

    if (this.refundAmount === RefundAmount.amount90Percent) {
      this.refundAmount = null
    }
  }

  @Emit()
  onConfirm() {}
}
