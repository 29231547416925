















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { InputWithValidation } from "@robocent/components"

@Component({
  components: {
    InputWithValidation,
    StepTemplate
  }
})
export default class IntendedUse extends VueComponent {
  get intendedUse() {
    return this.$store.getters["voterData/legalCompliance/intendedUse"]
  }

  set intendedUse(value: boolean) {
    this.$store.commit("voterData/legalCompliance/updateIntendedUse", value)
  }
}
