



































































































import {
  CampaignPricing,
  MessageCounter,
  Script,
  ScriptStatus,
  StringObject
} from "@robocent/components"
import Component from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    MessageCounter
  }
})
export default class ScriptItemBody extends VueComponent {
  statuses = ScriptStatus
  @Prop() script!: Script
  @Prop({ default: null }) pricing!: CampaignPricing

  get showStatus() {
    if (this.script.status === ScriptStatus.pending) {
      return "Pending Approval"
    } else {
      return this.script.status
    }
  }

  get statusType() {
    const statusToType: StringObject = {
      [ScriptStatus.draft]: "is-twitter",
      [ScriptStatus.approved]: "is-success",
      [ScriptStatus.pending]: "is-warning",
      [ScriptStatus.rejected]: "is-danger"
    }
    return statusToType[this.script.status]
  }

  @Emit()
  onEdit() {}
}
