import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"

export const voiceCampaignStatuses = {
  [VoiceCampaignStatus.allPending]: "Pending",
  [VoiceCampaignStatus.pending]: "Pending Approval",
  [VoiceCampaignStatus.approved]: "Approved",
  [VoiceCampaignStatus.archived]: "Deleted",
  [VoiceCampaignStatus.underReview]: "Under Review",
  [VoiceCampaignStatus.rejected]: "Rejected",
  [VoiceCampaignStatus.pendingCancel]: "Pending Cancelation",
  [VoiceCampaignStatus.canceled]: "Canceled",
  [VoiceCampaignStatus.outstanding]: "Outstanding",
  [VoiceCampaignStatus.deleted]: "Deleted"
}
