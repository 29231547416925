import { MergedTagsValues } from "@robocent/components"

export default {
  [MergedTagsValues.phone]: "555-555-5555",
  [MergedTagsValues.fname]: "John",
  [MergedTagsValues.mname]: "Apple",
  [MergedTagsValues.lname]: "Smith",
  [MergedTagsValues.suffix]: "Jr.",
  [MergedTagsValues.address1]: "123 Main St",
  [MergedTagsValues.address2]: "Apt. 416",
  [MergedTagsValues.city]: "Washington",
  [MergedTagsValues.state]: "DC",
  [MergedTagsValues.zip]: "20202",
  [MergedTagsValues.email]: "george@pattersonforgoverner.com",
  [MergedTagsValues.party]: "Independent",
  [MergedTagsValues.gender]: "Male",
  [MergedTagsValues.dob]: "7/30/87",
  [MergedTagsValues.aux4]: "voteforjohnsmith.com",
  [MergedTagsValues.aux5]: "White",
  [MergedTagsValues.voterId]: "123-45-678"
}
