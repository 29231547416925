





























import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import SalesCommissionRates from "@/components/salesPortal/SalesCommissionRates.vue"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import { Prop } from "vue-property-decorator"

@Component({
  components: {
    SalesCommissionRates,
    StepTemplate
  }
})
export default class SalesPortalTotalEarnings extends SalesPortalMixin {
  @Prop() isLoading!: boolean
}
