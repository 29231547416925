


































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"
import OrganizationPartnerPortalUsers from "@/components/organization/OrganizationPartnerPortalUsers.vue"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"
import { SalesRate } from "@/interfaces/salesPortal/salesRate"

@Component({
  components: {
    OrganizationPartnerPortalUsers,
    Card
  }
})
export default class OrganizationSalesPortal extends VueComponent {
  salesSettings: Partial<SalesRate> = {
    salesManagerId: ""
  }

  created() {
    this.getSalesPortalSettings()
  }

  get isSalesManagerSelected() {
    return this.salesSettings?.salesManagerId
  }

  get label() {
    if (!this.isSalesManagerSelected) {
      return "Please select a sales manager."
    }
    return ""
  }

  onSelectSalesManager(salesId = "") {
    this.salesSettings!.salesManagerId = salesId
  }

  async getSalesPortalSettings() {
    await this.request(async () => {
      const salesSettings = await SalesPortalDao.getOrganizationSalesSettings(
        this.$route.params.orgId
      )

      if (salesSettings) {
        this.salesSettings = salesSettings
      }
    })
  }
  async updateSalesPortalSettings() {
    await this.request(async () => {
      await SalesPortalDao.updateOrganizationSalesSettings(
        this.$route.params.orgId,
        this.salesSettings
      )
    }, "Sales Settings were updated successfully.")
  }
}
