































































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { ListCard, Upload } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { FileUtils } from "@/utils/fileUtils"
import DownloadFile from "@/components/uploads/DownloadFile.vue"
import UploadActions from "@/components/uploads/UploadActions.vue"
import SharedUploadTag from "@/components/uploads/SharedUploadTag.vue"

@Component({
  components: {
    SharedUploadTag,
    UploadActions,
    DownloadFile,
    ListCard
  }
})
export default class AudioFileItem extends VueComponent {
  @Prop() audioFile!: Upload
  @Prop() signedUrls!: { converted: string; lookup: string; original: string }
  @Prop() orgId!: string

  get url() {
    return this.signedUrls?.original
  }

  get isDisabled() {
    return this.audioFile.meta.deleted ? "disabled" : ""
  }

  recordingBetween(sourceDuration: number) {
    return FileUtils.recordingBetween(sourceDuration)
  }
}
