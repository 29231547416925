







import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"
import Vue from "vue"

@Component
export default class UnicodeInfo extends Vue {
  @Prop() unicodeChars!: string
}
