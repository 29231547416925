











































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { ListCard, Upload } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import DownloadFile from "@/components/uploads/DownloadFile.vue"
import UploadActions from "@/components/uploads/UploadActions.vue"
import SharedUploadTag from "@/components/uploads/SharedUploadTag.vue"
import MmsPreview from "@/components/elements/MmsPreview.vue"

@Component({
  components: {
    MmsPreview,
    SharedUploadTag,
    UploadActions,
    DownloadFile,
    ListCard
  }
})
export default class ImageFileItem extends VueComponent {
  @Prop() image!: Upload
  @Prop() signedUrls!: { converted: string; lookup: string; original: string }
  @Prop() orgId!: string

  get isDisabled() {
    return this.image.meta.deleted ? "disabled" : ""
  }
}
