















































































import { VueComponent } from "@/utils/VueComponent"
import Component, { mixins } from "vue-class-component"
import OrganizationUploadsListMixin from "@/mixins/organization/OrganizationUploadsListMixin"
import { whileComponentMounted } from "@/utils/operators"
import { EventBus } from "@/services/eventBus"
import { Watch } from "vue-property-decorator"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import {
  GenericFilter,
  PhoneListType,
  PhoneListUtilsMixin
} from "@robocent/components"
import { UploadType } from "@/enums/uploadType"
import PhoneListItem from "@/components/uploads/list/PhoneListItem.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import ShowDeletedFilter from "@/components/elements/ShowDeletedFilter.vue"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import OrganizationImages from "@/components/organization/OrganizationImages.vue"
import AudioFileItem from "@/components/uploads/audio/AudioFileItem.vue"
import ImageFileItem from "@/components/uploads/images/ImageFileItem.vue"
import OrganizationPhoneListMixin from "@/mixins/organization/OrganizationPhoneListMixin"

@Component({
  components: {
    ImageFileItem,
    AudioFileItem,
    OrganizationImages,
    PhoneListItem,
    PaginationItem,
    SearchItem,
    GenericFilter,
    ShowDeletedFilter
  }
})
export default class OrganizationAllFiles extends mixins(
  VueComponent,
  OrganizationUploadsListMixin,
  PhoneListUtilsMixin,
  OrganizationPhoneListMixin
) {
  defaultOptions = {
    type: [],
    q: null,
    deleted: false
  } as FindManyFilter & { type: UploadType[] | PhoneListType[] }
  uploadType = UploadType

  async created() {
    this.orgId = this.$route.params.orgId

    await this.getUploads()
    EventBus.getUploads$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getUploads)
  }

  async getUploads() {
    const filter = Object.assign(
      this.defaultOptions,
      this.pagination.query,
      this.$route.query
    )

    await this.request(
      async () => {
        const response = await UploadsDao.getUploads(this.orgId, filter)
        this.pagination.total = response.total
        this.pagination.current = Number(filter.page)
        this.uploads = response.data

        await this.getSignedUrls()

        if (
          this.pagination.current !== 1 &&
          response.total <= this.pagination.perPage
        ) {
          this.applyQuery({ ...filter, page: 1 })
        }
      },
      undefined,
      "Cannot get all files"
    )
  }

  isPhoneList(type: UploadType | PhoneListType) {
    return [PhoneListType.list, PhoneListType.voterData].includes(
      type as PhoneListType
    )
  }

  @Watch("$route.query")
  onRouteChange() {
    this.getUploads()
  }
}
