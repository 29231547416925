










import Component, { mixins } from "vue-class-component"
import AgentsPayoutTable from "@/components/agent/AgentsPayoutTable.vue"
import AgentsPayoutMixin from "@/mixins/AgentsPayoutMixin"

@Component({
  components: {
    AgentsPayoutTable
  }
})
export default class CompletedPayouts extends mixins(AgentsPayoutMixin) {
  async created() {
    await this.getAgentsPayouts({ paid: true })
    this.totals = this.getTotals(this.payouts)
  }
}
