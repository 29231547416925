













































import Component from "vue-class-component"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import { InputWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { RejectReason } from "../../../../interfaces/voiceCampaign/rejectReason"

@Component({
  components: {
    InputWithValidation,
    ValidationObserver
  }
})
export default class RejectCallModal extends OverviewMixin {
  listOfReasons = [
    {
      reason: "Not political in nature",
      lock: true
    },
    {
      reason: "No disclaimer at end of message",
      lock: false
    },
    {
      reason: "Not enough time to start or finish sending",
      lock: false
    },
    {
      reason: "Not enough phone numbers provided on contact list",
      lock: false
    },
    {
      reason: "Other",
      custom: true,
      lock: false,
      value: null
    }
  ] as RejectReason[]
  selectedReason: RejectReason = null!

  created() {
    this.selectedReason = this.listOfReasons[0]
  }

  async onConfirm() {
    await this.request(async () => {
      await CampaignConfigureDao.reject(this.campaign._id!, this.getReason())
      this.$emit("confirm")
      this.$parent.close()
    })
  }

  getReason() {
    let reason = {} as RejectReason
    if (this.selectedReason.custom) {
      reason.reason = this.selectedReason.value!
      reason.lock = this.selectedReason.lock
    } else {
      reason = this.selectedReason
    }

    return reason
  }
}
