import { Pie } from "vue-chartjs"
import Vue from "vue"
import _ from "lodash"

export default Vue.extend({
  extends: Pie,
  props: ["chartData", "options"],
  watch: {
    chartData: function () {
      this.calculate()
    }
  },
  mounted() {
    this.calculate()
  },
  data() {
    return {
      data: {
        labels: [],
        datasets: [
          {
            label: null,
            borderWidth: 1,
            backgroundColor: [
              "rgba(135, 187, 162, 0.5)",
              "rgba(239, 118, 122, 0.5)",
              "rgba(59, 96, 100, 0.5)",
              "rgba(238, 184, 104, 0.5)",
              "rgba(86, 102, 107, 0.5)"
            ],
            borderColor: [
              "rgba(135, 187, 162, 0.7)",
              "rgba(239, 118, 122, 0.7)",
              "rgba(59, 96, 100, 0.7)",
              "rgba(238, 184, 104, 0.7)",
              "rgba(86, 102, 107, 0.7)"
            ],
            data: []
          }
        ]
      }
    }
  },
  methods: {
    calculate() {
      this.$set(
        this.data,
        "labels",
        _.map(Object.keys(this.chartData), (key) => _.startCase(key))
      )
      this.$set(this.data.datasets[0], "data", Object.values(this.chartData))
      ;(this as any).renderChart(
        this.data,
        Object.assign(
          {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: "left"
            }
          },
          this.options || {}
        )
      )
    }
  }
})
