






















































































































import Component, { mixins } from "vue-class-component"
import Vue from "vue"
import { Prop, Ref } from "vue-property-decorator"
import {
  AgentPayout,
  AgentTotalsMixin,
  PayoutTableMixin,
  statuses
} from "@robocent/components"
import MarkAgentPayoutsAsPaidModal from "@/components/modals/MarkAgentPayoutsAsPaidModal.vue"

@Component
export default class AgentsPayoutTable extends mixins(
  AgentTotalsMixin,
  PayoutTableMixin,
  Vue
) {
  @Ref("table") table: any
  @Prop() data!: []
  @Prop({ default: () => ({ messages: 0, earned: 0 }) }) totals!: {
    messages: number
    earned: number
  }
  @Prop() type!: string
  @Prop() loading!: boolean

  statuses = statuses
  selectedRow = ""

  getClass(row: AgentPayout) {
    return row.userId === this.selectedRow && "selected-row"
  }

  onDetailsOpen(row: AgentPayout) {
    this.selectedRow = row.userId
  }

  toggle(row: AgentPayout) {
    this.table.toggleDetails(row)
  }

  markPayoutsAsPaid(row: AgentPayout) {
    this.$buefy.modal.open({
      parent: this,
      component: MarkAgentPayoutsAsPaidModal,
      width: 400,
      props: {
        userId: row.userId,
        name: `${row.firstName} ${row.lastName}`
      }
    })
  }
}
