import axios from "axios"

export class CampaignNotificationDao {
  static async shareByEmail(
    orgId: string,
    campaignId: string | number,
    email: string
  ) {
    return axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignId}/share-by-email`,
      { email }
    )
  }

  static async shareBySMS(
    orgId: string,
    campaignId: string | number,
    phone: string
  ) {
    return axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignId}/share-by-sms`,
      { phone }
    )
  }
}
