


















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import StepTemplate from "@/voterData/StepTemplate.vue"

@Component({
  components: {
    StepTemplate
  }
})
export default class AuthorizationToPurchase extends VueComponent {
  get authorizationToPurchase() {
    return this.$store.getters[
      "voterData/legalCompliance/authorizationToPurchase"
    ]
  }

  set authorizationToPurchase(value: boolean) {
    this.$store.commit(
      "voterData/legalCompliance/updateAuthorizationToPurchase",
      value
    )
  }
}
