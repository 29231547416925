

















































































import TextCampaignScheduleButton from "./textElements/TextCampaignScheduleButton.vue"
import TextCampaignCost from "./textElements/TextCampaignCost.vue"
import TextCampaignPhoneNumbersInfo from "./textElements/TextCampaignPhoneNumbersInfo.vue"
import TextCampaignAgents from "./textElements/TextCampaignAgents.vue"
import TextCampaignTypeStep from "./textElements/TextCampaignTypeStep.vue"
import TextCampaignScripts from "./textElements/TextCampaignScripts.vue"
import CampaignPhoneLists from "./elements/CampaignPhoneLists.vue"
import TextCampaignDate from "./textElements/TextCampaignDate.vue"
import CampaignName from "./elements/CampaignName.vue"
import CampaignOrganization from "./elements/CampaignOrganization.vue"
import Component, { mixins } from "vue-class-component"
import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import {
  Campaign,
  CampaignPricing,
  CampaignType,
  Customer,
  Organization
} from "@robocent/components"
import { Prop, Ref, Watch } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { CampaignDao } from "@/services/dao/campaignDao"
import _ from "lodash"
import CampaignCards from "@/components/scheduler/elements/CampaignCards.vue"
import { CampaignUtils } from "@/utils/campaignUtils"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import CampaignDateMixin from "@/mixins/campaign/CampaignDateMixin"
import TextCampaignMessageConfirmationModal from "../modals/textCampaign/TextCampaignMessageConfirmationModal.vue"

@Component({
  components: {
    CampaignCards,
    CampaignOrganization,
    CampaignName,
    TextCampaignDate,
    CampaignPhoneLists,
    TextCampaignScripts,
    TextCampaignTypeStep,
    TextCampaignAgents,
    TextCampaignPhoneNumbersInfo,
    TextCampaignCost,
    TextCampaignScheduleButton,
    ValidationObserver
  }
})
export default class TextSchedulerElements extends mixins(
  TextSchedulerUtilsMixin,
  CampaignDateMixin
) {
  @Prop() buttonText!: string
  @Ref("scheduler-observer") observer!: any

  types = CampaignType
  steps = TextSchedulerSteps
  campaignUtils = new CampaignUtils()
  pricing: CampaignPricing | null = null
  customer: Customer | null = null

  get filledSteps() {
    return this.$store.getters["textScheduler/steps"]
  }

  get step() {
    return this.$store.getters["textScheduler/step"]
  }

  @Watch("step")
  goToStep(step: TextSchedulerSteps) {
    this.scrollToStep(step)
  }

  @Watch("campaign.organization")
  async watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      await this.getPricing()
      await this.getCustomer()
    }
  }

  created() {
    if (this.organizationId) {
      this.getPricing()
      this.getCustomer()
    }
  }

  async save() {
    const campaignUUID = _.clone(this.campaign.uuid)
    const campaignToSave = this.campaignUtils.prepareCampaign(this.campaign)
    const hasIntroScript = this.campaignUtils.hasIntroScript(
      campaignToSave.scripts
    )
    const hasPhoneList = this.hasPhoneList(campaignToSave)

    if (!hasPhoneList) {
      this.openToast("Please select a phone list")
      return this.scrollToStep(TextSchedulerSteps.campaignPhoneLists)
    }
    if (!hasIntroScript) {
      this.openToast("Please select at least one INTRO script")
      return this.scrollToStep(TextSchedulerSteps.campaignScript)
    }
    if (
      !this.isCampaignUpdate &&
      !this.validatePicker(
        this.campaign.start,
        this.campaign.time?.monday?.start,
        this.campaign.timezone
      )
    ) {
      this.openToast("Please select correct time")
      return this.scrollToStep(TextSchedulerSteps.campaignDate)
    }

    await this.request(
      async () => {
        let campaign = null

        if (campaignUUID) {
          await CampaignDao.updateCampaign(
            campaignToSave.organizationId,
            campaignUUID,
            campaignToSave
          )
        } else {
          campaign = await CampaignDao.createCampaign(campaignToSave)
        }
        await this.$router.push({
          name: "Org Campaigns",
          params: { orgId: campaignToSave.organizationId },
          query: { created: "true" }
        })
        if (!campaignUUID && campaign) {
          await this.openTextCampaignConfirmationModal(campaign)
        }
      },
      "Campaign successfully saved",
      "Campaign can't be saved"
    )
  }

  async openTextCampaignConfirmationModal(campaign: Campaign) {
    this.$buefy.modal.open({
      parent: this,
      component: TextCampaignMessageConfirmationModal,
      width: 600,
      props: {
        campaign
      }
    })
  }

  hasPhoneList(campaign: Campaign) {
    return Boolean(campaign.listId)
  }

  async getPricing() {
    await this.request(async () => {
      this.pricing = await SmsBillingDao.getPricing(this.organizationId)
    })
  }

  async getCustomer() {
    await this.request(async () => {
      this.setCustomer(await SmsBillingDao.getCustomer(this.organizationId))
    })
  }

  setCustomer(customer: Customer) {
    this.customer = customer
  }
}
