





















import Component from "vue-class-component"
import FileName from "@/voterData/components/FileName.vue"
import RecordsToPurchase from "@/voterData/components/RecordsToPurchase.vue"
import { CountTypes } from "@/voterData/enums/countTypes"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    RecordsToPurchase,
    FileName
  }
})
export default class SubmitRequestStep extends VueComponent {
  countTypes = [
    {
      name: "Unique Landlines",
      value: CountTypes.phone
    },
    {
      name: "Unique Mobile",
      value: CountTypes.mobile
    },
    {
      name: "Unique Email",
      value: CountTypes.email
    },
    {
      name: "All Voters",
      value: CountTypes.individual
    }
  ]
}
