















































































import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import CampaignAutoRefresh from "@/components/report/CampaignAutoRefresh.vue"
import moment from "moment"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import VoiceCampaignDetails from "@/components/scheduler/voiceElements/VoiceCampaignDetails.vue"
import VoiceCampaignGeneralInfo from "@/components/report/voiceCampaign/VoiceCampaignGeneralInfo.vue"
import VoiceCampaignAdditionalInfo from "@/components/report/voiceCampaign/VoiceCampaignAdditionalInfo.vue"
import VoiceCampaignScheduledBy from "@/components/report/voiceCampaign/VoiceCampaignScheduledBy.vue"
import { User } from "@robocent/components"
import VoiceCampaignFiles from "@/components/report/voiceCampaign/VoiceCampaignFiles.vue"
import { VoiceCampaignStatsDetail } from "@/interfaces/voiceCampaign/voiceCampaignStatsDetail"
import { get } from "lodash"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"
import VoiceCampaignActions from "@/components/report/voiceCampaign/VoiceCampaignActions.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import VoiceCampaignReports from "@/components/report/voiceCampaign/VoiceCampaignReports.vue"
import VoiceCampaignTotal from "@/components/report/voiceCampaign/VoiceCampaignTotal.vue"
import { VoiceCampaignStatsLive } from "@/interfaces/voiceCampaign/voiceCampaignStatsLive"
import VoiceCampaignSurvey from "@/components/report/voiceCampaign/VoiceCampaignSurvey.vue"
import VoiceCampaignScript from "@/components/report/voiceCampaign/VoiceCampaignScript.vue"

@Component({
  components: {
    VoiceCampaignScript,
    VoiceCampaignSurvey,
    VoiceCampaignTotal,
    VoiceCampaignReports,
    VoiceCampaignActions,
    VoiceCampaignFiles,
    VoiceCampaignScheduledBy,
    VoiceCampaignAdditionalInfo,
    VoiceCampaignGeneralInfo,
    VoiceCampaignDetails,
    CampaignAutoRefresh
  }
})
export default class VoiceCampaignResults extends VueComponent {
  voiceCampaign: VoiceCampaign | null = null
  updatedAt: string = null!
  scheduledBy: User | null = null
  statsDetail: VoiceCampaignStatsDetail | null = null
  stats: VoiceCampaignStatsLive | null = null
  invoice: Invoice | null = null

  async created() {
    await this.getStats()
    this.getUser()
    this.prepareInvoice()
    EventBus.getCampaign$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getVoiceCampaign)
  }

  async getStats() {
    await this.getVoiceCampaign()

    if (this.voiceCampaign?.meta.straticsId) {
      this.getLiveStats()
      this.getStatsDetailed()
    }

    this.updatedAt = moment().format("h:mm:ss A").toString()
  }

  get isActive() {
    const endDate = get(this.statsDetail, "end_date")

    if (!endDate) {
      return true
    }

    const now = moment().utc()
    return now.isBefore(moment(endDate))
  }

  async getVoiceCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.voiceCampaign = await VoiceCampaignDao.getVoiceCampaign(
          orgId,
          campaignUUID
        )
      },
      undefined,
      "Cannot get campaign",
      "getCampaign"
    )
  }

  async getUser() {
    const { campaignUUID } = this.$route.params
    await this.request(
      async () => {
        this.scheduledBy = await VoiceCampaignDao.getUser(campaignUUID)
      },
      undefined,
      "Cannot get user",
      "getUser"
    )
  }

  async prepareInvoice() {
    const invoiceId = this.voiceCampaign?.meta?.invoiceId

    if (!invoiceId) {
      return
    }

    await this.request(
      async () => {
        this.invoice = await BillingDao.getInvoice(invoiceId)
      },
      undefined,
      "Cannot get invoice",
      "getInvoice"
    )
  }

  async getLiveStats() {
    const { orgId, campaignUUID } = this.$route.params

    await this.request(
      async () => {
        this.stats = await VoiceCampaignDao.getVoiceCampaignLiveStats(
          orgId,
          campaignUUID
        )
      },
      undefined,
      "Cannot get stats",
      "getLiveStats"
    )
  }

  async getStatsDetailed() {
    const { orgId, campaignUUID } = this.$route.params

    await this.request(
      async () => {
        this.statsDetail = await VoiceCampaignDao.getVoiceCampaignStatsDetail(
          orgId,
          campaignUUID
        )
      },
      undefined,
      "Cannot get stats",
      "getStatsDetailed"
    )
  }
}
