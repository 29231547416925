

























































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import {
  Campaign,
  CampaignPricing,
  CardTable,
  CardTableItem,
  PhoneList
} from "@robocent/components"
import CollapseCard from "@/components/elements/CollapseCard.vue"
import CardItem from "@/components/elements/CardItem.vue"
import CampaignPhoneList from "@/components/scheduler/elements/CampaignPhoneList.vue"
import ScriptItem from "@/components/scripts/ScriptItem.vue"

@Component({
  components: {
    ScriptItem,
    CollapseCard,
    CardItem,
    CardTable,
    CardTableItem,
    CampaignPhoneList
  }
})
export default class CampaignDetailsElements extends VueComponent {
  @Prop() campaign!: Campaign
  @Prop() list!: PhoneList
  @Prop() waiting!: boolean
  @Prop() shared!: boolean
  @Prop({ default: null }) pricing!: CampaignPricing
}
