





















import Component from "vue-class-component"
import OverviewMixin from "./OverviewMixin"
import VoiceCampaignScript from "../../report/voiceCampaign/VoiceCampaignScript.vue"
import VoiceCampaignGeneralInfo from "../../report/voiceCampaign/VoiceCampaignGeneralInfo.vue"
import VoiceCampaignAdditionalInfo from "../../report/voiceCampaign/VoiceCampaignAdditionalInfo.vue"
import VoiceCampaignScheduledBy from "../../report/voiceCampaign/VoiceCampaignScheduledBy.vue"
import VoiceCampaignFiles from "../../report/voiceCampaign/VoiceCampaignFiles.vue"
import { User } from "@robocent/components"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"

@Component({
  components: {
    VoiceCampaignGeneralInfo,
    VoiceCampaignAdditionalInfo,
    VoiceCampaignScheduledBy,
    VoiceCampaignFiles,
    VoiceCampaignScript
  }
})
export default class OverviewCampaign extends OverviewMixin {
  scheduledBy: User | null = null

  created() {
    this.getUser()
  }

  async getUser() {
    await this.request(async () => {
      this.scheduledBy = await VoiceCampaignDao.getUser(this.campaign._id!)
    })
  }
}
