

































import { links } from "@/data/links"
import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"

@Component
export default class ScriptRequirements extends VueComponent {
  docsLinks = links
}
