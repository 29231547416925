




























































































































import Component from "vue-class-component"
import { Feature } from "@/interfaces/feature/feature"
import { Prop } from "vue-property-decorator"
import { organizationPartnerType } from "@/data/organizationPartnerType"
import { PartnerType } from "@/enums/partnerPortal/partnerType"
import { PartnerPayoutWithCampaign } from "@/interfaces/partnerPortal/partnerPayoutWithCampaign"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import PayoutsTableMixin from "@/mixins/PayoutsTableMixin"

@Component({})
export default class PartnerCampaignsTable extends PayoutsTableMixin {
  @Prop() service!: Feature
  @Prop() type!: PartnerType
  @Prop() loading!: boolean
  @Prop() data!: PartnerPayoutWithCampaign[]
  @Prop() tab!: PartnerPayoutStatus
  partnerTypes = organizationPartnerType
  features = Feature

  get isCommission() {
    return this.type === PartnerType.commission
  }

  get isDiscount() {
    return this.type === PartnerType.discount
  }
}
