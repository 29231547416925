













import TextSchedulerElements from "../../../components/scheduler/TextSchedulerElements.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Campaign, CampaignStatus, CampaignType } from "@robocent/components"
import { CampaignDao } from "@/services/dao/campaignDao"
import _ from "lodash"
import { buildTextCampaignTime } from "@/components/campaign/utils/buildTextCampaign"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"

@Component({
  components: {
    TextSchedulerElements
  }
})
export default class RerunTextCampaign extends VueComponent {
  textCampaign: Campaign = null!

  async created() {
    await this.getTextCampaign()
    this.$store.commit("textScheduler/fillAllSteps", this.textCampaign)

    if (
      this.textCampaign!.type === CampaignType.managed &&
      !this.$store.getters.super
    ) {
      this.$store.commit(
        "textScheduler/remove",
        TextSchedulerSteps.campaignAgents
      )
    }
  }

  async getTextCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        const textCampaign: Campaign = await CampaignDao.getCampaign(
          orgId,
          campaignUUID
        )

        this.textCampaign = {
          ..._.cloneDeep(textCampaign),
          ...buildTextCampaignTime(),
          status: CampaignStatus.pending,
          uuid: ""
        }
      },
      undefined,
      "Cannot get campaign"
    )
  }
}
