





























































































































import Component, { mixins } from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"
import { statuses } from "@robocent/components"
import { PartnerPayoutTotals } from "@/interfaces/partnerPortal/partnerPayoutTotals"
import { OrganizationTab } from "@/enums/organizationTab"
import PartnerDetailsViewModal from "@/components/modals/partnerPortal/PartnerDetailsViewModal.vue"
import { PartnerOrganizationTotals } from "@/interfaces/partnerPortal/partnerOrganizationTotals"
import PartnerPaymentMixin from "@/mixins/partnerPortal/PartnerPaymentMixin"
import PayoutsTableMixin from "@/mixins/PayoutsTableMixin"

@Component
export default class PartnerPayoutsTable extends mixins(
  PartnerPaymentMixin,
  PayoutsTableMixin
) {
  @Ref("table") table: any
  @Prop() data!: []
  @Prop({
    default: () => ({
      discounts: 0,
      commission: 0
    })
  })
  totals!: {
    discounts: number
    commission: number
  }
  @Prop() type!: string
  @Prop() loading!: boolean

  statuses = statuses
  tabs = OrganizationTab
  selectedRow = ""

  get discountsSubheading() {
    return this.getValueInCurrency(this.totals?.discounts)
  }

  get commissionSubheading() {
    return this.getValueInCurrency(this.totals?.commission)
  }

  getClass(row: PartnerPayoutTotals) {
    return row.id === this.selectedRow && "selected-row"
  }

  onDetailsOpen(row: PartnerPayoutTotals) {
    this.selectedRow = row.id
  }

  toggle(row: PartnerPayoutTotals) {
    this.table.toggleDetails(row)
  }

  onViewDetails(
    row: PartnerPayoutTotals,
    organization: PartnerOrganizationTotals
  ) {
    this.$buefy.modal.open({
      component: PartnerDetailsViewModal,
      parent: this,
      width: 1600,
      props: {
        userId: row.id,
        title: `${row.firstName} ${row.lastName} - ${organization.name}`,
        orgId: organization.id
      }
    })
  }
}
