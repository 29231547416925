import axios from "axios"
import { PrePayItem } from "@/interfaces/prePayItem"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"

export class CheckoutDao {
  static async getStripePublicKey(): Promise<string> {
    return (await axios.get("checkout/stripe/key")).data?.publicKey
  }

  static async getPrePayItems(): Promise<PrePayItem[]> {
    return (await axios.get("checkout/pre-pay-items")).data?.items
  }

  static async createPrePayInvoice(params: any): Promise<Invoice> {
    return (await axios.post("checkout/pre-pay", params)).data
  }

  static async getSmsCost() {
    return await axios.get("checkout/sms-cost")
  }

  static async getCheckoutSession(params: any) {
    return await axios.post("checkout/sessions", params)
  }
}
