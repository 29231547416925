



















import Component from "vue-class-component"
import { CardTable, CardTableItem, Upload } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignService } from "@/services/voiceCampaignService"
import FileRow from "@/components/elements/voiceCampaign/FileRow.vue"

@Component({
  components: {
    FileRow,
    CardTable,
    CardTableItem,
    Card
  }
})
export default class VoiceCampaignFiles extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign

  liveRecording: Upload | null = null
  vmRecording: Upload | null = null
  list: Upload | null = null

  callService = new VoiceCampaignService()

  created() {
    this.prepareFiles()
  }

  async prepareFiles() {
    const uploads = this.voiceCampaign.uploads
    const orgId = this.$route.params.orgId

    await this.request(
      async () => {
        const liveRecording = uploads.liveRecording as Upload
        const vmRecording = uploads.vmRecording as Upload
        const list = uploads.list as Upload

        if (liveRecording?._id) {
          this.liveRecording = await this.callService.getFile(
            liveRecording?._id,
            orgId
          )
        }

        if (vmRecording?._id) {
          this.vmRecording = await this.callService.getFile(
            vmRecording?._id as string,
            orgId
          )
        }

        if (list?._id) {
          this.list = await this.callService.getFile(list?._id, orgId)
        }
      },
      undefined,
      "Cannot get phone lists"
    )
  }
}
