












import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import AddNewScriptModal from "@/components/modals/AddNewScriptModal.vue"

@Component
export default class ScriptsEmptyMessage extends VueComponent {
  @Prop() orgId!: string

  openCreateScriptModal() {
    this.$buefy.modal.open({
      width: 950,
      component: AddNewScriptModal,
      parent: this,
      props: {
        organizationId: this.orgId
      }
    })
  }
}
