<template>
  <form @submit.prevent="submit">
    <b-field grouped class="is-large has-padding-tb-xl filter">
      <b-input v-model.trim="query.q" placeholder="Search..." expanded />
      <div class="controls-wrapper">
        <div class="control search-btn">
          <b-button
            :loading="loading"
            native-type="submit"
            data-test="search-btn"
            icon-left="search"
            rounded
            >Search</b-button
          >
        </div>
        <div v-if="showDeleted" class="control" style="margin-top: 7px">
          <b-switch v-model="query.deleted" @input="submit">
            Show Deleted
          </b-switch>
        </div>
      </div>

      <b-dropdown
        v-if="filters && filters.length"
        v-model="selectedOptions"
        position="is-bottom-left"
        aria-role="list"
        @change="toggleFilter($event)"
        class="filter-dropdown"
      >
        <button
          slot="trigger"
          class="button"
          type="button"
          :class="{ 'is-primary': query.filter }"
        >
          <b-icon icon="filter" size="is-small" />
          <span>Filter</span>
        </button>

        <b-dropdown-item
          v-for="f in filters"
          :key="f"
          :value="f"
          aria-role="listitem"
        >
          <span class="is-capitalized">{{ f }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-field>
  </form>
</template>

<script>
import { reactive } from "@vue/composition-api"
import { statuses } from "@robocent/components"
import Vue from "vue"

export default Vue.extend({
  name: "GenericSearch",
  props: {
    filters: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showDeleted: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { selectedOptions: [] }
  },

  setup(props, { emit }) {
    const query = reactive({
      q: "",
      deleted: false,
      filter: "",
      invoices: ""
    })

    function submit() {
      emit("change", query)
    }

    function toggleFilter(filter) {
      if (filter === statuses.outstanding) {
        query.filter = statuses.approved
        query.invoices = statuses.open
        this.$router.push({
          query: { filter: query.filter, invoices: query.invoices }
        })
      } else if (filter === statuses.all) {
        query.filter = null
        this.$router.push({ query: {} })
      } else if (filter === query.filter) {
        query.filter = null
        this.$router.push({ query: {} })
      } else {
        query.filter = filter
        this.$router.push({ query: { filter: filter } })
      }
      submit()
    }

    return {
      query,
      submit,
      toggleFilter
    }
  }
})
</script>
<style scoped lang="scss">
.button {
  box-shadow: 0 2px 5px 0 rgba(60, 66, 87, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
}
.controls-wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .controls-wrapper .control {
    margin-right: 8px;
  }
}

@media screen and (max-width: 768px) {
  .filter {
    flex-direction: column;

    & > .control:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .filter-dropdown {
    justify-content: flex-end;
    margin-top: 8px;
  }

  .search-btn {
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
    order: 3;
  }

  .field.is-grouped > .control:not(:last-child) {
    margin-right: 0;
  }
}
</style>
