
































import { Campaign, CampaignDateMixin } from "@robocent/components"
import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import moment from "moment-timezone"
import CampaignMixin from "@/mixins/campaign/CampaignMixin"

@Component
export default class TextCampaignScheduleButton extends mixins(
  CampaignDateMixin,
  CampaignMixin
) {
  @Prop({ default: "calendar-check" }) icon!: string
  @Prop({ default: "Schedule" }) text!: string
  @Prop() campaign!: Campaign
  @Prop() isLoading!: boolean

  get campaignStartDate() {
    const { start, time } = this.campaign

    const startDayOfWeek = this.getDayOfWeek(start)
    const dateTime = moment(`${start} ${time[startDayOfWeek].start}`)

    return `${dateTime.format("dddd, MMMM Do, YYYY h:mm A")}`
  }

  getDayOfWeek(date: string) {
    return moment(date).format("dddd").toLowerCase()
  }
}
