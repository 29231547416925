






























































import Component from "vue-class-component"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"
import ImportExistingPhoneNumberModal from "@/components/modals/ImportExistingPhoneNumberModal.vue"
import PhoneNumbersEmptyMessage from "@/components/phoneNumbers/PhoneNumbersEmptyMessage.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import { Prop } from "vue-property-decorator"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"

@Component({
  components: {
    PaginationItem,
    SearchItem,
    ChooseItem,
    PhoneNumbersEmptyMessage,
    ChooseRow
  }
})
export default class CampaignCallerIds extends SchedulerUtilsMixin {
  @Prop() isDisabled!: boolean
  @Prop() phone!: string
  phoneNumbers = []

  created() {
    this.getPhoneNumbers()
    EventBus.getPhoneNumbers$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getPhoneNumbers)
  }

  async getPhoneNumbers() {
    const params = {
      q: this.search,
      page: this.pagination.current,
      limit: this.pagination.perPage
    }
    await this.request(async () => {
      const data = await PhoneNumberDao.getPhones(this.organizationId, params)
      this.phoneNumbers = data.data
      this.pagination.total = data.total
    })
  }

  openImportPhoneNumberModal() {
    this.$buefy.modal.open({
      parent: this,
      component: ImportExistingPhoneNumberModal,
      props: {
        orgId: this.organizationId
      }
    })
  }

  isSelected(phoneNumber: any) {
    return this.phone === phoneNumber.number
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getPhoneNumbers()
  }

  onSearch(search: string) {
    this.search = search
    this.pagination.current = 1
    this.getPhoneNumbers()
  }

  onSelect(phoneNumber: string) {
    this.$emit("select", phoneNumber)
  }
}
