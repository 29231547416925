









































































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { Organization } from "@robocent/components"

@Component
export default class OrganizationUserDropDown extends VueComponent {
  @Prop() organization!: Organization
}
