export const votingHistories = [
  {
    name: "2023 Primary (Where Available)",
    value: "VTR_PRI23"
  },
  {
    name: "2022 Primary",
    value: "VTR_PRI22"
  },
  {
    name: "2021 Primary",
    value: "VTR_PRI21"
  },
  {
    name: "2020 Primary",
    value: "VTR_PRI20"
  },
  {
    name: "2019 Primary",
    value: "VTR_PRI19"
  },
  {
    name: "2018 Primary",
    value: "VTR_PRI18"
  },
  {
    name: "2023 General (Where Available)",
    value: "VTR_GEN23"
  },
  {
    name: "2022 General",
    value: "VTR_GEN22"
  },
  {
    name: "2021 General",
    value: "VTR_GEN21"
  },
  {
    name: "2020 General",
    value: "VTR_GEN20"
  },
  {
    name: "2019 General",
    value: "VTR_GEN19"
  },
  {
    name: "2018 General",
    value: "VTR_GEN18"
  },
  {
    name: "2020 Presidential Primary",
    value: "VTR_PPP20"
  }
]

export const historyReqs = [
  {
    name: " At Least One (1 of X)",
    value: "1"
  },
  {
    name: "At Least Two (2 of X)",
    value: "2"
  },
  {
    name: "At Least Three (3 of X)",
    value: "3"
  },
  {
    name: "At Least Four (4 of X)",
    value: "4"
  },
  {
    name: "Must Have Voted in All Elections Selected",
    value: "all"
  }
]
