import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { IdentityStatus } from "@robocent/components"

@Component({})
export default class OrganizationStatusMixin extends VueComponent {
  isIncomplete(identity: any) {
    return identity?.status === "incomplete"
  }

  isUnsubmitted(identityStatus: string) {
    return identityStatus === IdentityStatus.unsubmitted
  }

  isSubmitted(identityStatus: string) {
    return identityStatus === IdentityStatus.submitted
  }
}
