

















import Component from "vue-class-component"
import ScriptMixin from "@/mixins/ScriptMixin"
import Card from "@/components/elements/Card.vue"
import ScriptFormStepper from "@/components/scheduler/textElements/scriptStepper/ScriptFormStepper.vue"

@Component({
  components: {
    ScriptFormStepper,
    Card
  }
})
export default class AddNewScriptModal extends ScriptMixin {
  created() {
    this.setInitialScript()
  }

  async handleSave() {
    await this.saveScript()
  }
}
