











































import Component from "vue-class-component"
import { Agent, Campaign } from "@robocent/components"
import AssignAgentsActions from "@/components/campaign/AssignAgentsActions.vue"
import { CampaignDao } from "@/services/dao/campaignDao"
import NotifyAgentModal from "@/components/modals/NotifyAgentModal.vue"
import AgentMixin from "@/mixins/AgentMixin"
import TextCampaignInvitedAgents from "@/components/scheduler/textElements/TextCampaignInvitedAgents.vue"
import TextCampaignActiveAgents from "@/components/scheduler/textElements/TextCampaignActiveAgents.vue"
import TextCampaignDefaultAgents from "@/components/scheduler/textElements/TextCampaignDefaultAgents.vue"

@Component({
  components: {
    TextCampaignDefaultAgents,
    AssignAgentsActions,
    TextCampaignInvitedAgents,
    TextCampaignActiveAgents
  }
})
export default class CampaignAgentsSettings extends AgentMixin {
  campaign: Campaign | null = null

  get selectedAgents(): Agent[] {
    return this.campaign?.agents || []
  }

  async created() {
    await this.getCampaignAgents()
  }

  async getCampaignAgents() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(async () => {
      this.campaign = (await CampaignDao.getCampaignAgents(
        orgId,
        campaignUUID
      )) as Campaign
    })
  }

  async assignAgents() {
    await this.request(async () => {
      const agentsIds = this.selectedAgents.map((agent: Agent) => ({
        id: agent.id
      }))
      const invitedAgentsIds = this.campaign?.invitedAgents.map(
        (agent: Agent) => ({
          id: agent.id
        })
      )
      await CampaignDao.updateCampaignAgents(
        this.$route.params.orgId,
        this.$route.params.campaignUUID,
        {
          agents: agentsIds,
          invitedAgents: invitedAgentsIds
        } as any
      )
      await this.$router.push({
        name: "Org Campaigns",
        params: { orgId: this.$route.params.orgId }
      })
    }, "Agents were assigned successfully")
  }

  openNotifyAgentsModal() {
    this.$buefy.modal.open({
      parent: this,
      component: NotifyAgentModal,
      width: 400,
      events: {
        confirm: this.notifyAgents
      }
    })
  }

  async notifyAgents(message: string) {
    await this.request(async () => {
      await CampaignDao.notifyAgents(
        this.$route.params.orgId,
        this.$route.params.campaignUUID,
        message
      )
    }, "Notifications were successfully sent to agents")
  }
}
