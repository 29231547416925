









































import { ValidationProvider } from "vee-validate"
import { TypeMixin } from "@robocent/components"
import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    ValidationProvider
  },
  mixins: [TypeMixin]
})
export default class InputWithCardValidation extends VueComponent {
  @Prop({ default: "" }) rules!: any
  @Prop({ default: null }) value!: any

  innerValue = ""
  mask = ""

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  @Watch("innerValue")
  watchInnerValue(newVal: any) {
    this.$emit("input", newVal)
  }

  @Watch("value")
  WatchValue(newVal: any) {
    this.innerValue = newVal
  }
}
