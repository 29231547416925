import { Module } from "vuex"
import { cancelAllRequests, refreshToken } from "@/config/axios"

export const requestsStore = <Module<any, any>>{
  actions: {
    cancelPendingRequests() {
      cancelAllRequests()
      refreshToken()
    }
  }
}
