















import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import OrganizationNotifications from "@/components/organization/OrganizationNotifications.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import { Watch } from "vue-property-decorator"

@Component({
  components: {
    OrganizationNotifications
  }
})
export default class OrganizationParent extends VueComponent {
  organization = null
  message = ""

  async created() {
    await this.getOrganization()
    EventBus.organizationChanged$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getOrganization)
  }

  beforeDestroy() {
    this.$store.commit("clearOrganization")
  }

  async getOrganization() {
    await this.request(
      async () => {
        const data = await OrganizationDao.getOrganization(
          this.$route.params.orgId
        )
        this.$store.commit("setOrganization", data)
        this.$store.commit("setLastVisitedOrganization", data)
        this.organization = this.$store.getters.organization
      },
      null,
      "The organization can't be loaded."
    )
  }

  @Watch("$route.params.orgId")
  updateOrganization() {
    this.getOrganization()
  }
}
