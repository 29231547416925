
















































































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { ListCard, Organization } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import OrganizationUserDropDown from "@/components/organization/OrganizationUserDropDown.vue"
import { CampaignDirection } from "@/enums/campaignDirection"
import { BillingDao } from "@/services/dao/robocent/billingDao"

@Component({
  components: { OrganizationUserDropDown, ListCard }
})
export default class OrganizationItem extends VueComponent {
  @Prop() org!: Organization
  @Prop() credits!: number[]
  campaignDirection = CampaignDirection
  amountDue: number | null = null

  balanceClass(property: number) {
    return {
      "is-info": property < 0,
      "is-danger": property > 0,
      "is-success": property === 0,
      "is-dark": !property
    }
  }

  created() {
    if (this.$store.getters.super) {
      this.getAmountDue()
    }
  }

  isCreditExist(credit: number) {
    return Number(credit) > 0
  }

  redirectToOrgCampaigns() {
    this.$store.commit("setOrganization", this.org)
    this.$store.commit("setLastVisitedOrganization", this.org)

    this.$router.push({
      name: `Org Campaigns`,
      params: { orgId: this.org._id },
      query: { direction: this.campaignDirection.text }
    })
  }

  async getAmountDue() {
    await this.request(async () => {
      this.amountDue = (
        await BillingDao.getAmountDue(this.org.meta.stripeId)
      ).totalAmountRemaining
    })
  }
}
