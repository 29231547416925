













import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { ReportDao } from "@/services/dao/reportDao"
import { SharedReportDao } from "@/services/dao/sharedReportDao"

@Component({
  components: { Card }
})
export default class SurveyResponsesModal extends VueComponent {
  @Prop() orgId!: string
  @Prop() campaignId!: string
  @Prop() scriptId!: number
  @Prop() shared!: boolean

  columns = [
    {
      field: "conversation.lead.phone",
      label: "Phone",
      searchable: true,
      sortable: true
    },
    {
      field: "conversation.lead.fname",
      label: "First Name",
      searchable: true,
      sortable: true
    },
    {
      field: "conversation.lead.lname",
      label: "Last Name",
      searchable: true,
      sortable: true
    },
    {
      field: "surveyOption.text",
      label: "Reply",
      searchable: true,
      sortable: true
    }
  ]
  responses: any[] = []

  created() {
    this.getSurveyResponses()
  }

  async getSurveyResponses() {
    await this.request(async () => {
      this.responses = this.shared
        ? await SharedReportDao.getCampaignSurveyResponses(
            this.orgId,
            this.campaignId,
            this.scriptId
          )
        : await ReportDao.getCampaignSurveyResponses(
            this.orgId,
            this.campaignId,
            this.scriptId
          )
    })
  }
}
