































































































import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "../../utils/VueComponent"
import { CampaignCostService } from "@/services/campaignCostService"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { Prop } from "vue-property-decorator"
import _ from "lodash"
import { Upload } from "@robocent/components"
import { AudioDao } from "@/services/dao/robocent/audioDao"

interface MessageType {
  cost: number
  minimalCost: number
  name: string
  type: string
  unit: string
  link: string
  leads: number
  total: number
  useMobiles?: boolean
  useLandlines?: boolean
  audioDuration: string
}

@Component({
  components: { Card }
})
export default class GetQuoteModal extends VueComponent {
  @Prop() orgId!: string
  @Prop() details!: {
    all: number
    good: number
    duplicated: number
    invalid: number
    landline: number
    mobile: number
  }

  messages: MessageType[] = []
  landlines = this.details.landline || 0
  mobiles = this.details.mobile || 0
  calculatedCosts: MessageType[] = []
  baseDuration = "30"
  length = "30"
  selectedFile: Upload | null = null
  uploadsAudio: Upload[] = []
  campaignCostService = new CampaignCostService()

  get filteredDataObj() {
    return this.uploadsAudio.filter((option) => {
      return option.name
        .toLowerCase()
        .includes(this.length.toString().toLowerCase())
    })
  }

  get audioDuration() {
    if (this.selectedFile && this.selectedFile.meta) {
      return Math.round(parseInt(this.selectedFile.meta.duration!))
    }

    const length = parseInt(this.length)
    if (!length || isNaN(length)) {
      return this.baseDuration
    }

    return Math.round(length)
  }

  created() {
    this.getQuote()
    this.getUploadsAudio()
  }

  async getQuote() {
    await this.request(
      async () => {
        this.messages = await BillingDao.getQuote(this.orgId)
        this.calculateCost()
      },
      undefined,
      "Getting a quote failed"
    )
  }

  async getUploadsAudio() {
    await this.request(async () => {
      this.uploadsAudio = (await AudioDao.getAudios(this.orgId, {})).data
    })
  }

  selectAudio(option: Upload) {
    this.selectedFile = option
  }

  calculateCost() {
    this.calculatedCosts = _.map(this.messages, (message: MessageType) => {
      message.total = 0

      if (message.type.includes("sms")) {
        message.link = "https://robocent.com/text-marketing/"
        message.useMobiles = true
        message.leads = this.mobiles
        message = this.campaignCostService.calculateSimpleMessage(message)
      }

      switch (message.type) {
        case "robocall":
          message.link = "https://robocent.com/call-marketing/"
          message.useLandlines = true
          message.leads = this.landlines
          message = this.campaignCostService.calculateRobocall(
            message,
            this.audioDuration
          )
          message.audioDuration = `(${this.audioDuration} seconds)`
          break
        case "transfer":
          message.link = "https://robocent.com/call-marketing/robocalls/"
          message.useLandlines = true
          message.leads = this.landlines
          message = this.campaignCostService.calculateBasedOnRobocall(
            message,
            this.audioDuration
          )
          message.audioDuration = `(${this.audioDuration} seconds)`
          break
        case "survey":
          message.link = "https://robocent.com/call-marketing/robocalls/"
          message.useLandlines = true
          message.leads = this.landlines
          message = this.campaignCostService.calculateSimpleMessage(message)
          break
        case "teletownhall":
          message.link = "https://robocent.com/pricing/teletownhall-pricing/"
          message.useLandlines = true
          message.leads = this.landlines
          message = this.campaignCostService.calculateTeleTownHall(message)
          break
      }
      message.total = this.campaignCostService.checkMinimalCost(message)
      message = this.campaignCostService.checkLeadsDiscount(message)
      return message
    }) as MessageType[]
  }
}
