










































































import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { ValidationObserver } from "vee-validate"
import { InputWithValidation } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"

@Component({
  components: {
    Card,
    ValidationObserver,
    InputWithValidation
  }
})
export default class AdjustAccountBalanceModal extends VueComponent {
  @Prop() orgId!: string
  @Prop() balance!: number
  amount = 0
  debit = false

  get calculatedAmount(): number {
    const inCurrency = Math.round(this.amount * 100)
    return this.debit ? Number(-inCurrency) : Number(inCurrency)
  }

  get estimatedBalance() {
    return this.balance + this.calculatedAmount
  }

  async adjustBalance() {
    await this.request(async () => {
      await SmsBillingDao.adjustAccountBalance(
        this.orgId,
        this.calculatedAmount
      )
      this.$parent.close()
      this.$emit("changed")
    })
  }
}
