











import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import MarkPayoutsAsPaid from "@/components/payouts/MarkPayoutsAsPaid.vue"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import { VueComponent } from "@/utils/VueComponent"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({
  components: {
    MarkPayoutsAsPaid
  }
})
export default class MarkPartnerPayoutsAsPaidModal extends VueComponent {
  @Prop() userId!: string
  @Prop() name!: number
  amount = 0

  created() {
    this.getPartnerPayout()
  }

  async getPartnerPayout() {
    await this.request(async () => {
      this.amount = await PartnerPortalDao.getSinglePartnerPayout(this.userId)
    })
  }

  async onConfirm(check: string) {
    await this.request(async () => {
      const data = {
        check,
        amount: this.$options.filters!.toCurrency(this.amount)
      }

      await PartnerPortalDao.markPayoutsAsPaid(this.userId, data)

      this.$parent.close()
      EventBus.getPartnersPayouts$.next({ status: PartnerPayoutStatus.open })
    }, "The Payouts were paid successfully")
  }
}
