




import StraticsPhoneList from "./StraticsPhoneList.vue"
import Component from "vue-class-component"
import { VoiceCampaignService } from "@/services/voiceCampaignService"
import { ExtendedUpload } from "@/interfaces/extendedUpload"
import { Upload } from "@robocent/components"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"

@Component({
  components: { StraticsPhoneList }
})
export default class ConfigurePhoneList extends OverviewMixin {
  list: ExtendedUpload | null = null

  voiceCampaignService = new VoiceCampaignService()

  created() {
    this.prepareFiles()
  }

  async prepareFiles() {
    const uploads: { list: Upload } = this.campaign.uploads as { list: Upload }
    if (!uploads.list) {
      return
    }

    const orgId = this.$route.params.orgId

    await this.request(
      async () => {
        this.list = await this.voiceCampaignService.getFile(
          uploads.list._id,
          orgId
        )
      },
      null,
      "Cannot get phone lists"
    )
  }
}
