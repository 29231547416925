











































































import Component, { mixins } from "vue-class-component"
import { CampaignUtilsMixin, statuses } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"
import { EventBus } from "@/services/eventBus"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import TestVoiceCampaignModal from "@/components/modals/voiceCampaign/TestVoiceCampaignModal.vue"
import moment from "moment"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import DeleteCampaignButton from "@/components/campaign/DeleteCampaignButton.vue"
import { InvoiceStatus } from "@/voterData/enums/invoiceStatus"

@Component({
  components: { DeleteCampaignButton }
})
export default class VoiceCampaignDropdown extends mixins(
  VueComponent,
  CampaignUtilsMixin
) {
  @Prop() voiceCampaign!: VoiceCampaign

  get isSuper() {
    return this.$store.getters.super
  }

  get isShowOptions() {
    return (
      this.isTestCallAvailable ||
      this.isRerunAvailable ||
      this.isRequestCancelationAvailable ||
      this.isDeleteAvailable
    )
  }

  get isPending() {
    return this.voiceCampaign.meta.status === VoiceCampaignStatus.pending
  }

  get isDeleteAvailable() {
    return (
      this.isPending ||
      (this.isSuper &&
        this.voiceCampaign.invoice?.status !== InvoiceStatus.paid &&
        !this.voiceCampaign.meta.deleted)
    )
  }

  get isRerunAvailable() {
    return (
      this.voiceCampaign.meta.status === VoiceCampaignStatus.approved ||
      this.isSuper
    )
  }

  get isTestCallAvailable() {
    return (
      this.voiceCampaign.meta.status === VoiceCampaignStatus.approved &&
      this.voiceCampaign.meta.straticsId &&
      (this.voiceCampaign.type === VoiceCampaignType.robocall ||
        this.voiceCampaign.type === VoiceCampaignType.survey ||
        this.voiceCampaign.type === VoiceCampaignType.transfer)
    )
  }

  get isRequestCancelationAvailable() {
    const invoiceStatus = this.voiceCampaign?.invoice?.status
    const voiceCampaignStatus = this.voiceCampaign?.meta?.status

    const wasSentHourAgo = this.checkIfHourPassed()

    return (
      [statuses.draft, statuses.open, statuses.paid].includes(invoiceStatus) &&
      voiceCampaignStatus === VoiceCampaignStatus.approved &&
      (wasSentHourAgo || this.isSuper)
    )
  }

  onTestOutboundMessageClick() {
    this.$buefy.modal.open({
      width: 500,
      props: {
        voiceCampaign: this.voiceCampaign
      },
      component: TestVoiceCampaignModal,
      trapFocus: true
    })
  }

  async onRequestCancelation() {
    await this.confirm(
      "This will notifiy RoboCent that you want to cancel this message."
    )
    await this.request(async () => {
      await VoiceCampaignDao.requestVoiceCampaignCancelation(
        this.voiceCampaign.organization._id,
        this.voiceCampaign._id!
      )
      EventBus.getCampaigns$.next()
    }, "The cancelation request was sent.")
  }

  checkIfHourPassed() {
    return (
      moment
        .duration(
          moment
            .tz(this.voiceCampaign.timezone)
            .diff(
              moment
                .utc(this.voiceCampaign.time)
                .tz(this.voiceCampaign.timezone)
            )
        )
        .asHours() < 1
    )
  }
}
