



















































































































































































































import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { EstimatedCost } from "@/interfaces/estimatedCost"
import { CampaignDao } from "@/services/dao/campaignDao"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import {
  CardTable,
  CardTableItem,
  Customer,
  Organization,
  Script,
  ScrubListButton
} from "@robocent/components"
import _ from "lodash"
import Component, { mixins } from "vue-class-component"
import { Prop, Ref, Watch } from "vue-property-decorator"
import CheckIcon from "../../elements/CheckIcon.vue"
import ChooseItem from "../elements/ChooseItem.vue"
import ChooseRow from "../elements/ChooseRow.vue"
import { PhoneListMixin } from "@/mixins/campaign/PhoneListMixin"

@Component({
  components: {
    ChooseItem,
    ChooseRow,
    CardTableItem,
    CardTable,
    CheckIcon,
    ScrubListButton
  }
})
export default class TextCampaignEstimatedCost extends mixins(
  TextSchedulerUtilsMixin,
  PhoneListMixin
) {
  @Prop() customer!: Customer
  @Prop() isMergedFieldsEnabled!: boolean
  @Ref("countdown") countdown: any
  leftTime = 15 * 1000
  estimatedCost: EstimatedCost | null = null
  error: string | null = null
  eventBus = EventBus

  get listSelected(): boolean {
    return Boolean(this.campaign.listId)
  }

  get isEstimatedCostExists() {
    return Boolean(this.estimatedCost)
  }

  get calculateDisabled() {
    return !(this.isListReady(this.listSelected) && this.introScriptSelected)
  }

  get hasIssueDuringEstimate() {
    return Boolean(this.calculateDisabled) || Boolean(this.error)
  }

  get introScriptSelected() {
    return Boolean(this.campaign.scripts.find((script: Script) => script.intro))
  }

  get accountBalance() {
    return this.customer && this.customer?.balance < 0
      ? this.customer.balance * -1
      : 0
  }

  get newAccountBalance() {
    return this.accountBalance - this.appliedBalance
  }

  get appliedBalance() {
    return this.accountBalance >= this.cost ? this.cost : this.accountBalance
  }

  get cost() {
    let cost = this.estimatedCost!.cost + this.estimatedCost!.minimumOrderCost
    if (this.estimatedCost?.appliedDiscount) {
      cost = this.estimatedCost?.costWithAppliedDiscount
    }

    if (this.estimatedCost?.appliedCouponDiscount) {
      cost = this.estimatedCost?.costWithAppliedCoupon
    }
    return cost
  }

  get costWithAppliedBalance() {
    return this.cost - this.appliedBalance
  }

  notRoundedCost(cost: number) {
    if (this.isMergedFieldsEnabled) {
      return `$${(cost / 100).toFixed(4)}`
    }
    return this.$options.filters!.toCurrency(cost)
  }

  currencyWithPlus(cost: number, rounded = true) {
    if (!rounded) {
      return `+${this.notRoundedCost(cost)}`
    }

    return `+${this.$options.filters!.toCurrency(cost)}`
  }

  created() {
    this.getData()
    EventBus.startScrubPhoneList$
      .pipe(whileComponentMounted(this))
      .subscribe(async () => this.getData())
    EventBus.getEstimatedCost$
      .pipe(whileComponentMounted(this))
      .subscribe(() => this.getData())
  }

  async getData() {
    if (this.listSelected) {
      await this.getList()
    }

    if (this.isListReady(this.listSelected)) {
      await this.getEstimatedCost()
    } else {
      await this.refresh()
    }
  }

  async refresh() {
    if (!this.estimatedCost) {
      this.countdown?.startCountdown("restart")
    }
  }

  async getList() {
    await this.request(async () => {
      this.list = (
        await UploadsDao.getUpload(this.organizationId, this.campaign.listId)
      ).data
    })
  }

  async getEstimatedCost() {
    try {
      this.loading = true
      this.error = null
      if (this.isListEmpty) {
        return
      }
      const campaign = _.cloneDeep(this.campaign)
      this.estimatedCost = await CampaignDao.getEstimatedCampaignCost(
        this.organizationId,
        campaign
      )
    } catch (e) {
      const error =
        (this.isManaged ? "Total" : "Estimated") + " Cost can't be calculated. "
      this.error = error + _.get(e, "response.data.message")
    } finally {
      this.loading = false
      await this.refresh()
    }
  }

  @Watch("campaign.type")
  @Watch("campaign.listId")
  @Watch("campaign.scripts.length")
  @Watch("campaign.promoCode")
  async watchDependedField() {
    this.estimatedCost = null
    this.list = null

    this.emitWatchDependedFieldChange()
  }

  emitWatchDependedFieldChange = _.debounce(async () => this.getData(), 300)

  @Watch("campaign.organization")
  async watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      this.estimatedCost = null
      this.list = null
    }
  }
}
