import { render, staticRenderFns } from "./DeleteInvoiceModal.vue?vue&type=template&id=06c91286&scoped=true&"
import script from "./DeleteInvoiceModal.vue?vue&type=script&lang=ts&"
export * from "./DeleteInvoiceModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c91286",
  null
  
)

export default component.exports