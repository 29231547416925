import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { PhoneListConfigureService } from "@/services/phoneListConfigureService"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

export class VoicePhoneListService extends PhoneListConfigureService {
  async checkPhoneListStatus(campaignId: string) {
    return await CampaignConfigureDao.checkPhoneListStatus(campaignId)
  }

  async getPhoneListData(campaign: VoiceCampaign | any) {
    return await UploadsDao.getFileData(
      campaign.organization._id,
      campaign.uploads.list._id
    )
  }

  async uploadPhoneList(campaign: VoiceCampaign, config: any, mapping: any) {
    if (!campaign.meta.stratics?.phoneListToken) {
      await CampaignConfigureDao.uploadPhoneList(campaign._id!, {
        config,
        mapping
      })
    }

    await this.waitWhenPhoneListWillBeUploaded(campaign._id!)
  }
}
