import axios, { CancelTokenStatic } from "axios"
import * as Sentry from "@sentry/browser"

const cancelToken: CancelTokenStatic = axios.CancelToken
export let cancelSource = cancelToken.source()
export const refreshToken = () => {
  cancelSource = cancelToken.source()
}
export const cancelAllRequests = () => {
  cancelSource.cancel()
}

export function setupAxios() {
  const { protocol, hostname } = window.location
  let baseUrl

  baseUrl = "https://api.robocent.com/api/v2"

  if (
    hostname.includes("netlify") ||
    hostname.includes("dev") ||
    process.env.VUE_APP_E2E_RUNNING === "true"
  ) {
    baseUrl = "https://api-dev.robocent.com/api/v2"
  } else if (hostname.includes("localhost")) {
    baseUrl = `${protocol}//${hostname}:3000/api/v2`
  }

  axios.defaults.baseURL = baseUrl

  axios.interceptors.response.use(
    (res) => res,
    (e) => {
      if (e.response) {
        const {
          status,
          data: { message, statusCode },
          config: { url }
        } = e.response

        if (status === 401) {
          location.replace(location.origin)
          return Promise.reject(e)
        }

        const error = `Request failed to ${url}. Status: ${
          statusCode || status
        }. Message: ${message || "No message"}`
        Sentry.captureException(new Error(error))
      } else {
        Sentry.captureException(new Error(e))
      }

      return Promise.reject(e)
    }
  )
}

export function setSharedToken(sharedToken: string) {
  axios.defaults.headers.sharedtoken = sharedToken
}
