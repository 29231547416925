















































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"
import { OrganizationFeatureRequest } from "@/interfaces/feature/organizationFeatureRequest"
import { Prop } from "vue-property-decorator"
import { FeatureDao } from "@/services/dao/featureDao"
import { EventBus } from "@/services/eventBus"
import { FeatureRequestStatus } from "@/interfaces/feature/featureRequestStatus"

@Component({
  components: { Card }
})
export default class FeatureRequestItem extends VueComponent {
  @Prop() featureRequest!: OrganizationFeatureRequest

  get isPending() {
    return this.featureRequest.status === FeatureRequestStatus.pending
  }

  async approve() {
    await this.request(async () => {
      await FeatureDao.approveRequest(this.featureRequest.id)
      EventBus.getFeatures$.next()
    }, "Request Approved")
  }

  async reject() {
    await this.request(async () => {
      await FeatureDao.rejectRequest(this.featureRequest.id)
      EventBus.getFeatures$.next()
    }, "Request Rejected")
  }
}
