export class Pagination {
  constructor(
    public total = 0,
    public current = 1,
    public perPage = 20,
    public rangeBefore = 3,
    public rangeAfter = 3,
    public order = "is-centered"
  ) {}

  get query() {
    return {
      page: this.current,
      limit: this.perPage
    }
  }

  atLeastOnePage(search?: string | null) {
    return this.total > this.perPage || search
  }
}
