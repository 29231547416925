<template>
  <div class="container mt-md">
    <b-loading :active.sync="loading" :can-cancel="true" />
    <b-message v-if="orgId && phone" class="is-primary is-borderless">
      <div class="columns is-vcentered">
        <div class="column">
          <p>
            <strong class="is-size-5"
              >Messages for {{ phone.name ? phone.name + " - " : "" }}
              {{ phone.number | phone }}
            </strong>
          </p>
          <p class="is-size-6 has-text-grey">
            <strong>Inbound Voicemail & SMS</strong>
          </p>
        </div>
        <div class="buttons has-text-right">
          <router-link
            class="button"
            :to="`/organizations/${orgId}/phones/${phone.uuid}/subscribers`"
          >
            Subscribers
          </router-link>
          <router-link
            class="button"
            :to="`/organizations/${orgId}/phones/${phone.uuid}`"
          >
            Callback Number Settings
          </router-link>
        </div>
      </div>
    </b-message>
    <big-card>
      <b-tabs v-model="activeTab">
        <b-tab-item label="SMS">
          <b-table
            v-if="smsMessages.length"
            :data="smsMessages"
            ref="table"
            paginated
            per-page="20"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="sid"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
            <template slot-scope="props">
              <b-table-column field="body" label="Content">
                <span>{{ props.row.body.replace(/(.{180})..+/, "$1…") }}</span>
              </b-table-column>

              <b-table-column
                field="date"
                label="Date"
                width="250"
                sortable
                centered
              >
                {{ props.row.dateSent | date }}
              </b-table-column>

              <b-table-column field="from" label="From" sortable centered>
                {{ props.row.from | phone }}
              </b-table-column>
              <b-table-column field="from" label="To" sortable centered>
                {{ props.row.to | phone }}
              </b-table-column>

              <!-- <b-table-column field="sid" centered>
                <b-button size="is-small" type="is-success">
                  Mark as Read
                </b-button>
              </b-table-column> -->
            </template>

            <template slot="detail" slot-scope="props">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
                      {{ props.row.body }}
                    </p>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
          <b-message v-else-if="!loading" type="is-info">
            <strong>No Messages</strong>
          </b-message>
        </b-tab-item>
        <b-tab-item label="Voicemail">
          <b-table
            v-if="voiceMessages.length"
            :data="voiceMessages"
            ref="table"
            paginated
            per-page="20"
            :opened-detailed="defaultOpenedDetails"
            @details-open="(row, index) => getTranscripts(row)"
            detailed
            detail-key="id"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
            <template slot-scope="props">
              <b-table-column
                field="date"
                label="Date"
                width="250"
                sortable
                centered
              >
                {{ props.row.createdAt | date }}
              </b-table-column>

              <b-table-column field="url" label="Audio" sortable centered>
                <audio controls preload="auto" :src="props.row.url">
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </b-table-column>

              <b-table-column field="from" label="From" sortable centered>
                {{ props.row.from | phone }}
              </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <article class="media">
                <div class="media-content">
                  <div class="content">
                    <p>
                      {{
                        props.row.transcript ||
                        "Transcripts are not enabled for this number. Enable from the Inbound Voice Settings Page."
                      }}
                    </p>
                  </div>
                </div>
              </article>
            </template>
          </b-table>
          <b-message v-else-if="!loading" type="is-info">
            <strong>No Voicemail Messages</strong>
          </b-message>
        </b-tab-item>
      </b-tabs>
    </big-card>
  </div>
</template>

<script>
import BigCard from "../elements/BigCard.vue"
import axios from "axios"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"

export default {
  name: "Number",
  components: { BigCard },
  data() {
    return {
      orgId: this.$route.params.orgId,
      phoneId: this.$route.params.phoneId,
      phone: null,
      voiceMessages: [],
      smsMessages: [],
      loading: true,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      activeTab: 0
    }
  },
  computed: {},
  created() {
    if (this.$route.params.phoneId) {
      this.get()
    }
  },
  methods: {
    async get() {
      PhoneNumberDao.getPhone(this.orgId, this.phoneId).then(
        ({ data }) => (this.phone = data)
      )
      await this.getSms()
      await this.getVms()
      this.loading = false
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    async getSms() {
      const { data } = await axios.get(
        `organizations/${this.orgId}/phones/${this.phoneId}/sms`
      )
      this.smsMessages = data
    },
    async getVms() {
      const { data } = await axios.get(
        `organizations/${this.orgId}/phones/${this.phoneId}/vms`
      )
      this.voiceMessages = data
    },
    async getTranscripts(row) {
      const { data } = await axios.get(
        `organizations/${this.orgId}/phones/${this.phoneId}/vms/${row.uuid}/recordings`
      )
      if (data && data.length) {
        this.$set(row, "transcript", data[0].transcriptionText)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";
table td:not([align]),
table th:not([align]) {
  vertical-align: middle;
}

@include mobile {
  ::v-deep .media-content {
    display: contents;
  }
}
</style>
