import { render, staticRenderFns } from "./PaginationItem.vue?vue&type=template&id=eb1bab92&scoped=true&"
import script from "./PaginationItem.vue?vue&type=script&lang=ts&"
export * from "./PaginationItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb1bab92",
  null
  
)

export default component.exports