import { isNumber } from "lodash"

export function creditFilter(value: number | undefined) {
  if (!isNumber(value)) {
    return value
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  })
  return formatter.format(value / 100)
}
