import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import moment, { Moment } from "moment"

@Component
export default class CampaignDateMixin extends VueComponent {
  minTime: Date | null = null
  startHour = 8
  startTime = `0${this.startHour}:00`
  endHour = "20:45"
  dateError = ""
  timeError = ""

  get isSuper() {
    return this.$store.getters.super
  }

  getCurrentTimeInTimezone(timezone: string) {
    return moment.tz(timezone)
  }

  roundMinutes(time: Moment) {
    return time.add(45 - (time.minute() % 15), "minutes")
  }

  calculateMinTime(timezone: string, date: string) {
    const roundedTime = this.roundMinutes(
      this.getCurrentTimeInTimezone(timezone)
    )
    const ifTimeBeforeStart = this.checkIfTimeBeforeStart(roundedTime, timezone)
    const isFutureDate = this.checkIfFutureDate(date)

    if (ifTimeBeforeStart || isFutureDate) {
      roundedTime.set({ hour: this.startHour, minute: 0 })
    }

    this.minTime = new Date(
      this.formattedTimeJS(roundedTime.toDate(), timezone)
    )
    return roundedTime.format("HH:mm")
  }

  checkIfTimeBeforeStart(time: Moment, timezone: string) {
    return time.isBefore(
      moment.tz(timezone).set({ hour: this.startHour, minute: 0 })
    )
  }

  checkIfFutureDate(date: string) {
    return moment(date).diff(moment(moment().format("YYYY-MM-DD")), "days") >= 1
  }

  formattedTimeJS(date: Date | null, timezone: string) {
    const dateToFormat = date ? date : new Date()
    const intlDateObj = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timezone
    })
    return intlDateObj.format(dateToFormat)
  }

  getTimeInTimezone(time: string, timezone: string) {
    return moment.tz(time, timezone)
  }

  validatePicker(startDate: string, startTime: string, timezone: string) {
    this.dateError = ""
    this.timeError = ""

    if (this.isSuper) {
      return true
    }

    const timeInTimezone = this.getTimeInTimezone(
      `${startDate}T${startTime}`,
      timezone
    )

    const endTime = this.getTimeInTimezone(
      `${startDate}T${this.endHour}`,
      timezone
    )

    const currentTimeInTimezone = this.getCurrentTimeInTimezone(timezone)

    if (
      timeInTimezone.isAfter(endTime) ||
      timeInTimezone.hours() < this.startHour
    ) {
      this.timeError = "Campaign cannot be sent between 9:00 PM and 8:00 AM!"
      return false
    }

    const selectedDate = moment(moment(timeInTimezone).format("YYYY-MM-DD"))
    const currentDate = moment(
      moment(currentTimeInTimezone).format("YYYY-MM-DD")
    )
    const minutesDiff = moment(timeInTimezone).diff(
      currentTimeInTimezone,
      "minutes"
    )

    if (selectedDate.isSame(currentDate) && minutesDiff < 0) {
      this.timeError = "Campaign cannot be sent in the past!"
      return false
    }

    if (selectedDate.isBefore(currentDate)) {
      this.dateError = "Campaign cannot be sent in the past!"
      return false
    }

    return true
  }
}
