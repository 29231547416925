



























import ChooseRow from "./ChooseRow.vue"
import Card from "../../elements/Card.vue"
import ChooseItem from "./ChooseItem.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { LocationService } from "@/services/locationService"
import { Emit, Prop } from "vue-property-decorator"
import { CampaignDirection as ECampaignDirection } from "@/enums/campaignDirection"

@Component({
  components: {
    ChooseItem,
    Card,
    ChooseRow
  }
})
export default class CampaignDirection extends VueComponent {
  locationService = new LocationService()
  @Prop() direction!: ECampaignDirection
  directions = ECampaignDirection

  @Emit()
  onClick(direction: ECampaignDirection) {
    return direction
  }
}
