

























































import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VoterDataRequest } from "@/voterData/interfaces/VoterDataRequest"
import _ from "lodash"
import { CardTable, CardTableItem } from "@robocent/components"
import { CountTypeNames } from "@/voterData/data/countTypeNames"
import VoterDataRequestMixin from "@/voterData/mixins/VoterDataRequestMixin"
import { VoterDataPricing } from "@/voterData/interfaces/VoterDataPricing"

@Component({
  components: { CardTableItem, CardTable }
})
export default class ConfirmPurchasingModal extends VoterDataRequestMixin {
  @Prop() items!: VoterDataRequest[]
  @Prop() pricing!: VoterDataPricing
  countTypeNames = CountTypeNames

  calculatePrice() {
    return _.sum(_.map(this.items, (item) => this.getSingleItemPrice(item)))
  }

  onConfirmClick() {
    this.$emit("confirm")
    this.$parent.close()
  }
}
