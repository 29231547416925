




import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Script } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import TestTextMessage from "@/components/campaign/TestTextMessage.vue"
import { ScriptDao } from "@/services/dao/scriptDao"
import { get } from "lodash"

@Component({
  components: {
    TestTextMessage
  }
})
export default class TestScriptModal extends VueComponent {
  @Prop() script!: Script

  async sendMessage(phone: string) {
    try {
      this.loading = true
      await ScriptDao.sendTestMessage(
        this.script.organizationId,
        this.script.id,
        phone
      )
      this.openToast("Text Message successfully sent")
      this.$parent.close()
    } catch (e) {
      const message =
        get(e, "response.data.message") || "The message can't be sent."
      this.openToast(message)
    } finally {
      this.loading = false
    }
  }
}
