











import Component from "vue-class-component"
import DataRequestForm from "@/voterData/DataRequestForm.vue"
import RecordsSummary from "@/voterData/RecordsSummary.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    RecordsSummary,
    DataRequestForm
  }
})
export default class VoterDataBuilder extends VueComponent {
  mounted() {
    this.$store.dispatch("voterData/clearAll")
  }
}
