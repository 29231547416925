


















import Component from "vue-class-component"
import CampaignInvoice from "../elements/CampaignInvoice.vue"
import CampaignInvoiceMixin from "@/mixins/campaign/CampaignInvoiceMixin"

@Component({
  components: { CampaignInvoice }
})
export default class TextCampaignInvoice extends CampaignInvoiceMixin {}
