














import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import VoiceSchedulerElements from "@/components/scheduler/VoiceSchedulerElements.vue"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { CampaignCostService } from "@/services/campaignCostService"
import { CampaignDirection as ECampaignDirection } from "@/enums/campaignDirection"

@Component({
  components: {
    VoiceSchedulerElements
  }
})
export default class CopyVoiceCampaign extends VueComponent {
  voiceCampaign: VoiceCampaign = null!
  campaignCostService = new CampaignCostService()

  async created() {
    await this.getVoiceCampaign()
    this.$store.commit("voiceScheduler/fillAllSteps", this.voiceCampaign)
  }

  async getVoiceCampaign() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        const voiceCampaign: VoiceCampaign =
          await VoiceCampaignDao.getVoiceCampaign(orgId, campaignUUID)
        voiceCampaign.uploads.vmRecording =
          voiceCampaign.uploads.vmRecording ?? null

        this.voiceCampaign = voiceCampaign
        this.voiceCampaign.time = ""
        this.voiceCampaign.createdAt = null
      },
      undefined,
      "Cannot get campaign"
    )
  }

  async copy() {
    await this.request(
      async () => {
        this.campaignCostService.setScriptField(this.voiceCampaign)
        await VoiceCampaignDao.copyVoiceCampaign(this.voiceCampaign)
        await this.$router.push({
          name: "Org Campaigns",
          params: {
            orgId: this.voiceCampaign.organization._id
          },
          query: {
            direction: ECampaignDirection.voice,
            created: "true"
          }
        })
      },
      "Successfully Re-Run Voice Campaign!",
      "Failed Re-Run Voice Campaign."
    )
  }
}
