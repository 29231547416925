import axios from "axios"
import { StatsConfig } from "@/interfaces/voiceCampaign/statsConfig"
import { StraticsAudio } from "@/interfaces/voiceCampaign/straticsAudio"
import { StatsPhoneListStatus } from "@/interfaces/statsPhoneListStatus"

export class CampaignConfigureDao {
  static async getAudioFiles(campaignId: string): Promise<StraticsAudio[]> {
    return (await axios.get(`/voice-campaign/${campaignId}/audio`)).data
  }

  static async uploadAudioFile(campaignId: string, file: any) {
    return await axios.post(`/voice-campaign/${campaignId}/audio`, {
      file
    })
  }

  static async deleteAudioFile(campaignId: string, file: any) {
    return await axios.delete(`/voice-campaign/${campaignId}/audio/${file}`)
  }

  static async getCallerId(campaignId: string) {
    return await axios.get(`/voice-campaign/${campaignId}/caller-id`)
  }

  static async createCallerId(campaignId: string) {
    return await axios.post(`/voice-campaign/${campaignId}/caller-id`)
  }

  static async deleteCallerId(campaignId: string, callerId: string) {
    return await axios.delete(
      `/voice-campaign/${campaignId}/caller-id/${callerId}`
    )
  }

  static async getPhoneList(
    campaignId: string,
    listId: string
  ): Promise<StatsConfig & { list_id: string }> {
    return (
      await axios.get(`/voice-campaign/${campaignId}/phone-list/${listId}`)
    ).data
  }

  static async checkPhoneListStatus(
    campaignId: string
  ): Promise<StatsPhoneListStatus> {
    return (
      await axios.get(`/voice-campaign/${campaignId}/phone-list/check-status`)
    ).data
  }

  static async uploadPhoneList(campaignId: string, data: any) {
    return await axios.post(`/voice-campaign/${campaignId}/phone-list`, data, {
      timeout: 15 * 60 * 1000
    })
  }

  static async deletePhoneList(campaignId: string, listId: string) {
    return await axios.delete(
      `/voice-campaign/${campaignId}/phone-list/${listId}`
    )
  }

  static async getSchedule(campaignId: string) {
    return (await axios.get(`/voice-campaign/${campaignId}/schedule`)).data
  }

  static async createSchedule(campaignId: string, data: any) {
    return await axios.post(`/voice-campaign/${campaignId}/schedule`, data)
  }

  static async deleteSchedule(campaignId: string, scheduleId: string) {
    return await axios.delete(
      `/voice-campaign/${campaignId}/schedule/${scheduleId}`
    )
  }

  static async getAgentPool(campaignId: string) {
    return (await axios.get(`/voice-campaign/${campaignId}/agent-pool`)).data
  }

  static async createAgentPool(campaignId: string, data: any) {
    return await axios.post(`/voice-campaign/${campaignId}/agent-pool`, data)
  }

  static async updateAgentPool(campaignId: string, data: any) {
    return await axios.put(`/voice-campaign/${campaignId}/agent-pool`, data)
  }

  static async createCampaign(campaignId: string, data: any) {
    return await axios.post(`/voice-campaign/${campaignId}/campaign`, data)
  }

  static async updateCampaign(campaignId: string, data: any) {
    return await axios.patch(`/voice-campaign/${campaignId}/campaign`, data)
  }

  static async getCampaign(campaignId: string) {
    return (await axios.get(`/voice-campaign/${campaignId}/campaign`)).data
  }

  static async deleteCampaign(campaignId: string, externalCampaignId: string) {
    return await axios.delete(
      `/voice-campaign/${campaignId}/campaign/${externalCampaignId}`
    )
  }

  static async pauseCampaign(campaignId: string) {
    return await axios.post(`/voice-campaign/${campaignId}/pause-campaign`)
  }

  static async activateCampaign(campaignId: string) {
    return await axios.post(`/voice-campaign/${campaignId}/activate-campaign`)
  }

  static async createIvr(campaignId: string, data: any) {
    return await axios.post(`/voice-campaign/${campaignId}/ivr`, data)
  }

  static async getIvr(campaignId: string) {
    return await axios.get(`/voice-campaign/${campaignId}/ivr`)
  }

  static async deleteIvr(campaignId: string, ivrId: string) {
    return await axios.delete(`/voice-campaign/${campaignId}/ivr/${ivrId}`)
  }

  static async reject(campaignId: string, data: any) {
    return await axios.post(`/voice-campaign/${campaignId}/reject`, data)
  }

  static async lockCall(campaignId: string) {
    return axios.put(`/voice-campaign/${campaignId}/lock`)
  }

  static async unlockCall(campaignId: string) {
    return axios.put(`/voice-campaign/${campaignId}/unlock`)
  }
}
