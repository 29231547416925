



















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { InvitedAgent } from "@robocent/components"
import InvitedAgentItem from "./InvitedAgentItem.vue"
import { EventBus } from "@/services/eventBus"
import { AgentDao } from "@/services/dao/agentDao"
import { whileComponentMounted } from "@/utils/operators"
import InviteAgentModal from "@/components/modals/InviteAgentModal.vue"

@Component({
  components: { InvitedAgentItem }
})
export default class InvitedAgentsList extends VueComponent {
  invitedAgents: InvitedAgent[] = []

  async created() {
    EventBus.getInvitedAgents$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getInvitedAgents)
    await this.getInvitedAgents()
  }

  async getInvitedAgents() {
    await this.request(async () => {
      this.invitedAgents = (
        await AgentDao.getInvitedAgents(this.$route.params.orgId, {
          limit: 1000
        })
      ).data
    })
  }

  inviteAgentModal() {
    this.$buefy.modal.open({
      parent: this,
      component: InviteAgentModal,
      width: 400,
      props: {
        orgId: this.$route.params.orgId
      }
    })
  }
}
