

































import Component from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import SalesManagerFilter from "@/components/salesPortal/SalesManagerFilter.vue"
import SalesPortalTotalEarnings from "@/components/salesPortal/SalesPortalTotalEarnings.vue"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"
import OrganizationEarnings from "@/components/salesPortal/OrganizationEarnings.vue"
import SalesCommissionRates from "@/components/salesPortal/SalesCommissionRates.vue"
import SalesPayouts from "@/components/salesPortal/SalesPayouts.vue"

@Component({
  components: {
    SalesPayouts,
    SalesCommissionRates,
    OrganizationEarnings,
    SalesPortalTotalEarnings,
    SalesManagerFilter,
    Card
  }
})
export default class SalesDetailsViewModal extends SalesPortalMixin {
  @Prop() userId!: string
  @Prop() title!: string

  created() {
    this.$store.commit("salesPortal/clear")
    this.getSalesRateByUserId()
    this.getSalesEarningsByUserId()
  }

  async getSalesPayoutsByUserId(params: {
    date: string
    status: PartnerPayoutStatus
  }) {
    this.payouts = []

    await this.request(
      async () => {
        const { campaigns } = await SalesPortalDao.getSalesPayoutsByUserId(
          this.selectedOrg?._id,
          this.userId,
          params
        )
        this.payouts = campaigns
      },
      undefined,
      undefined,
      "payouts"
    )
  }

  async getSalesRateByUserId() {
    await this.request(
      async () => {
        this.salesRate = await SalesPortalDao.getSalesRateByUserId(this.userId)
      },
      undefined,
      undefined,
      "sales-rates"
    )
  }

  async getSalesEarningsByUserId() {
    const params = {
      date: this.selectedDate.toDateString()
    }
    await this.request(
      async () => {
        this.salesEarnings = await SalesPortalDao.getSalesEarningsByUserId(
          this.userId,
          params
        )
      },
      undefined,
      undefined,
      "sales-earnings"
    )
  }

  @Watch("selectedDate")
  watchSelectedDate() {
    this.getSalesEarningsByUserId()
  }
}
