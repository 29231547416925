



























import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { PhoneNumberWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { DndDao } from "@/services/dao/dndDao"
import { Prop } from "vue-property-decorator"

@Component({
  components: { Card, PhoneNumberWithValidation, ValidationObserver }
})
export default class AddDndModal extends VueComponent {
  phone: string = null!
  @Prop() orgId!: string

  async add() {
    await this.request(async () => {
      await DndDao.addToDnd(this.orgId, this.phone)
      this.$parent.close()
      this.openSuccessToast("Phone Number successfully added")
      this.$emit("added")
    })
  }
}
