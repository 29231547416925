











































import Component, { mixins } from "vue-class-component"
import { CampaignPricing, PhoneListUtilsMixin } from "@robocent/components"
import { CampaignDao } from "@/services/dao/campaignDao"
import ReviewScripts from "@/components/campaign/textAdminReview/ReviewScripts.vue"
import OrganizationSettings from "@/views/organization/OrganizationSettings.vue"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import CampaignDetails from "@/components/report/CampaignDetails.vue"
import CampaignDetailsElements from "@/components/report/CampaignDetailsElements.vue"
import ReviewTextCampaign from "@/components/campaign/textAdminReview/ReviewTextCampaign.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import TextCampaignReviewHeader from "@/components/campaign/textAdminReview/TextCampaignReviewHeader.vue"
import TextOverviewMixin from "@/components/campaign/textAdminReview/TextOverviewMixin"
import TextCampaignDetails from "@/components/campaign/textAdminReview/TextCampaignDetails.vue"

@Component({
  components: {
    TextCampaignDetails,
    TextCampaignReviewHeader,
    ReviewTextCampaign,
    CampaignDetailsElements,
    CampaignDetails,
    OrganizationSettings,
    ReviewScripts
  }
})
export default class TextCampaignAdminReview extends mixins(
  PhoneListUtilsMixin,
  TextOverviewMixin
) {
  pricing: CampaignPricing = null!
  isMergedFieldsEnabled = false

  get orgId() {
    return this.$route.params.orgId
  }

  get campaignUUID() {
    return this.$route.params.campaignUUID
  }

  async getPricing() {
    await this.request(async () => {
      this.pricing = await SmsBillingDao.getPricing(this.orgId)
    })
  }

  async created() {
    await this.$store.commit("textConfigurator/clearCampaign")
    await this.getCampaign()
    this.getPricing()
    await this.checkIfMergedFieldsEnabled()
    EventBus.startScrubPhoneList$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getList)
    EventBus.getScripts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getCampaign)
  }

  async getList() {
    await this.request(async () => {
      this.$store.commit(
        "textConfigurator/setList",
        (
          await UploadsDao.getUpload(
            this.campaign!.organizationId,
            this.campaign!.listId
          )
        ).data
      )
    })
  }

  async getCampaign() {
    await this.$store.dispatch("textConfigurator/updateCampaign", {
      orgId: this.orgId,
      campaignUUID: this.campaignUUID
    })
  }

  async checkIfMergedFieldsEnabled() {
    await this.request(async () => {
      this.isMergedFieldsEnabled = await CampaignDao.checkIfMergedFieldsEnabled(
        this.campaign.organizationId,
        this.campaign
      )
    })
  }
}
