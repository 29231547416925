









import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Invoice } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import RefundAmountCard from "@/components/elements/RefundAmountCard.vue"
import RefundCampaignCostModal from "@/components/modals/RefundCampaignCostModal.vue"
import { VueComponent } from "@/utils/VueComponent"
import { RefundTypes } from "@/enums/refundTypes"
import { RefundAmount } from "@/enums/refundAmount"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"

interface CampaignExtended extends VoiceCampaign {
  invoice: Invoice
}

@Component({
  components: { RefundCampaignCostModal, RefundAmountCard, Card }
})
export default class RefundVoiceCampaign extends VueComponent {
  @Prop() campaign!: CampaignExtended

  async onConfirmClick(refundType: RefundTypes, refundAmount: RefundAmount) {
    await this.request(async () => {
      await VoiceCampaignDao.cancelVoiceCampaign(
        this.campaign.organization?._id,
        this.campaign._id as string,
        refundType,
        refundAmount
      )
      this.$emit("onConfirm")
      this.$parent.close()
    })
  }
}
