import axios from "axios"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { User } from "@robocent/components"
import { VoiceCampaignStatsLive } from "@/interfaces/voiceCampaign/voiceCampaignStatsLive"
import { VoiceCampaignStatsDetail } from "@/interfaces/voiceCampaign/voiceCampaignStatsDetail"
import { RefundTypes } from "@/enums/refundTypes"
import { RefundAmount } from "@/enums/refundAmount"

export class VoiceCampaignDao {
  static async createCampaign(campaign: VoiceCampaign) {
    return axios.post(
      `/organizations/${campaign.organization._id}/voice-campaigns`,
      campaign
    )
  }

  static async editCampaign(campaign: VoiceCampaign) {
    return axios.put(
      `/organizations/${campaign.organization._id}/voice-campaigns/${campaign._id}`,
      campaign
    )
  }

  static async makeCampaignPublic(
    orgId: string,
    campaignUUID: string,
    isPublic: boolean
  ) {
    return axios.put(
      `/organizations/${orgId}/voice-campaigns/${campaignUUID}/shared`,
      {
        isPublic
      }
    )
  }

  static async getAllVoiceCampaigns(
    params: FindManyFilter
  ): Promise<{ data: VoiceCampaign[]; total: number }> {
    return (await axios.get("/voice-campaigns", { params })).data
  }

  static async getVoiceCampaigns(
    orgId: string,
    params: FindManyFilter
  ): Promise<{ data: VoiceCampaign[]; total: number }> {
    return (
      await axios.get(`/organizations/${orgId}/voice-campaigns`, { params })
    ).data
  }

  static async getVoiceCampaign(
    orgId: string,
    uuid: string
  ): Promise<VoiceCampaign> {
    return (await axios.get(`/organizations/${orgId}/voice-campaigns/${uuid}`))
      .data
  }

  static async deleteVoiceCampaign(orgId: string, voiceCampaignId: string) {
    return await axios.put(
      `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/delete`
    )
  }

  static async campaignTestCall(straticsId: string, phone: string) {
    return await axios.post("/voice-campaigns/campaign-test-call", {
      straticsId,
      phone
    })
  }

  static async outboundCampaignTestCall(campaignId: string, phone: string) {
    return await axios.post("/voice-campaigns/outbound-campaign-test-call", {
      campaignId,
      phone
    })
  }

  static async requestVoiceCampaignCancelation(
    orgId: string,
    voiceCampaignId: string
  ) {
    return await axios.post(
      `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/request-cancel`
    )
  }

  static async cancelVoiceCampaign(
    orgId: string,
    voiceCampaignId: string,
    refundType: RefundTypes,
    refundAmount: RefundAmount
  ) {
    return await axios.post(
      `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/cancel`,
      {
        refundType,
        refundAmount
      }
    )
  }

  static async getUser(voiceCampaignId: string): Promise<User> {
    return (await axios.get(`/voice-campaigns/${voiceCampaignId}/user`)).data
  }

  static async getVoiceCampaignReports(voiceCampaignId: string) {
    return (await axios.get(`/voice-campaigns/${voiceCampaignId}/reports`)).data
  }

  static async cancelReportDownloading(
    orgId: string,
    voiceCampaignId: string,
    reportId: number
  ) {
    return await axios.put(
      `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/stats-report/${reportId}/cancel`
    )
  }

  static async getVoiceCampaignReport(orgId: string, voiceCampaignId: string) {
    return await axios.get(
      `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/stats-report`,
      { timeout: 5 * 60 * 1000 }
    )
  }

  static async getVoiceCampaignLiveStats(
    orgId: string,
    voiceCampaignId: string
  ): Promise<VoiceCampaignStatsLive> {
    return (
      await axios.get(
        `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/stats`
      )
    ).data
  }

  static async getVoiceCampaignStatsDetail(
    orgId: string,
    voiceCampaignId: string
  ): Promise<VoiceCampaignStatsDetail> {
    return (
      await axios.get(
        `/organizations/${orgId}/voice-campaigns/${voiceCampaignId}/stats-detail`
      )
    ).data
  }

  static async copyVoiceCampaign(campaign: VoiceCampaign) {
    return axios.post(
      `/organizations/${campaign.organization._id}/voice-campaigns/${campaign._id}/copy`,
      campaign
    )
  }
}
