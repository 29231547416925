import { render, staticRenderFns } from "./VoiceCampaignGeneralInfo.vue?vue&type=template&id=72b1b594&scoped=true&"
import script from "./VoiceCampaignGeneralInfo.vue?vue&type=script&lang=ts&"
export * from "./VoiceCampaignGeneralInfo.vue?vue&type=script&lang=ts&"
import style0 from "./VoiceCampaignGeneralInfo.vue?vue&type=style&index=0&id=72b1b594&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b1b594",
  null
  
)

export default component.exports