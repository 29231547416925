




















































































import Component from "vue-class-component"
import {
  CampaignPricing,
  InputWithValidation,
  MergedTagsValues,
  MergeField,
  Script
} from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import ScriptPreview from "@/components/scheduler/textElements/ScriptPreview.vue"
import ScriptRequirements from "@/components/scheduler/textElements/ScriptRequirements.vue"
import MessageCounter from "@/components/scripts/MessageCounter.vue"
import UnicodeInfo from "@/components/elements/UnicodeInfo.vue"
import { UnicodeUtil } from "@/utils/unicodeUtil"

@Component({
  components: {
    ScriptRequirements,
    ScriptPreview,
    MessageCounter,
    InputWithValidation,
    UnicodeInfo
  }
})
export default class BuildScriptStep extends VueComponent {
  @Prop() script!: Script
  @Prop() minOptionsNumber!: number
  @Prop() defaultText!: string
  @Prop() pricing!: CampaignPricing

  enableMMS = false
  mergeFields = Object.keys(MergeField)
  maxMmsLength = 1500
  maxSmsLength = 800
  maxCharacters = 0
  unicodeUtil = new UnicodeUtil()

  get isMMSEnabled(): boolean {
    return this.enableMMS || Boolean(this.script.mms)
  }

  get additionalSegmentCost() {
    if (this.script.mms) {
      return 0
    }

    return this.pricing.additionalSegmentCost
  }

  created() {
    this.enableMMS = this.isMMSEnabled
    this.maxCharacters = this.getMaxCharacters()

    if (!this.isMMSEnabled && this.script.text) {
      this.script.text = this.unicodeUtil.replaceUnicode(this.script.text)
      this.unicodeUtil.detectUnicode(this.script.text)
    }
  }

  getMaxCharacters() {
    const length = this.isMMSEnabled ? this.maxMmsLength : this.maxSmsLength

    return this.script.isDefaultScriptNeeded
      ? length - this.defaultText.length
      : length
  }

  getMergeFieldValue(key: keyof typeof MergeField) {
    return MergeField[key as keyof typeof MergeField]
  }

  addMergeField(field: string) {
    this.script.text += `{${
      MergedTagsValues[field as keyof typeof MergedTagsValues]
    }}`
  }

  getTagType(tag: MergeField) {
    return tag.includes("agent") ? "is-twitter" : "is-primary"
  }

  @Watch("enableMMS")
  watchScriptMMSChange(currentStatus: boolean) {
    if (!currentStatus) {
      this.script.mms = ""
    }
  }

  @Watch("script.text")
  @Watch("script.mms")
  watchScriptForUnicodeChars() {
    if (this.isMMSEnabled) {
      this.unicodeUtil.clearUnicodeChars()
      this.maxCharacters = this.getMaxCharacters()
      return
    }

    this.script.text = this.unicodeUtil.replaceUnicode(this.script.text)
    this.unicodeUtil.detectUnicode(this.script.text)

    if (this.unicodeUtil.unicodeChars) {
      this.maxCharacters = this.getMaxCharacters() / 2
      return
    }

    this.unicodeUtil.clearUnicodeChars()
    this.maxCharacters = this.getMaxCharacters()
  }
}
