












import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import CreateNewOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"

@Component
export default class OrganizationsEmptyMessage extends VueComponent {
  openCreateOrganizationModal() {
    this.$buefy.modal.open({
      width: 640,
      component: CreateNewOrganizationModal,
      parent: this
    })
  }
}
