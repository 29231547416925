
















import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import { Watch } from "vue-property-decorator"
import { DatasetFields } from "@/voterData/enums/datasetFields"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"
import _ from "lodash"

@Component({
  components: {
    StepTemplate
  }
})
export default class Age extends VoterDataUtilsMixin {
  ageRanges: FieldInfo[] = []

  get age() {
    return this.$store.getters["voterData/demographicsInfo/age"]
  }
  set age(value: string[]) {
    this.$store.commit("voterData/demographicsInfo/updateAge", value)
  }

  mounted() {
    this.getAgeRanges()
  }

  async getAgeRanges() {
    await this.request(async () => {
      this.ageRanges = await this.getStepData(DatasetFields.age)

      if (!this.age.length) {
        this.age = _.map(this.ageRanges, "value") as string[]
      }
    })
  }

  @Watch("age")
  watchAge(nextValue: string[], prevValue: string[]) {
    if (!_.isEqual(nextValue, prevValue)) {
      this.updateQuery()
    }
  }
}
