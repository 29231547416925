






































































import Component from "vue-class-component"
import { Campaign, statuses } from "@robocent/components"
import { Emit, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { EventBus } from "@/services/eventBus"
import { messages } from "@/data/messages"
import DeleteInvoiceModal from "@/components/modals/DeleteInvoiceModal.vue"

@Component
export default class CampaignAdminActionsDropdown extends VueComponent {
  @Prop() campaign!: Campaign | VoiceCampaign
  @Prop() parentLoading!: boolean
  statuses = statuses

  get isInvoiceDeletingAvailable() {
    return (
      this.campaign.invoice?.status === statuses.draft ||
      this.campaign.invoice?.status === statuses.open
    )
  }

  get finalisingOrSendingInvoiceAvailable() {
    return [statuses.draft, statuses.open].includes(
      this.campaign?.invoice?.status
    )
  }

  get invoiceAction() {
    return this.campaign?.invoice.collection_method === "charge_automatically"
      ? "Charge"
      : "Send"
  }

  get orgName() {
    return this.campaign.organization?.name
  }

  payInvoiceConfirm() {
    this.$buefy.dialog.prompt({
      message: messages.finalizeInvoice(this.orgName),
      inputAttrs: {
        placeholder: "e.g. Patterson Sample Campaign",
        required: false
      },
      onConfirm: (value) => this.payInvoice(value)
    })
  }

  async payInvoice(value: string) {
    const body = { forceCharge: false }
    this.loading = true

    if (value && value !== this.orgName) {
      return this.openToast("Organization Name is Incorrect!")
    } else if (value === this.orgName) {
      body.forceCharge = true
    }

    try {
      await BillingDao.payInvoice(this.campaign?.invoice.id, body)
      this.openToast(
        "The invoice was sent or charged depending on AutoPay settings."
      )
      EventBus.getCampaigns$.next()
    } catch (e) {
      const eMessage =
        e.response && e.response.data
          ? e.response.data.message
          : "An error occurred."
      this.openToast(eMessage)
    } finally {
      this.loading = false
    }
  }

  openDeleteInvoiceModal() {
    this.$buefy.modal.open({
      parent: this,
      component: DeleteInvoiceModal,
      width: 500,
      events: {
        deleted: this.onDeleteInvoice
      }
    })
  }

  @Emit()
  onDeleteInvoice() {}
}
