
















































import Component from "vue-class-component"
import { CardTable, CardTableItem } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  },
  filters: {
    true_false(bool: boolean) {
      if (bool === true) {
        return "Yes"
      } else if (bool === false) {
        return "No"
      } else {
        return "No"
      }
    }
  }
})
export default class VoiceCampaignAdditionalInfo extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() waiting!: boolean
}
