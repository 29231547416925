










import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { RequestedPayout } from "@robocent/components"
import { Ref } from "vue-property-decorator"
import { AgentPayoutDao } from "@/services/dao/agentPayoutDao"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { BDialogConfig } from "buefy/types/components"
import RequestedPayoutsTable from "@/components/payouts/RequestedPayoutsTable.vue"
import { PayoutType } from "@/enums/payoutType"
import MarkAgentPayoutsAsPaidModal from "@/components/modals/MarkAgentPayoutsAsPaidModal.vue"

@Component({
  components: { RequestedPayoutsTable }
})
export default class AgentRequestedPayoutsTable extends VueComponent {
  @Ref("table") table: any
  payouts: RequestedPayout[] = []
  payoutTypes = PayoutType

  created() {
    EventBus.getAgentsPayouts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getRequestedPayouts)
    this.getRequestedPayouts()
  }

  async getRequestedPayouts() {
    await this.request(
      async () => {
        this.payouts = await AgentPayoutDao.getRequestedPayouts()
      },
      null,
      "Cannot get the data"
    )
  }

  async onDismiss(payout: RequestedPayout) {
    await this.confirm("Are you sure you want to dismiss this request?", {
      confirmText: "Confirm"
    } as BDialogConfig)
    await this.request(async () => {
      await AgentPayoutDao.dismissPayout(payout.id)
      await this.getRequestedPayouts()
    })
  }

  markPayoutsAsPaid({ userId, name }: { userId: string; name: string }) {
    this.$buefy.modal.open({
      parent: this,
      component: MarkAgentPayoutsAsPaidModal,
      width: 400,
      props: {
        userId,
        name
      }
    })
  }
}
