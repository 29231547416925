import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Script } from "@robocent/components"
import { ScriptDao } from "@/services/dao/scriptDao"
import { BDialogConfig } from "buefy/types/components"
import { EventBus } from "@/services/eventBus"
import _ from "lodash"

@Component
export default class DeleteScriptMixin extends VueComponent {
  async onScriptDelete(script: Script): Promise<Script> {
    const isScriptUsed = await this.checkIfScriptUsed(script)
    let clonedScript = _.cloneDeep(script)
    if (isScriptUsed) {
      this.$buefy.dialog.alert({
        type: "is-warning",
        hasIcon: true,
        message: `This script is currently assigned to at least one active campaign.
        Deleting this script it's not possible now`
      } as BDialogConfig)
      return clonedScript
    } else {
      await this.confirm("Are you sure you want to delete this script?", {
        confirmText: "Delete"
      } as BDialogConfig)
    }

    await this.request(async () => {
      clonedScript = await ScriptDao.deleteScript(
        script.organizationId,
        script.id
      )
      EventBus.getScripts$.next(null)
    }, "The Script was deleted successfully")

    return clonedScript
  }

  async checkIfScriptUsed(script: Script) {
    return Boolean(
      await ScriptDao.scriptUsedIn(script.organizationId, script.id)
    )
  }
}
