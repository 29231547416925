import axios from "axios"
import {
  StringObject,
  User,
  UserAppMetadata,
  UserMetadata,
  UserV2,
  UserV2Update,
  UserTosAgreement
} from "@robocent/components"
import { UserLevel } from "@/enums/userLevel"

export class UserDao {
  static async acceptInvites(email: string) {
    return await axios.post("/user/accept-invites", { email: email })
  }

  static async getUserHash() {
    return (await axios.post("/user/hash")).data
  }

  static async updateUserMetaData(
    appMetaData: UserAppMetadata,
    userMetaData: UserMetadata
  ) {
    return await axios.put("/user", { appMetaData, userMetaData })
  }

  static async createUser(
    appMetaData: UserAppMetadata,
    userMetaData: UserMetadata
  ) {
    return await axios.post("/user", { appMetaData, userMetaData })
  }

  static async updateUserLevel(level: UserLevel) {
    return await axios.put("/user-level", { level })
  }

  static async updateUserLevelById(
    userId: string,
    data: { level: UserLevel; internalAgent: boolean }
  ) {
    return await axios.put(`/users-level/${userId}`, { ...data })
  }

  static async getAllUsers(params: StringObject) {
    return await axios.get("/admin/users", { params })
  }

  static async getInternalUsers(): Promise<UserV2[]> {
    return (await axios.get("/admin/internal-users")).data
  }

  static async updateUser(userId: string, data: UserV2Update) {
    return await axios.put(`/users/${userId}`, data)
  }

  static async getUserById(sub: string) {
    return await axios.get(`/user/${sub}`)
  }

  static async getUser(): Promise<User> {
    return (await axios.get(`/user`)).data
  }

  static async getUserTosAgreement(userId?: string): Promise<UserTosAgreement> {
    return (
      await axios.get(`/user/tos-agreement`, {
        params: {
          userId
        }
      })
    ).data
  }

  static async updateUserTosAgreement(data: UserTosAgreement) {
    return await axios.put(`/user/tos-agreement`, data)
  }

  // static async getUserAccounts() {
  //   return (await axios.get("/user-accounts")).data
  // }

  // static async linkAccounts() {
  //   await axios.post("/user-accounts")
  // }

  // static async skipLinkAccounts() {
  //   await axios.post("/user-accounts/skip-link")
  // }

  static async verifyUser(id: string) {
    return await axios.patch(
      `${process.env.VUE_APP_API_URL_V3}/admin/users/${id}/verify`
    )
  }
}
