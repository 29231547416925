import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Feature } from "@/interfaces/feature/feature"
import { OrganizationPartnerType } from "@/interfaces/partnerPortal/organizationPartnerType"
import { PartnerPayoutWithCampaign } from "@/interfaces/partnerPortal/partnerPayoutWithCampaign"
import { PartnerPortalTotals } from "@/interfaces/partnerPortal/partnerPortalTotals"

@Component({})
export default class PartnerPortalMixin extends VueComponent {
  features = Feature

  get selectedService() {
    return this.$store.getters["partnerPortal/selectedService"]
  }

  set selectedService(selectedService: Feature | null) {
    this.$store.commit("partnerPortal/setService", selectedService)
  }

  get selectedOrg() {
    return this.$store.getters["partnerPortal/selectedOrg"]
  }

  set selectedOrg(orgId: string) {
    this.$store.commit("partnerPortal/setOrganization", orgId)
  }

  get partnerSettings() {
    return this.$store.getters["partnerPortal/partnerSettings"]
  }

  set partnerSettings(settings: OrganizationPartnerType[]) {
    this.$store.commit("partnerPortal/setPartnerSettings", settings)
  }

  get payouts() {
    return this.$store.getters["partnerPortal/payouts"]
  }

  set payouts(payouts: PartnerPayoutWithCampaign[]) {
    this.$store.commit("partnerPortal/setPayouts", payouts)
  }

  get servicesTotals() {
    return this.$store.getters["partnerPortal/servicesTotals"]
  }

  set servicesTotals(servicesTotals: {
    [Feature.smsCampaign]: PartnerPortalTotals
    [Feature.voiceCampaign]: PartnerPortalTotals
  }) {
    this.$store.commit("partnerPortal/setServicesTotals", servicesTotals)
  }
}
