









import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { EventBus } from "@/services/eventBus"

@Component
export default class OrganizationAutoPay extends VueComponent {
  @Prop() organizationId!: string
  @Prop() autoPay!: boolean
  @Prop({ default: "" }) label!: string

  autoPayEnabled: boolean | null = null

  created() {
    this.setAutoPay(this.autoPay)
  }

  async onAutoPayChanged(autoPayEnabled: boolean) {
    await this.request(async () => {
      this.autoPayEnabled = await OrganizationDao.organizationAutoPay(
        this.organizationId,
        autoPayEnabled
      )
      const message = `AutoPay for Organization ${
        this.autoPayEnabled ? "Enabled" : "Disabled"
      }`
      EventBus.organizationChanged$.next()
      this.openToast(message)
    })
  }

  setAutoPay(autoPay: boolean) {
    this.autoPayEnabled = autoPay
  }

  @Watch("autoPay")
  watchAutoPay(autoPay: boolean) {
    this.setAutoPay(autoPay)
  }
}
