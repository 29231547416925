import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import { Invoice, statuses } from "@robocent/components"
import { RefundTypes } from "@/enums/refundTypes"
import { RefundAmount } from "@/enums/refundAmount"
import { Prop } from "vue-property-decorator"

@Component({})
export default class RefundCampaignCostMixin extends VueComponent {
  @Prop() invoice!: Invoice | null
  @Prop() organizationId!: string

  hasCard = false
  refundType: { name: string; disabled: boolean; id: RefundTypes } | null = null
  refundAmount: RefundAmount | null = RefundAmount.amountTotal
  types = RefundTypes

  get refundOptions() {
    return [
      {
        id: RefundTypes.doNotRefund,
        name: "Do Not Refund",
        disabled: false
      },
      {
        id: RefundTypes.creditAmount,
        name: "Account Balance Credit",
        disabled: !this.invoiceIsPaid
      },
      {
        id: RefundTypes.refundAmount,
        name: "Payment Source (Credit Card)",
        disabled: !this.invoiceIsPaid
      },
      {
        id: RefundTypes.outOfBandAmount,
        name: "Payment Outside of Stripe (e.g. cash)",
        disabled: !this.invoiceIsPaid
      }
    ]
  }

  get invoiceIsPaid() {
    return this.invoice?.status === statuses.paid
  }

  get noCardPaymentReceived() {
    return (
      this.refundType?.id === RefundTypes.refundAmount &&
      this.invoice?.amount_paid === 0
    )
  }

  setRefundType() {
    if (!this.invoiceIsPaid) {
      this.refundType =
        this.refundOptions.find(({ id }) => id === RefundTypes.doNotRefund) ||
        null
      return
    }
    this.refundType =
      this.refundOptions.find(({ id }) => id === RefundTypes.creditAmount) ||
      null
    return
  }

  async checkCreditCard(orgId: string) {
    await this.request(async () => {
      const customer = await SmsBillingDao.getCustomer(orgId)
      this.hasCard =
        customer.sources.data.filter((item) => item.object === "card").length >
        0
    })
  }
}
