








































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"
import { PhoneNumberWithValidation } from "@robocent/components"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import { Prop } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"

@Component({
  components: { Card, PhoneNumberWithValidation }
})
export default class ImportExistingPhoneNumberModal extends VueComponent {
  @Prop() orgId!: string
  phoneNumber: string = null!
  confirmPhoneNumber = null

  get isCallerIdConfirmed() {
    return this.phoneNumber && this.phoneNumber === this.confirmPhoneNumber
  }

  async createCallerId() {
    await this.request(
      async () => {
        await OrganizationDao.createCallerId(this.orgId, this.phoneNumber)
        EventBus.getPhoneNumbers$.next()
        this.$parent.close()
      },
      "Caller ID imported successfully",
      "Failed importing Caller ID"
    )
  }
}
