

























































import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { CardTable, CardTableItem } from "@robocent/components"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  }
})
export default class VoiceCampaignTotalInfo extends VueComponent {
  @Prop() waiting!: boolean
  @Prop() activeStats!: { label: string; value: number }[]
  @Prop() resultStats!: { label: string; value: number; perc: string }[]
  @Prop() voiceCampaignCost!: {
    campaign: number
    invoice: number
    difference: number
  }

  isPercentExist(percent: string) {
    return !isNaN(parseFloat(percent))
  }
}
