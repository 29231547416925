















































import InvoiceItemMixin from "@/mixins/InvoiceItemMixin"
import Component, { mixins } from "vue-class-component"

@Component
export default class ApprovePercentDiscount extends mixins(InvoiceItemMixin) {}
