import axios from "axios"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { User } from "@robocent/components"
import { VoiceCampaignStatsLive } from "@/interfaces/voiceCampaign/voiceCampaignStatsLive"
import { VoiceCampaignStatsDetail } from "@/interfaces/voiceCampaign/voiceCampaignStatsDetail"

export class VoiceCampaignPublicDao {
  static async getVoiceCampaign(
    voiceCampaignId: string
  ): Promise<VoiceCampaign> {
    return (await axios.get(`/public/calls/${voiceCampaignId}`)).data
  }

  static async getUser(voiceCampaignId: string): Promise<User> {
    return (await axios.get(`/public/calls/${voiceCampaignId}/user`)).data
  }

  static async getVoiceCampaignReports(voiceCampaignId: string) {
    return (await axios.get(`/public/calls/${voiceCampaignId}/reports`)).data
  }

  static async cancelReportDownloading(
    voiceCampaignId: string,
    reportId: number
  ) {
    return await axios.put(
      `/public/calls/${voiceCampaignId}/stats-report/${reportId}/cancel`
    )
  }

  static async generateVoiceCampaignReport(voiceCampaignId: string) {
    return await axios.get(`/public/calls/${voiceCampaignId}/stats-report`)
  }

  static async getVoiceCampaignLiveStats(
    voiceCampaignId: string
  ): Promise<VoiceCampaignStatsLive> {
    return (await axios.get(`/public/calls/${voiceCampaignId}/stats`)).data
  }

  static async getVoiceCampaignStatsDetail(
    voiceCampaignId: string
  ): Promise<VoiceCampaignStatsDetail> {
    return (await axios.get(`/public/calls/${voiceCampaignId}/stats-detail`))
      .data
  }

  static async testCall(phone: string) {
    return await axios.post("/voice-campaigns/test-call", {
      phone
    })
  }
}
