import Component from "vue-class-component"
import { statuses, StringObject } from "@robocent/components"
import { VoterDataInvoice } from "@/voterData/interfaces/VoterDataInvoice"
import Vue from "vue"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({})
export default class PayoutsTableMixin extends Vue {
  partnerPayoutStatuses = PartnerPayoutStatus
  statusToType(status: string) {
    const statusToType: StringObject = {
      [statuses.paid]: "is-success"
    }
    return statusToType[status] || "is-danger"
  }

  getInvoiceStatus(status: string) {
    const invoiceStatuses = {
      [statuses.paid]: "Paid"
    }

    return invoiceStatuses[status] || "Not Paid"
  }

  onInvoiceClick(invoice: VoterDataInvoice) {
    if (!invoice.hosted_invoice_url) {
      return
    }

    window.open(invoice.hosted_invoice_url, "_blank")
  }

  getValueInCurrency(value: number) {
    return `${this.$options.filters!.toCurrency(value || 0)}`
  }
}
