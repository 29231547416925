


































































import Component, { mixins } from "vue-class-component"
import { CampaignUtilsMixin } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component
export default class VoiceCampaignActions extends mixins(
  VueComponent,
  CampaignUtilsMixin
) {
  @Prop() voiceCampaign!: VoiceCampaign

  get isSuper() {
    return this.$store.getters.super
  }

  get isResubmitAvailable() {
    const isLocked =
      this.voiceCampaign.meta.reject && this.voiceCampaign.meta.reject.lock
    return (
      this.voiceCampaign.meta.status === VoiceCampaignStatus.rejected &&
      !isLocked
    )
  }

  get isPending() {
    return this.voiceCampaign.meta.status === VoiceCampaignStatus.pending
  }

  get isUnderReview() {
    return this.voiceCampaign.meta.status === VoiceCampaignStatus.underReview
  }
}
