<template>
  <div
    class="modal-card is-info purchase-number"
    style="max-width: max-content"
  >
    <b-loading :active.sync="loading" :is-full-page="false" />
    <header class="modal-card-head">
      <p class="modal-card-title">Purchase Number</p>
    </header>
    <section class="modal-card-body">
      <div class="mb-md">
        <b-field position="is-centered">
          <b-input
            maxlength="3"
            :has-counter="false"
            placeholder="Area Code"
            v-model="areaCode"
          ></b-input>
          <p class="control">
            <button @click="getAvailableNumbers()" class="button is-primary">
              Search
            </button>
          </p>
        </b-field>
      </div>
      <div class="hr-line has-padding-tb"></div>
      <div
        v-for="item in availableNumbers"
        :key="item.phoneNumber"
        class="columns is-centered"
      >
        <div class="column mb-sm">
          <strong>{{ item.friendlyName }}</strong>
        </div>
        <div class="mr-sm mb-sm">
          <b-button
            :disabled="loading"
            @click="purchasePhoneConfirm(item.phoneNumber)"
            type="is-success"
          >
            Purchase
          </b-button>
        </div>
      </div>
      <b-message v-if="!availableNumbers.length && !loading" type="is-info">
        No Callback Numbers available in this area code.
      </b-message>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-danger" @click="$parent.close()">Close</button>
    </footer>
  </div>
</template>
<script>
import axios from "axios"
import { get } from "lodash"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"

export default {
  name: "PurchasePhoneNumber",
  props: ["org"],
  data() {
    return {
      areaCode: null,
      availableNumbers: [],
      loading: true
    }
  },
  created() {
    this.getAvailableNumbers()
  },
  methods: {
    async getAvailableNumbers() {
      try {
        this.loading = true
        const { data } = await axios.get(
          "organizations/" + this.org + "/phones-available",
          { params: { areaCode: this.areaCode } }
        )
        this.availableNumbers = data
      } catch (e) {
        this.$buefy.toast.open({
          message: "Failed loading phone numbers.",
          type: "is-danger"
        })
      } finally {
        this.loading = false
      }
    },
    purchasePhoneConfirm(num) {
      this.$buefy.dialog.confirm({
        message: "Purchase? You will be subscribed for $50/mo.",
        trapFocus: true,
        onConfirm: () => this.purchasePhone(num)
      })
    },
    async purchasePhone(phoneNumber) {
      try {
        this.loading = true
        const { data } = await PhoneNumberDao.createPhone(this.org, {
          phoneNumber
        })
        this.$buefy.toast.open({
          duration: 3000,
          message: "Callback Number Subscription Activated",
          position: "is-bottom",
          type: "is-success"
        })
        this.$emit("on-purchase")
        await PhoneNumberDao.enableNotify(this.org, data.uuid)
        this.$buefy.toast.open({
          duration: 3000,
          message: "Mailing List Enabled",
          position: "is-bottom",
          type: "is-success"
        })
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message:
            get(e, "response.data.message") || "Error during purchase phone",
          position: "is-bottom",
          type: "is-danger"
        })
      } finally {
        this.loading = false
        this.$parent.close()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.modal-card.purchase-number {
  min-width: 325px;
  margin: 0 auto;
}
</style>
