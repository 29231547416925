


















import Component from "vue-class-component"
import { Invoice } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import VoiceCampaignTotalInfo from "@/components/report/voiceCampaign/VoiceCampaignTotalInfo.vue"
import { VoiceCampaignStatsLive } from "@/interfaces/voiceCampaign/voiceCampaignStatsLive"
import { VoiceCampaignStatsDetail } from "@/interfaces/voiceCampaign/voiceCampaignStatsDetail"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import { VoiceCampaignService } from "@/services/voiceCampaignService"
import VoiceCampaignTotalDiagrams from "@/components/report/voiceCampaign/VoiceCampaignTotalDiagrams.vue"

@Component({
  components: {
    VoiceCampaignTotalDiagrams,
    VoiceCampaignTotalInfo
  }
})
export default class VoiceCampaignTotal extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() waiting!: boolean
  @Prop() invoice!: Invoice
  @Prop() liveStats!: VoiceCampaignStatsLive
  @Prop() statsDetail!: VoiceCampaignStatsDetail

  active: { label: string; value: number }[] | null = null
  result: { label: string; value: number; perc: string }[] | null = null
  voiceCampaignCost: {
    campaign: number
    invoice: number
    difference: number
  } = {
    campaign: 0,
    invoice: 0,
    difference: 0
  }
  callService = new VoiceCampaignService()

  created() {
    if (this.liveStats) {
      this.prepareActiveStats()
    }

    if (this.statsDetail) {
      this.prepareResultStats()
    }

    if (this.statsDetail && this.invoice) {
      this.prepareCallCost()
    }
  }

  prepareActiveStats() {
    const active = [
      {
        label: "Total Leads",
        value: this.liveStats?.total_dials
      },
      {
        label: "Ringing",
        value: this.liveStats?.ringing
      },
      {
        label: "Connected",
        value: this.liveStats?.connected
      },
      {
        label: "Remaining Leads",
        value: this.liveStats?.remaining_leads
      }
    ]

    if (this.voiceCampaign.type === VoiceCampaignType.transfer) {
      active.push({
        label: "Transferred",
        value: this.liveStats.transfer_attempts
      })
    }

    this.active = active
  }

  prepareResultStats() {
    const result = []
    const stats: any = this.callService.calculateCallStats(this.statsDetail)

    result.push({
      label: "Total Dials",
      value: stats?.total_dials.value,
      perc: stats?.total_dials.perc
    })

    if (this.voiceCampaign.type === VoiceCampaignType.rvm) {
      result.push({
        label: "Busy",
        value: stats?.busy.value,
        perc: stats?.busy.perc
      })
      result.push({
        label: "Played",
        value: stats?.played.value,
        perc: stats?.played.perc
      })
      result.push({
        label: "Unavailable",
        value: stats?.unavailable.value,
        perc: stats?.unavailable.perc
      })
      result.push({
        label: "Error",
        value: stats?.error.value,
        perc: stats?.error.perc
      })

      this.result = result

      return
    }

    result.push({
      label: "Live Answer",
      value: stats?.answer.value,
      perc: stats?.answer.perc
    })
    result.push({
      label: "Machine",
      value: stats?.machine.value,
      perc: stats?.machine.perc
    })
    result.push({
      label: "Busy",
      value: stats?.busy.value,
      perc: stats?.busy.perc
    })
    result.push({
      label: "No Answer",
      value: this.calculateNoAnswer(stats).value,
      perc: this.calculateNoAnswer(stats).perc
    })

    if (this.voiceCampaign.type === VoiceCampaignType.transfer) {
      result.push({
        label: "Transferred",
        value: stats?.transfers.value,
        perc: stats?.transfers.perc
      })
    }

    this.result = result
  }

  prepareCallCost() {
    if (!this.statsDetail || !this.invoice) {
      return
    }

    const campaign = this.callService.calculateCallCost(this.statsDetail)
    const invoice = parseInt(String(this.invoice.amount_due) || "0", 10) / 100

    const difference = invoice - campaign

    this.voiceCampaignCost = {
      campaign,
      invoice,
      difference
    }
  }

  calculateNoAnswer(stats: any) {
    return {
      value:
        parseFloat(stats?.congestion.value) +
        parseFloat(stats?.no_answer.value) +
        parseFloat(stats?.other.value),
      perc:
        parseFloat(stats?.congestion.perc) +
        parseFloat(stats?.no_answer.perc) +
        parseFloat(stats?.other.perc)
    }
  }

  @Watch("liveStats", { deep: true })
  watchLiveStats() {
    this.prepareActiveStats()
  }

  @Watch("statsDetail", { deep: true })
  watchStatsDetail() {
    this.prepareResultStats()
    this.prepareCallCost()
  }

  @Watch("invoice", { deep: true })
  watchInvoice() {
    this.prepareCallCost()
  }
}
