

























































import Component from "vue-class-component"
import { ScriptDao } from "@/services/dao/scriptDao"
import AddNewScriptModal from "@/components/modals/AddNewScriptModal.vue"
import Card from "@/components/elements/Card.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import ScriptsMixin from "@/mixins/ScriptsMixin"
import { ScriptStatus } from "@robocent/components"
import CardItem from "@/components/elements/CardItem.vue"
import ScriptsEmptyMessage from "@/components/scripts/ScriptsEmptyMessage.vue"
import ScriptItem from "@/components/scripts/ScriptItem.vue"

@Component({
  components: {
    CardItem,
    SearchItem,
    Card,
    PaginationItem,
    ScriptsEmptyMessage,
    ScriptItem
  }
})
export default class OrganizationScripts extends ScriptsMixin {
  status: ScriptStatus[] = Object.keys(ScriptStatus) as ScriptStatus[]

  created() {
    this.getScripts()
  }

  get orgId() {
    return this.$route.params.orgId
  }

  async getScripts() {
    await this.request(async () => {
      const { data, total, pricing } = (
        await ScriptDao.getScripts(this.$route.params.orgId, {
          q: this.search,
          page: this.pagination.current,
          limit: this.pagination.perPage,
          status: this.status
        })
      ).data
      this.pagination.total = total
      this.scripts = data
      this.pricing = pricing
    })
  }

  onAddScript() {
    this.$buefy.modal.open({
      parent: this,
      component: AddNewScriptModal,
      width: 950,
      props: {
        organizationId: this.$route.params.orgId
      }
    })
  }
}
