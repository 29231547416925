import { render, staticRenderFns } from "./InviteAgentModal.vue?vue&type=template&id=7844edf6&scoped=true&"
import script from "./InviteAgentModal.vue?vue&type=script&lang=ts&"
export * from "./InviteAgentModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7844edf6",
  null
  
)

export default component.exports