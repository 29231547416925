










import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import { Upload } from "@robocent/components"
import { Prop } from "vue-property-decorator"

@Component({})
export default class MmsPreview extends VueComponent {
  @Prop() upload!: Upload
  @Prop() customClass!: string
  @Prop() isRounded!: boolean
}
