











































import Component from "vue-class-component"
import { CardTable, CardTableItem } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import moment from "moment"
import { voiceCampaignTypes } from "@/data/voiceCampaign/voiceCampaignTypes"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  }
})
export default class VoiceCampaignGeneralInfo extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
  @Prop() waiting!: boolean

  get voiceCampaignTypeName() {
    return voiceCampaignTypes[this.voiceCampaign.type]
  }

  get formattedCampaignDate() {
    return moment
      .utc(this.voiceCampaign.time)
      .tz(this.voiceCampaign.timezone)
      .format("dddd, MMMM Do, YYYY h:mm A")
  }
}
