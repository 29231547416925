import { InvoiceItem } from "@/interfaces/invoiceItem"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import Component from "vue-class-component"

@Component
export default class InvoiceItemMixin extends VueComponent {
  @Prop({ required: false })
  invoiceItemList!: InvoiceItem[]

  invoiceItemTemplate: InvoiceItem = {
    description: "",
    amount: null
  }

  get isInvoiceItemTemplateValuesExists() {
    const { description, amount } = this.invoiceItemTemplate

    return !!description && !!amount
  }

  addInvoiceItemToList() {
    if (!this.validateInvoiceItem(this.invoiceItemTemplate)) {
      return
    }

    this.$emit("addInvoiceItemToList", this.invoiceItemTemplate)
    this.resetInvoiceItemTemplate()
  }

  removeInvoiceItemFromList(index: number) {
    this.$emit("removeInvoiceItemFromList", index)
  }

  validateInvoiceItem({ description, amount }: InvoiceItem) {
    return (
      description &&
      amount &&
      !isNaN(amount) &&
      !this.isDescriptionExists(description)
    )
  }

  isDescriptionExists(str: string) {
    return this.invoiceItemList.some(({ description }) => description === str)
  }

  resetInvoiceItemTemplate() {
    this.invoiceItemTemplate = {
      description: "",
      amount: null
    }
  }
}
