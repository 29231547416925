






























import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import PieChart from "@/components/elements/PieChart"
import HorizontalBarChart from "@/components/elements/HorizontalBarChart"
import { ChartDataSets } from "chart.js"
import VoiceCampaignPieChart from "@/components/elements/voiceCampaign/VoiceCampaignPieChart"

@Component({
  components: {
    VoiceCampaignPieChart,
    HorizontalBarChart,
    PieChart,
    Card
  }
})
export default class VoiceCampaignTotalDiagrams extends VueComponent {
  @Prop() stats!: { label: string; value: number; perc: string }[]
  @Prop() waiting!: boolean

  activeTab = null

  barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    }
  }

  pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "left"
    }
  }

  data: { labels: string[]; datasets: ChartDataSets[] } | null = null

  created() {
    this.prepareChart()
  }

  prepareChart() {
    const chartData: { labels: string[]; datasets: ChartDataSets[] } = {
      labels: [],
      datasets: [
        {
          label: "",
          borderWidth: 1,
          backgroundColor: [
            "rgba(135, 187, 162, 0.5)",
            "rgba(59, 96, 100, 0.5)",
            "rgba(238, 184, 104, 0.5)",
            "rgba(239, 118, 122, 0.5)",
            "rgba(86, 102, 107, 0.5)"
          ],
          borderColor: [
            "rgba(135, 187, 162, 0.7)",
            "rgba(59, 96, 100, 0.7)",
            "rgba(238, 184, 104, 0.7)",
            "rgba(239, 118, 122, 0.7)",
            "rgba(86, 102, 107, 0.7)"
          ],
          data: []
        }
      ]
    }
    this.stats
      .filter(({ label }) => !this.skipItem(label))
      .forEach(({ label, value, perc }) => {
        chartData!.labels.push(label)

        // Percentages
        if (this.activeTab === 1) {
          chartData!.datasets[0].data!.push(parseFloat(perc))
        } else {
          chartData!.datasets[0].data!.push(value)
        }
      })

    this.data = chartData
  }

  skipItem(label: string) {
    return label === "Total Dials"
  }

  @Watch("stats", { deep: true })
  watchStats() {
    this.prepareChart()
  }
}
