















































































































































































import Component, { mixins } from "vue-class-component"
import {
  Campaign,
  CampaignDaysRemainingDetails,
  CampaignPhoneDetails,
  CampaignScheduledDate,
  CampaignStatus,
  campaignStatuses,
  CampaignUtilsMixin,
  IdentityStatus,
  Invoice,
  ListCard,
  statuses
} from "@robocent/components"
import { Prop, Watch } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { EventBus } from "@/services/eventBus"
import { LocationService } from "@/services/locationService"
import CampaignScriptsNotifications from "@/components/campaign/CampaignScriptsNotifications.vue"
import CampaignItemsDropdown from "@/components/campaign/TextCampaignActionsDropdown.vue"
import CampaignActionsDropdown from "@/components/campaign/TextCampaignActionsDropdown.vue"
import TextAdminActionsDropdown from "@/components/campaign/TextAdminActionsDropdown.vue"
import CampaignLinkToOrg from "@/components/campaign/CampaignLinkToOrg.vue"
import CampaignMixin from "@/mixins/campaign/CampaignMixin"
import TextCampaignInvoice from "@/components/campaign/TextCampaignInvoice.vue"
import InvoiceButton from "@/components/checkout/InvoiceButton.vue"
import InvoiceCreditNotes from "@/components/elements/InvoiceCreditNotes.vue"
import { JobAgentDao } from "@/services/dao/p2p/jobAgentDao"
import TextCampaignMessageConfirmationModal from "@/components/modals/textCampaign/TextCampaignMessageConfirmationModal.vue"
import CampaignInvoice from "@/components/elements/CampaignInvoice.vue"

interface CampaignExtended extends Campaign {
  uuid: string
  invoice: Invoice
  reasonForRefusal?: string
}

@Component({
  components: {
    CampaignInvoice,
    InvoiceCreditNotes,
    InvoiceButton,
    TextCampaignInvoice,
    CampaignLinkToOrg,
    TextAdminActionsDropdown,
    CampaignActionsDropdown,
    CampaignItemsDropdown,
    CampaignScriptsNotifications,
    CampaignPhoneDetails,
    ListCard,
    CampaignDaysRemainingDetails,
    CampaignScheduledDate
  }
})
export default class TextCampaignItem extends mixins(
  CampaignUtilsMixin,
  CampaignMixin,
  VueComponent
) {
  @Prop() campaign!: CampaignExtended
  campaignStatus = CampaignStatus
  locationService = new LocationService()

  // just quick fix
  get localStatusName() {
    const name = this.getStatusName
    if (name === "Awaiting Account Registration") {
      return "Brand Info Required"
    }
    return name
  }

  get showAdminReview() {
    return [
      CampaignStatus.pending,
      CampaignStatus.waitingAccountRegistration
    ].includes(this.campaign.status)
  }

  get showStatus() {
    if (this.isCampaignInvoiceDraft) {
      return campaignStatuses.underReview
    }
    return this.localStatusName
  }

  get isSuper() {
    return this.$store.getters.super
  }

  get canShowInvoice() {
    return (
      this.isSuper || this.isCampaignInvoiceOpen || this.isCampaignInvoicePaid
    )
  }

  get isPending() {
    return this.campaign.status === CampaignStatus.pending
  }

  get isRejected() {
    return this.campaign.status === CampaignStatus.rejected
  }

  get isAgentPortalAvailable() {
    return !(
      this.statusBeforeActivation ||
      [
        CampaignStatus.pendingCancelation,
        CampaignStatus.canceled,
        CampaignStatus.closed
      ].includes(this.campaign.status)
    )
  }

  get isCampaignInvoiceOpen() {
    return this.campaign?.invoice?.status === statuses.open
  }

  get isCampaignInvoiceDraft() {
    return this.campaign?.invoice?.status === statuses.draft
  }

  get isCampaignInvoicePaid() {
    return this.campaign?.invoice?.status === statuses.paid
  }

  get isIdentitySubmitted() {
    return [IdentityStatus.submitted, "incomplete"].includes(
      this.campaign.organization?.identity?.status
    )
  }

  get isDisabled() {
    return this.campaign.deleted ? "disabled" : ""
  }

  created() {
    this.setCampaignStatusByAccount()
  }

  setCampaignStatusByAccount() {
    if (this.isIdentitySubmitted) {
      return
    }

    if (this.campaign.status === CampaignStatus.pending) {
      this.campaign.status = CampaignStatus.waitingAccountRegistration
    }
  }

  async goToAgentPortal() {
    await this.request(
      async () => {
        const { url } = await JobAgentDao.getAgentLoginLink()

        location.replace(url)
      },
      undefined,
      "Sorry, the link doesn't work. Please try again later.",
      "agentLink"
    )
  }

  onInvoiceChange() {
    return EventBus.getCampaigns$.next()
  }

  registerAccount() {
    if (this.isIdentitySubmitted) {
      return
    }

    window.open(this.campaign.organization?.identity.link, "_blank")
  }

  @Watch("campaign")
  watchIdentity() {
    this.setCampaignStatusByAccount()
  }

  async openTextCampaignConfirmationModal(campaign: Campaign) {
    this.$buefy.modal.open({
      parent: this,
      component: TextCampaignMessageConfirmationModal,
      width: 600,
      props: {
        campaign
      }
    })
  }
}
