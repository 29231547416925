


















import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import HorizontalBarChart from "../elements/HorizontalBarChart"
import BarChart from "../elements/BarChart"
import { CampaignAgentsStats } from "@/interfaces/campaignAgentsStats"

@Component({
  components: {
    HorizontalBarChart,
    BarChart,
    Card
  }
})
export default class AgentsDiagram extends VueComponent {
  @Prop() agentsStats!: CampaignAgentsStats[]
  @Prop() waiting!: boolean

  options = {
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true
        }
      ]
    }
  }

  chartData = {}

  get isMobile() {
    return this.$screen.sm
  }

  @Watch("agentsStats")
  onAgentsStatsChanged() {
    this.prepareData()
  }

  prepareData() {
    const chartData = {
      labels: [] as string[],
      datasets: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.5,
          label: "Conversations Created",
          backgroundColor: "rgba(135, 187, 162, 0.5)",
          data: [] as number[]
        },
        {
          barPercentage: 0.9,
          categoryPercentage: 0.5,
          label: "Conversations Ended",
          backgroundColor: "rgba(58, 137, 255, 0.5)",
          data: [] as number[]
        }
      ]
    }
    this.agentsStats?.map((agentStats: CampaignAgentsStats) => {
      const { firstName, lastName, email, internal } = agentStats.agent

      if (internal) {
        chartData.labels.push(`${firstName} ${lastName} / ${email}`)
      } else {
        chartData.labels.push(`${firstName} ${lastName}`)
      }
      chartData.datasets[0].data.push(agentStats.initial)
      chartData.datasets[1].data.push(agentStats.ended)
    })

    this.chartData = chartData
  }
}
