import axios from "axios"

export class NotificationsDao {
  static async shareCallByEmail(email: string, voiceCampaignId: string) {
    return await axios.post(
      `/notifications/calls/${voiceCampaignId}/share-by-email`,
      {
        email
      }
    )
  }

  static async shareUploadByEmail(email: string, uploadId: string) {
    return await axios.post(
      `/notifications/uploads/${uploadId}/share-by-email`,
      {
        email
      }
    )
  }

  static async shareUploadBySMS(phone: string, uploadId: string) {
    return await axios.post(`/notifications/uploads/${uploadId}/share-by-sms`, {
      phone
    })
  }
}
