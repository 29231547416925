import axios from "axios"
import { StringObject } from "@robocent/components"
import { cancelSource } from "@/config/axios"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { VoterDataPricing } from "@/voterData/interfaces/VoterDataPricing"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"
import { VoterDataQuery } from "@/voterData/interfaces/VoterDataQuery"

export class VoterDataDao {
  static async submitQuery(state: string, fields: object) {
    return axios.post(
      `/voter-data/submit-query`,
      {
        state,
        fields
      },
      {
        cancelToken: cancelSource.token
      }
    )
  }

  static async queryBreakout(
    queryId: string,
    type: string,
    fields: string
  ): Promise<number> {
    return (
      await axios.post(
        `/voter-data/query-breakout`,
        {
          queryId,
          type,
          fields
        },
        {
          cancelToken: cancelSource.token
        }
      )
    ).data
  }

  static async getStepData(state: string, field: string): Promise<FieldInfo[]> {
    return (await axios.get(`/voter-data/${state}/fields/${field}`)).data
  }

  static async submitDataRequest(data: {
    userId: string
    query: StringObject
    queryId: string
    recordsAmount: object
    fileName: string
  }) {
    return axios.post(
      `/voter-data/organizations/${data.query.orgId}/submit-request`,
      data
    )
  }

  static async getAllRequests(
    params: FindManyFilter
  ): Promise<{ data: VoterDataQuery[]; total: number }> {
    return (await axios.get(`/voter-data/requests`, { params })).data
  }

  static async deleteRequest(orgId: string, id: number) {
    return axios.put(`/voter-data/organizations/${orgId}/requests/${id}`, {
      deleted: true
    })
  }

  static async purchaseData(orgId: string, requestIds: number[]) {
    return axios.post(`/voter-data/organizations/${orgId}/purchase-data`, {
      requestIds
    })
  }

  static async getPricing(): Promise<VoterDataPricing> {
    return (await axios.get(`/voter-data/pricing`)).data
  }
}
