export const TextCampaignMultis = {
  // TODO refactor in v14
  baseSegmentCost: (isRegistered: boolean) =>
    isRegistered ? "Registered SMS" : "Unregistered SMS",
  additionalSegmentCost: "Additional Segment(s)",
  managedCost: "Managed",
  mmsCost: (isRegistered: boolean) =>
    isRegistered ? "Registered MMS" : "Unregistered MMS",
  carrierFee: "Carrier Fee"
}
