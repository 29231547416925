export class LocationService {
  goToAgentUI(path = "") {
    const url = this.getUrl(path)

    if (url) {
      window.open(url, "_blank")
    }
  }

  replaceToAgentUI(path = "") {
    const url = this.getUrl(path)

    if (url) {
      window.open(url, "_self")
    }
  }

  getUrl(path = "") {
    const isProd = location.hostname.includes("app")
    const isDev = location.hostname.includes("dev")
    const isLocalhost = location.hostname.includes("localhost")
    let url = null

    if (isProd) {
      url = "https://agent.robocent.com/"
    }

    if (isDev) {
      url = "https://agent-dev.robocent.com/"
    }

    if (isLocalhost) {
      url = "http://localhost:8087/"
    }

    url += path

    return url
  }

  refreshPage() {
    location.reload()
  }
}
