





















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"

@Component
export default class CollapseCard extends VueComponent {
  @Prop() title!: string
  @Prop({ default: false }) open!: boolean
}
