




















import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { CardTable, CardTableItem } from "@robocent/components"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"

@Component({
  components: {
    CardTable,
    CardTableItem,
    Card
  }
})
export default class TextCampaignCostBasis extends VueComponent {
  @Prop() waiting!: boolean
  @Prop() statsDetail!: any
  @Prop() invoice!: Invoice

  get textCampaignCost() {
    if (!this.statsDetail || !this.invoice) {
      return {
        campaign: 0,
        invoice: 0,
        difference: 0
      }
    }

    const campaign = this.statsDetail?.total_cost || 0
    const invoice = parseInt(String(this.invoice.amount_due) || "0", 10) / 100

    const difference = invoice - campaign

    return {
      campaign,
      invoice,
      difference
    }
  }
}
