import axios from "axios"
import { PhoneList } from "@robocent/components"

export class PhoneListDao {
  static async getPhoneLists(
    orgId: string,
    params: object
  ): Promise<{ data: PhoneList[]; total: number }> {
    return (await axios.get(`/organizations/${orgId}/uploads`, { params })).data
  }

  static async createPhoneList(orgId: string, upload: FormData) {
    return await axios.post(`/organizations/${orgId}/uploads/`, upload)
  }

  static async getPhoneList(orgId: string, phoneListId: string) {
    return await axios.get(`/organizations/${orgId}/uploads/${phoneListId}`)
  }
}
