



























import { Component, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import moment from "moment"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: { ChooseItem }
})
export default class TextCampaignStatusSubmitted extends VueComponent {
  @Prop() start!: string
  @Prop() timezone!: string
  @Prop() time!: any

  get campaignStartDate() {
    const startDayOfWeek = this.getDayOfWeek(this.start)
    const dateTime = moment(`${this.start} ${this.time[startDayOfWeek].start}`)

    return `${dateTime.format("dddd, MMMM Do, YYYY h:mm A")}`
  }

  getDayOfWeek(date: string) {
    return moment(date).format("dddd").toLowerCase()
  }
}
