






















































import Component from "vue-class-component"
import { statuses } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import CampaignPaymentTypeTag from "@/components/elements/voiceCampaign/CampaignPaymentTypeTag.vue"
import { voiceCampaignTypes } from "@/data/voiceCampaign/voiceCampaignTypes"

@Component({
  components: { CampaignPaymentTypeTag }
})
export default class VoiceCampaignInfoTags extends VueComponent {
  statuses = statuses
  @Prop() voiceCampaign!: VoiceCampaign

  get voiceCampaignTypeName() {
    return voiceCampaignTypes[this.voiceCampaign.type]
  }

  get isAutoPayEnabled() {
    return (
      this.voiceCampaign?.invoice?.collection_method ===
        "charge_automatically" ||
      this.voiceCampaign.organization.meta.autoPayEnabled
    )
  }
}
