



















































import Component from "vue-class-component"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import {
  InputWithValidation,
  PhoneNumberWithValidation
} from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { Ref } from "vue-property-decorator"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"

@Component({
  components: {
    ChooseRow,
    InputWithValidation,
    ValidationObserver,
    PhoneNumberWithValidation
  }
})
export default class VoiceCampaignTransferOptions extends VoiceSchedulerUtilsMixin {
  @Ref("transfer-info-observer") observer!: any

  async confirm() {
    if (await this.observer.validate()) {
      this.next(VoiceSchedulerSteps.campaignDate)
    }
  }
}
