import { Subject } from "rxjs"
import { Organization, Script } from "@robocent/components"
import { Location } from "vue-router/types/router"

export class EventBus {
  static getOrganizations$ = new Subject<Location | null>()
  static organizationChanged$ = new Subject()
  static organizationCreated$ = new Subject<Organization | null>()
  static getAgents$ = new Subject()
  static getInvitedAgents$ = new Subject()
  static getDeletedAgents$ = new Subject()
  static setPhoneList$ = new Subject()
  static getCampaigns$ = new Subject()
  static getScripts$ = new Subject<Script | null>()
  static getReports$ = new Subject()
  static getBalance$ = new Subject()
  static getAgentsPayouts$ = new Subject()
  static inviteUserModal$ = new Subject()
  static getUsers$ = new Subject()
  static startScrubPhoneList$ = new Subject()
  static getEstimatedCost$ = new Subject()
  static getPhoneNumbers$ = new Subject()
  static getUploads$ = new Subject()
  static getCampaign$ = new Subject()
  static getFeatures$ = new Subject()
  static approveCampaign$ = new Subject()
  static getVoterDataRequests$ = new Subject()
  static getPartnersPayouts$ = new Subject()
  static getSalesPayouts$ = new Subject()
}
