









































import { InputWithValidation } from "@robocent/components"
import Component from "vue-class-component"
import { Emit, Prop, Ref } from "vue-property-decorator"
import ChooseRow from "./ChooseRow.vue"
import { ValidationObserver } from "vee-validate"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"

@Component({
  components: { ChooseRow, InputWithValidation, ValidationObserver }
})
export default class CampaignName extends SchedulerUtilsMixin {
  @Ref("observer") observer!: any
  @Prop() isCampaignUpdate!: boolean

  async confirm() {
    if (!this.organizationId) {
      return this.openToast("Please select organization")
    }

    if (await this.observer.validate()) {
      this.nextStep()
    }
  }

  @Emit()
  nextStep() {}
}
