






























import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import Vue from "vue"

@Component
export default class StepTemplate extends Vue {
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop() loading!: boolean
  @Prop() descriptions!: string[]
}
