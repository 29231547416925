





























































import Component from "vue-class-component"
import { GenericFilter, Script } from "@robocent/components"
import { ScriptDao } from "@/services/dao/scriptDao"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import Card from "@/components/elements/Card.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import ApproveScriptModal from "@/components/modals/ApproveScriptModal.vue"
import RejectScriptModal from "@/components/modals/RejectScriptModal.vue"
import ScriptsMixin from "@/mixins/ScriptsMixin"
import CardItem from "@/components/elements/CardItem.vue"
import ScriptItem from "@/components/scripts/ScriptItem.vue"

@Component({
  components: {
    CardItem,
    SearchItem,
    Card,
    PaginationItem,
    GenericFilter,
    ScriptItem
  }
})
export default class Scripts extends ScriptsMixin {
  created() {
    this.getScripts()
  }

  async getScripts() {
    await this.request(async () => {
      const { data, total, pricing } = (
        await ScriptDao.getAllScripts({
          q: this.search,
          page: this.pagination.current,
          limit: this.pagination.perPage,
          status: this.status
        })
      ).data
      this.pagination.total = total
      this.scripts = data
      this.pricing = pricing
    })
  }

  approveScriptModal(script: Script) {
    this.$buefy.modal.open({
      parent: this,
      component: ApproveScriptModal,
      width: 600,
      props: {
        script
      },
      events: {
        changed: this.getScripts
      }
    })
  }

  rejectScriptModal(script: Script) {
    this.$buefy.modal.open({
      parent: this,
      component: RejectScriptModal,
      width: 600,
      props: {
        script
      },
      events: {
        changed: this.getScripts
      }
    })
  }
}
