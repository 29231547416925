


































import Component, { mixins } from "vue-class-component"
import OrganizationStatusMixin from "@/mixins/organization/OrganizationStatusMixin"
import { links } from "@/data/links"
import { OrganizationVertical } from "@robocent/components"

@Component({})
export default class BusinessNotRegistered extends mixins(
  OrganizationStatusMixin
) {
  docsLinks = links
  organizationVertical = OrganizationVertical

  get typeByStatus() {
    if (
      this.isIncomplete(this.organizationIdentity) ||
      this.isUnsubmitted(this.organizationIdentity.status)
    ) {
      return "is-primary"
    } else if (this.isSubmitted(this.organizationIdentity.status)) {
      return "is-success"
    }
    return ""
  }

  get organizationIdentity() {
    return this.$store.getters.organization?.identity
  }

  get orgId() {
    return this.$route.params.orgId
  }

  goToCarrierRegistration() {
    window.open(`${process.env.VUE_APP_LOGOUT_URL}/10dlc`, "_self")
  }

  goTo10DLC() {
    window.open(this.docsLinks.registration10DLC, "_blank")
  }
}
