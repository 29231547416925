















import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import Vue from "vue"

@Component
export default class ChooseRow extends Vue {
  @Prop() title!: string
  @Prop() border!: boolean
  @Prop({ default: false }) loading!: boolean
}
