


























import Component from "vue-class-component"
import OrganizationSettings from "@/views/organization/OrganizationSettings.vue"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"
import { NavigationGuardNext, Route } from "vue-router/types/router"
import ReviewVoiceCampaign from "@/components/campaign/voiceAdminReview/ReviewVoiceCampaign.vue"
import VoiceCampaignReviewHeader from "@/components/campaign/voiceAdminReview/VoiceCampaignReviewHeader.vue"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import OverviewCampaign from "./OverviewCampaign.vue"
import ConfigurePhoneList from "@/components/campaign/voiceAdminReview/configure/ConfigurePhoneList.vue"
import ConfigureAudio from "@/components/campaign/voiceAdminReview/configure/ConfigureAudio.vue"

Component.registerHooks(["beforeRouteLeave", "beforeDestroy"])

@Component({
  components: {
    ConfigureAudio,
    VoiceCampaignReviewHeader,
    ReviewVoiceCampaign,
    OrganizationSettings,
    OverviewCampaign,
    ConfigurePhoneList
  }
})
export default class VoiceCampaignAdminReview extends OverviewMixin {
  get isApproveAvailable() {
    return (
      this.campaign?.meta.status === VoiceCampaignStatus.pending ||
      this.campaign?.meta.status === VoiceCampaignStatus.underReview
    )
  }

  get orgId() {
    return this.$route.params.orgId
  }

  get campaignUUID() {
    return this.$route.params.campaignUUID
  }

  async created() {
    await this.$store.commit("voiceConfigurator/clearCampaign")
    await this.getCampaign()
    await this.lockCampaign(true)
  }

  beforeDestroy() {
    this.$store.commit("voiceConfigurator/clearCampaign")
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    await this.lockCampaign()
    next()
  }

  async getCampaign() {
    await this.$store.dispatch("voiceConfigurator/updateCampaign", {
      orgId: this.orgId,
      callId: this.campaignUUID
    })
  }

  async lockCampaign(lock = false) {
    if (!this.isApproveAvailable) return

    if (lock) {
      return await CampaignConfigureDao.lockCall(this.campaignUUID)
    }

    return await CampaignConfigureDao.unlockCall(this.campaignUUID)
  }
}
