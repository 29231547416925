


























































































import {
  DatePickerWithValidation,
  TimePickerWithValidation,
  timezones
} from "@robocent/components"
import moment from "moment"
import Component, { mixins } from "vue-class-component"
import ChooseRow from "../elements/ChooseRow.vue"
import CampaignDateMixin from "@/mixins/campaign/CampaignDateMixin"
import { Ref, Watch } from "vue-property-decorator"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"

@Component({
  components: {
    ChooseRow,
    TimePickerWithValidation,
    DatePickerWithValidation
  }
})
export default class VoiceCampaignDate extends mixins(
  VoiceSchedulerUtilsMixin,
  CampaignDateMixin
) {
  @Ref("date-observer") observer!: any
  maxDate = moment(new Date()).add({ months: 3 })
  zones = timezones
  datetime = new Date()
  rush = false

  created() {
    this.setTime()
    this.validateTime()
  }

  get date() {
    return moment(this.datetime).format("YYYY-MM-DD")
  }

  set date(date) {
    this.datetime = new Date(`${date}T${this.time}:00`)
  }

  get time() {
    return moment(this.datetime).format("HH:mm")
  }

  set time(time) {
    this.datetime = new Date(`${this.date}T${time}:00`)
  }

  get unlimitedTime() {
    return this.isSuper
  }

  get formattedDate() {
    return `${this.date}T${this.time}:00`
  }

  setTime() {
    if (!this.campaign.time) {
      this.setMinTime()
    } else {
      this.datetime = new Date(
        this.formattedTimeJS(
          moment.utc(this.campaign.time).toDate(),
          this.campaign.timezone
        )
      )
    }
  }

  setMinTime() {
    this.calculateMinTime(this.campaign.timezone, this.formattedDate)
    this.datetime = this.minTime as Date
  }

  validateTime() {
    const result = this.validatePicker(
      this.date,
      this.time,
      this.campaign.timezone
    )
    this.checkRush()
    return result
  }

  async nextStep() {
    if (!this.validateTime()) {
      return this.openToast("Please select correct time")
    }
    this.next(VoiceSchedulerSteps.campaignPhoneLists)
  }

  // TODO: Add tests
  checkRush() {
    const callType = this.campaign.type
    this.rush = false
    const datetime = this.formattedDate
    const dateTimezoneUTC = moment(
      this.getTimeInTimezone(datetime, this.campaign.timezone)
    ).tz("UTC")
    const createdAtTimeUTC = moment.utc(this.campaign.createdAt || moment())

    const duration = moment
      .duration(dateTimezoneUTC.diff(createdAtTimeUTC))
      .asHours()

    if (callType && callType === VoiceCampaignType.survey) {
      if (duration <= 4 && duration >= 0) {
        this.rush = true
      }
    } else {
      if (duration <= 2 && duration >= 0) {
        this.rush = true
      }
    }
  }

  @Watch("campaign.timezone")
  watchTimezone(nextVal: string) {
    this.calculateMinTime(nextVal, this.formattedDate)
    this.validateTime()
  }

  @Watch("datetime")
  @Watch("campaign.timezone")
  watchDatetime() {
    const scheduledTime = moment.tz(this.formattedDate, this.campaign.timezone)
    this.campaign.time = scheduledTime.tz("UTC").format("YYYY-MM-DDTHH:mm:ss")
    this.calculateMinTime(this.campaign.timezone, this.formattedDate)
  }
}
