













import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import _ from "lodash"

@Component
export default class SearchItem extends VueComponent {
  @Prop() value!: string
  search: string | null = null

  mounted() {
    if (this.value) {
      this.loading = true
      this.search = this.value
      this.$nextTick(() => (this.loading = false))
    }
  }

  @Watch("search")
  onChange(search: string | null) {
    if (!this.loading) {
      this.emitChange(search)
    }
  }

  @Watch("value")
  valueChanged(value: string | null) {
    this.search = value
  }

  emitChange = _.debounce((search) => {
    this.$emit("on-change", search)
  }, 300)
}
