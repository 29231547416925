

















import Component from "vue-class-component"
import IntendedUse from "@/voterData/components/IntendedUse.vue"
import AuthorizationToPurchase from "@/voterData/components/AuthorizationToPurchase.vue"
import TosAcceptance from "@/voterData/components/TosAcceptance.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    TosAcceptance,
    AuthorizationToPurchase,
    IntendedUse
  }
})
export default class LegalComplianceStep extends VueComponent {}
