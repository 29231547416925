









import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  props: ["value", "successIcon"],
  filters: {
    icon(bool: boolean, successIcon = "check-circle") {
      return bool ? successIcon : "times-circle"
    },
    type(bool: boolean) {
      return bool ? "is-success" : "is-danger"
    }
  }
})
export default class CheckIcon extends VueComponent {}
