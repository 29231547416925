import axios from "axios"
import { CampaignPricing, Customer } from "@robocent/components"

export class SmsBillingDao {
  static async getCustomer(orgId: string): Promise<Customer> {
    return (await axios.get(`/text/organizations/${orgId}/billing`)).data
  }

  static async getPricing(orgId: string): Promise<CampaignPricing> {
    return (await axios.get(`/text/organizations/${orgId}/pricing`)).data
  }

  static async updatePricing(
    orgId: string,
    rates: CampaignPricing
  ): Promise<CampaignPricing> {
    return (await axios.post(`/text/organizations/${orgId}/pricing`, rates))
      .data
  }

  static async getSession(orgId: string, url: string) {
    return await axios.post(`/text/organizations/${orgId}/session`, { url })
  }

  static async adjustAccountBalance(orgId: string, amount: number) {
    return await axios.post(`/text/organizations/${orgId}/account-balance`, {
      amount
    })
  }

  static async deleteTextCampaignInvoice(orgId: string, invoiceId: string) {
    return await axios.delete(
      `/text/organizations/${orgId}/invoices/${invoiceId}`
    )
  }
}
