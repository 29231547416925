import {
  Agent,
  Campaign,
  CampaignStatus,
  InvitedAgent,
  Organization,
  Script,
  StringObject
} from "@robocent/components"
import axios from "axios"
import { EstimatedCost } from "@/interfaces/estimatedCost"
import { RefundAmount } from "@/enums/refundAmount"
import { RefundTypes } from "@/enums/refundTypes"
import { ApproveTextCampaign } from "@/interfaces/approveTextCampaign"

export class CampaignDao {
  static async createCampaign(campaign: Campaign): Promise<Campaign> {
    return (
      await axios.post(
        `/text/organizations/${campaign.organizationId}/campaigns`,
        campaign
      )
    ).data
  }

  static async getCampaigns(orgId: string, params?: StringObject) {
    return (
      await axios.get(`/text/organizations/${orgId}/campaigns`, { params })
    ).data
  }

  static async getAllCampaigns(orgId: string, params?: StringObject) {
    return (
      await axios.get(`/text/organizations/${orgId}/campaigns-all`, { params })
    ).data
  }

  static async getCampaign(
    orgId: string,
    campaignUUID: string
  ): Promise<Campaign & { organization: Organization }> {
    return (
      await axios.get(`/text/organizations/${orgId}/campaigns/${campaignUUID}`)
    ).data
  }

  static async getCampaignSharedInfo(orgId: string, campaignUUID: string) {
    return await axios.get(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/shared`
    )
  }

  static async makeCampaignShared(
    orgId: string,
    campaignUUID: string,
    shared: boolean
  ) {
    return await axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/shared`,
      { shared }
    )
  }

  static async getCampaignAgents(
    orgId: string,
    campaignUUID: string
  ): Promise<{ id: number; agents: Agent[]; invitedAgents: InvitedAgent[] }> {
    return (
      await axios.get(
        `/text/organizations/${orgId}/campaigns/${campaignUUID}/agents`
      )
    ).data
  }

  static async updateCampaignScripts(
    orgId: string,
    campaignUUID: string,
    scripts: Script[]
  ) {
    return await axios.put(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/scripts`,
      {
        scripts
      }
    )
  }

  static async updateCampaign(
    orgId: string,
    campaignUUID: string,
    data: Campaign
  ) {
    return await axios.put(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}`,
      data
    )
  }

  static async updateCampaignAgents(
    orgId: string,
    campaignUUID: string,
    data: any
  ) {
    return (
      await axios.put(
        `/text/organizations/${orgId}/campaigns/${campaignUUID}/agents`,
        data
      )
    ).data
  }

  static async archiveCampaign(orgId: string, campaignUUID: string) {
    return await axios.put(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/archive`
    )
  }

  static async pauseCampaign(orgId: string, campaignUUID: string) {
    return await axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/pause`
    )
  }

  static async unpauseCampaign(orgId: string, campaignUUID: string) {
    return await axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/unpause`
    )
  }

  static async requestCampaignCancelation(orgId: string, campaignUUID: string) {
    return await axios.put(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/request-cancel`
    )
  }

  static async cancelCampaign(
    orgId: string,
    campaignUUID: string,
    refundType: RefundTypes,
    refundAmount: RefundAmount | null
  ) {
    return await axios.put(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/cancel`,
      {
        refundType,
        refundAmount
      }
    )
  }

  static async getEstimatedCampaignCost(
    orgId: string,
    campaign: Campaign
  ): Promise<EstimatedCost> {
    return (
      await axios.post(`/text/organizations/${orgId}/estimated-cost`, {
        campaign
      })
    ).data
  }

  static async notifyAgents(
    orgId: string,
    campaignUUID: string,
    message?: string
  ) {
    return await axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/notify-agents`,
      { message }
    )
  }

  static async checkIfMergedFieldsEnabled(
    orgId: string,
    campaign: Campaign
  ): Promise<boolean> {
    return (
      await axios.post(`/text/organizations/${orgId}/merged-fields`, {
        campaign
      })
    ).data
  }

  static async deleteCampaign(orgId: string, campaignUUID: string) {
    return await axios.delete(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}`
    )
  }

  static async getAllTextCampaigns(params?: StringObject) {
    return (await axios.get(`/text/admin/campaigns`, { params })).data
  }

  static async getAllAdminCampaigns(params?: StringObject) {
    return (await axios.get(`/text/admin/campaigns-all`, { params })).data
  }

  static async sendTestMessage(
    orgId: string,
    campaignUUID: string,
    phone: string
  ) {
    return await axios.post(
      `/text/organizations/${orgId}/campaigns/${campaignUUID}/test-message`,
      { phone }
    )
  }

  static async approveCampaign(
    campaignUUID: string,
    data: ApproveTextCampaign
  ) {
    return await axios.post(
      `/text/admin/campaigns/${campaignUUID}/approve`,
      data
    )
  }

  static async rejectCampaign(campaignUUID: string) {
    return await axios.post(`/text/admin/campaigns/${campaignUUID}/reject`)
  }

  static async deleteCampaignPermanently(campaignUUID: string) {
    return await axios.delete(`/text/admin/campaigns/${campaignUUID}`)
  }

  static async updateCampaignStatus(
    campaignUUID: string,
    status: CampaignStatus
  ) {
    return await axios.put(`/admin/campaigns/${campaignUUID}`, { status })
  }
}
