import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Campaign, CampaignStatus } from "@robocent/components"

@Component({})
export default class TextCampaignResultsMixin extends VueComponent {
  campaign: Campaign | null = null

  get isArchived() {
    return this.campaign?.status === CampaignStatus.archived
  }
}
