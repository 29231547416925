




























import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { Organization } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import BusinessNotRegistered from "@/components/organization/account/BusinessNotRegistered.vue"
import { OrganizationTab } from "@/enums/organizationTab"

@Component({
  components: { BusinessNotRegistered }
})
export default class OrganizationNotifications extends VueComponent {
  @Prop() organization!: Organization
  tabs = OrganizationTab

  get isOrganizationInfoCompleted() {
    return Boolean(this.organization.webPage)
  }

  get orgId() {
    return this.$route.params.orgId
  }
}
