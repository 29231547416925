import store from "../store"
import { NavigationGuardNext, Route } from "vue-router/types/router"

export function isSuper(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters.super) {
    return next("/404")
  }

  return next()
}

export function isPartner(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters.user?.app_metadata?.partner) {
    return next("/404")
  }

  return next()
}

export function isSalesManager(
  to: Route,
  from: Route,
  next: NavigationGuardNext
) {
  if (!store.getters.user?.app_metadata?.isSalesManager) {
    return next("/404")
  }

  return next()
}
