import { statuses } from "@robocent/components"

export function statusTypeFilter(property: string) {
  return {
    "is-danger": property === statuses.open || property === statuses.rejected,
    "is-success": property === statuses.paid,
    "is-warning":
      property === statuses.pendingCancel || property === statuses.pending,
    "is-dark":
      property === statuses.uncollectible ||
      property === statuses.void ||
      property === statuses.deleted,
    "is-light": property === statuses.draft || !property
  }
}
