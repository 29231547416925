















































import Component from "vue-class-component"
import { PhoneNumberWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { Prop, Ref } from "vue-property-decorator"
import Vue from "vue"

@Component({
  components: {
    PhoneNumberWithValidation,
    ValidationObserver
  }
})
export default class TestTextMessage extends Vue {
  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>
  @Prop() disabled!: boolean
  @Prop() loading!: boolean
  phone: string = null!

  sendTestMessage() {
    this.$emit("send", this.phone)
  }
}
