





















import { VueComponent } from "@/utils/VueComponent"
import { Component, Prop } from "vue-property-decorator"
import { OrganizationPartnerType } from "@/interfaces/partnerPortal/organizationPartnerType"
import { UserV2 } from "@robocent/components"

@Component
export default class CommissionTag extends VueComponent {
  @Prop() commission!: OrganizationPartnerType
  @Prop() partner!: UserV2
}
