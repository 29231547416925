


































































import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { ValidationObserver } from "vee-validate"
import {
  InputWithValidation,
  PhoneNumberWithValidation
} from "@robocent/components"
import { CampaignNotificationDao } from "@/services/dao/campaignNotificationDao"
import { Ref } from "vue-property-decorator"

@Component({
  components: {
    Card,
    ValidationObserver,
    InputWithValidation,
    PhoneNumberWithValidation
  }
})
export default class CampaignShareModal extends VueComponent {
  @Ref("emailObserver") emailObserver!: InstanceType<typeof ValidationObserver>
  @Ref("phoneObserver") phoneObserver!: InstanceType<typeof ValidationObserver>
  email: string | null = null
  phone: string | null = null

  async sendEmail() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        await CampaignNotificationDao.shareByEmail(
          orgId,
          campaignUUID,
          this.email!
        )
        this.email = null
        this.$nextTick(() => this.emailObserver.reset())
      },
      "Shared link was successfully sent via Email",
      undefined,
      "r1"
    )
  }

  async sendSms() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(
      async () => {
        await CampaignNotificationDao.shareBySMS(
          orgId,
          campaignUUID,
          this.phone!
        )
        this.phone = null
        this.$nextTick(() => this.phoneObserver.reset())
      },
      "Shared link was successfully sent via SMS",
      undefined,
      "r2"
    )
  }
}
