






























import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { Prop, Watch } from "vue-property-decorator"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import SalesManagerPayoutsTable from "@/components/salesPortal/SalesManagerPayoutsTable.vue"

@Component({
  components: {
    SalesManagerPayoutsTable,
    Card
  }
})
export default class SalesPayouts extends SalesPortalMixin {
  @Prop() isLoading!: boolean
  partnerPayoutStatuses = PartnerPayoutStatus
  mappedTabs: { [key: number]: PartnerPayoutStatus } = {
    0: PartnerPayoutStatus.open,
    1: PartnerPayoutStatus.paid
  }
  activeTab = 0

  @Watch("selectedOrg")
  async watchSelectedOrg() {
    this.activeTab = 0
    await this.onChange()
  }

  @Watch("activeTab")
  @Watch("selectedDate")
  async watchActiveTab() {
    await this.onChange()
  }

  onChange() {
    if (!this.selectedOrg) {
      this.payouts = []
      return
    }

    this.$emit("on-change", {
      date: this.selectedDate.toDateString(),
      status: this.mappedTabs[this.activeTab]
    })
  }
}
