import { RouteConfig } from "vue-router"
import EditVoiceCampaign from "@/views/campaign/voiceCampaign/EditVoiceCampaign.vue"
import CopyVoiceCampaign from "@/views/campaign/voiceCampaign/CopyVoiceCampaign.vue"
import VoiceCampaignResults from "@/views/campaign/voiceCampaign/VoiceCampaignResults.vue"
import ReSubmitVoiceCampaign from "@/views/campaign/voiceCampaign/ReSubmitVoiceCampaign.vue"
import VoiceCampaignAdminReview from "@/components/campaign/voiceAdminReview/VoiceCampaignAdminReview.vue"
import { isSuper } from "@/router/guards"

export const voiceCampaignRoutes: Array<RouteConfig> = [
  {
    path: "edit",
    name: "Edit Voice Campaign",
    component: EditVoiceCampaign
  },
  {
    path: "resubmit",
    name: "ReSubmit Voice Campaign",
    component: ReSubmitVoiceCampaign
  },
  {
    path: "copy",
    name: "ReRun Voice Campaign",
    component: CopyVoiceCampaign
  },
  {
    path: "results",
    name: "Voice Campaign Report",
    component: VoiceCampaignResults
  },
  {
    path: "voice-admin-review",
    beforeEnter: isSuper,
    name: "Voice Campaign Admin Review",
    component: VoiceCampaignAdminReview
  }
]
