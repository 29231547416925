





































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { Script } from "@robocent/components"
import ScriptItemBody from "@/components/scripts/ScriptItemBody.vue"
import Card from "@/components/elements/Card.vue"
import { ScriptDao } from "@/services/dao/scriptDao"

@Component({
  components: { ScriptItemBody, Card }
})
export default class RejectScriptModal extends VueComponent {
  @Prop() script!: Script
  comment: string = null!

  async onConfirm() {
    await this.request(async () => {
      await ScriptDao.rejectScript(this.script.id, this.comment)
      this.$emit("changed")
      this.$parent.close()
    })
  }
}
