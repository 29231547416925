













































































import { FileUtils } from "@/utils/fileUtils"
import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import {
  PhoneListType,
  PhoneListUtilsMixin,
  Upload
} from "@robocent/components"
import { UploadType } from "@/enums/uploadType"

@Component({})
export default class DownloadFile extends mixins(PhoneListUtilsMixin) {
  @Prop() upload!: Upload
  @Prop() signedUrls!: object

  get isList() {
    return (
      this.upload.type === PhoneListType.list ||
      this.upload.type === PhoneListType.voterData
    )
  }

  downloadFile(url: string, name: string, fileType: string) {
    FileUtils.downloadFileByExternalUrl(
      url,
      `${name}.${FileUtils.getFileExtFromName(fileType)}`
    )
  }

  fileIcon(fileType: string) {
    if (this.upload.type === UploadType.audio) {
      return "file-audio"
    }

    if (FileUtils.getFileExtFromName(fileType) === "csv") {
      return "file-csv"
    } else {
      return "file-excel"
    }
  }
}
