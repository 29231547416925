





































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"
import { CheckoutDao } from "@/services/dao/robocent/checkoutDao"
import PrePayAmount from "@/components/billing/PrePayAmount.vue"
import { PrePayItem } from "@/interfaces/prePayItem"

@Component({
  components: { PrePayAmount, Card }
})
export default class PrePay extends VueComponent {
  stripePublicApiKey = ""
  prePayItems: PrePayItem[] = [] as PrePayItem[]

  async created() {
    await this.setStripePublicApiKey()
    await this.setPrePayItems()
  }

  get orgId() {
    return this.$route.params.orgId
  }

  async confirmItem(prePayItem: PrePayItem) {
    this.$buefy.dialog.confirm({
      message: `You will be invoiced for $${(
        prePayItem.amount / 100
      ).toFixed()} and will receive $${(
        (prePayItem.amount + prePayItem.bonus) /
        100
      ).toFixed()} in credit. Payment via credit card or check is accepted. Pay now, or later by visiting your invoices page.`,
      confirmText: "Continue",
      onConfirm: () => this.toCheckout(prePayItem)
    })
  }

  async toCheckout(prePayItem: PrePayItem) {
    await this.request(async () => {
      const prePayInvoice = await CheckoutDao.createPrePayInvoice({
        organizationId: this.orgId,
        prePayAmount: prePayItem.amount
      })

      if (prePayInvoice && prePayInvoice.hosted_invoice_url) {
        window.open(prePayInvoice.hosted_invoice_url, "_blank")
        await this.$router.push({
          name: `Org Billing`,
          params: { orgId: this.orgId }
        })
      }
    })
  }

  async setStripePublicApiKey() {
    await this.request(async () => {
      this.stripePublicApiKey = await CheckoutDao.getStripePublicKey()
    })
  }

  async setPrePayItems() {
    await this.request(async () => {
      this.prePayItems = await CheckoutDao.getPrePayItems()
    })
  }
}
