






































































import Component from "vue-class-component"
import { CampaignPricing, Script } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop, Ref } from "vue-property-decorator"
import ScriptInfoStep from "@/components/scheduler/textElements/scriptStepper/steps/ScriptInfoStep.vue"
import { ValidationObserver } from "vee-validate"
import ScriptMediaStep from "@/components/scheduler/textElements/scriptStepper/steps/ScriptMediaStep.vue"
import BuildScriptStep from "@/components/scheduler/textElements/scriptStepper/steps/BuildScriptStep.vue"

@Component({
  components: {
    BuildScriptStep,
    ScriptMediaStep,
    ValidationObserver,
    ScriptInfoStep
  }
})
export default class ScriptFormStepper extends VueComponent {
  @Prop() script!: Script
  @Prop() minOptionsNumber!: number
  @Prop() defaultText!: string
  @Prop() pricing!: CampaignPricing
  @Prop() isEdit!: boolean
  @Ref("observer") observer!: any

  infoStep = 0
  mediaStep = 1
  lastStep = 2
  activeStep = this.isEdit ? this.lastStep : 0

  get isLastStep() {
    return this.activeStep === this.lastStep
  }

  get buttonName() {
    return this.activeStep === this.mediaStep && !this.script.mms
      ? "Skip"
      : "Next"
  }

  async nextStep() {
    const isValid = await this.observer.validate()

    if (!isValid) {
      this.activateCurrentStepByError()
      return this.openToast("Please fill in the required fields.")
    }

    if (this.isLastStep) {
      return this.submit()
    }

    this.activeStep++
  }

  activateCurrentStepByError() {
    if (this.observer.errors?.Name.length) {
      this.activeStep = this.infoStep
      return
    }

    if (this.observer.errors?.Script.length) {
      this.activeStep = this.lastStep
    }
  }

  @Emit()
  submit() {}
}
