import { render, staticRenderFns } from "./TextCampaignActiveAgents.vue?vue&type=template&id=95d38d7a&scoped=true&"
import script from "./TextCampaignActiveAgents.vue?vue&type=script&lang=ts&"
export * from "./TextCampaignActiveAgents.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d38d7a",
  null
  
)

export default component.exports