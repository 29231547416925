import axios from "axios"
import { StatsPhoneListStatus } from "@/interfaces/statsPhoneListStatus"

export class TextCampaignConfigureDao {
  static async createSchedule(campaignUUID: string, data: any) {
    return await axios.post(`/text/${campaignUUID}/schedule`, data)
  }

  static async checkPhoneListStatus(
    campaignUUID: string
  ): Promise<StatsPhoneListStatus> {
    return (await axios.get(`/text/${campaignUUID}/phone-list/check-status`))
      .data
  }

  static async uploadPhoneList(campaignUUID: string, data: any) {
    return await axios.post(`/text/${campaignUUID}/phone-list`, data, {
      timeout: 15 * 60 * 1000
    })
  }

  static async createTextJob(campaignUUID: string) {
    return await axios.post(`/text/${campaignUUID}/job`)
  }
}
