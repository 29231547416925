var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"data":_vm.data,"loading":_vm.loading,"paginated":"","per-page":"10","sort-icon":"chevron-up","pagination-size":"is-small"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"id","label":"ID","sortable":""}},[_vm._v(" "+_vm._s(props.row.campaign.id)+" ")]),_c('b-table-column',{staticClass:"has-text-link",attrs:{"field":"name","label":"Campaign Name","sortable":""}},[(_vm.service === _vm.features.smsCampaign)?_c('router-link',{attrs:{"to":{
          name: 'Campaign Report',
          params: {
            campaignUUID: props.row.campaign.uuid,
            orgId: props.row.campaign.organizationId
          }
        }}},[_vm._v(_vm._s(props.row.campaign.name))]):_c('router-link',{attrs:{"to":{
          name: 'Voice Campaign Report',
          params: {
            orgId: props.row.campaign.organization,
            campaignUUID: props.row.campaign._id
          }
        }}},[_vm._v(_vm._s(props.row.campaign.name))])],1),_c('b-table-column',{attrs:{"field":"invoice.total","label":"Total","numeric":"","sortable":"","centered":""}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(props.row.campaign.invoice.total))+" ")]),_c('b-table-column',{attrs:{"field":"invoice.status","label":"Invoice Status","centered":"","numeric":"","sortable":""}},[_c('b-button',{staticClass:"tag has-text-weight-medium is-capitalized invoice-btn",attrs:{"type":_vm.statusToType(props.row.campaign.invoice.status)},on:{"click":function($event){return _vm.onInvoiceClick(props.row.campaign.invoice)}}},[_vm._v(" "+_vm._s(_vm.getInvoiceStatus(props.row.campaign.invoice.status)))])],1),_c('b-table-column',{attrs:{"visible":_vm.isCommission,"label":"Applied Commission","centered":"","numeric":""}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(props.row.amount))+" ")]),_c('b-table-column',{attrs:{"visible":_vm.isDiscount,"label":"Applied Discount","centered":"","numeric":"","sortable":""}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(props.row.amount))+" ")]),_c('b-table-column',{attrs:{"visible":_vm.tab === _vm.partnerPayoutStatuses.paid,"label":"Check","centered":""}},[_c('span',[_vm._v(" "+_vm._s(props.row.check ? props.row.check.number : "-")+" ")])]),_c('b-table-column',{attrs:{"visible":_vm.isCommission,"label":"Commission Status","centered":"","numeric":"","sortable":""}},[_c('b-button',{staticClass:"tag has-text-weight-medium is-capitalized",attrs:{"type":props.row.check ? 'is-success' : 'is-danger'}},[_vm._v(" "+_vm._s(props.row.check ? "Paid" : "Not Paid"))])],1)]}}])},[_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v("You have no earnings.")])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }