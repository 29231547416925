


























































import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import { Watch } from "vue-property-decorator"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import UpdateMaxPortsModal from "@/components/campaign/voiceAdminReview/modals/UpdateMaxPortsModal.vue"

@Component({
  components: { Card }
})
export default class OverviewExternalCampaign extends OverviewMixin {
  externalCampaign: any = null
  agentPool: any = null

  created() {
    this.prepareInfo()
  }

  async prepareInfo() {
    await this.request(async () => {
      if (this.campaign.meta.straticsId) {
        await this.getExternalCampaign()
      }
      if (
        this.campaign.meta.stratics &&
        this.campaign.meta.stratics.agentPoolId
      ) {
        await this.getAgentPool()
      }
    })
  }

  updatePorts() {
    this.$buefy.modal.open({
      parent: this,
      component: UpdateMaxPortsModal,
      width: 600,
      props: {
        maxPorts: this.externalCampaign.max_ports,
        transferMaxPorts: this.agentPool?.max_xfers
      },
      events: {
        updated: () => this.prepareInfo()
      }
    })
  }

  async getExternalCampaign() {
    await this.request(async () => {
      this.externalCampaign = await CampaignConfigureDao.getCampaign(
        this.campaign._id!
      )
    })
  }

  async getAgentPool() {
    await this.request(async () => {
      this.agentPool = await CampaignConfigureDao.getAgentPool(
        this.campaign._id!
      )
    })
  }

  @Watch("campaign")
  watchCampaignChange() {
    this.prepareInfo()
  }
}
