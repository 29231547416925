

















import Component from "vue-class-component"
import SalesRates from "@/components/salesPortal/admin/SalesRates.vue"
import OpenSalesPayouts from "@/components/salesPortal/admin/OpenSalesPayouts.vue"
import PaidSalesPayouts from "@/components/salesPortal/admin/PaidSalesPayouts.vue"
import SalesPayoutMixin from "@/mixins/salesPortal/SalesPayoutMixin"

@Component({
  components: { PaidSalesPayouts, OpenSalesPayouts, SalesRates }
})
export default class SalesPayout extends SalesPayoutMixin {
  created() {
    this.selectedDate = new Date()
  }
}
