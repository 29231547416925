export enum TextSchedulerSteps {
  campaignName = "campaignName",
  campaignDate = "campaignDate",
  campaignPhoneLists = "campaignPhoneLists",
  campaignScript = "campaignScript",
  campaignType = "campaignType",
  campaignAgents = "campaignAgents",
  campaignPhoneNumbers = "campaignPhoneNumbers",
  campaignPhoneNumbersInfo = "campaignPhoneNumbersInfo",
  campaignCost = "campaignCost",
  campaignCard = "campaignCard"
}
