




















































































































import Card from "@/components/elements/Card.vue"
import Component from "vue-class-component"
import CreditCard from "@/components/CreditCard.vue"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import OrganizationBillingMixin from "@/mixins/organization/OrganizationBillingMixin"
import OrganizationNoCardMessage from "@/components/organization/OrganizationNoCardMessage.vue"
import OrganizationAutoPay from "@/components/organization/OrganizationAutoPay.vue"
import PrePayStart from "@/components/billing/PrePayStart.vue"
import AdjustAccountBalanceModal from "@/components/modals/AdjustAccountBalanceModal.vue"
import { BillingDao } from "@/services/dao/robocent/billingDao"

@Component({
  components: {
    PrePayStart,
    OrganizationAutoPay,
    OrganizationNoCardMessage,
    CreditCard,
    Card
  }
})
export default class OrganizationBilling extends OrganizationBillingMixin {
  smsBalance = 0
  amountDue: number | null = null

  created() {
    this.getCustomer(this.orgId)
    this.getAmountDue()
    EventBus.getBalance$.pipe(whileComponentMounted(this)).subscribe(() => {
      this.getCustomer(this.orgId)
    })
  }

  get organization() {
    return this.$store.getters.organization
  }

  get orgId() {
    return this.$route.params.orgId
  }

  async goToCustomerPortal() {
    await this.request(
      async () => {
        const { url } = (
          await SmsBillingDao.getSession(this.orgId, location.href)
        ).data
        window.open(url, "_self")
      },
      undefined,
      undefined,
      "customerPortal"
    )
  }

  openAdjustAccountBalanceModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AdjustAccountBalanceModal,
      width: 500,
      props: {
        orgId: this.orgId,
        balance: this.creditBalance
      },
      events: {
        changed: () => this.getCustomer(this.orgId)
      }
    })
  }

  async getAmountDue() {
    await this.request(
      async () => {
        this.amountDue = (
          await BillingDao.getAmountDue(this.organization.meta.stripeId)
        ).totalAmountRemaining
      },
      undefined,
      undefined,
      "amountDue"
    )
  }
}
