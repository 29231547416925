


























































import CheckIcon from "../elements/CheckIcon.vue"
import { DndDao } from "@/services/dao/dndDao"
import { VueComponent } from "@/utils/VueComponent"
import { DoNotDisturbKeyword, DoNotDisturbAction } from "@robocent/components"
import Component from "vue-class-component"
import AddDndKeywordModal from "../modals/AddDndKeywordModal.vue"

@Component({
  components: { CheckIcon }
})
export default class DndKeywords extends VueComponent {
  data: DoNotDisturbKeyword[] = []

  created() {
    this.getKeywords()
  }

  actionName(action: DoNotDisturbAction) {
    return DoNotDisturbAction.optOut === action ? "Unsubscribe" : "Subscribe"
  }

  async getKeywords() {
    await this.request(async () => {
      this.data = (await DndDao.getDndKeywords(this.$route.params.orgId)).data
    })
  }

  addKeywordModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddDndKeywordModal,
      width: 600,
      props: {
        orgId: this.$route.params.orgId
      },
      events: {
        added: this.getKeywords
      }
    })
  }

  async removeDndKeyword(id: number) {
    await this.confirm("Do you really want to remove from keywords?")
    await this.request(async () => {
      await DndDao.removeDndKeyword(this.$route.params.orgId, id)
      this.openToast("Keyword successfully removed")
      await this.getKeywords()
    })
  }
}
