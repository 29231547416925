














































































import _ from "lodash"
import { ValidationObserver } from "vee-validate"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import { ListMap, Upload } from "@robocent/components"
import { VoicePhoneListService } from "@/services/voice/voicePhoneListService"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import Card from "@/components/elements/Card.vue"
import { StatsConfig } from "@/interfaces/voiceCampaign/statsConfig"
import { PhoneListMapping } from "@/interfaces/phoneListMapping"

@Component({
  components: {
    Card,
    ValidationObserver
  }
})
export default class UploadPhoneList extends VueComponent {
  @Prop() campaign!: VoiceCampaign

  config: StatsConfig | null = null
  mapping: PhoneListMapping | null = null
  phoneListService = new VoicePhoneListService()

  get token() {
    return Boolean(this.campaign?.meta?.stratics?.phoneListToken)
  }

  get listId() {
    return Boolean(this.campaign?.meta?.stratics?.phoneListId)
  }

  created() {
    this.populateValues()
  }

  populateValues() {
    this.config = this.phoneListService.getConfig(
      this.campaign,
      this.campaign.uploads.list as Upload
    )
  }

  onScrubListClick() {
    const list: Upload = this.campaign.uploads.list as Upload
    this.$buefy.modal.open({
      parent: this,
      component: ListMap,
      props: {
        fileToParse: list._id,
        orgId: this.campaign.organization._id,
        submit: this.submit
      },
      events: {
        onMappingChange: (data: PhoneListMapping) =>
          (this.mapping = _.cloneDeep(data))
      }
    })
  }

  async submit() {
    await this.request(
      async () => {
        await CampaignConfigureDao.uploadPhoneList(
          this.campaign._id as string,
          {
            config: this.config,
            mapping: this.mapping
          }
        )
        await this.onUpdate()
      },
      null,
      "Error Upload Phone List to dialer"
    )
  }

  @Emit()
  onUpdate() {}
}
