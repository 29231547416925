import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { Upload } from "@robocent/components"
import { VoiceCampaignPublicDao } from "@/services/dao/robocent/voiceCampaignPublicDao"
import { NavigationGuardNext, Route } from "vue-router/types/router"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignStatsDetail } from "@/interfaces/voiceCampaign/voiceCampaignStatsDetail"

export class VoiceCampaignService {
  defaultValue = { value: 0, perc: 0 }

  static async isPublic(to: Route, from: Route, next: NavigationGuardNext) {
    const campaignId = to.params.campaignUUID
    let call: VoiceCampaign | null = null

    if (!campaignId) {
      next("/404")
      return
    }

    try {
      call = await VoiceCampaignPublicDao.getVoiceCampaign(campaignId)
    } catch (e) {
      next("/404")
      return
    }

    if (!call.meta.public) {
      next("/404")
      return
    }

    to.meta["call"] = call

    next()
  }

  calculateCallCost(statsDetail: VoiceCampaignStatsDetail) {
    const statsDetailedByType: any = this.getStatsDetailedByType(statsDetail)

    return parseFloat(statsDetailedByType?.cost?.toString() || 0)
  }

  getStatsDetailedByType(statsDetail: VoiceCampaignStatsDetail) {
    if (statsDetail.campaign_summary) {
      return statsDetail.campaign_summary
    } else if (statsDetail.outbound_summary) {
      return statsDetail.outbound_summary
    } else if (statsDetail.rvm_id) {
      return statsDetail
    }
  }

  calculateCallStats(statsDetailed: VoiceCampaignStatsDetail) {
    if (statsDetailed.campaign_summary) {
      return this.formatCampaignSummary(statsDetailed.campaign_summary)
    } else if (statsDetailed.outbound_summary) {
      return this.formatOutboundSummary(statsDetailed.outbound_summary)
    } else if (statsDetailed.rvm_id) {
      return this.formatCampaignSummary(statsDetailed)
    } else {
      return null
    }
  }

  formatCampaignSummary(campaignSummary: any) {
    const {
      error,
      played,
      unavailable,
      incompatable,
      answers,
      noans,
      busy,
      machines,
      cong,
      other,
      transfers,
      intercepts,
      total
    } = campaignSummary

    return {
      error: this.createStatsObject(error),
      played: this.createStatsObject(played),
      unavailable: this.createStatsObject(unavailable),
      incompatable: this.createStatsObject(incompatable),
      answer: this.createStatsObject(answers),
      no_answer: this.createStatsObject(noans),
      busy: this.createStatsObject(busy),
      machine: this.createStatsObject(machines),
      congestion: this.createStatsObject(cong),
      other: this.createStatsObject(other),
      transfers: this.createStatsObject(transfers),
      intercepts: this.createStatsObject(intercepts),
      total_dials: { value: total, perc: 100 }
    }
  }

  formatOutboundSummary(outboundSummary: any) {
    const {
      error,
      played,
      unavailable,
      incompatable,
      answer,
      no_answer,
      busy,
      machines,
      congestion,
      other,
      transfers,
      intercepts,
      total
    } = outboundSummary

    return {
      error: this.calculateStatsObject(error, total),
      played: this.calculateStatsObject(played, total),
      unavailable: this.calculateStatsObject(unavailable, total),
      incompatable: this.calculateStatsObject(incompatable, total),
      answer: this.calculateStatsObject(answer, total),
      no_answer: this.calculateStatsObject(no_answer, total),
      busy: this.calculateStatsObject(busy, total),
      machine: this.calculateStatsObject(machines, total),
      congestion: this.calculateStatsObject(congestion, total),
      other: this.calculateStatsObject(other, total),
      transfers: this.calculateStatsObject(transfers, total),
      intercepts: this.calculateStatsObject(intercepts, total),
      total_dials: { value: total, perc: 100 }
    }
  }

  createStatsObject(value: number | null) {
    return value || this.defaultValue
  }

  calculateStatsObject(value: number | null, total: number) {
    if (!value) {
      return this.defaultValue
    }

    let perc: number | string = (value / total) * 100
    perc = perc.toFixed(2)

    return {
      value,
      perc
    }
  }

  async getFile(uploadId: string, orgId: string) {
    const file: Upload & {
      url: { original: string; converted: string }
    } = (await UploadsDao.getUpload(orgId, uploadId)).data as Upload & {
      url: { original: string; converted: string }
    }
    file.url = await UploadsDao.getLinkForUpload(orgId, uploadId)
    return file
  }
}
