











import Component from "vue-class-component"
import { Campaign, CampaignType } from "@robocent/components"
import { CampaignDao } from "@/services/dao/campaignDao"
import { Prop } from "vue-property-decorator"
import { EstimatedCost } from "@/interfaces/estimatedCost"
import { ApproveTextCampaign } from "@/interfaces/approveTextCampaign"
import ApproveCampaignModal from "@/components/modals/ApproveCampaignModal.vue"
import { VueComponent } from "@/utils/VueComponent"
import { InvoiceCoupon } from "@/interfaces/invoiceCoupon"
import { TextCampaignMultis } from "@/consts/textCampaignMultis"
import { Feature } from "@/interfaces/feature/feature"

@Component({
  components: {
    ApproveCampaignModal
  }
})
export default class ApproveTextCampaignModal extends VueComponent {
  @Prop() orgId!: string
  @Prop() campaign!: Campaign

  data: ApproveTextCampaign = null!
  features = Feature

  async created() {
    this.setInitialData()
    await this.initCampaignCost()
  }

  setInitialData() {
    this.data = {
      leads: null,
      cost: 0,
      multis: [],
      fixedDiscounts: [],
      percentDiscounts: [],
      fees: [],
      coupon: null,
      pureCost: 0
    } as ApproveTextCampaign
  }

  async initCampaignCost() {
    await this.request(
      async () => {
        const cost = await CampaignDao.getEstimatedCampaignCost(
          this.orgId,
          this.campaign
        )
        this.prepareData(cost)
      },
      undefined,
      "Failed getting campaign cost"
    )
  }

  prepareData(cost: EstimatedCost) {
    this.data = {
      ...this.data,
      coupon: cost.appliedCoupon as InvoiceCoupon,
      leads: cost.basedOnCount,
      multis: this.prepareMultis(cost)
    }
  }

  prepareMultis(cost: EstimatedCost) {
    const defaultMultis = []

    if (cost.message.baseSegmentCost) {
      defaultMultis.push({
        description: TextCampaignMultis.baseSegmentCost(
          cost.isIdentityRegistered
        ),
        amount: this.getAmountInDollars(cost.message.baseSegmentCost)
      })
    }

    if (cost.message.additionalSegmentCost) {
      defaultMultis.push({
        description: TextCampaignMultis.additionalSegmentCost,
        amount: this.getAmountInDollars(cost.message.additionalSegmentCost)
      })
    }

    if (cost.message.mmsCost) {
      defaultMultis.push({
        description: TextCampaignMultis.mmsCost(cost.isIdentityRegistered),
        amount: this.getAmountInDollars(cost.message.mmsCost)
      })
    }

    if (this.campaign.type === CampaignType.managed) {
      defaultMultis.push({
        description: TextCampaignMultis.managedCost,
        amount: this.getAmountInDollars(cost.message.managedCost)
      })
    }

    return defaultMultis
  }

  getAmountInDollars(amount: number) {
    if (!amount) {
      return 0
    }
    return amount / 100
  }
}
