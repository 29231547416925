import axios from "axios"
import { Organization, StringObject } from "@robocent/components"

export class OrganizationAccountDao {
  static async getAllAccounts(
    params: StringObject
  ): Promise<{ data: Organization[]; total: number }> {
    return (await axios.get(`/text/accounts`, { params })).data
  }

  static async createIdentity(orgId: string) {
    return await axios.post(`/text/organizations/${orgId}/identity`)
  }
}
