

































import Component from "vue-class-component"
import { ImageFile, Script } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Emit } from "vue-property-decorator"
import _ from "lodash"
import { ImageDao } from "@/services/dao/robocent/imageDao"
import ImageItem from "@/components/elements/ImageItem.vue"
import { Pagination } from "@/utils/pagination"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"

@Component({
  components: { ImageItem, PaginationItem }
})
export default class ScriptMediaStep extends VueComponent {
  @Prop() script!: Script

  orgId = this.script.organizationId
  url = this.script.mms || ""

  images: ImageFile[] = []
  selectedUrl: string | null = null
  imageFileSizeLimit = 550000
  perPage = 4

  pagination = new Pagination(0, 1, this.perPage)

  created() {
    this.getImages()
    this.selectedUrl = _.clone(this.url)
  }

  async getImages() {
    this.params = {
      page: this.pagination.current,
      limit: this.pagination.perPage
    }

    await this.request(async () => {
      const { data } = await ImageDao.getImages(this.orgId, this.params)
      this.images = data.data
      this.pagination.total = data.total
    })
  }

  isSelected(image: ImageFile) {
    return this.selectedUrl === image.original.url
  }

  async onUploaded(image: ImageFile) {
    await this.getImages()
    this.onSelect(image)
    this.openSuccessToast("File selected automatically")
  }

  onSelect(image: ImageFile) {
    if (Number(image?.meta?.size) > this.imageFileSizeLimit) {
      return this.openToast("File size should be less than 550 kB")
    }
    this.selectedUrl = image?.original?.url
    this.script.mms = this.selectedUrl

    if (this.selectedUrl) {
      this.select()
    }
  }

  async onRemove(id: string) {
    await this.confirm(
      "Do you really want to delete the image from the organization?"
    )
    await this.request(async () => {
      await ImageDao.deleteImage(this.orgId, id)
      this.pagination.current = 1
      await this.getImages()
    })
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getImages()
  }

  @Emit()
  select() {}
}
