































































































import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import {
  DatePickerWithValidation,
  ScheduleDays,
  TimePickerWithValidation,
  timezones
} from "@robocent/components"
import moment from "moment"
import Component, { mixins } from "vue-class-component"
import ChooseRow from "../elements/ChooseRow.vue"
import { LocationService } from "@/services/locationService"
import CampaignMixin from "@/mixins/campaign/CampaignMixin"
import CampaignDateMixin from "@/mixins/campaign/CampaignDateMixin"
import { Ref, Watch } from "vue-property-decorator"

@Component({
  components: {
    ChooseRow,
    TimePickerWithValidation,
    DatePickerWithValidation
  }
})
export default class TextCampaignDate extends mixins(
  TextSchedulerUtilsMixin,
  CampaignMixin,
  CampaignDateMixin
) {
  @Ref("date-observer") observer!: any
  minDate = new Date()
  maxDate = moment(new Date()).add({ months: 3 })
  zones = timezones
  range = 7
  locationService = new LocationService()
  endTime = "21:00"

  created() {
    this.setMinTime()

    if (!this.isCampaignUpdate) {
      this.validateTime(this.campaign.time.monday.start)
    }
  }

  get unlimitedTime() {
    return this.isSuper
  }

  get editingDateDisabled() {
    return this.isCampaignUpdate
  }

  setMinTime() {
    const minTime = this.calculateMinTime(
      this.campaign.timezone,
      this.campaign.start
    )
    if (!this.campaign.time.monday.start) {
      this.campaign.time.monday.start = minTime
    }
  }

  validateTime(startTime: string) {
    this.validatePicker(
      this.campaign.start,
      this.campaign.time.monday.start,
      this.campaign.timezone
    )
    this.populateOtherDays(startTime)
  }

  populateOtherDays(startTime: string) {
    Object.values(ScheduleDays).forEach((day: ScheduleDays) => {
      this.campaign.time[day].start = startTime
      this.campaign.time[day].end = this.endTime
    })
  }

  async nextStep() {
    const isValid = await this.validatePicker(
      this.campaign.start,
      this.campaign.time.monday.start,
      this.campaign.timezone
    )
    if (!isValid) {
      return this.openToast("Please select correct time")
    }
    this.next(TextSchedulerSteps.campaignPhoneLists)
  }

  @Watch("campaign.timezone")
  watchTimezone(nextVal: string) {
    this.calculateMinTime(nextVal, this.campaign.start)
    this.validatePicker(
      this.campaign.start,
      this.campaign.time.monday.start,
      nextVal
    )
  }

  @Watch("campaign.start")
  watchStartDate() {
    this.setMinTime()

    this.validatePicker(
      this.campaign.start,
      this.campaign.time.monday.start,
      this.campaign.timezone
    )
  }
}
