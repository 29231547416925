import { Module } from "vuex"
import _ from "lodash"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import { cancelAllRequests, refreshToken } from "@/config/axios"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { CampaignConfigureService } from "@/services/voice/campaignConfigureService"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { AudioService } from "@/services/voice/audioService"
import { VoiceCampaignType } from "@/enums/voiceCampaignType"
import { Upload } from "@robocent/components"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"

const initState = {
  campaign: null,
  loading: false,
  status: {}
}

const callConfigureService = new CampaignConfigureService()
const audioService = new AudioService()

export const voiceConfiguratorStore = <Module<any, any>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    campaign: (state) => state.campaign,
    status: (state) => state.status,
    loading: (state) => state.loading
  },
  mutations: {
    setCampaign: (state, campaign) => {
      state.campaign = _.cloneDeep(campaign)
    },
    setStatus: (state, status) => {
      state.status = _.cloneDeep(status)
    },
    setLoading: (state, loading) => {
      state.loading = loading
    },
    clearCampaign: (state) => {
      state.campaign = null
    }
  },
  actions: {
    updateCampaign: async (context, { orgId, callId }) => {
      try {
        context.commit("setLoading", true)
        const campaign = await VoiceCampaignDao.getVoiceCampaign(orgId, callId)

        const liveRecording = campaign.uploads.liveRecording as Upload
        const vmRecording = campaign.uploads.vmRecording as Upload
        const list = campaign.uploads.list as Upload

        if (liveRecording._id) {
          campaign.uploads.liveRecording = (
            await UploadsDao.getUpload(orgId, liveRecording._id)
          ).data
        }

        if (vmRecording._id) {
          campaign.uploads.vmRecording = (
            await UploadsDao.getUpload(orgId, vmRecording._id)
          ).data
        }

        if (list._id) {
          campaign.uploads.list = (
            await UploadsDao.getUpload(orgId, list._id)
          ).data
        }

        if (campaign.uploads.mms) {
          campaign.uploads.mms = (
            await UploadsDao.getUpload(orgId, campaign.uploads.mms)
          ).data
        }

        context.commit("setCampaign", campaign)
      } finally {
        context.commit("setLoading", false)
      }
      await context.dispatch("calculateStatus")
    },
    calculateStatus: async (context) => {
      try {
        context.commit("setLoading", true)
        const campaign = context.getters.campaign
        const status: any = {}

        if (
          campaign.type === VoiceCampaignType.survey &&
          campaign?.meta.stratics
        ) {
          status.isPhoneListReady = Boolean(campaign!.meta.stratics.phoneListId)
          status.isCallApproved =
            campaign.meta.status === VoiceCampaignStatus.approved
        } else {
          status.isAudioReady = await audioService.audioFilesUploaded(
            campaign as VoiceCampaign
          )
          status.isCallerIdReady = Boolean(
            await callConfigureService.getCallerId(campaign!._id!)
          )

          if (campaign?.meta.stratics) {
            status.isPhoneListReady = Boolean(
              campaign!.meta.stratics.phoneListId
            )
            status.isScheduleReady = Boolean(campaign!.meta.stratics.scheduleId)
            status.isAgentPoolReady = Boolean(
              campaign!.meta.stratics.agentPoolId
            )
            status.isIvrReady = Boolean(campaign!.meta.stratics.ivrId)
            status.isCampaignReady = Boolean(campaign!.meta.straticsId)
            status.isCallApproved =
              campaign.meta.status === VoiceCampaignStatus.approved
          }
        }

        context.commit("setStatus", status)
      } finally {
        context.commit("setLoading", false)
      }
    },
    cancelPendingRequests() {
      cancelAllRequests()
      refreshToken()
    }
  }
}
