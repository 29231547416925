
































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"

@Component({
  components: {
    Card
  }
})
export default class PrePayStart extends VueComponent {}
