


































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { CouponsDao } from "@/services/dao/robocent/couponsDao"
import PromoCodeModal from "@/components/modals/PromoCodeModal.vue"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import { Pagination } from "@/utils/pagination"
import { Coupon } from "@/interfaces/coupon"
import PromoCodesTable from "@/components/promoCodes/PromoCodesTable.vue"
import { messages } from "@/data/messages"
import { BDialogConfig } from "buefy/types/components"

@Component({
  components: { PromoCodesTable, PaginationItem }
})
export default class PromoCodes extends VueComponent {
  pagination = new Pagination(0, 1, 20)
  promoCodes: (Coupon & { discount: number | null; terms: string })[] = []
  hasMore = false

  created() {
    this.getPromoCodes()
  }

  async getPromoCodes() {
    this.params = {
      ...this.params,
      limit: this.pagination.perPage
    }

    await this.request(async () => {
      const responseData = await CouponsDao.getCoupons(this.params)
      this.hasMore = responseData.has_more
      this.setTotal()
      this.promoCodes = responseData.data.map((item) =>
        Object.assign(item, {
          discount: item.amount_off
            ? Number(item.amount_off) / 100
            : item.percent_off,
          terms: item.currency ? item.currency : "%"
        })
      )
    })
  }

  setTotal() {
    this.pagination.total = this.hasMore
      ? this.pagination.current * this.pagination.perPage + 1
      : this.pagination.current * this.pagination.perPage
  }

  openPromoCodeModal() {
    this.$buefy.modal.open({
      parent: this,
      component: PromoCodeModal,
      width: 500,
      events: {
        onCreate: (data: Coupon) => this.onCreateCoupon(data)
      }
    })
  }

  async onCreateCoupon(data: Coupon) {
    await this.request(async () => {
      await CouponsDao.createCoupon(data)
      await this.getPromoCodes()
    })
  }

  async onPageChange(page: number) {
    if (this.pagination.current < page) {
      this.params = Object.assign(this.params, {
        starting_after: [...this.promoCodes].pop()!.id
      })
      delete this.params.ending_before
    } else {
      this.params = Object.assign(this.params, {
        ending_before: this.promoCodes[0].id
      })
      delete this.params.starting_after
      this.hasMore = true
    }
    await this.getPromoCodes()
    this.pagination.current = page
  }

  async deleteCouponConfirmation(coupon: Coupon) {
    await this.confirm(messages.deletePromoCode(coupon), {
      title: "Delete Promo Code",
      type: "is-danger",
      hasIcon: true,
      icon: "times-circle",
      iconPack: "fa"
    } as BDialogConfig)
    await this.deleteCoupon(coupon.id)
  }

  async deleteCoupon(id: string) {
    await this.request(async () => {
      await CouponsDao.deleteCoupon(id)
      await this.getPromoCodes()
    })
  }
}
