






























import Card from "../elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import PieChart from "../elements/PieChart"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { CampaignSurveyStats } from "@/interfaces/campaignSurveyStats"
import SurveyResponsesModal from "../modals/SurveyResponsesModal.vue"

@Component({
  components: {
    PieChart,
    Card
  }
})
export default class SurveyDiagrams extends VueComponent {
  @Prop() surveyStats!: CampaignSurveyStats[]
  @Prop() shared!: boolean
  @Prop() waiting!: boolean

  showSurveyResponses(scriptId: number) {
    const { orgId, campaignUUID } = this.$route.params
    this.$buefy.modal.open({
      parent: this,
      component: SurveyResponsesModal,
      width: 800,
      props: {
        orgId,
        campaignId: campaignUUID,
        scriptId,
        shared: this.shared
      }
    })
  }
}
