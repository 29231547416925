






































import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import {
  PhoneNumberWithValidation,
  DoNotDisturbAction
} from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { DndDao } from "@/services/dao/dndDao"
import { Prop } from "vue-property-decorator"

@Component({
  components: { Card, PhoneNumberWithValidation, ValidationObserver }
})
export default class AddDndKeywordModal extends VueComponent {
  types = DoNotDisturbAction
  @Prop() orgId!: string

  text: string = null!
  action = DoNotDisturbAction.optOut
  caseSensitive = false

  async add() {
    await this.request(async () => {
      await DndDao.addDndKeyword(this.orgId, {
        text: this.text,
        action: this.action,
        caseSensitive: this.caseSensitive
      })
      this.$parent.close()
      this.openSuccessToast("Keyword successfully added")
      this.$emit("added")
    })
  }
}
