import Component from "vue-class-component"
import { BDialogConfig } from "buefy/types/components"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { VueComponent } from "@/utils/VueComponent"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"
import { messages } from "@/data/messages"

@Component({})
export default class PaymentMixin extends VueComponent {
  async confirmPayWithBalanceOrCreditCard(invoice: Invoice) {
    await this.confirm(messages.confirmPayWithCard, {
      title: "Pay with Card/Credit",
      confirmText: "Submit",
      type: "is-warning",
      hasIcon: true
    } as BDialogConfig)
    await this.payWithBalanceOrCreditCard(invoice)
  }

  async payWithBalanceOrCreditCard(invoice: Invoice) {
    await this.request(
      async () => {
        await BillingDao.payInvoiceWithBalanceCreditCard(
          this.$route.params.orgId,
          invoice.id
        )
        this.$emit("on-invoice-change")
      },
      "The invoice has been paid.",
      "Not Enough Credit Available and No Credit Card on File"
    )
  }
}
