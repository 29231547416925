import Component, { mixins } from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { AgentPayoutDao } from "@/services/dao/agentPayoutDao"
import { AgentTotalsMixin, AgentPayout } from "@robocent/components"
import _ from "lodash"

@Component({})
export default class AgentsPayoutMixin extends mixins(
  AgentTotalsMixin,
  VueComponent
) {
  payouts: AgentPayout[] = []
  totals = { messages: 0, earned: 0 }

  async getAgentsPayouts(params?: { paid: boolean }) {
    await this.request(
      async () => {
        const payouts = await AgentPayoutDao.getAgentsPayout(params)
        this.preparePayouts(payouts)
      },
      null,
      "Cannot get the data"
    )
  }

  preparePayouts(payouts: AgentPayout[]) {
    this.payouts = _.map(payouts, (payout) => {
      const { messages, earned } = this.getTotals(payout.campaignPayouts)
      return {
        ...payout,
        sent: messages,
        earned
      }
    })
  }
}
