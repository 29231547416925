import { Script } from "@robocent/components"
import axios from "axios"
import { FindManyFilter } from "@/interfaces/findManyFilter"

export class ScriptDao {
  static async createScript(script: Script): Promise<Script> {
    return (
      await axios.post(
        `/text/organizations/${script.organizationId}/scripts`,
        script
      )
    ).data
  }

  static async getScripts(orgId: string, params?: FindManyFilter) {
    return await axios.get(`/text/organizations/${orgId}/scripts`, { params })
  }

  static async getScript(orgId: string, scriptId: number): Promise<Script> {
    return (await axios.get(`/text/organizations/${orgId}/scripts/${scriptId}`))
      .data
  }

  static async scriptUsedIn(orgId: string, scriptId: number): Promise<number> {
    return (
      await axios.get(
        `/text/organizations/${orgId}/scripts/${scriptId}/used-in`
      )
    ).data
  }

  static async updateScript(
    orgId: string,
    scriptId: number,
    script: Script
  ): Promise<Script> {
    return axios.put(`/text/organizations/${orgId}/scripts/${scriptId}`, script)
  }

  static async getAllScripts(params?: FindManyFilter) {
    return await axios.get(`/text/scripts`, { params })
  }

  static async deleteScript(orgId: string, scriptId: number) {
    return (
      await axios.delete(`text/organizations/${orgId}/scripts/${scriptId}`)
    ).data
  }

  static async approveScript(scriptId: number) {
    return axios.post(`text/scripts/${scriptId}/approve`)
  }

  static async rejectScript(scriptId: number, comment: string) {
    return axios.post(`text/scripts/${scriptId}/reject`, { comment })
  }

  static async sendTestMessage(orgId: string, scriptId: number, phone: string) {
    return await axios.post(
      `/text/organizations/${orgId}/scripts/${scriptId}/test-message`,
      { phone }
    )
  }
}
