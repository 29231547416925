export default function useIntercom() {
  function update(data?: object) {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("update", {
        app_id: process.env.VUE_APP_INTERCOM_ID,
        ...data
      })
    }
  }

  return {
    update
  }
}
