













































import Component from "vue-class-component"
import { User } from "@robocent/components"
import { Emit, Prop } from "vue-property-decorator"
import { Pagination } from "@/utils/pagination"
import GenericSearch from "@/components/GenericSearch.vue"
import Vue from "vue"

@Component({
  components: {
    GenericSearch
  }
})
export default class SelectUsers extends Vue {
  pagination = new Pagination()
  @Prop() users!: User[]
  @Prop({ default: false }) loading!: boolean
  columns = [
    {
      field: "email",
      label: "Email"
    },
    {
      field: "app_metadata.level",
      label: "Level"
    },
    {
      field: "app_metadata.internal",
      label: "RoboCent Agent"
    }
  ]
  selectedUsers = []

  created() {
    this.$emit("init", this.pagination)
  }

  @Emit()
  onPageChange(page: number) {
    this.pagination.current = page
    return this.pagination
  }

  isRowChecked(a: User, b: User) {
    return a.user_id === b.user_id
  }

  @Emit()
  check(selectedRows: User[]) {
    return selectedRows
  }
}
