import { render, staticRenderFns } from "./OrganizationCIRL.vue?vue&type=template&id=043e4675&scoped=true&"
import script from "./OrganizationCIRL.vue?vue&type=script&lang=ts&"
export * from "./OrganizationCIRL.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043e4675",
  null
  
)

export default component.exports