









































import Card from "../elements/Card.vue"
import Component from "vue-class-component"
import { CampaignDao } from "@/services/dao/campaignDao"
import { CampaignSharedInfo } from "@/interfaces/campaignSharedInfo"
import CampaignShareModal from "@/components/modals/CampaignShareModal.vue"
import { CardTable, CardTableItem } from "@robocent/components"
import ShareablePageMixin from "@/mixins/ShareablePageMixin"

@Component({
  components: { Card, CardTableItem, CardTable }
})
export default class CampaignShare extends ShareablePageMixin {
  campaignSharedInfo: CampaignSharedInfo | null = null

  created() {
    this.getCampaignSharedInfo()
  }

  async onStatusChanged(shared: boolean) {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(async () => {
      this.campaignSharedInfo = (
        await CampaignDao.makeCampaignShared(orgId, campaignUUID, shared)
      ).data
      await this.getCampaignSharedInfo()
    })
  }

  async getCampaignSharedInfo() {
    const { orgId, campaignUUID } = this.$route.params
    await this.request(async () => {
      this.campaignSharedInfo = (
        await CampaignDao.getCampaignSharedInfo(orgId, campaignUUID)
      ).data
    })
  }

  openModal() {
    this.$buefy.modal.open({
      parent: this,
      component: CampaignShareModal,
      width: 800
    })
  }
}
