import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Pagination } from "@/utils/pagination"
import { Prop } from "vue-property-decorator"
import { Campaign, Organization } from "@robocent/components"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component
export class SchedulerUtilsMixin extends VueComponent {
  @Prop() campaign!: {
    organization?: Organization
    organizationId?: string
  }
  pagination = new Pagination(0, 1, 9)
  search: string | null = null

  reset() {
    this.pagination = new Pagination(0, 1, 9)
    this.search = null
  }

  get organizationId(): string {
    return this.campaign.organization?._id || this.campaign.organizationId!
  }

  get atLeastOnePage() {
    return this.pagination.total > this.pagination.perPage || this.search
  }

  get hideFromCreatedCampaign() {
    return !(
      (this.campaign as unknown as Campaign)?.id ||
      (this.campaign as unknown as VoiceCampaign)?._id
    )
  }

  scrollToStep(step: TextSchedulerSteps | VoiceSchedulerSteps) {
    this.$nextTick(() => this.$scrollTo(`#${step}`, { offset: -50 }))
  }
}
