




































































































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import GeneralInfoStep from "@/voterData/GeneralInfoStep.vue"
import LocationStep from "@/voterData/LocationStep.vue"
import DemographicsStep from "@/voterData/DemographicsStep.vue"
import PoliticalAffiliationStep from "@/voterData/PoliticalAffiliationStep.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import LegalComplianceStep from "@/voterData/LegalComplianceStep.vue"
import { Ref } from "vue-property-decorator"
import SubmitRequestStep from "@/voterData/SubmitRequestStep.vue"
import { BDialogConfig } from "buefy/types/components"

@Component({
  components: {
    SubmitRequestStep,
    ValidationObserver,
    LegalComplianceStep,
    PoliticalAffiliationStep,
    DemographicsStep,
    LocationStep,
    GeneralInfoStep
  }
})
export default class DataRequestForm extends VoterDataUtilsMixin {
  @Ref("observer") observer!: any

  get user() {
    return this.$store.getters.user
  }

  get isStepClickable() {
    return Boolean(this.state) && Boolean((this as any).organization?._id)
  }

  get activeStep() {
    return this.$store.getters["voterData/activeStep"]
  }

  set activeStep(value) {
    this.$store.commit("voterData/setStep", value)
  }

  get isLastStep() {
    return this.activeStep === 5
  }

  get isLoadingActive() {
    return Object.values(this.$store.getters["voterData/loading"]).some(
      (val) => val === true
    )
  }

  get noRecordsAvailable() {
    return [
      this.$store.getters["voterData/individual"],
      this.$store.getters["voterData/phone"],
      this.$store.getters["voterData/mobile"],
      this.$store.getters["voterData/email"]
    ].every((entry) => entry === 0)
  }

  get notAcceptedTerms() {
    return (
      !(this as any).authorizationToPurchase || !(this as any).tosAcceptance
    )
  }

  async nextStep() {
    const isValid = await this.observer.validate()

    if (!isValid) {
      return this.openToast("Please fill in the required fields.")
    }

    if (this.isLastStep) {
      return await this.confirmSubmitting()
    }

    this.activeStep++
  }

  async confirmSubmitting() {
    const message =
      "After confirmation we will save your request and you will be able to check the status of this request on the All Requests Page."
    await this.confirm(message, {
      message,
      onConfirm: async () => await this.submitDataRequest(),
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "is-success"
    } as BDialogConfig)
  }

  async submitDataRequest() {
    await this.request(async () => {
      await this.$store.dispatch(
        "voterData/submitRequestStep/submitDataRequest",
        {
          userId: this.user.user_id,
          query: this.getFullQuery(),
          fileName: this.$store.getters["voterData/submitRequestStep/fileName"],
          queryId: this.$store.getters["voterData/queryId"],
          recordsAmount:
            this.$store.getters["voterData/submitRequestStep/recordsAmount"]
        }
      )
      this.$router.push({ name: "All Requests" })
    }, "The Request has been submitted successfully.")
  }
}
