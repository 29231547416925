import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { SalesManagerPayout } from "@/interfaces/salesPortal/salesManagerPayout"
import { SalesEarnings } from "@/interfaces/salesPortal/salesEarnings"
import { SalesRate } from "@/interfaces/salesPortal/salesRate"
import { Organization } from "@robocent/components"
import moment from "moment/moment"

@Component({})
export default class SalesPortalMixin extends VueComponent {
  get currentMonth() {
    return moment(this.selectedDate).format("MMMM")
  }

  get selectedOrg() {
    return this.$store.getters["salesPortal/selectedOrg"]
  }

  set selectedOrg(organization: Organization) {
    this.$store.commit("salesPortal/setOrganization", organization)
  }

  get selectedDate(): Date {
    return this.$store.getters["salesPortal/selectedDate"]
  }

  set selectedDate(date: Date) {
    this.$store.commit("salesPortal/setDate", date)
  }

  get payouts() {
    return this.$store.getters["salesPortal/payouts"]
  }

  set payouts(payouts: SalesManagerPayout[]) {
    this.$store.commit("salesPortal/setPayouts", payouts)
  }

  get salesEarnings() {
    return this.$store.getters["salesPortal/salesEarnings"]
  }

  set salesEarnings(earnings: SalesEarnings) {
    this.$store.commit("salesPortal/setSalesEarnings", earnings)
  }

  get salesRate() {
    return this.$store.getters["salesPortal/salesRate"]
  }

  set salesRate(salesRate: SalesRate) {
    this.$store.commit("salesPortal/setSalesRate", salesRate)
  }

  get salesOrganizations() {
    return this.$store.getters["salesPortal/salesOrganizations"]
  }

  set salesOrganizations(salesOrganizations: Organization[]) {
    this.$store.commit("salesPortal/setSalesOrganizations", salesOrganizations)
  }

  selectFirstOrg() {
    this.onSelectOrg(this.salesOrganizations[0])
  }

  onSelectOrg(organization: Organization) {
    this.selectedOrg = organization
  }
}
