


















import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { CampaignDirection } from "@/enums/campaignDirection"

@Component
export default class CampaignLinkToOrg extends VueComponent {
  campaignDirection = CampaignDirection
  @Prop() orgId!: string
  @Prop() orgName!: string
}
