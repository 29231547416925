












































































import Component from "vue-class-component"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import { Prop, Watch } from "vue-property-decorator"
import { Pagination } from "@/utils/pagination"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import { Organization } from "@robocent/components"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"

@Component({
  components: {
    SearchItem
  }
})
export default class SalesManagerOrganizations extends SalesPortalMixin {
  @Prop() isLoading!: boolean
  @Prop() userId!: string

  limit = 5
  pagination = new Pagination(0, 1, this.limit)
  search = ""

  get isSuper() {
    return this.$store.getters.super
  }

  created() {
    this.getSalesManagerOrganizations()
  }

  async getSalesManagerOrganizations(params?: {
    page: number
    limit?: number
    q?: string
  }) {
    this.search = params?.q || ""

    const paramsToFind = {
      q: params?.q || this.search,
      page: params?.page || 1,
      limit: params?.limit || this.limit,
      date: this.selectedDate.toDateString()
    }

    await this.request(
      async () => {
        const { data, total } =
          this.isSuper && this.userId
            ? await SalesPortalDao.getSalesOrganizationsByUserId(
                this.userId,
                paramsToFind
              )
            : await SalesPortalDao.getSalesManagerOrganizations(paramsToFind)

        this.salesOrganizations = data
        this.pagination.total = total
        this.selectFirstOrg()
      },
      undefined,
      undefined,
      "orgs"
    )
  }

  async onPageChange(page: number) {
    this.pagination.current = page
    await this.getSalesManagerOrganizations({
      limit: this.pagination.perPage,
      page: this.pagination.current,
      q: this.search
    })
  }

  isSelected(org: Organization) {
    return org._id === this.selectedOrg._id
  }

  @Watch("selectedDate")
  watchSelectedDate() {
    this.onPageChange(1)
  }
}
