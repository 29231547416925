

































import Component from "vue-class-component"
import SalesPortalTotalEarnings from "@/components/salesPortal/SalesPortalTotalEarnings.vue"
import SalesPayouts from "@/components/salesPortal/SalesPayouts.vue"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"
import SalesManagerFilter from "@/components/salesPortal/SalesManagerFilter.vue"
import OrganizationEarnings from "@/components/salesPortal/OrganizationEarnings.vue"
import SalesCommissionRates from "@/components/salesPortal/SalesCommissionRates.vue"
import { Watch } from "vue-property-decorator"

@Component({
  components: {
    SalesCommissionRates,
    OrganizationEarnings,
    SalesManagerFilter,
    SalesPayouts,
    SalesPortalTotalEarnings
  }
})
export default class SalesPortal extends SalesPortalMixin {
  created() {
    this.$store.commit("salesPortal/clear")
    this.getSalesRate()
    this.getSalesEarnings()
  }

  async getSalesPayouts(params: { date: string; status: PartnerPayoutStatus }) {
    this.payouts = []

    await this.request(
      async () => {
        const { campaigns } = await SalesPortalDao.getSalesManagerPayouts(
          this.selectedOrg?._id,
          params
        )
        this.payouts = campaigns
      },
      undefined,
      undefined,
      "payouts"
    )
  }
  async getSalesRate() {
    await this.request(
      async () => {
        this.salesRate = await SalesPortalDao.getSalesRate()
      },
      undefined,
      undefined,
      "sales-rates"
    )
  }

  async getSalesEarnings() {
    const params = {
      date: this.selectedDate.toDateString()
    }
    await this.request(
      async () => {
        this.salesEarnings = await SalesPortalDao.getSalesEarnings(params)
      },
      undefined,
      undefined,
      "sales-earnings"
    )
  }

  @Watch("selectedDate")
  async watchSelectedDate() {
    await this.getSalesEarnings()
  }
}
