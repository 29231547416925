












import AdminNavBar from "@/components/nav/AdminNavBar.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: { AdminNavBar }
})
export default class AdminParent extends VueComponent {}
