
























import Component from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import Vue from "vue"
import { PhoneNumberWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"

@Component({
  components: { PhoneNumberWithValidation, ValidationObserver }
})
export default class TestCall extends Vue {
  @Prop() loading!: boolean
  @Prop() isRounded!: boolean
  phone = ""

  @Emit()
  submit() {
    return this.phone.replace(new RegExp("-", "gi"), "")
  }
}
