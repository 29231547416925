import axios from "axios"
import { Card, Customer } from "@robocent/components"
import { Invoice } from "@robocent/components/types/generated/interfaces/invoice"

export class BillingDao {
  static async addCard(orgId: string, card: Card) {
    return (await axios.put(`billing/organizations/${orgId}/cards`, card)).data
  }

  static async makeCardDefault(
    orgId: string,
    cardId: string
  ): Promise<Customer> {
    return (await axios.put(`billing/organizations/${orgId}/cards/${cardId}`))
      .data
  }

  static async deleteCard(orgId: string, cardId: string): Promise<Customer> {
    return (
      await axios.put(`billing/organizations/${orgId}/cards/${cardId}/delete`)
    ).data
  }

  static async payInvoiceWithBalanceCreditCard(
    orgId: string,
    invoiceId: string
  ) {
    return await axios.post(
      `${process.env.VUE_APP_API_URL_V3}/organizations/${orgId}/billing/invoices/${invoiceId}/pay`
    )
  }

  static async getCostBasedOnCallObject(updCall: any) {
    return await axios.post("billing/call-object-cost", updCall)
  }

  static async getCallCost(callId: string) {
    return (await axios.get(`billing/call-cost/${callId}`)).data
  }

  static async getInvoice(invoiceId: string): Promise<Invoice> {
    return (await axios.get("billing/invoices/" + invoiceId)).data
  }

  static async getQuote(orgId: string) {
    return (await axios.get(`billing/organizations/${orgId}/quote`)).data
  }

  static async getAmountDue(stripeId: string) {
    return (
      await axios.get("billing/amount-due", {
        params: {
          customer_id: stripeId
        }
      })
    ).data
  }

  static async approveCall(data: any) {
    return await axios.post("billing/invoices", data)
  }

  static async payInvoice(invoiceId: string, body: { forceCharge: boolean }) {
    return await axios.post(`billing/invoices/${invoiceId}/pay`, body)
  }

  static async deleteInvoice(invoiceId: string) {
    return await axios.delete(`billing/invoices/${invoiceId}`)
  }
}
