


























































import Component from "vue-class-component"
import Vue from "vue"

@Component
export default class RecordsSummary extends Vue {
  get loading() {
    return this.$store.getters["voterData/loading"]
  }

  get voterDataState() {
    return this.$store.getters["voterData/locationInfo/state"]
  }

  get individual() {
    return this.$store.getters["voterData/individual"]
  }

  get mobile() {
    return this.$store.getters["voterData/mobile"]
  }

  get phone() {
    return this.$store.getters["voterData/phone"]
  }

  get email() {
    return this.$store.getters["voterData/email"]
  }
}
