













import Component from "vue-class-component"
import SalesPayoutsTable from "@/components/salesPortal/admin/SalesPayoutsTable.vue"
import SalesPayoutMixin from "@/mixins/salesPortal/SalesPayoutMixin"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import MonthPicker from "@/components/salesPortal/MonthPicker.vue"

@Component({
  components: {
    MonthPicker,
    SalesPayoutsTable
  }
})
export default class OpenSalesPayouts extends SalesPayoutMixin {
  async created() {
    await this.getAllSalesPayouts(this.defaultFilter)

    EventBus.getSalesPayouts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getAllSalesPayouts as any)
  }
}
