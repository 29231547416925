
























































































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import { ListCard, Organization } from "@robocent/components"
import { Ref } from "vue-property-decorator"
import { ValidationContext } from "vee-validate/dist/types/components/common"
import { OrganizationDao } from "@/services/dao/robocent/organizationDao"
import OrganizationForm from "@/components/organization/OrganizationForm.vue"
import { cloneDeep } from "lodash"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"
import { EventBus } from "@/services/eventBus"

@Component({
  components: {
    Card,
    OrganizationForm,
    ListCard,
    ValidationObserver
  }
})
export default class OrganizationDetails extends VueComponent {
  @Ref("observer") observer!: ValidationContext
  organization: Organization | null = null

  created() {
    this.organization = cloneDeep(this.$store.getters.organization)
  }

  async save() {
    try {
      this.loading = true
      await OrganizationDao.saveOrganization(this.organization!)
      EventBus.getOrganizations$.next()
      this.$buefy.toast.open("The organization successfully saved!")
      EventBus.organizationChanged$.next()
      this.$router.push({ path: "/" })
    } catch (e) {
      this.$buefy.toast.open("Organization can't be created.")
    } finally {
      this.loading = false
    }
  }

  confirmDeleteOrganization() {
    this.$buefy.dialog.confirm({
      message: "Remove this organization?",
      onConfirm: () => this.deleteOrganization()
    })
  }

  async deleteOrganization() {
    try {
      this.loading = true
      await OrganizationDao.deleteOrganization(this.organization!._id)
      EventBus.getOrganizations$.next()
      this.$buefy.toast.open("The organization successfully deleted!")
      this.$store.commit("clearLastVisitedOrganization")
      this.$router.push({ path: "/" })
    } catch (e) {
      this.$buefy.toast.open("Organization can't be deleted.")
    } finally {
      this.loading = false
    }
  }

  confirmRestoreOrganization() {
    this.$buefy.dialog.confirm({
      message: "Restore this organization?",
      onConfirm: () => this.restoreOrganization()
    })
  }

  async restoreOrganization() {
    try {
      this.loading = true
      await OrganizationDao.restoreOrganization(this.organization!._id)
      EventBus.getOrganizations$.next()
      this.$buefy.toast.open("The organization successfully restored!")
      this.$router.push({ path: "/" })
    } catch (e) {
      this.$buefy.toast.open("Organization can't be restored.")
    } finally {
      this.loading = false
    }
  }
}
