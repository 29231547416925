




































import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { ValidationObserver } from "vee-validate"
import { InputWithValidation } from "@robocent/components"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"
import _ from "lodash"
import { Prop } from "vue-property-decorator"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"

@Component({
  components: { Card, ValidationObserver, InputWithValidation }
})
export default class UpdateMaxPortsModal extends OverviewMixin {
  @Prop() maxPorts!: number
  @Prop() transferMaxPorts!: number

  data = {
    maxPorts: 0,
    transferMaxPorts: 0
  }

  created() {
    this.data.maxPorts = _.clone(this.maxPorts)
    this.data.transferMaxPorts = _.clone(this.transferMaxPorts)
  }

  async update() {
    await this.request(async () => {
      if (Number(this.data.maxPorts) !== this.maxPorts) {
        await this.updateCampaignMaxPorts()
      }
      if (
        this.isTransfer &&
        Number(this.data.transferMaxPorts) !== this.transferMaxPorts
      ) {
        await this.updateTransferMaxPorts()
      }

      this.$emit("updated")
      this.$parent.close()
    })
  }

  async updateCampaignMaxPorts() {
    const max_ports = this.data.maxPorts

    return await CampaignConfigureDao.updateCampaign(this.campaign._id!, {
      max_ports
    })
  }

  async updateTransferMaxPorts() {
    const max_xfers = this.data.transferMaxPorts
    return await CampaignConfigureDao.updateAgentPool(this.campaign._id!, {
      max_xfers
    })
  }
}
