var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container mt-sm"},[_c('h2',{staticClass:"title is-size-3"},[_vm._v("Welcome to RoboCent!")]),_c('p',{staticClass:"title is-size-5"},[_vm._v("What would you like to do?")]),_c('div',{staticClass:"columns is-multiline"},[_c('dashboard-action',{key:1,attrs:{"name":_vm.dashboardActions.textCampaign.name,"guide-link":_vm.dashboardActions.textCampaign.guideLink,"icon":_vm.dashboardActions.textCampaign.icon,"link":{
          name: 'CreateScheduler',
          query: {
            direction: _vm.campaignDirection.text
          }
        },"data-test":"dashboard-schedule-text-campaign"}}),_c('dashboard-action',{key:2,attrs:{"name":_vm.dashboardActions.voiceCampaign.name,"guide-link":_vm.dashboardActions.voiceCampaign.guideLink,"icon":_vm.dashboardActions.voiceCampaign.icon,"link":{
          name: 'CreateScheduler',
          query: {
            direction: _vm.campaignDirection.voice
          }
        }}}),_c('dashboard-action',{key:3,attrs:{"name":_vm.dashboardActions.uploadPhoneList.name,"guide-link":_vm.dashboardActions.uploadPhoneList.guideLink,"icon":_vm.dashboardActions.uploadPhoneList.icon,"link":{
          name: 'Org Files',
          params: { orgId: _vm.orgId },
          query: {
            type: [_vm.phoneListTypes.list, _vm.phoneListTypes.voterData]
          }
        },"data-test":"dashboard-upload-phone-list"}}),_c('dashboard-action',{key:4,attrs:{"name":_vm.dashboardActions.requestVoterData.name,"guide-link":_vm.dashboardActions.requestVoterData.guideLink,"icon":_vm.dashboardActions.requestVoterData.icon,"link":{
          name: 'Request Form'
        }}}),_c('dashboard-action',{key:5,attrs:{"name":_vm.dashboardActions.recordCIRL.name,"guide-link":_vm.dashboardActions.recordCIRL.guideLink,"icon":_vm.dashboardActions.recordCIRL.icon,"link":{
          name: 'Org CIRL',
          params: { orgId: _vm.orgId },
          query: { direction: _vm.campaignDirection.text }
        }}}),_c('dashboard-action',{key:6,attrs:{"name":_vm.dashboardActions.viewCampaigns.name,"guide-link":_vm.dashboardActions.viewCampaigns.guideLink,"icon":_vm.dashboardActions.viewCampaigns.icon,"link":{
          name: 'Org Campaigns',
          params: { orgId: _vm.orgId },
          query: { direction: _vm.campaignDirection.text }
        }}})],1)]),_c('tour')],1)}
var staticRenderFns = []

export { render, staticRenderFns }