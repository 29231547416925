





















































import TextSchedulerElements from "../../components/scheduler/TextSchedulerElements.vue"
import Component from "vue-class-component"
import { Campaign, User } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { messages } from "@/data/messages"
import { NavigationGuardNext, Route } from "vue-router/types/router"
import { Watch } from "vue-property-decorator"
import CampaignDirection from "@/components/scheduler/elements/CampaignDirection.vue"
import { CampaignDirection as ECampaignDirection } from "@/enums/campaignDirection"
import VoiceSchedulerElements from "@/components/scheduler/VoiceSchedulerElements.vue"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { CampaignCostService } from "@/services/campaignCostService"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import VoiceCampaignMessageConfirmationModal from "@/components/modals/voiceCampaign/VoiceCampaignMessageConfirmationModal.vue"
import { links } from "@/data/links"
import { buildTextCampaign } from "@/components/campaign/utils/buildTextCampaign"

Component.registerHooks(["beforeRouteLeave"])

@Component({
  components: {
    VoiceSchedulerElements,
    TextSchedulerElements,
    CampaignDirection
  }
})
export default class Scheduler extends VueComponent {
  defaultTime = {
    start: null,
    end: null
  }
  textCampaign: Campaign | null = null
  voiceCampaign: VoiceCampaign = null!
  campaignChanged = false
  direction: ECampaignDirection | null = ECampaignDirection.voice
  directions = ECampaignDirection
  campaignCostService = new CampaignCostService()
  range = 7
  docsLinks = links

  get user(): User {
    return this.$store.getters.user
  }

  created() {
    this.onSelectDirection(ECampaignDirection.voice)
  }

  async onSelectDirection(direction: ECampaignDirection) {
    if (direction === ECampaignDirection.text) {
      if (this.$store.getters.super) {
        this.$store.commit("textScheduler/clear")
        return this.buildTextCampaign()
      }
      await this.confirm("", {
        type: "is-warning",
        message: "You will be redirected to the new Text Scheduler."
      })
      window.open(`${process.env.VUE_APP_LOGOUT_URL}/scheduler`, "_self")
    }

    if (direction === ECampaignDirection.voice) {
      this.$store.commit("voiceScheduler/clear")
      this.buildVoiceCampaign()
    }
  }

  buildTextCampaign() {
    this.textCampaign = buildTextCampaign() as Campaign
    this.direction = ECampaignDirection.text
  }

  buildVoiceCampaign() {
    this.voiceCampaign = {
      type: "",
      time: "",
      timezone: "America/New_York",
      emails: [],
      meta: { agreeDisclaimer: false, agreeTOS: false, promoCode: "" },
      uploads: {
        list: null,
        liveRecording: null,
        vmRecording: undefined
      },
      organization: null,
      organizationId: "",
      state: "",
      areaCode: "",
      speed: "medium",
      callerId: ""
    } as unknown as VoiceCampaign
    this.direction = ECampaignDirection.voice
  }

  async saveVoiceCampaign() {
    await this.request(async () => {
      this.campaignCostService.setScriptField(this.voiceCampaign)
      this.voiceCampaign.userId = this.user.sub
      this.voiceCampaign.emails = [this.user.email] as any
      await VoiceCampaignDao.createCampaign(this.voiceCampaign)
      await this.$router.push({
        name: "Org Campaigns",
        params: {
          orgId: this.voiceCampaign.organization._id
        },
        query: {
          direction: ECampaignDirection.voice,
          created: "true"
        }
      })
      await this.openVoiceCampaignConfirmationModal()
    }, "Successfully Scheduled New Call!")
  }

  async openVoiceCampaignConfirmationModal() {
    this.$buefy.modal.open({
      parent: this,
      component: VoiceCampaignMessageConfirmationModal,
      width: 600,
      props: {
        time: this.voiceCampaign.time,
        timezone: this.voiceCampaign.timezone
      }
    })
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (to?.query.created) {
      return next()
    }

    if (this.campaignChanged) {
      await this.confirm(messages.schedulerBeforeRouteLeave, {
        type: "is-warning",
        message: messages.schedulerBeforeRouteLeave
      })
    }
    next()
  }

  beforeDestroy() {
    window.onbeforeunload = null
  }

  @Watch("textCampaign", { deep: true })
  @Watch("voiceCampaign", { deep: true })
  watchCampaignChanges(campaign: Campaign) {
    if (campaign.name) {
      this.campaignChanged = true
      window.onbeforeunload = function () {
        return messages.schedulerBeforeRouteLeave
      }
    }
  }
}
