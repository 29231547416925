import { getInstance } from "./plugin"
import store from "../store"
import axios from "axios"
import {
  checkIfUserIsBlocked,
  checkIfUserTosDeclined,
  User,
  USER_BLOCKED,
  UserTosAgreement
} from "@robocent/components"
// import { InviteUtil } from "@/utils/invite.util"
import { NavigationGuardNext, Route } from "vue-router/types/router"
import { UserLevel } from "@/enums/userLevel"
// import { checkUserPermission } from "@/utils/userInfo"
import { LocationService } from "@/services/locationService"
import { UserDao } from "@/services/dao/userDao"
import { InviteUtil } from "@/utils/invite.util"
import useIntercom from "@/utils/intercom"

export class Auth {
  static requireAuth(to: Route, from: Route, next: NavigationGuardNext) {
    const authService = getInstance()

    const fn = () => {
      // If the user is authenticated, continue with the route
      if (authService.isAuthenticated) {
        return next()
      }

      // Otherwise, log in
      authService.loginWithRedirect({
        appState: { targetUrl: to.fullPath, org: to.query.org }
      })
    }

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
      return fn()
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", (loading: boolean) => {
      if (!loading) {
        return fn()
      }
    })
  }

  static async initAppState(token: string) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    const intercom = useIntercom()
    const user: User = await UserDao.getUser()
    let userTos: UserTosAgreement | null = null
    let agreeTos

    try {
      if (!user) {
        window.open(process.env.VUE_APP_LOGOUT_URL, "_self")
        // const level = await checkUserPermission(user)
        // Auth.checkIfAgent(level)
        // user = await setupUser(level)
        // userTos = await UserDao.getUserTosAgreement()
      } else {
        await InviteUtil.inviteAgentHandler(user)
        await InviteUtil.inviteUserHandler(user)
        Auth.checkIfAgent(user.app_metadata.level)
        await checkIfUserIsBlocked(user)
        userTos = await UserDao.getUserTosAgreement()
        agreeTos = await checkIfUserTosDeclined(userTos)
      }

      if (!userTos?.agreeTOS && !agreeTos) {
        // await agreeToTOS()
        window.open(process.env.VUE_APP_LOGOUT_URL, "_self")
      }

      // const userAccounts = await UserDao.getUserAccounts()

      // if (!user.isAccountLinkingSkipped && userAccounts.length > 0) {
      //   await openLinkAccountsModal()
      // }

      const hash = await UserDao.getUserHash()
      intercom.update({
        email: user.email,
        name: `${user.user_metadata.firstName} ${user.user_metadata.lastName}`,
        phone: user.user_metadata.phone,
        user_hash: hash
      })
      store.commit("setUser", user)
      store.commit("setAuth", true)
      await store.dispatch("setLevel", user.app_metadata.level)
    } catch (e) {
      console.error(e)

      if (e !== USER_BLOCKED) {
        new LocationService().refreshPage()
      }
    }
  }

  static checkIfAgent(level: UserLevel | undefined) {
    if (level === UserLevel.agent) {
      return new LocationService().replaceToAgentUI()
    }
  }
}
