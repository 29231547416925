import { saveAs } from "file-saver"
import { ToastProgrammatic as Toast } from "buefy"
import _ from "lodash"
import { Upload } from "@robocent/components"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

export class FileUtils {
  static downloadFileByExternalUrl(url: string, fileName: string) {
    Toast.open(`Download ${fileName} was started.`)
    return fetch(url, {
      method: "GET",
      responseType: "blob"
    } as any)
      .then((response) =>
        response.blob().then((blob) => saveAs(blob, fileName))
      )
      .catch(() =>
        Toast.open({
          message: `Download ${fileName} failed. Please try again`,
          type: "is-danger"
        })
      )
  }

  static normalizeAudioFileName(file: Upload, orgName: string, ext = "wav") {
    const orgNameNormalized = FileUtils.getNormalizedOrgName(orgName)
    return `${file.humanId}_${orgNameNormalized}.${ext}`
  }

  static getNormalizedOrgName(name: string) {
    return this.getNormalizedName(name).replace(new RegExp(" ", "g"), "_")
  }

  static getNormalizedName(name: string) {
    return name.replace(new RegExp("[^a-zA-Z \\d._-]", "g"), "")
  }

  static straticsAudioPath(file: Upload, campaign: VoiceCampaign) {
    const orgNameNormalized = FileUtils.getNormalizedOrgName(
      campaign.organization.name
    )
    return `${campaign.meta.straticsAcctId}/${file.humanId}_${orgNameNormalized}`
  }

  static getFileExtFromName(fileName: string) {
    const fileNameCopy = _.clone(fileName)
    return fileNameCopy.split(".").pop()
  }

  static recordingBetween(sourceDuration: number) {
    const duration = Math.round(sourceDuration)

    return `${Math.ceil(duration / 15) * 15 - 15} and ${
      Math.ceil(duration / 15) * 15
    } s.`
  }

  static removeAllSpecialCharacters(fileName: string) {
    return fileName.replace(/[^\w\s]/gi, "")
  }
}
