










import Component from "vue-class-component"
import AgentsPayoutTable from "@/components/agent/AgentsPayoutTable.vue"
import AgentsPayoutMixin from "@/mixins/AgentsPayoutMixin"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: {
    AgentsPayoutTable
  }
})
export default class OpenPayouts extends AgentsPayoutMixin {
  async created() {
    EventBus.getAgentsPayouts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getAgentsPayouts as any)
    await this.getAgentsPayouts({ paid: false })
    this.totals = this.getTotals(this.payouts)
  }
}
