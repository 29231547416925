import { Auth } from "@/auth/auth"
import { adminRoutes } from "@/router/adminRoutes"
import { organizationRoutes } from "@/router/organizationRoutes"
import AdminParent from "@/views/admin/AdminParent.vue"
import Help from "@/views/Help.vue"
import OrganizationParent from "@/views/organization/OrganizationParent.vue"
import Dashboard from "@/views/Dashboard.vue"
import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import SharedPage from "@/views/shared/SharedPage.vue"
import { sharedRoutes } from "@/router/sharedRoutes"
import { UserProfile } from "@robocent/components"
import Scheduler from "@/views/shedulers/Scheduler.vue"
import { VoiceCampaignService } from "@/services/voiceCampaignService"
import SharedVoiceCampaignResults from "@/views/shared/voiceCampaign/SharedVoiceCampaignResults.vue"
import { UploadService } from "@/services/uploadService"
import SharedUpload from "@/views/shared/SharedUpload.vue"
import NotFound from "@/views/NotFound.vue"
import VoterDataBuilder from "@/voterData/VoterDataBuilder.vue"
import RequestsList from "@/voterData/RequestsList.vue"
import { isPartner, isSalesManager, isSuper } from "@/router/guards"
import multiguard from "vue-router-multiguard"
import PartnerPortal from "@/views/partnerPortal/PartnerPortal.vue"
import SalesPortal from "@/views/salesPortal/SalesPortal.vue"
import { salesRoutes } from "@/router/salesRoutes"
import SalesManagerParent from "@/views/salesPortal/SalesManagerParent.vue"
import useIntercom from "@/utils/intercom"

Vue.use(VueRouter)

const intercom = useIntercom()

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    beforeEnter: Auth.requireAuth,
    component: Dashboard
  },
  { path: "/signup", redirect: "/" },
  {
    path: "/organizations/:orgId",
    beforeEnter: Auth.requireAuth,
    component: OrganizationParent,
    children: organizationRoutes
  },
  {
    path: "/admin",
    beforeEnter: multiguard([Auth.requireAuth, isSuper]),
    component: AdminParent,
    children: adminRoutes
  },
  {
    path: "/sales",
    beforeEnter: multiguard([Auth.requireAuth, isSalesManager]),
    component: SalesManagerParent,
    children: salesRoutes
  },
  {
    path: "/scheduler",
    name: "CreateScheduler",
    beforeEnter: Auth.requireAuth,
    component: Scheduler
  },
  {
    path: "/voter-data",
    name: "Request Form",
    beforeEnter: Auth.requireAuth,
    component: VoterDataBuilder
  },
  {
    path: "/voter-data/requests",
    name: "All Requests",
    beforeEnter: Auth.requireAuth,
    component: RequestsList
  },
  {
    path: "/help",
    name: "Help",
    beforeEnter: Auth.requireAuth,
    component: Help
  },
  {
    path: "/profile",
    name: "UserProfile",
    beforeEnter: Auth.requireAuth,
    component: UserProfile as any
  },
  {
    path: "/partner-portal",
    name: "PartnerPortal",
    beforeEnter: multiguard([Auth.requireAuth, isPartner]),
    component: PartnerPortal as any
  },
  {
    path: "/sales-portal",
    name: "SalesPortal",
    beforeEnter: multiguard([Auth.requireAuth, isSalesManager]),
    component: SalesPortal
  },
  {
    path: "/shared/:token",
    name: "Shared",
    component: SharedPage,
    children: sharedRoutes
  },
  {
    path: "/shared/campaigns/:campaignUUID",
    beforeEnter: VoiceCampaignService.isPublic,
    component: SharedPage,
    children: [
      {
        path: "/",
        name: "Shared Voice Campaign",
        component: SharedVoiceCampaignResults
      }
    ]
  },
  {
    path: "/shared/uploads/:uploadId",
    beforeEnter: UploadService.isPublic,
    component: SharedPage,
    children: [
      {
        path: "/",
        name: "Shared Upload",
        component: SharedUpload
      }
    ]
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.afterEach(() => {
  intercom.update()
})

export default router
