import { render, staticRenderFns } from "./OrganizationAllFiles.vue?vue&type=template&id=15957c71&scoped=true&"
import script from "./OrganizationAllFiles.vue?vue&type=script&lang=ts&"
export * from "./OrganizationAllFiles.vue?vue&type=script&lang=ts&"
import style0 from "./OrganizationAllFiles.vue?vue&type=style&index=0&id=15957c71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15957c71",
  null
  
)

export default component.exports