import { Module } from "vuex"
import _ from "lodash"
import { DemographicsInfo } from "@/voterData/interfaces/DemographicsInfo"
import { State } from "@/voterData/interfaces/state"

const initState = {
  gender: [],
  age: [],
  ethnicity: []
}

export const demographicsInfo = <Module<DemographicsInfo, State>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    gender: (state) => state.gender,
    age: (state) => state.age,
    ethnicity: (state) => state.ethnicity
  },
  mutations: {
    updateGender: (state, value: string[]) => {
      state.gender = value
    },
    updateAge: (state, value: string[]) => {
      state.age = value
    },
    updateEthnicity: (state, value: string[]) => {
      state.ethnicity = value
    },
    clearState: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key as keyof DemographicsInfo] = _.cloneDeep(initState as any)[
          key
        ]
      })
    }
  }
}
