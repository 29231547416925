












import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import AddNewCardModal from "@/components/modals/AddNewCardModal.vue"
import { Customer } from "@robocent/components"
import { Emit, Prop } from "vue-property-decorator"

@Component
export default class OrganizationNoCardMessage extends VueComponent {
  @Prop() orgId!: string

  async openAddNewCardModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AddNewCardModal,
      width: 500,
      props: {
        orgId: this.orgId
      },
      events: {
        added: this.added
      }
    })
  }

  @Emit()
  added(customer: Customer) {
    return customer
  }
}
