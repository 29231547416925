import axios from "axios"
import { OrganizationPartnerSettings } from "@/interfaces/partnerPortal/organizationPartnerSettings"
import { OrganizationPartnerType } from "@/interfaces/partnerPortal/organizationPartnerType"
import { Feature } from "@/interfaces/feature/feature"
import { OrganizationPartnerService } from "@/interfaces/partnerPortal/organizationPartnerService"
import { PartnerPortalTotals } from "@/interfaces/partnerPortal/partnerPortalTotals"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { OrganizationExtended } from "@/interfaces/partnerPortal/organizationWithTotals"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { PartnerPayoutWithCampaign } from "@/interfaces/partnerPortal/partnerPayoutWithCampaign"
import { PartnerPayoutTotals } from "@/interfaces/partnerPortal/partnerPayoutTotals"
import { RequestedPayout } from "@robocent/components"

export class PartnerPortalDao {
  static async getOrganizationPartnerSettings(
    orgId: string
  ): Promise<OrganizationPartnerType[]> {
    return (await axios.get(`/partner-portal/organizations/${orgId}/settings`))
      .data
  }

  static async saveOrganizationPartnerSettings(
    orgId: string,
    settings: OrganizationPartnerSettings
  ) {
    return await axios.post(
      `/partner-portal/organizations/${orgId}/settings`,
      settings
    )
  }

  static async updateOrganizationPartnerSettings(
    orgId: string,
    settings: OrganizationPartnerSettings
  ) {
    return await axios.put(
      `/partner-portal/organizations/${orgId}/settings`,
      settings
    )
  }

  static async getOrganizationPartnerService(
    orgId: string,
    params: { type: Feature }
  ): Promise<OrganizationPartnerService> {
    return (
      await axios.get(`/partner-portal/organizations/${orgId}/service`, {
        params
      })
    ).data
  }

  static async getPartnerOrganizations(
    params: FindManyFilter
  ): Promise<{ data: OrganizationExtended[]; total: number }> {
    return (
      await axios.get(`/partner-portal/partner-orgs`, {
        params
      })
    ).data
  }

  static async getPartnerSettings(
    orgId: string
  ): Promise<OrganizationPartnerType[]> {
    return (
      await axios.get(`/partner-portal/organizations/${orgId}/partner-settings`)
    ).data
  }

  static async getPartnerPayoutsWithCampaigns(
    orgId: string,
    params: {
      type: Feature | null
      status: PartnerPayoutStatus
    }
  ): Promise<PartnerPayoutWithCampaign[]> {
    return (
      await axios.get(`/partner-portal/organizations/${orgId}/payout`, {
        params
      })
    ).data
  }

  static async getPartnerTotalEarnings(): Promise<PartnerPortalTotals> {
    return (await axios.get(`/partner-portal/total`)).data
  }

  static async getAllPartnersPayouts(params: {
    status: PartnerPayoutStatus
  }): Promise<PartnerPayoutTotals[]> {
    return (await axios.get(`/partner-portal/payouts`, { params })).data
  }

  static async requestPartnerPayout(amount: string) {
    return await axios.post(`/partner-portal/request-payout`, { amount })
  }

  static async getRequestedPartnerPayout(): Promise<RequestedPayout> {
    return (await axios.get(`/partner-portal/requested-payout`)).data
  }

  static async getRequestedPartnerPayouts(): Promise<RequestedPayout[]> {
    return (await axios.get(`/partner-portal/requested-payouts`)).data
  }

  static async getTotalEarningsByService(orgId: string): Promise<{
    [Feature.smsCampaign]: PartnerPortalTotals
    [Feature.voiceCampaign]: PartnerPortalTotals
  }> {
    return (
      await axios.get(`/partner-portal/organizations/${orgId}/service-totals`)
    ).data
  }

  static async getPartnerPayoutsByUser(
    userId: string,
    orgId: string,
    params: {
      type: Feature | null
      status: PartnerPayoutStatus
    }
  ): Promise<PartnerPayoutWithCampaign[]> {
    return (
      await axios.get(
        `/partner-portal/organizations/${orgId}/users/${userId}/payouts`,
        { params }
      )
    ).data
  }

  static async getTotalEarningsByServiceAndUser(
    userId: string,
    orgId: string
  ): Promise<{
    [Feature.smsCampaign]: PartnerPortalTotals
    [Feature.voiceCampaign]: PartnerPortalTotals
  }> {
    return (
      await axios.get(
        `/partner-portal/organizations/${orgId}/users/${userId}/service-totals`
      )
    ).data
  }

  static async getSinglePartnerPayout(userId: string): Promise<number> {
    return (await axios.get(`/partner-portal/users/${userId}/payouts`)).data
  }

  static async markPayoutsAsPaid(
    userId: string,
    data: { check: string; amount: string }
  ) {
    return await axios.post(
      `/partner-portal/users/${userId}/payouts/mark-as-paid`,
      data
    )
  }
}
