



































































import _ from "lodash"
import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { InvoiceItem } from "@/interfaces/invoiceItem"
import InvoiceItemMixin from "@/mixins/InvoiceItemMixin"

@Component
export default class ApproveFee extends mixins(InvoiceItemMixin) {
  @Prop() fees!: InvoiceItem[]
  @Prop() tags!: InvoiceItem[]

  availableTags: InvoiceItem[] = []

  mounted() {
    this.cloneTags()
  }

  addInvoiceItemToList() {
    this.$emit("addInvoiceItemToList", _.cloneDeep(this.invoiceItemTemplate))
    this.cloneTags()
    this.resetInvoiceItemTemplate()
  }

  cloneTags() {
    this.availableTags = _.cloneDeep(this.tags)
  }
}
