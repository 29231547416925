




















































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Tour from "@/views/tour/Tour.vue"
import { PhoneListType } from "@robocent/components"
import { CampaignDirection } from "@/enums/campaignDirection"
import DashboardAction from "@/components/dashboard/DashboardAction.vue"
import { links } from "@/data/links"

@Component({
  components: { DashboardAction, Tour }
})
export default class Dashboard extends VueComponent {
  phoneListTypes = PhoneListType
  campaignDirection = CampaignDirection

  dashboardActions = {
    textCampaign: {
      name: "Schedule a Text Message Campaign",
      guideLink: links.howToScheduleTextMessage,
      icon: "comments"
    },
    voiceCampaign: {
      name: "Schedule a Voice Campaign",
      guideLink: links.howToScheduleVoiceCampaign,
      icon: "phone"
    },
    uploadPhoneList: {
      name: "Upload a phone list or audio file",
      guideLink: links.howToScrubAList,
      icon: "address-book"
    },
    requestVoterData: {
      name: "Request Voter Data",
      guideLink: links.voterData,
      icon: "search-location"
    },
    recordCIRL: {
      name: "Record Audio File for RoboCall over the phone",
      guideLink: "",
      icon: "phone"
    },
    viewCampaigns: {
      name: "View Scheduled Campaigns",
      guideLink: "",
      icon: "comments"
    }
  }

  get orgId() {
    return this.$store.getters.lastOrg?._id
  }
}
