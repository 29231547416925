import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { CampaignDirection } from "@/enums/campaignDirection"
import { FindManyFilter } from "@/interfaces/findManyFilter"

@Component
export default class CampaignsListMixin extends VueComponent {
  applyQuery(filter: FindManyFilter & { direction?: CampaignDirection }) {
    let query: any

    if (filter.direction && filter.direction !== this.$route.query.direction) {
      query = Object.assign({}, filter)
    } else {
      query = Object.assign({}, this.$route.query, filter)
    }

    this.$router.push({ query })
  }
}
