import { render, staticRenderFns } from "./OrganizationImages.vue?vue&type=template&id=eca02be6&scoped=true&"
import script from "./OrganizationImages.vue?vue&type=script&lang=ts&"
export * from "./OrganizationImages.vue?vue&type=script&lang=ts&"
import style0 from "./OrganizationImages.vue?vue&type=style&index=0&id=eca02be6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eca02be6",
  null
  
)

export default component.exports