









































































import Component from "vue-class-component"
import { InputWithValidation, Script } from "@robocent/components"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import { links } from "@/data/links"

@Component({
  components: { InputWithValidation, ChooseRow, ChooseItem }
})
export default class ScriptInfoStep extends VueComponent {
  @Prop() script!: Script
  docsLinks = links

  onIntroChanged(value: boolean) {
    this.script.intro = value
    this.script.isDefaultScriptNeeded = value
  }
}
