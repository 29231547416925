import { render, staticRenderFns } from "./ListsEmptyMessage.vue?vue&type=template&id=f098a92e&scoped=true&"
import script from "./ListsEmptyMessage.vue?vue&type=script&lang=ts&"
export * from "./ListsEmptyMessage.vue?vue&type=script&lang=ts&"
import style0 from "./ListsEmptyMessage.vue?vue&type=style&index=0&id=f098a92e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f098a92e",
  null
  
)

export default component.exports