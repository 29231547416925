



























































import Component from "vue-class-component"
import { CampaignScheduledDate, ListCard, statuses } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"
import { voiceCampaignStatuses } from "@/data/voiceCampaign/voiceCampaignStatuses"
import CampaignLinkToOrg from "@/components/campaign/CampaignLinkToOrg.vue"
import moment from "moment-timezone"
import VoiceCampaignInfoTags from "@/components/campaign/voiceCampaign/VoiceCampaignInfoTags.vue"
import VoiceCampaignInvoice from "@/components/campaign/voiceCampaign/VoiceCampaignInvoice.vue"
import VoiceCampaignActions from "@/components/campaign/voiceCampaign/VoiceCampaignActions.vue"
import VoiceCampaignDropdown from "@/components/campaign/voiceCampaign/VoiceCampaignDropdown.vue"
import InvoiceButton from "@/components/checkout/InvoiceButton.vue"
import { EventBus } from "@/services/eventBus"
import VoiceAdminActionsDropdown from "@/components/campaign/voiceCampaign/VoiceAdminActionsDropdown.vue"
import InvoiceCreditNotes from "@/components/elements/InvoiceCreditNotes.vue"

@Component({
  components: {
    InvoiceCreditNotes,
    VoiceAdminActionsDropdown,
    InvoiceButton,
    VoiceCampaignDropdown,
    VoiceCampaignActions,
    VoiceCampaignInfoTags,
    CampaignLinkToOrg,
    VoiceCampaignInvoice,
    ListCard,
    CampaignScheduledDate
  }
})
export default class VoiceCampaignItem extends VueComponent {
  voiceStatuses = voiceCampaignStatuses
  statuses = statuses
  @Prop() voiceCampaign!: VoiceCampaign

  get isInvoiceStatusDisplayed() {
    const isCallStatusHasHigherPriority =
      this.voiceCampaign.meta.status === VoiceCampaignStatus.canceled ||
      this.voiceCampaign.meta.status === VoiceCampaignStatus.pendingCancel

    return Boolean(
      this.voiceCampaign.meta.invoiceId &&
        this.voiceCampaign.invoice &&
        !isCallStatusHasHigherPriority
    )
  }

  get campaignStartTime() {
    const utcTime = moment
      .utc(this.voiceCampaign.time)
      .tz(this.voiceCampaign.timezone)
      .toDate()
    return moment(utcTime)
  }

  get statusToType() {
    const status = this.isInvoiceStatusDisplayed
      ? this.voiceCampaign.invoice?.status
      : this.voiceCampaign.meta.status
    return {
      "is-danger":
        status === statuses.open || status === VoiceCampaignStatus.rejected,
      "is-success":
        status === statuses.paid || status === VoiceCampaignStatus.approved,
      "is-twitter":
        status === VoiceCampaignStatus.canceled ||
        status === VoiceCampaignStatus.underReview,
      "is-warning":
        status === VoiceCampaignStatus.pendingCancel ||
        status === VoiceCampaignStatus.pending,
      "is-primary":
        status === statuses.uncollectible ||
        status === statuses.void ||
        status === VoiceCampaignStatus.archived ||
        status === VoiceCampaignStatus.deleted,
      "is-light": status === statuses.draft || !status
    }
  }

  get statusToShow() {
    if (this.voiceCampaign.invoice?.status === statuses.draft) {
      return VoiceCampaignStatus.approved
    }

    return this.voiceCampaign.invoice?.status
  }

  get isDisabled() {
    return this.voiceCampaign.meta.deleted ? "disabled" : ""
  }

  onInvoiceChange() {
    return EventBus.getCampaigns$.next()
  }
}
