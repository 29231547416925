

























import Component from "vue-class-component"
import PartnerPortalTotalEarnings from "@/components/partnerPortal/PartnerPortalTotalEarnings.vue"
import PartnerOrganizations from "@/components/partnerPortal/partner/PartnerOrganizations.vue"
import PartnerServiceCategories from "@/components/partnerPortal/PartnerServiceCategories.vue"
import PartnerPayouts from "@/components/partnerPortal/PartnerPayouts.vue"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import PartnerPortalMixin from "@/mixins/partnerPortal/PartnerPortalMixin"
import { Feature } from "@/interfaces/feature/feature"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({
  components: {
    PartnerPayouts,
    PartnerServiceCategories,
    PartnerOrganizations,
    PartnerPortalTotalEarnings
  }
})
export default class PartnerPortal extends PartnerPortalMixin {
  created() {
    this.$store.commit("partnerPortal/clear")
  }

  async getPartnerPayouts(params: {
    type: Feature | null
    status: PartnerPayoutStatus
  }) {
    this.payouts = []

    await this.request(
      async () => {
        this.payouts = await PartnerPortalDao.getPartnerPayoutsWithCampaigns(
          this.selectedOrg,
          params
        )
      },
      undefined,
      undefined,
      "payouts"
    )
  }

  async getServiceTotals() {
    await this.request(
      async () => {
        this.servicesTotals = await PartnerPortalDao.getTotalEarningsByService(
          this.selectedOrg
        )
      },
      undefined,
      undefined,
      "totals"
    )
  }
}
