



















































import Component, { mixins } from "vue-class-component"
import InvoiceItemMixin from "@/mixins/InvoiceItemMixin"
import { InvoiceItem } from "@/interfaces/invoiceItem"

@Component
export default class ApproveMultipliers extends mixins(InvoiceItemMixin) {
  invoiceItemTemplate: InvoiceItem = {
    description: "Multiplier",
    amount: null
  }

  getInvoiceAmount(amount: number | null) {
    if (amount) {
      return Number(amount).toFixed(4)
    }
  }
}
