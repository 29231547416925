

























































import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import CampaignAdminActionsDropdown from "@/components/campaign/CampaignAdminActionsDropdown.vue"
import { EventBus } from "@/services/eventBus"
import { VoiceCampaignStatus } from "@/enums/voiceCampaignStatus"
import RefundVoiceCampaign from "@/components/campaign/voiceCampaign/RefundVoiceCampaign.vue"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { CampaignStatus } from "@robocent/components"
import DeleteCampaignMixin from "@/mixins/campaign/DeleteCampaignMixin"
import { CampaignDao } from "@/services/dao/campaignDao"

@Component({
  components: { CampaignAdminActionsDropdown }
})
export default class VoiceAdminActionsDropdown extends mixins(
  DeleteCampaignMixin
) {
  @Prop() voiceCampaign!: VoiceCampaign
  campaignStatus = CampaignStatus

  get isCancelationAvailable() {
    return this.voiceCampaign.meta.status === VoiceCampaignStatus.pendingCancel
  }

  onCancelClick() {
    this.$buefy.modal.open({
      component: RefundVoiceCampaign,
      width: 500,
      props: { campaign: this.voiceCampaign },
      events: {
        onConfirm: () => EventBus.getCampaigns$.next()
      }
    })
  }

  async onDeleteInvoice() {
    await this.request(async () => {
      await BillingDao.deleteInvoice(this.voiceCampaign.invoice?.id)
    })
    await this.updateCampaignStatus()
    EventBus.getCampaigns$.next()
  }

  async updateCampaignStatus() {
    await this.request(async () => {
      await CampaignDao.updateCampaignStatus(
        this.voiceCampaign.uuid,
        CampaignStatus.pending
      )
    })
  }
}
