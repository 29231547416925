


























import Component from "vue-class-component"
import { Organization } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import CreateNewOrganizationModal from "@/components/modals/CreateOrganizationModal.vue"

@Component
export default class OrganizationsNotifications extends VueComponent {
  @Prop() organizations!: Organization[]

  get buttonAvailable(): boolean {
    return Boolean(!this.$route.query.user)
  }

  get isAdminPage() {
    return this.$route.name?.includes("Admin")
  }

  openCreateOrganizationModal() {
    this.$buefy.modal.open({
      width: 640,
      component: CreateNewOrganizationModal,
      parent: this
    })
  }
}
