




















import Component from "vue-class-component"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import VoiceCampaignEstimatedCost from "@/components/scheduler/voiceElements/VoiceCampaignEstimatedCost.vue"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import { Prop } from "vue-property-decorator"
import { Customer } from "@robocent/components"

@Component({
  components: { VoiceCampaignEstimatedCost, ChooseItem, ChooseRow }
})
export default class VoiceCampaignCost extends VoiceSchedulerUtilsMixin {
  @Prop() customer!: Customer
}
