














import DndKeywords from "../../components/dnd/DndKeywords.vue"
import DndList from "../../components/dnd/DndList.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: { DndList, DndKeywords }
})
export default class OrganizationDND extends VueComponent {}
