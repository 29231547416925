















































import OrganizationItem from "@/components/organization/OrganizationItem.vue"
import { GenericFilter, Organization, StringObject } from "@robocent/components"
import Component from "vue-class-component"
import { Pagination } from "@/utils/pagination"
import OrganizationsNotifications from "@/components/organization/OrganizationsNotifications.vue"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import OrganizationsEmptyMessage from "@/components/organization/OrganizationsEmptyMessage.vue"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { OrderBy } from "@/enums/orderBy"
import SearchItem from "@/components/scheduler/elements/SearchItem.vue"
import ShowDeletedFilter from "@/components/elements/ShowDeletedFilter.vue"
import OrderingFilterMixin from "@/mixins/OrderingFilterMixin"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"

@Component({
  components: {
    PaginationItem,
    ShowDeletedFilter,
    SearchItem,
    OrganizationsEmptyMessage,
    OrganizationsNotifications,
    OrganizationItem,
    GenericFilter
  }
})
export default class Organizations extends OrderingFilterMixin {
  pagination = new Pagination(0, 1, 25)
  organizations: Organization[] = []
  credits = {}
  params: StringObject = {}
  search = ""
  isDeletedFilterEnabled = false

  get user(): object {
    return this.$store.getters.user
  }

  get hasMore(): boolean {
    return (
      Boolean(this.params.q) ||
      this.pagination.total > this.pagination.perPage ||
      Boolean(this.params.filter)
    )
  }

  get atLeastOnePage() {
    return this.pagination.total > this.pagination.perPage || this.search
  }

  created() {
    this.getOrganizations()
    EventBus.getOrganizations$
      .pipe(whileComponentMounted(this))
      .subscribe((searchQuery) => this.getOrganizations(searchQuery as any))
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getOrganizations()
  }

  async getOrganizations(searchQuery?: { q: string }) {
    this.params = {
      page: this.pagination.current,
      orderBy: this.orderBy,
      deleted: String(this.isDeletedFilterEnabled),
      ...searchQuery
    }

    if (this.$route.query.user) {
      delete this.params.user
      this.params = { ...this.params, userId: this.$route.query.user }
    }

    this.loading = true
    try {
      const data = await this.$route.meta["organizations"](this.params)
      this.setOrganizations(data)
    } finally {
      this.loading = false
    }
  }

  setOrganizations(orgData: { data: Organization[]; total: number }) {
    this.organizations = orgData.data
    this.pagination.total = orgData.total
    this.getCustomers()
  }

  async getCustomers() {
    if (!this.$store.getters.super) {
      return
    }

    for (const org of this.organizations) {
      try {
        const customer = await SmsBillingDao.getCustomer(org._id)
        const balance = customer.balance < 0 ? customer.balance * -1 : 0
        this.$set(this.credits, org._id, balance)
      } catch (e) {
        this.$set(this.credits, org._id, "–")
      }
    }
  }

  onToggleFilter(filter: OrderBy) {
    this.orderBy = filter
    this.getOrganizations()
  }

  onToggleDeleted(deletedFilter: boolean) {
    this.isDeletedFilterEnabled = deletedFilter
    this.getOrganizations()
  }
}
