


















































































































import { VueComponent } from "../../utils/VueComponent"
import Component from "vue-class-component"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: {
    ChooseRow,
    ChooseItem
  }
})
export default class Tour extends VueComponent {
  tours = [
    {
      key: "scheduleTextCampaign",
      title: "Schedule Text Campaign",
      description: "Schedule Campaign Tour",
      icon: "comments"
    },
    {
      key: "scheduleVoiceCampaign",
      title: "Schedule Voice Campaign",
      description: "Schedule Campaign Tour",
      icon: "phone"
    },
    {
      key: "voterData",
      title: "Buy Voter Data",
      description: "Buy Voter Data Tour",
      icon: "address-book"
    }
  ]
  selectedTour = ""
  activeStep = 0
  toursData = {
    scheduleTextCampaign: {
      action: {
        link: "/scheduler",
        label: "Schedule Text Campaign",
        description:
          "We think that you are ready to schedule your own Campaign!"
      },
      data: [
        {
          label: "Schedule",
          icon: "calendar-alt",
          caption:
            "Choose the type of marketing you want to schedule and follow step-by-step on scheduler. We've added tips for each step to make it easier for you to fill out the scheduler",
          img: "schedule-001"
        },
        {
          label: "Upload Phone List",
          icon: "upload",
          caption:
            "Upload your Phone List (Excel or CSV format) by clicking the button or dropping your file on the Uploads Page",
          img: "schedule-004"
        },
        {
          label: "Scrub Phone List",
          icon: "columns",
          caption:
            "Scrub your phone list using our complementary Phone List Scrubber to return a count of all working phone numbers",
          img: "schedule-005"
        },
        {
          label: "Script",
          icon: "keyboard",
          caption: "Create the script you want to send",
          img: "schedule-006"
        },
        {
          label: "Cost",
          icon: "credit-card",
          caption:
            "At the last step you will see cost of the campaign and you can complete the schedule",
          img: "schedule-002"
        },
        {
          label: "Results",
          icon: "info-circle",
          caption: "You can see Live Results and Details",
          img: "schedule-003"
        }
      ]
    },
    scheduleVoiceCampaign: {
      action: {
        link: "/scheduler",
        label: "Schedule Voice Campaign",
        description:
          "We think that you are ready to schedule your own Campaign!"
      },
      data: [
        {
          label: "Schedule",
          icon: "calendar-alt",
          caption:
            "Choose the type of voice marketing you want to schedule and follow step-by-step on scheduler. We've added tips for each step to make it easier for you to fill out the scheduler",
          img: "voice-001"
        },
        {
          label: "Upload Phone List",
          icon: "upload",
          caption:
            "Upload your Phone List (Excel or CSV format) by clicking the button or dropping your file on the Uploads Page",
          img: "schedule-004"
        },
        {
          label: "Scrub Phone List",
          icon: "columns",
          caption:
            "Scrub your phone list using our complementary Phone List Scrubber to return a count of all working phone numbers",
          img: "schedule-005"
        },
        {
          label: "Audio",
          icon: "file-audio",
          caption: "Select or upload new one audio file you want to send",
          img: "voice-002"
        },
        {
          label: "Cost",
          icon: "credit-card",
          caption:
            "At the last step you will see cost of the campaign and you can complete the schedule",
          img: "schedule-002"
        },
        {
          label: "Results",
          icon: "info-circle",
          caption: "You can see Live Results and Details",
          img: "schedule-003"
        }
      ]
    },
    voterData: {
      action: {
        link: "/voter-data",
        label: "Request Voter Data",
        description: "We think that you are ready to request Voter Data!"
      },
      data: [
        {
          label: "Fill",
          icon: "calendar-alt",
          caption:
            "Follow step-by-step by form. We've added tips for each step to make it easier for you to fill out the form",
          img: "voterData-001"
        },
        {
          label: "Submit",
          icon: "calendar-alt",
          caption:
            "At the last step, you will see an info summary and you can request Voter Data",
          img: "voterData-002"
        },
        {
          label: "Results",
          icon: "info-circle",
          caption: "You can see Results and Details for your Voter Data",
          img: "voterData-003"
        },
        {
          label: "Files",
          icon: "file",
          caption: "You can see Files for your Voter Data",
          img: "voterData-004"
        }
      ]
    }
  }

  scrollTourInView() {
    this.$nextTick(() => this.$scrollTo("#b-steps", { offset: 4 }))
  }

  select(key: string) {
    this.selectedTour = key
    this.activeStep = 0
    this.scrollTourInView()
  }

  isSelected(key: string) {
    return this.selectedTour === key
  }

  onImageClick(action: any) {
    this.$buefy.toast.open({
      message: `This is a demo mode, navigate to <strong>${action.label}</strong> if you want try this`,
      position: "is-top",
      type: "is-light"
    })
  }
}
