import Vue from "vue"
import {
  confirmed,
  digits,
  email,
  length,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  numeric,
  required
} from "vee-validate/dist/rules"
import en from "vee-validate/dist/locale/en.json"
import { extend } from "vee-validate"

extend("required", {
  ...required,
  message: en.messages.required
})

extend("length", {
  ...length,
  message: en.messages.length
})

extend("phone-number", {
  message: (field) => `The ${field} field should contain 10 digits`,
  validate: (value) => value.length === 12
})

extend("phone-number-mask", {
  message: (field) => `The ${field} field should contain 10 digits`,
  validate: (value) => value.length === 15
})

extend("credit-card-number", {
  message: (field) => `Invalid Credit Card ${field}`,
  validate: (value) => Vue.prototype.$cardFormat.validateCardNumber(value)
})

extend("credit-card-expire", {
  message: (field) => `Invalid ${field} Date`,
  validate: (value) => Vue.prototype.$cardFormat.validateCardExpiry(value)
})

extend("credit-card-cvc", {
  message: (field) => `Invalid ${field}`,
  validate: (value) => Vue.prototype.$cardFormat.validateCardCVC(value)
})

extend("min_value", {
  ...minValue,
  message: en.messages.min_value
})

extend("max_value", {
  ...maxValue,
  message: en.messages.max_value
})

extend("max", {
  ...max,
  message: en.messages.max
})

extend("min", {
  ...min,
  message: en.messages.min
})

extend("email", {
  ...email,
  message: en.messages.email
})

extend("digits", {
  ...digits,
  message: en.messages.digits
})

extend("numeric", {
  ...numeric,
  message: en.messages.numeric
})

extend("confirmed", {
  ...confirmed,
  message: en.messages.confirmed
})
