

































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import {
  CardTable,
  CardTableItem,
  Script,
  ScriptStatus
} from "@robocent/components"
import CardItem from "@/components/elements/CardItem.vue"
import Card from "@/components/elements/Card.vue"

@Component({
  components: { Card, CardItem, CardTable, CardTableItem }
})
export default class CampaignScriptsNotifications extends VueComponent {
  @Prop() campaignId!: number
  @Prop() organizationId!: string
  @Prop({ default: () => [] }) scripts!: Script[]

  get type() {
    if (this.rejectedScripts.length) {
      return {
        icon: "info-circle",
        type: "is-danger"
      }
    }

    if (this.pendingScripts.length || this.isWithoutScripts) {
      return {
        icon: "exclamation-circle",
        type: "is-warning"
      }
    }

    return {
      icon: "check-circle",
      type: "is-success"
    }
  }

  get genericMessage() {
    const message = []

    if (this.isWithoutScripts) {
      return "You don't have scripts for this campaign yet"
    }

    if (this.rejectedScripts.length) {
      message.push(`${this.rejectedScripts.length} rejected`)
    }

    if (this.pendingScripts.length) {
      message.push(`${this.pendingScripts.length} pending`)
    }

    if (message.length === 0) {
      return "All scripts are approved!"
    }

    return `You have ${message.join(" and ")} scripts`
  }

  get pendingScripts() {
    return this.scripts.filter(
      (script) => script.status === ScriptStatus.pending
    )
  }

  get approvedScripts() {
    return this.scripts.filter(
      (script) => script.status === ScriptStatus.approved
    )
  }

  get rejectedScripts() {
    return this.scripts.filter(
      (script) => script.status === ScriptStatus.rejected
    )
  }

  get isWithoutScripts() {
    return this.scripts.length === 0
  }
}
