export enum VoiceSchedulerSteps {
  campaignName = "campaignName",
  campaignType = "campaignType",
  campaignDate = "campaignDate",
  campaignPhoneLists = "campaignPhoneLists",
  campaignAudio = "campaignAudio",
  campaignVM = "campaignVM",
  campaignPhoneNumbers = "campaignPhoneNumbers",
  campaignCard = "campaignCard",
  campaignDetails = "campaignDetails",
  campaignCost = "campaignCost",
  // Transfer
  campaignTransferOptions = "campaignTransferOptions",
  // Survey
  campaignSurveyScript = "campaignSurveyScript"
}
