


























import Component from "vue-class-component"
import { get } from "lodash"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import { CampaignDao } from "@/services/dao/campaignDao"
import {
  CampaignStatus,
  IdentityStatus,
  ScriptStatus
} from "@robocent/components"
import { ApproveTextCampaign } from "@/interfaces/approveTextCampaign"
import { InvoiceItem } from "@/interfaces/invoiceItem"
import TextOverviewMixin from "@/components/campaign/textAdminReview/TextOverviewMixin"
import { TextScheduleService } from "@/services/text/textScheduleService"
import ApproveTextCampaignModal from "@/components/campaign/textAdminReview/modals/ApproveTextCampaignModal.vue"
import { TextCampaignConfigureDao } from "@/services/dao/p2p/textCampaignConfigureDao"
import { TextPhoneListService } from "@/services/text/textPhoneListService"
import { OrganizationAccountDao } from "@/services/dao/organizationAccountDao"

@Component
export default class TextCampaignReviewHeader extends TextOverviewMixin {
  scheduleService = new TextScheduleService()
  phoneListService = new TextPhoneListService()

  get isApproveDisabled() {
    return (
      this.campaign.status !== CampaignStatus.pending ||
      // this.ifProfileUnSubmitted ||
      !this.atLeastOneScriptApproved ||
      !this.isEstimatedMobileExist
    )
  }

  get atLeastOneScriptApproved() {
    return Boolean(
      this.campaign.scripts.filter(
        (script) => script.status === ScriptStatus.approved
      ).length
    )
  }

  get isEstimatedMobileExist() {
    return Boolean(this.list?.meta?.details?.mobile)
  }

  get ifProfileSubmitted() {
    return (
      this.campaign.organization.identity?.status === IdentityStatus.submitted
    )
  }

  created() {
    EventBus.approveCampaign$
      .pipe(whileComponentMounted(this))
      .subscribe((data) => this.doPostConfirmSteps(data as ApproveTextCampaign))
  }

  async doPreConfirmSteps() {
    await this.request(async () => {
      if (!this.status.isScheduleReady) {
        await this.scheduleService.createTextSchedule(this.campaign)
        this.status.isScheduleReady = true
      }

      if (!this.status.isIdentityCreated) {
        await OrganizationAccountDao.createIdentity(
          this.campaign.organizationId
        )
        this.status.isIdentityCreated = true
        await this.updateCampaign()
      }

      if (!this.status.isPhoneListReady) {
        await this.phoneListService.processPhoneListUploading(this.campaign)
        this.status.isPhoneListReady = true
      }

      if (!this.status.isJobReady) {
        await this.updateCampaign()
      }

      await this.openApprovalModal()
    })
  }

  async doPostConfirmSteps(data: ApproveTextCampaign) {
    this.loading = true
    try {
      if (!this.status.isJobReady) {
        await TextCampaignConfigureDao.createTextJob(this.campaign.uuid)
        await this.updateCampaign()
      }

      if (!this.status.isCampaignApproved) {
        await this.approveCampaign(data)
      }

      await this.updateCampaign()
    } catch (e) {
      console.error(e)
      this.$buefy.toast.open({
        message:
          get(e, "response.data.message") || "Error creating Job in dialer",
        type: "is-danger"
      })
    } finally {
      this.loading = false
    }
  }

  async approveCampaign(data: ApproveTextCampaign) {
    const preparedData = this.prepareData(data)

    await this.request(async () => {
      await CampaignDao.approveCampaign(this.campaign?.uuid, preparedData)
    })
  }

  prepareData(data: ApproveTextCampaign) {
    const preparedData = { ...data }

    preparedData.multis.forEach(
      (entry: InvoiceItem) => (entry.amount = entry.amount! * 100)
    )
    preparedData.fees.forEach(
      (entry: InvoiceItem) => (entry.amount = Math.round(entry.amount! * 100))
    )
    preparedData.fixedDiscounts.forEach(
      (entry: InvoiceItem) => (entry.amount = Math.round(entry.amount! * 100))
    )

    return preparedData
  }

  async openApprovalModal() {
    const { orgId } = this.$route.params

    this.$buefy.modal.open({
      parent: this,
      component: ApproveTextCampaignModal,
      width: 600,
      props: {
        orgId,
        campaign: this.campaign
      }
    })
  }

  async rejectCampaign() {
    await this.confirm("Do you want to reject this campaign?")
    await this.request(async () => {
      await CampaignDao.rejectCampaign(this.campaign.uuid)
      await this.$router.push({ name: "Org Campaigns" })
    })
  }
}
