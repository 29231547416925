
























































import Component from "vue-class-component"
import { CardTable, CardTableItem } from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { OrganizationPartnerType } from "@/interfaces/partnerPortal/organizationPartnerType"
import { organizationFeature } from "@/data/organizationFeature"
import { organizationPartnerType } from "@/data/organizationPartnerType"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { Feature } from "@/interfaces/feature/feature"
import { PartnerType } from "@/enums/partnerPortal/partnerType"
import { PartnerPortalTotals } from "@/interfaces/partnerPortal/partnerPortalTotals"
import Vue from "vue"

@Component({
  components: {
    StepTemplate,
    CardTable,
    CardTableItem
  }
})
export default class OrganizationPartnerService extends Vue {
  @Prop() partnerType!: OrganizationPartnerType
  @Prop() active!: boolean
  @Prop() loading!: boolean
  @Prop({
    default: () => ({
      commission: { paid: 0, notPaid: 0 },
      discounts: 0
    })
  })
  totals!: PartnerPortalTotals
  orgFeatures = organizationFeature
  partnerTypes = organizationPartnerType

  get icon() {
    const icons = {
      [Feature.smsCampaign]: "comments",
      [Feature.voiceCampaign]: "phone",
      [Feature.voterData]: "address-book"
    }
    return icons[this.partnerType.feature as Feature]
  }

  get isCommission() {
    return this.partnerType.type === PartnerType.commission
  }

  get calculatedTotals() {
    if (this.isCommission) {
      return this.totals?.commission?.paid + this.totals?.commission?.notPaid
    }

    return this.totals?.discounts || 0
  }
}
