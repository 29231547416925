












































import Component, { mixins } from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { Prop } from "vue-property-decorator"
import { Campaign, OrganizationVertical } from "@robocent/components"
import OrganizationStatusMixin from "@/mixins/organization/OrganizationStatusMixin"
import TextCampaignSendingPreference from "@/components/campaign/textCampaign/statuses/TextCampaignSendingPreference.vue"
import TextCampaignStatusIncomplete from "@/components/campaign/textCampaign/statuses/TextCampaignStatusIncomplete.vue"
import NonPoliticalStatusIncomplete from "@/components/campaign/textCampaign/statuses/NonPoliticalStatusIncomplete.vue"
import TextCampaignStatusUnsubmitted from "@/components/campaign/textCampaign/statuses/TextCampaignStatusUnsubmitted.vue"
import TextCampaignStatusSubmitted from "@/components/campaign/textCampaign/statuses/TextCampaignStatusSubmitted.vue"
import SupportGuides from "@/components/campaign/textCampaign/statuses/SupportGuides.vue"

@Component({
  components: {
    SupportGuides,
    Card,
    TextCampaignSendingPreference,
    TextCampaignStatusUnsubmitted,
    TextCampaignStatusIncomplete,
    NonPoliticalStatusIncomplete,
    TextCampaignStatusSubmitted
  }
})
export default class TextCampaignMessageConfirmationModal extends mixins(
  OrganizationStatusMixin
) {
  @Prop()
  campaign!: Campaign

  currentStatus = {
    template: "",
    data: {}
  }

  get needCompleteRegistration() {
    return (
      this.isUnsubmitted(this.identity.status) ||
      this.isIncomplete(this.identity)
    )
  }
  get identity() {
    return this.campaign.organization?.identity
  }

  mounted() {
    this.defineCurrentStatus()
  }

  defineCurrentStatus() {
    if (this.isUnsubmitted(this.identity.status)) {
      this.currentStatus.template = "text-campaign-status-unsubmitted"
      this.currentStatus.data = {
        "organization-identity-link": this.identity.link
      }
      return
    }

    this.currentStatus.data = {
      start: this.campaign.start,
      time: this.campaign.time,
      timezone: this.campaign.timezone
    }

    if (this.isIncomplete(this.identity)) {
      if (this.identity.vertical === OrganizationVertical.political) {
        this.currentStatus.template = "text-campaign-status-incomplete"
      } else {
        this.currentStatus.template = "non-political-status-incomplete"
      }
    }

    if (this.isSubmitted(this.identity.status)) {
      this.currentStatus.template = "text-campaign-status-submitted"
    }
  }

  completeRegistration() {
    window.open(this.identity.link, "_blank")
    this.$parent.close()
  }
}
