































































































import Card from "@/components/elements/Card.vue"
import UploadPhoneListGuide from "@/components/elements/UploadPhoneListGuide.vue"
import TestVoiceCampaignModal from "@/components/modals/voiceCampaign/TestVoiceCampaignModal.vue"
import TestCall from "@/components/elements/voiceCampaign/TestCall.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { VoiceCampaignPublicDao } from "@/services/dao/robocent/voiceCampaignPublicDao"
import RcCostCalc from "@robocent/ccalc"

@Component({
  components: {
    TestCall,
    TestVoiceCampaignModal,
    UploadPhoneListGuide,
    Card,
    RcCostCalc
  }
})
export default class Help extends VueComponent {
  mailToSupport() {
    window.open(
      "mailto:support@robocent.com?subject=New Support Ticket",
      "_blank"
    )
  }

  async call(formattedPhone: string) {
    try {
      this.loading = true
      await VoiceCampaignPublicDao.testCall(formattedPhone)
      setTimeout(() => {
        this.loading = false
      }, 12000)
    } catch (e) {
      this.loading = false
      console.error(e)
    }
  }
}
