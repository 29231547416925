import { Bar } from "vue-chartjs"
import Vue from "vue"

export default Vue.extend({
  extends: Bar,
  props: ["chartData", "options", "labels"],
  watch: {
    chartData: function () {
      this.calculate()
    }
  },
  mounted() {
    this.calculate()
  },
  data() {
    return {
      data: {
        labels: [],
        datasets: []
      }
    }
  },
  methods: {
    calculate() {
      this.$set(this.data, "labels", this.labels)
      this.$set(this.data, "datasets", this.chartData)
      ;(this as any).renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom"
        },
        ...this.options
      })
    }
  }
})
