

















import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import OpenPartnersPayouts from "@/components/partnerPortal/admin/OpenPartnersPayouts.vue"
import PartnerRequestedPayoutsTable from "@/components/partnerPortal/admin/PartnerRequestedPayoutsTable.vue"
import CompletedPartnerPayouts from "@/components/partnerPortal/admin/CompletedPartnerPayouts.vue"

@Component({
  components: {
    CompletedPartnerPayouts,
    PartnerRequestedPayoutsTable,
    OpenPartnersPayouts
  }
})
export default class PartnersPayout extends VueComponent {}
