import { RouteConfig } from "vue-router"
import { Resolvers } from "@/router/resolvers"
import Organizations from "@/views/Organizations.vue"
import Users from "@/views/admin/Users.vue"
import AgentsPayout from "@/views/admin/AgentsPayout.vue"
import Campaigns from "@/views/admin/Campaigns.vue"
import Scripts from "@/views/admin/Scripts.vue"
import Features from "@/views/admin/Features.vue"
import RegisteredAccounts from "@/views/admin/RegisteredAccounts.vue"
import PartnersPayout from "@/views/admin/PartnersPayout.vue"
import PromoCodes from "@/views/admin/PromoCodes.vue"
import SalesPayout from "@/views/admin/SalesPayout.vue"

export const adminRoutes: Array<RouteConfig> = [
  {
    path: "campaigns",
    component: Campaigns,
    props: (route) => ({ query: route.query.type }),
    name: "Admin Campaigns"
  },
  {
    path: "organizations",
    component: Organizations,
    beforeEnter: Resolvers.getAllOrganizations,
    name: "Admin Organizations"
  },
  {
    path: "registered-accounts",
    component: RegisteredAccounts,
    name: "Admin Registered Accounts"
  },
  {
    path: "features",
    component: Features,
    name: "Admin Features"
  },
  {
    path: "users",
    component: Users,
    name: "Admin Users"
  },
  {
    path: "scripts",
    component: Scripts,
    name: "Admin Scripts"
  },
  {
    path: "agent-payout",
    component: AgentsPayout,
    name: "Admin Agents Payouts"
  },
  {
    path: "partner-payout",
    component: PartnersPayout,
    name: "Admin Partners Payouts"
  },
  {
    path: "sales-payout",
    component: SalesPayout,
    name: "Admin Sales Payouts"
  },
  {
    path: "promo-codes",
    component: PromoCodes,
    name: "Admin Promo Codes"
  }
]
