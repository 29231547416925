


















































































import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import PayoutsTableMixin from "@/mixins/PayoutsTableMixin"
import { SalesManagerPayout } from "@/interfaces/salesPortal/salesManagerPayout"
import SalesPortalMixin from "@/mixins/salesPortal/SalesPortalMixin"

@Component({})
export default class SalesManagerPayoutsTable extends mixins(
  PayoutsTableMixin,
  SalesPortalMixin
) {
  @Prop() dataLoading!: boolean
  @Prop({ default: () => [] }) data!: SalesManagerPayout[]
  @Prop() tab!: PartnerPayoutStatus

  isCommissionPaid(row: SalesManagerPayout) {
    return row.salesManagerPayout?.check
  }

  calculateCommission(value: number) {
    const threshold = this.salesRate.threshold

    if (this.salesEarnings.totalSales < threshold) {
      return (value * this.salesRate.rateBefore) / 100
    }

    if (this.salesEarnings.totalSales >= threshold) {
      return (value * this.salesRate.rateAfter) / 100
    }
  }
}
