








import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"

@Component({
  components: {
    Card
  }
})
export default class VoiceCampaignScript extends VueComponent {
  @Prop() voiceCampaign!: VoiceCampaign
}
