




























































import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { VoterDataQuery } from "@/voterData/interfaces/VoterDataQuery"
import QueryItem from "@/voterData/components/QueryItem.vue"
import { StringObject } from "@robocent/components"
import { VoterDataDao } from "@/services/dao/voterDataDao"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"

@Component({
  components: { QueryItem, StepTemplate }
})
export default class RequestsList extends VueComponent {
  queries: VoterDataQuery[] = []
  pricing = {}
  pagination = {
    total: 0,
    current: 1,
    perPage: 10,
    rangeBefore: 3,
    rangeAfter: 3,
    order: "is-centered"
  }
  params: StringObject = {}

  get hasMore(): boolean {
    return this.pagination.total > this.pagination.perPage
  }

  mounted() {
    this.getAllQueriesWithRequests()
    this.getPricing()
    EventBus.getVoterDataRequests$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getAllQueriesWithRequests)
  }

  async getAllQueriesWithRequests() {
    this.params = {
      page: this.pagination.current,
      limit: this.pagination.perPage
    }

    await this.request(async () => {
      const response = await VoterDataDao.getAllRequests(this.params)
      this.queries = response.data
      this.pagination.total = response.total
    })
  }

  async getPricing() {
    this.pricing = await VoterDataDao.getPricing()
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getAllQueriesWithRequests()
  }

  goToRequestForm() {
    window.open(
      `${process.env.VUE_APP_LOGOUT_URL}/voter-data/request-data`,
      "_self"
    )
  }
}
