import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import _ from "lodash"
import { PartnerPayoutTotals } from "@/interfaces/partnerPortal/partnerPayoutTotals"
import { PartnerOrganizationTotals } from "@/interfaces/partnerPortal/partnerOrganizationTotals"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"

@Component({})
export default class PartnerPayoutMixin extends VueComponent {
  payouts: PartnerPayoutTotals[] = []
  totals: { discounts: number; commission: number } = {
    discounts: 0,
    commission: 0
  }

  async getPartnersPayouts(params: { status: PartnerPayoutStatus }) {
    await this.request(
      async () => {
        const payouts = await PartnerPortalDao.getAllPartnersPayouts(params)
        this.preparePayoutsTotals(payouts)
      },
      null,
      "Cannot get the data"
    )
  }

  preparePayoutsTotals(payouts: PartnerPayoutTotals[]) {
    this.payouts = _.map(payouts, (payout: PartnerPayoutTotals) => {
      const { discounts, commission } = this.getTotals(payout.organizations)
      this.totals.discounts += discounts
      this.totals.commission += commission

      return {
        ...payout,
        totalDiscounts: discounts,
        totalCommission: commission
      }
    })
  }

  getTotals(data: PartnerOrganizationTotals[]) {
    return _.reduce(
      data,
      (acc, val) => {
        acc.discounts += val.totals.discounts
        acc.commission += val.totals.commission
        return acc
      },
      {
        discounts: 0,
        commission: 0
      }
    )
  }
}
