















import Component from "vue-class-component"
import { Emit, Prop } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "../../utils/VueComponent"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: { ChooseItem, Card }
})
export default class RefundCampaignCostModal extends VueComponent {
  @Prop() title!: string
  @Prop() icon!: string
  @Prop() amount!: number
  @Prop() active!: boolean
  @Prop() disabled!: boolean
  @Prop() subtitle!: string

  @Emit()
  onSelect() {}
}
