







































































































































































































import { FileUtils } from "@/utils/fileUtils"
import { CampaignConfigureDao } from "@/services/dao/robocent/campaignConfigureDao"
import UploadPhoneList from "./UploadPhoneList.vue"
import { VoicePhoneListService } from "@/services/voice/voicePhoneListService"
import { PhoneListDetails, Upload } from "@robocent/components"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { ExtendedUpload } from "@/interfaces/extendedUpload"
import Card from "@/components/elements/Card.vue"
import { StatsConfig } from "@/interfaces/voiceCampaign/statsConfig"

@Component({
  components: {
    Card,
    UploadPhoneList,
    PhoneListDetails
  }
})
export default class StraticsPhoneList extends VueComponent {
  @Prop() campaign!: VoiceCampaign
  @Prop() list!: ExtendedUpload

  phoneList: (StatsConfig & { list_id: string }) | null = null
  token: string | null = null
  phoneListService = new VoicePhoneListService()

  get straticsPhoneListName() {
    return this.phoneListService.getPhoneListName(
      this.campaign,
      (this.campaign.uploads.list as Upload).humanId
    )
  }

  get phoneListId() {
    return this.campaign?.meta?.stratics?.phoneListId || null
  }

  get mainPhoneListId() {
    return this.list?.stratics?.id
  }

  get rvmPhoneListId() {
    return this.list?.stratics?.rvm?.id
  }

  async created() {
    await this.preparePhoneList()
  }

  async checkPhoneListStatus() {
    return await CampaignConfigureDao.checkPhoneListStatus(
      this.campaign._id as string
    )
  }

  async preparePhoneList() {
    if (!this.campaign.meta.stratics) return

    await this.request(
      async () => {
        if (this.campaign.meta.stratics.phoneListToken) {
          await this.checkPhoneListStatus()
          await this.updateVoiceCampaign()
        }
        if (this.phoneListId) {
          this.phoneList = await CampaignConfigureDao.getPhoneList(
            this.campaign._id as string,
            this.phoneListId as string
          )
        }
        await this.updateVoiceCampaign()
      },
      null,
      "Error get Phone List from dialer"
    )
  }

  async deletePhoneList() {
    if (!this.phoneList) return

    await this.request(
      async () => {
        await CampaignConfigureDao.deletePhoneList(
          this.campaign._id as string,
          this.phoneList?.list_id as string
        )
        this.phoneList = null
        await this.updateVoiceCampaign()
      },
      null,
      "Error delete Phone List. Probably List currently assigned to campaign"
    )
  }

  async downloadFile(url: string, fileKey: string, humanId: number) {
    const type = FileUtils.getFileExtFromName(fileKey)
    const name = `${humanId} ${this.campaign.organization.name}.${type}`

    await FileUtils.downloadFileByExternalUrl(url, name)
  }

  async updateVoiceCampaign() {
    await this.$store.dispatch("voiceConfigurator/updateCampaign", {
      orgId: this.campaign.organization._id,
      callId: this.campaign._id
    })
  }
}
