














































































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import Avatar from "vue-avatar"
import Card from "@/components/elements/Card.vue"
import { CardTable, CardTableItem, UserV2 } from "@robocent/components"

@Component({
  components: {
    Card,
    Avatar,
    CardTableItem,
    CardTable
  }
})
export default class OrganizationUserItem extends VueComponent {
  @Prop() user!: UserV2

  get partnerId() {
    return this.$store.getters.organization.partnerId
  }

  get isOrgPartner() {
    return this.partnerId === this.user.id
  }

  get currentUser() {
    return this.partnerId === this.$store.getters.user.user_id
  }
}
