































import { Component, Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import { links } from "@/data/links"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"

@Component({
  components: {
    ChooseItem
  }
})
export default class TextCampaignStatusIncompleted extends VueComponent {
  @Prop() organizationIdentityLink!: string
  docsLinks = links
}
