var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"slot":"trigger","type":"button"},slot:"trigger"},[[_c('b-icon',{attrs:{"icon":"layer-group"}}),_c('span',[_vm._v("More")])],_c('b-icon',{attrs:{"icon":"caret-down"}})],2),_c('b-dropdown-item',{directives:[{name:"only-super",rawName:"v-only-super"}],attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{
        name: "Org Scripts",
        params: { orgId: _vm.organization._id }
      }}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"keyboard"}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v("Scripts")]),_c('small',[_vm._v("Organization Scripts")])])],1)])],1),_c('b-dropdown-item',{directives:[{name:"only-super",rawName:"v-only-super"}],attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{
        name: "Org Agents",
        params: { orgId: _vm.organization._id }
      }}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"headset"}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v("Agents")]),_c('small',[_vm._v("Organization Agents")])])],1)])],1),_c('b-dropdown-item',{directives:[{name:"only-super",rawName:"v-only-super"}],attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: "Org DND", params: { orgId: _vm.organization._id } }}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"ban"}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v("Do Not Disturb")]),_c('small',[_vm._v("Do Not Disturb Settings")])])],1)])],1),_c('b-dropdown-item',{directives:[{name:"only-super",rawName:"v-only-super"}],attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{
        name: "Org Callback Numbers",
        params: { orgId: _vm.organization._id }
      }}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"phone"}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v("Callback Numbers")]),_c('small',[_vm._v("Organization Callback Numbers")])])],1)])],1),_c('b-dropdown-item',{directives:[{name:"only-super",rawName:"v-only-super"}],attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: "Org Billing", params: { orgId: _vm.organization._id } }}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"money-check-alt"}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v("Billing")]),_c('small',[_vm._v("Organization Billing")])])],1)])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","has-link":""}},[_c('router-link',{attrs:{"to":{ name: "Org Settings", params: { orgId: _vm.organization._id } }}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":"cogs"}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v("Settings")]),_c('small',[_vm._v("Organization Settings")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }