






















































































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import { NumberInputWithValidation } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { Ref } from "vue-property-decorator"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"
import { SalesRate } from "@/interfaces/salesPortal/salesRate"
import { SalesPayout } from "@/interfaces/salesPortal/salesPayout"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    Card,
    NumberInputWithValidation,
    ValidationObserver
  }
})
export default class SalesRates extends VueComponent {
  @Ref("table") table: any
  @Ref("observer") observer!: any
  salesRates: SalesPayout[] = []

  setThresholds() {
    this.salesRates.map(
      (salesRate) =>
        (salesRate.salesRate.threshold! = salesRate.salesRate.threshold! / 100)
    )
  }

  async created() {
    await this.getAllSalesRates()
    this.setThresholds()
  }

  async getAllSalesRates() {
    await this.request(async () => {
      this.salesRates = await SalesPortalDao.getAllSalesRates()
    })
  }
  async saveSalesRate(salesRate: SalesRate) {
    const isValid = await this.observer.validate()

    if (!isValid) {
      return this.openToast("Please fill in the required fields.")
    }

    await this.request(
      async () => {
        await SalesPortalDao.saveSalesRate(salesRate)
      },
      null,
      "Cannot save the data"
    )
  }
}
