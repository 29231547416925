export enum CriteriaNames {
  gender = "gender",
  age = "age",
  ethnicity = "ethnicity",
  partyCode = "partyCode",
  votingHistory = "votingHistory",
  historyReqs = "historyReqs",
  county = "county",
  zipCode = "zipCode",
  municipality = "municipality",
  precinct = "precinct",
  congDist = "congDist"
}
