






























































































































import Component from "vue-class-component"
import Vue from "vue"
import {
  AutocompleteWithValidation,
  InputWithValidation,
  Organization,
  OrganizationElectionType,
  OrganizationLegalEntityType,
  OrganizationType,
  PhoneNumberWithValidation
} from "@robocent/components"
import { Prop } from "vue-property-decorator"
import { states } from "@/data/states"

@Component({
  components: {
    InputWithValidation,
    AutocompleteWithValidation,
    PhoneNumberWithValidation
  }
})
export default class OrganizationForm extends Vue {
  @Prop() organization!: Organization
  states = states
  types = OrganizationType
  electionTypes = OrganizationElectionType

  legalEntityTypes = [
    {
      name: "Non-Profit Organization",
      value: OrganizationLegalEntityType.nonProfit
    },
    {
      name: "Private Company",
      value: OrganizationLegalEntityType.privateProfit
    },
    {
      name: "Government",
      value: OrganizationLegalEntityType.government
    }
  ]

  get nameLabel(): string {
    if (this.organization?.type === OrganizationType.political) {
      return "Political Organization Legal Name"
    }

    return "Organization Legal Name"
  }

  get isPolitical() {
    return this.organization?.type === OrganizationType.political
  }
}
