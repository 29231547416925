<template>
  <b-message
    :title="`Step ${index}: Send to Voicemail`"
    @close="$emit('remove')"
  >
    <b-switch
      v-model="configuration.transcribe"
      size="is-large"
      @input="update()"
    >
      {{
        configuration.transcribe
          ? "Transcriptions Enabled"
          : "Transcriptions Disabled"
      }}
    </b-switch>
  </b-message>
</template>

<script>
export default {
  name: "VoiceRecord",
  props: ["index", "config"],
  data() {
    return {
      configuration: this.config
    }
  },
  methods: {
    update() {
      this.$emit("updateConfig", this.configuration)
    }
  }
}
</script>
