




















































import Component, { mixins } from "vue-class-component"
import {
  PhoneList,
  PhoneListUtilsMixin,
  ScrubListButton
} from "@robocent/components"
import { Prop, Ref } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { UploadsDao } from "@/services/dao/robocent/uploadDao"
import _ from "lodash"
import { EventBus } from "@/services/eventBus"

@Component({
  components: {
    ScrubListButton,
    ChooseItem
  }
})
export default class CampaignPhoneList extends mixins(
  VueComponent,
  PhoneListUtilsMixin
) {
  @Prop() list!: PhoneList
  @Prop() active!: boolean
  @Prop() disabled!: boolean
  @Prop() calculateLandline!: boolean
  leftTime = 10 * 1000
  @Ref("countdown") countdown: any
  eventBus = EventBus

  get label() {
    return this.calculateLandline ? "Landlines" : "Mobiles"
  }

  get isEstimatedExist() {
    return this.calculateLandline
      ? _.isNumber(this.list.meta.details?.landline)
      : _.isNumber(this.list.meta.details?.mobile)
  }

  get isConfirmedExist() {
    return this.calculateLandline
      ? _.isNumber(this.list.meta.lookup?.landline)
      : _.isNumber(this.list.meta.lookup?.mobile)
  }

  get estimated() {
    return this.calculateLandline
      ? this.list.meta.details?.landline
      : this.list.meta.details?.mobile
  }

  get confirmed() {
    return this.calculateLandline
      ? this.list.meta.lookup?.landline
      : this.list.meta.lookup?.mobile
  }

  select() {
    if (this.refreshIsAvailable(this.list)) {
      this.openToast(
        "We are calculating phones for this phone list, please wait"
      )
      return this.$emit("on-click")
    }

    if (this.estimated === 0) {
      return this.openToast(
        "Should be at least one mobile phone number in the list"
      )
    }
    this.$emit("on-click")
  }

  async checkPhoneList() {
    try {
      const response = (
        await UploadsDao.getUpload(this.list.organization._id, this.list._id)
      ).data
      this.list.meta = response.meta

      if (
        !response.meta.status &&
        response.stratics.id &&
        response.stratics.rvm.id
      ) {
        this.list.stratics = response.stratics
        this.$buefy.toast.open({
          message: "File successfully processed",
          type: "is-success"
        })
      }
    } catch (e) {
      this.$buefy.toast.open({
        message: "Refreshing failed",
        type: "is-danger"
      })
    }

    if (this.refreshIsAvailable(this.list)) {
      this.countdown.startCountdown("restart")
    }
  }
}
