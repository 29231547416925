























import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import OverviewMixin from "@/components/campaign/voiceAdminReview/OverviewMixin"

@Component({
  components: { Card }
})
export default class OverviewReject extends OverviewMixin {}
