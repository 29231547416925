import axios from "axios"
import { SalesRate } from "@/interfaces/salesPortal/salesRate"
import { FindManyFilter } from "@/interfaces/findManyFilter"
import { SalesEarnings } from "@/interfaces/salesPortal/salesEarnings"
import { OrganizationWithEarnings } from "@/interfaces/salesPortal/organizationWithEarnings"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import { OrganizationPayout } from "@/interfaces/salesPortal/organizationPayout"
import { SalesPayout } from "@/interfaces/salesPortal/salesPayout"

export class SalesPortalDao {
  static async getAllSalesPayouts(params?: {
    date: string
    status: PartnerPayoutStatus
  }): Promise<SalesPayout[]> {
    return (await axios.get(`/sales-portal/sales`, { params })).data
  }

  static async getAllSalesRates(): Promise<SalesPayout[]> {
    return (await axios.get(`/sales-portal/rates`)).data
  }

  static async getSalesRate(): Promise<SalesRate> {
    return (await axios.get(`/sales-portal/sales-managers/rates`)).data
  }

  static async getSalesRateByUserId(userId: string): Promise<SalesRate> {
    return (await axios.get(`/sales-portal/sales-managers/${userId}/rates`))
      .data
  }

  static async getSalesEarnings(
    params: FindManyFilter
  ): Promise<SalesEarnings> {
    return (
      await axios.get(`/sales-portal/sales-managers/earnings`, { params })
    ).data
  }

  static async getSalesEarningsByUserId(
    userId: string,
    params: FindManyFilter
  ): Promise<SalesEarnings> {
    return (
      await axios.get(`/sales-portal/sales-managers/${userId}/earnings`, {
        params
      })
    ).data
  }

  static async getSalesManagerOrganizations(
    params: FindManyFilter
  ): Promise<{ data: OrganizationWithEarnings[]; total: number }> {
    return (await axios.get(`/sales-portal/sales-managers/orgs`, { params }))
      .data
  }

  static async getSalesOrganizationsByUserId(
    userId: string,
    params: FindManyFilter
  ): Promise<{ data: OrganizationWithEarnings[]; total: number }> {
    return (
      await axios.get(`/sales-portal/sales-managers/${userId}/orgs`, { params })
    ).data
  }

  static async getSalesManagerPayouts(
    orgId: string,
    params: {
      date: string
      status: PartnerPayoutStatus
    }
  ): Promise<OrganizationPayout> {
    return (
      await axios.get(`/sales-portal/organizations/${orgId}/payouts`, {
        params
      })
    ).data
  }

  static async getSalesPayoutsByUserId(
    orgId: string,
    userId: string,
    params: {
      date: string
      status: PartnerPayoutStatus
    }
  ): Promise<OrganizationPayout> {
    return (
      await axios.get(
        `/sales-portal/organizations/${orgId}/sales-managers/${userId}/payouts`,
        {
          params
        }
      )
    ).data
  }

  static async saveSalesRate(rate: SalesRate | null) {
    return await axios.put(
      `/sales-portal/sales-managers/${rate?.salesManagerId}/rates`,
      rate
    )
  }

  static async getOrganizationSalesSettings(orgId: string): Promise<SalesRate> {
    return (await axios.get(`/sales-portal/organizations/${orgId}/settings`))
      .data
  }

  static async updateOrganizationSalesSettings(
    orgId: string,
    settings: Partial<SalesRate>
  ) {
    return await axios.post(
      `/sales-portal/organizations/${orgId}/settings`,
      settings
    )
  }

  static async markPayoutsAsPaid(
    userId: string,
    data: { check: string; date: string }
  ) {
    return await axios.post(
      `/sales-portal/sales-managers/${userId}/payouts/mark-as-paid`,
      data
    )
  }
}
