import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { SalesPayout } from "@/interfaces/salesPortal/salesPayout"
import { SalesPortalDao } from "@/services/dao/salesPortalDao"
import { PartnerPayoutStatus } from "@/enums/partnerPortal/partnerPayoutStatus"
import _ from "lodash"
import { OrganizationWithEarnings } from "@/interfaces/salesPortal/organizationWithEarnings"
import { Watch } from "vue-property-decorator"

@Component({})
export default class SalesPayoutMixin extends VueComponent {
  activeTab = 0
  salesPayouts: SalesPayout[] = []
  totals: { totalSales: number; totalEarnings: number } = {
    totalSales: 0,
    totalEarnings: 0
  }

  get defaultFilter() {
    return {
      date: this.selectedDate.toDateString(),
      status: PartnerPayoutStatus.open
    }
  }

  get selectedDate(): Date {
    return this.$store.getters["salesPortal/selectedDate"]
  }

  set selectedDate(date: Date) {
    this.$store.commit("salesPortal/setDate", date)
  }

  async getAllSalesPayouts(params?: {
    date: string
    status: PartnerPayoutStatus
  }) {
    await this.request(
      async () => {
        const salesPayouts = await SalesPortalDao.getAllSalesPayouts(params)
        this.preparePayoutsTotals(salesPayouts)
      },
      null,
      "Cannot get the data"
    )
  }

  preparePayoutsTotals(salesPayouts: SalesPayout[]) {
    this.totals = {
      totalSales: 0,
      totalEarnings: 0
    }

    this.salesPayouts = _.map(salesPayouts, (payout: SalesPayout) => {
      const { totalSales, totalEarnings } = this.getTotals(payout.organizations)
      this.totals.totalSales += totalSales
      this.totals.totalEarnings += totalEarnings

      return {
        ...payout,
        totalSales: totalSales,
        totalEarnings: totalEarnings
      }
    })
  }

  getTotals(data: OrganizationWithEarnings[]) {
    return _.reduce(
      data,
      (acc, val) => {
        acc.totalSales += val.earnings.totalSales
        acc.totalEarnings += val.earnings.totalEarnings
        return acc
      },
      {
        totalSales: 0,
        totalEarnings: 0
      }
    )
  }

  @Watch("selectedDate")
  async watchSelectedDate() {
    this.activeTab = 0
    await this.getAllSalesPayouts(this.defaultFilter)
  }
}
