
























import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import CampaignInvoiceMixin from "@/mixins/campaign/CampaignInvoiceMixin"
import CampaignInvoice from "@/components/elements/CampaignInvoice.vue"

@Component({
  components: { CampaignInvoice }
})
export default class VoiceCampaignInvoice extends CampaignInvoiceMixin {
  @Prop() statusToShow!: string
}
