import { Module } from "vuex"
import _ from "lodash"
import { LocationInfo } from "@/voterData/interfaces/LocationInfo"
import { State } from "@/voterData/interfaces/state"
import { JurisdictionTypes } from "@/voterData/enums/jurisdictionTypes"
import { JurisdictionType } from "@/voterData/interfaces/JurisdictionType"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"

const initState: LocationInfo = {
  state: null,
  jurisdictionTypes: [],
  jurisdictionValues: {
    [JurisdictionTypes.county]: [],
    [JurisdictionTypes.zipCode]: []
  }
}

export const locationInfo = <Module<LocationInfo, State>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    state: (state) => state.state,
    jurisdictionTypes: (state) => state.jurisdictionTypes,
    county: (state) => state.jurisdictionValues.county,
    zipCode: (state) => state.jurisdictionValues.zipCode
  },
  mutations: {
    updateState: (state, value: string) => {
      state.state = value
    },
    updateJurisdictionTypes: (state, value: JurisdictionType[]) => {
      state.jurisdictionTypes = _.cloneDeep(value)
    },
    updateJurisdictionValue: (
      state,
      { type, value }: { type: JurisdictionTypes; value: FieldInfo[] }
    ) => {
      state.jurisdictionValues[type] = _.cloneDeep(value)
    },
    clear: (state) => {
      state.jurisdictionTypes = _.cloneDeep(initState.jurisdictionTypes)
      state.jurisdictionValues = _.cloneDeep(initState.jurisdictionValues)
    },
    clearField: (state, field: JurisdictionTypes) => {
      state.jurisdictionValues[field] = _.cloneDeep(
        initState.jurisdictionValues[field]
      )
    },
    clearState: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key as keyof LocationInfo] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
