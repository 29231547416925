







































































import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { states } from "@/data/states"
import Component, { mixins } from "vue-class-component"
import ChooseRow from "../elements/ChooseRow.vue"
import {
  AutocompleteWithValidation,
  InputWithValidation,
  PhoneNumberWithValidation
} from "@robocent/components"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { Ref } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { PromoCodeMixin } from "@/mixins/campaign/PromoCodeMixin"

@Component({
  components: {
    ChooseRow,
    PhoneNumberWithValidation,
    InputWithValidation,
    AutocompleteWithValidation,
    ValidationObserver
  }
})
export default class TextCampaignPhoneNumbersInfo extends mixins(
  TextSchedulerUtilsMixin,
  PromoCodeMixin
) {
  @Ref("numbers-info-observer") observer!: any
  states = states

  selectState(option: string) {
    this.$set(this.campaign, "state", option)
  }

  async nextStep() {
    if (await this.observer.validate()) {
      this.next(TextSchedulerSteps.campaignCard)
    }
  }
}
