import { Module } from "vuex"
import _ from "lodash"
import { Feature } from "@/interfaces/feature/feature"
import { OrganizationPartnerType } from "@/interfaces/partnerPortal/organizationPartnerType"
import { PartnerPayoutWithCampaign } from "@/interfaces/partnerPortal/partnerPayoutWithCampaign"
import { PartnerPortalTotals } from "@/interfaces/partnerPortal/partnerPortalTotals"

const initState = {
  selectedService: null,
  partnerSettings: [],
  selectedOrg: "",
  payouts: [],
  servicesTotals: {
    [Feature.smsCampaign]: {
      commission: { paid: 0, notPaid: 0 },
      discounts: 0
    },
    [Feature.voiceCampaign]: {
      commission: { paid: 0, notPaid: 0 },
      discounts: 0
    }
  }
}

export const partnerPortalStore = <Module<any, any>>{
  namespaced: true,
  state: _.cloneDeep(initState),
  getters: {
    selectedService: (state) => state.selectedService,
    partnerSettings: (state) => state.partnerSettings,
    selectedOrg: (state) => state.selectedOrg,
    payouts: (state) => state.payouts,
    servicesTotals: (state) => state.servicesTotals
  },
  mutations: {
    setService: (state, selectedService: Feature) => {
      state.selectedService = selectedService
    },
    setPartnerSettings: (state, partnerSettings: OrganizationPartnerType[]) => {
      state.partnerSettings = partnerSettings
    },
    setOrganization: (state, orgId: string) => {
      state.selectedOrg = orgId
    },
    setPayouts: (state, payouts: PartnerPayoutWithCampaign[]) => {
      state.payouts = payouts
    },
    setServicesTotals: (
      state,
      servicesTotals: {
        [Feature.smsCampaign]: PartnerPortalTotals
        [Feature.voiceCampaign]: PartnerPortalTotals
      }
    ) => {
      state.servicesTotals = servicesTotals
    },
    clear: (state) => {
      Object.keys(initState).forEach((key: string) => {
        state[key] = _.cloneDeep(initState as any)[key]
      })
    }
  }
}
