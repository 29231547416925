






















import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VueComponent } from "@/utils/VueComponent"

@Component
export default class CampaignPaymentTypeTag extends VueComponent {
  @Prop() enabled!: boolean
  @Prop() orgId!: string
  @Prop() name!: string
}
