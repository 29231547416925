







import Component from "vue-class-component"
import State from "@/voterData/components/State.vue"
import Jurisdiction from "@/voterData/components/Jurisdiction.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    Jurisdiction,
    State
  }
})
export default class LocationStep extends VueComponent {}
