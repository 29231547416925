<template>
  <b-message
    :title="`Step ${index}: Play Audio from File`"
    @close="$emit('remove')"
  >
    <b-select
      v-model="value"
      @input="$emit('update', value)"
      size="is-medium"
      placeholder="Select an File"
    >
      <option v-for="item in uploads" :key="item._id" :value="item._id">
        {{ item.name }}
      </option>
    </b-select>
  </b-message>
</template>

<script>
export default {
  name: "VoicePlay",
  props: ["index", "audio", "uploads"],
  data() {
    return {
      value: this.audio
    }
  }
}
</script>
