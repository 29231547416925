

















































































import Component from "vue-class-component"
import { Prop, Ref } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { ValidationObserver } from "vee-validate"
import { InputWithValidation } from "@robocent/components"
import { VoiceCampaign } from "@/interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignDao } from "@/services/dao/robocent/voiceCampaignDao"
import { NotificationsDao } from "@/services/dao/robocent/notificationsDao"
import { ValidationContext } from "vee-validate/dist/types/components/common"
import { EventBus } from "@/services/eventBus"
import ShareablePageMixin from "@/mixins/ShareablePageMixin"

@Component({
  components: {
    Card,
    ValidationObserver,
    InputWithValidation
  }
})
export default class VoiceCampaignShareableResultsModal extends ShareablePageMixin {
  @Prop() voiceCampaign!: VoiceCampaign
  @Ref("observer") observer!: ValidationContext

  email = ""

  get sharedURL() {
    return `${location.protocol}//${location.host}/shared/campaigns/${this.voiceCampaign._id}`
  }

  async toggleShareResults() {
    this.voiceCampaign.meta = {
      ...this.voiceCampaign.meta,
      public: !this.voiceCampaign.meta.public
    }

    await this.request(async () => {
      await VoiceCampaignDao.makeCampaignPublic(
        this.voiceCampaign.organization?._id,
        this.voiceCampaign.uuid,
        this.voiceCampaign.meta.public
      )
      EventBus.getCampaign$.next()
    }, `Results link is now ${this.voiceCampaign.meta.public ? "public" : "private"}`)
  }

  async send() {
    await this.request(
      async () => {
        await NotificationsDao.shareCallByEmail(
          this.email,
          this.voiceCampaign._id!
        )
        this.email = ""
        this.$nextTick(() => this.observer.reset())
      },
      "Email successfully sent",
      "Email sent failed. Please try again"
    )
  }

  async goToSharedResults() {
    await this.$router.push({
      name: "Shared Voice Campaign",
      params: { campaignId: this.voiceCampaign._id! }
    })
    this.$parent.close()
  }
}
