import Vue from "vue"
import Component from "vue-class-component"
import { BDialogConfig, BNoticeConfig } from "buefy/types/components"
import { StringObject } from "@robocent/components"
import { Dictionary } from "vue-router/types/router"
import _ from "lodash"
import * as Sentry from "@sentry/browser"

@Component
export class VueComponent extends Vue {
  loadState: { [x: string]: boolean } = {}
  params: StringObject = {}
  error: string | null = null

  set loading(value: boolean) {
    this.$set(this.loadState, "global", value)
  }

  get loading() {
    return Object.values(this.loadState).some((loading: boolean) => loading)
  }

  loadingByKey(key: string): boolean {
    return this.loadState[key]
  }

  async request<T>(
    callback: () => Promise<T>,
    successMessage: string | null = null,
    failedMessage = "Something went wrong. Please try again",
    key = "request",
    skipError = false
  ) {
    try {
      this.error = null
      this.$set(this.loadState, key, true)
      await callback()
      if (successMessage) {
        this.openToast(successMessage)
      }
    } catch (e) {
      if (!e?.response) {
        console.error(e)
        Sentry.captureException(e)
      }
      this.error = _.get(e, "response.data.message")
      if (!skipError) {
        this.openFailedToast(failedMessage)
      }
    } finally {
      this.$set(this.loadState, key, false)
    }
  }

  openToast(message: string, params?: BNoticeConfig) {
    this.$buefy.toast.open({
      ...params,
      message
    })
  }

  openSuccessToast(message: string, params?: BNoticeConfig) {
    this.$buefy.toast.open({
      ...params,
      message,
      type: "is-success"
    })
  }

  openFailedToast(message: string, params?: BNoticeConfig) {
    this.$buefy.toast.open({
      ...params,
      message,
      type: "is-danger"
    })
  }

  confirm(message: string, params?: BDialogConfig) {
    return new Promise((resolve) =>
      this.$buefy.dialog.confirm({
        message,
        onConfirm: resolve,
        ...params
      })
    )
  }

  updateQuery(
    query:
      | Dictionary<string | (string | null)[] | null | undefined>
      | StringObject
  ) {
    this.$router.push(
      {
        name: this.$route.name as string,
        query: query as Dictionary<
          string | (string | null)[] | null | undefined
        >
      },
      () => {},
      () => {}
    )
  }

  hasListener(listenerName: string): boolean {
    return this.$listeners && Boolean(this.$listeners[listenerName])
  }
}
