








import Component from "vue-class-component"
import Gender from "@/voterData/components/Gender.vue"
import Age from "@/voterData/components/Age.vue"
import Ethnicity from "@/voterData/components/Ethnicity.vue"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: {
    Ethnicity,
    Age,
    Gender
  }
})
export default class DemographicsStep extends VueComponent {}
