























































import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import { CardTable, CardTableItem } from "@robocent/components"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import { Pagination } from "@/utils/pagination"
import PaginationItem from "@/components/scheduler/elements/PaginationItem.vue"
import PartnerPortalMixin from "@/mixins/partnerPortal/PartnerPortalMixin"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import ChooseItem from "@/components/scheduler/elements/ChooseItem.vue"
import { OrganizationExtended } from "@/interfaces/partnerPortal/organizationWithTotals"

@Component({
  components: {
    ChooseItem,
    PaginationItem,
    ChooseRow,
    StepTemplate,
    CardTable,
    CardTableItem
  }
})
export default class PartnerOrganizations extends PartnerPortalMixin {
  organizations: OrganizationExtended[] = []
  pagination = new Pagination(0, 1, 3)

  get atLeastOnePage() {
    return this.pagination.total > this.pagination.perPage
  }

  async created() {
    await this.getPartnerOrgs()
  }

  async getPartnerOrgs() {
    const params = {
      limit: this.pagination.perPage,
      page: this.pagination.current
    }

    await this.request(
      async () => {
        const { data, total } = await PartnerPortalDao.getPartnerOrganizations(
          params
        )
        this.pagination.total = total
        this.organizations = data
        this.selectFirstOrg()
      },
      undefined,
      undefined,
      "orgs"
    )
  }

  active(orgId: string) {
    return this.selectedOrg === orgId
  }

  selectFirstOrg() {
    if (this.selectedOrg) {
      return
    }
    this.onSelectOrg(this.organizations[0]?._id)
  }

  onSelectOrg(orgId: string) {
    this.selectedOrg = orgId
  }

  onPageChange(page: number) {
    this.pagination.current = page
    this.getPartnerOrgs()
  }
}
