
















































































import CreditCard from "@/components/CreditCard.vue"
import OrganizationAutoPay from "@/components/organization/OrganizationAutoPay.vue"
import OrganizationNoCardMessage from "@/components/organization/OrganizationNoCardMessage.vue"
import ChooseRow from "@/components/scheduler/elements/ChooseRow.vue"
import OrganizationBillingMixin from "@/mixins/organization/OrganizationBillingMixin"
import { CardTable, CardTableItem, Customer } from "@robocent/components"
import Component, { mixins } from "vue-class-component"
import { Emit, Prop, Watch } from "vue-property-decorator"
import { SchedulerUtilsMixin } from "@/mixins/campaign/SchedulerUtilsMixin"

@Component({
  components: {
    OrganizationAutoPay,
    OrganizationNoCardMessage,
    CreditCard,
    ChooseRow,
    CardTableItem,
    CardTable
  }
})
export default class CampaignCards extends mixins(
  OrganizationBillingMixin,
  SchedulerUtilsMixin
) {
  @Prop() orgCustomer!: Customer

  created() {
    if (this.orgCustomer) {
      this.customer = this.orgCustomer
    }
  }

  @Watch("orgCustomer")
  watchCampaignOrg(orgCustomer: Customer) {
    this.customer = orgCustomer
  }

  @Emit()
  onChange(customer: Customer) {
    return customer
  }

  @Emit()
  nextStep() {}
}
