






















import CampaignReportItem from "./CampaignReportItem.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "../elements/Card.vue"
import { Prop } from "vue-property-decorator"
import {
  CampaignReport,
  ReportStatus,
  ReportType
} from "@/interfaces/campaignReports"
import { Campaign, CardTable, Timer } from "@robocent/components"
import { EventBus } from "@/services/eventBus"
import { CampaignStats } from "@/interfaces/campaignStats"

@Component({
  components: {
    CardTable,
    Card,
    CampaignReportItem,
    Timer
  }
})
export default class CampaignReports extends VueComponent {
  @Prop() campaign!: Campaign
  @Prop() campaignReports!: CampaignReport[]
  @Prop() campaignStats!: CampaignStats
  @Prop() surveyStatsAvailable!: boolean
  @Prop() shared!: boolean
  @Prop() waiting!: boolean
  @Prop() campaignArchived!: boolean

  get isSuper() {
    return this.$store.getters.super
  }

  get ifJobIdExists() {
    return Boolean(this.campaign?.metadata?.jobId)
  }

  get displayedReports() {
    const reports = [
      {
        label: "Detailed Report",
        type: ReportType.detailedReport,
        hidden: !this.ifJobIdExists,
        disabled: false
      },
      {
        label: "Initial messages",
        type: ReportType.initialMessages,
        hidden: this.ifJobIdExists,
        disabled: !this.campaignStats.leads.contacted
      },
      {
        label: "Conversations",
        type: ReportType.conversations,
        hidden: this.ifJobIdExists,
        disabled: !this.campaignStats.leads.contacted
      },
      {
        label: "Survey Responses",
        type: ReportType.surveyResponses,
        hidden: !this.surveyStatsAvailable || this.ifJobIdExists,
        disabled: !this.campaignStats.leads.contacted
      },
      {
        label: "List",
        type: ReportType.list
      },
      {
        label: "Full",
        type: ReportType.full,
        hidden: !this.isSuper || this.ifJobIdExists
      }
    ]

    return reports.filter((report) => !report.hidden)
  }

  getReport(type: ReportType) {
    return this.campaignReports.find((report) => report.type === type)
  }

  get leastOneProcessing() {
    return Boolean(
      this.campaignReports.find(
        (report) => report.status === ReportStatus.processing
      )
    )
  }

  updateReports() {
    EventBus.getReports$.next()
  }
}
