<template>
  <div>
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            <img
              src="@/assets/robocent_final_logo_only_white_v2.png"
              alt="RoboCent, Inc."
              width="250"
            />
          </h1>
        </div>
      </div>
    </section>
    <div class="container mt-sm">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { VueComponent } from "@/utils/VueComponent"
import Component from "vue-class-component"

@Component
export default class PublicPageHeader extends VueComponent {}
</script>
<style scoped lang="scss">
@import "@/assets/styles/colors.scss";

.hero {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding-left: 1rem;
  padding-right: 1rem;
  background: $dark-heavy !important;

  &-body {
    padding: 1rem 1.5rem !important;
  }
}
</style>
