









import Component, { mixins } from "vue-class-component"
import { Ref } from "vue-property-decorator"
import { EventBus } from "@/services/eventBus"
import { whileComponentMounted } from "@/utils/operators"
import RequestedPayoutsTable from "@/components/payouts/RequestedPayoutsTable.vue"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import { PayoutType } from "@/enums/payoutType"
import { RequestedPayout } from "@robocent/components"
import PartnerPaymentMixin from "@/mixins/partnerPortal/PartnerPaymentMixin"
import { VueComponent } from "@/utils/VueComponent"

@Component({
  components: { RequestedPayoutsTable }
})
export default class PartnerRequestedPayoutsTable extends mixins(
  PartnerPaymentMixin,
  VueComponent
) {
  @Ref("table") table: any
  payouts: RequestedPayout[] = []
  payoutTypes = PayoutType

  created() {
    EventBus.getPartnersPayouts$
      .pipe(whileComponentMounted(this))
      .subscribe(this.getRequestedPayouts)
    this.getRequestedPayouts()
  }

  async getRequestedPayouts() {
    await this.request(
      async () => {
        this.payouts = await PartnerPortalDao.getRequestedPartnerPayouts()
      },
      null,
      "Cannot get the data"
    )
  }
}
