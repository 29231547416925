
























import Component from "vue-class-component"
import Card from "@/components/elements/Card.vue"
import { VueComponent } from "@/utils/VueComponent"
import { Prop, Watch } from "vue-property-decorator"
import VoiceCampaignPieChart from "@/components/elements/voiceCampaign/VoiceCampaignPieChart"
import { ChartData, ChartDataSets } from "chart.js"
import _ from "lodash"
import { VoiceCampaignSurveyResults } from "@/interfaces/voiceCampaign/VoiceCampaignSurveyResults"

@Component({
  components: {
    VoiceCampaignPieChart,
    Card
  }
})
export default class VoiceCampaignSurvey extends VueComponent {
  @Prop() waiting!: boolean
  @Prop() survey!: VoiceCampaignSurveyResults[]

  items: VoiceCampaignSurveyResults[] = []
  options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      mode: "single",
      callbacks: {
        label: (
          tooltipItem: { datasetIndex: number; index: number },
          data: ChartData
        ) => {
          const allData = data.datasets![tooltipItem.datasetIndex].data
          const tooltipLabel = data.labels![tooltipItem.index]
          const tooltipData: number = allData![tooltipItem.index] as number
          const percentage = (100 * (tooltipData / _.sum(allData))).toFixed(2)

          return `${tooltipLabel}: ${percentage}% (${tooltipData})`
        }
      }
    }
  }

  data: { labels: string[]; datasets: ChartDataSets[] } = {
    labels: [],
    datasets: [
      {
        label: "",
        borderWidth: 1,
        backgroundColor: [
          "rgba(135, 187, 162, 0.5)",
          "rgba(239, 118, 122, 0.5)",
          "rgba(59, 96, 100, 0.5)",
          "rgba(238, 184, 104, 0.5)",

          "rgba(86, 102, 107, 0.5)"
        ],
        borderColor: [
          "rgba(135, 187, 162, 0.7)",
          "rgba(239, 118, 122, 0.7)",
          "rgba(59, 96, 100, 0.7)",
          "rgba(238, 184, 104, 0.7)",
          "rgba(86, 102, 107, 0.7)"
        ]
      }
    ]
  }

  created() {
    this.prepareItems()
  }

  prepareItems() {
    this.items = this.survey
      .filter((survey) => survey.total)
      .map((survey) => {
        const item: any = _.cloneDeep(survey)
        item.chart = _.cloneDeep(this.data)

        const digits = item.digits.filter(
          (digit: { value: number; text: string }) => Number(digit.value) > 0
        )
        item.chart.labels = digits.map(
          (digit: { value: number; text: string }) => digit.text
        )
        item.chart.datasets[0].data = digits.map(
          (digit: { value: number; text: string }) =>
            parseFloat(digit.value.toString())
        )

        return item
      }) as VoiceCampaignSurveyResults[]
  }

  @Watch("survey", { deep: true })
  watchSurvey() {
    this.prepareItems()
  }
}
