


























































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import { Ref } from "vue-property-decorator"
import { ValidationContext } from "vee-validate/dist/types/components/common"
import { VueComponent } from "@/utils/VueComponent"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import {
  CampaignPricing,
  CardTable,
  CardTableItem,
  NumberInputWithValidation
} from "@robocent/components"
import Card from "@/components/elements/Card.vue"

enum textCampaignRate {
  baseSegmentCost = "baseSegmentCost",
  additionalSegmentCost = "additionalSegmentCost",
  mmsCost = "mmsCost",
  managedCost = "managedCost"
}

@Component({
  components: {
    Card,
    CardTable,
    CardTableItem,
    ValidationObserver,
    NumberInputWithValidation
  }
})
export default class TextCampaignRates extends VueComponent {
  @Ref("rates-observer") observer!: ValidationContext

  data = [
    {
      name: "Base Segment",
      key: textCampaignRate.baseSegmentCost
    },
    {
      name: "Additional Segment",
      key: textCampaignRate.additionalSegmentCost
    },
    {
      name: "MMS",
      key: textCampaignRate.mmsCost
    },
    {
      name: "Managed",
      key: textCampaignRate.managedCost
    }
  ]

  rates: CampaignPricing | null = null
  isEditing = false

  get isSuper() {
    return this.$store.getters.super
  }

  created() {
    this.getRates()
  }

  async getRates() {
    await this.request(async () => {
      const { orgId } = this.$route.params
      this.rates = await SmsBillingDao.getPricing(orgId)
    })
  }

  async save() {
    await this.request(async () => {
      const { orgId } = this.$route.params
      await SmsBillingDao.updatePricing(orgId, this.rates as CampaignPricing)
      this.isEditing = false
      await this.getRates()
    }, "The Rates were updated successfully")
  }

  onEditClick() {
    this.isEditing = !this.isEditing
  }
}
