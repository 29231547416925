<template>
  <div class="container mt-md">
    <b-loading :active.sync="loading" :can-cancel="true" />
    <b-message v-if="orgId && phone" class="is-primary is-borderless">
      <div class="columns is-vcentered">
        <div class="column">
          <p>
            <strong class="is-size-5"
              >Subscribers for {{ phone.name ? phone.name + " - " : "" }}
              {{ phone.number | phone }}
            </strong>
          </p>
          <p class="is-size-6 has-text-grey">
            <strong>Send SMS Messages to All Subscribers Instantly!</strong>
          </p>
        </div>
        <div class="buttons has-text-right">
          <b-field>
            <div class="buttons is-just-end">
              <router-link
                class="button"
                :to="`/organizations/${orgId}/phones/${phone.uuid}/messages`"
              >
                View Text & Voice Messages
              </router-link>
              <b-button
                v-if="!phone.metadata.notifyService"
                @click="enableSmsMailing()"
                class="button is-success"
              >
                Enable SMS Mailing List
              </b-button>
              <b-button v-else @click="open = true" class="button">
                Send SMS to {{ subscriberCount }} Subscribers
              </b-button>
              <router-link
                class="button"
                :to="`/organizations/${orgId}/phones/${phone.uuid}`"
              >
                Callback Number Settings
              </router-link>
            </div>
          </b-field>
        </div>
      </div>
    </b-message>
    <b-message type="is-info">
      <strong
        >Contacts can text "Subscribe" to this number to be added to the SMS
        Mailing List</strong
      >
    </b-message>

    <big-card>
      <b-table
        v-if="subscribers.length"
        :data="subscribers"
        ref="table"
        paginated
        per-page="20"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <template slot-scope="props">
          <b-table-column field="id" label="ID">
            <span>{{ props.row.id }}</span>
          </b-table-column>
          <!-- <b-table-column field="name" label="Name">
            <span>{{ props.row.name || "Rename" }}</span>
          </b-table-column> -->
          <b-table-column field="number" label="Phone Number">
            <span>{{ props.row.number | phone }}</span>
          </b-table-column>
          <b-table-column field="createdAt" label="Subscriber Since">
            <span>{{ props.row.createdAt | date }}</span>
          </b-table-column>
          <!-- <b-table-column field="sid" centered>
            <b-button size="is-small" type="is-success">
              Send Mesasge
            </b-button>
          </b-table-column> -->
        </template>
      </b-table>
      <b-message v-else-if="!loading" type="is-info">
        <strong>This number does not have any subscribers</strong>
      </b-message>
    </big-card>
    <b-modal :active.sync="open" has-modal-card trap-focus>
      <send-bulk-message
        :phone="phone"
        :count="subscriberCount"
      ></send-bulk-message>
    </b-modal>
  </div>
</template>

<script>
import BigCard from "../elements/BigCard.vue"
import SendBulkMessage from "../SendBulkMessage.vue"
import { get } from "lodash"
import { PhoneNumberDao } from "@/services/dao/robocent/phoneNumberDao"

export default {
  name: "PhoneSubscribers",
  components: { BigCard, SendBulkMessage },
  data() {
    return {
      orgId: this.$route.params.orgId,
      phoneId: this.$route.params.phoneId,
      phone: null,
      subscribers: [],
      subscriberCount: 0,
      loading: true,
      open: false
    }
  },
  computed: {},
  created() {
    if (this.$route.params.phoneId) {
      this.get()
    }
  },
  methods: {
    async get() {
      PhoneNumberDao.getPhone(this.orgId, this.phoneId).then(
        ({ data }) => (this.phone = data)
      )
      await this.getSubscribers()
      this.loading = false
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    async getSubscribers() {
      const { data, total } = await PhoneNumberDao.getSubscribers(
        this.orgId,
        this.phoneId
      )

      this.subscribers = data
      this.subscriberCount = total
    },
    async enableSmsMailing() {
      try {
        await PhoneNumberDao.enableNotify(this.orgId, this.phoneId)
        this.$buefy.toast.open("Mailing List Enabled")
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message:
            get(e, "response.data.message") ||
            "Error during enable sms mailing",
          position: "is-bottom",
          type: "is-danger"
        })
      }
    },
    sendNotification() {
      this.$buefy.dialog.prompt({
        message: "What's the Message?",
        inputAttrs: {
          placeholder: "e.g. Thank you for supporting us!",
          maxlength: 300
        },
        trapFocus: true,
        onConfirm: (value) =>
          PhoneNumberDao.sendNotify(this.orgId, this.phoneId, {
            message: value
          }).then(() => this.$buefy.toast.open("Notification Sent"))
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins.scss";

table td:not([align]),
table th:not([align]) {
  vertical-align: middle;
}
.table th:last-child {
  text-align: right;
}

@include mobile {
  ::v-deep .media-content {
    display: contents;
  }
}
</style>
