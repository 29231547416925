


































































import {
  InputWithValidation,
  PhoneNumberWithValidation as InputWithMaskAndValidation
} from "@robocent/components"
import InputWithCardValidation from "@/components/elements/InputWithCardValidation.vue"
import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { ValidationObserver } from "vee-validate"
import { BillingDao } from "@/services/dao/robocent/billingDao"
import { Prop } from "vue-property-decorator"
import { Card as ICard } from "@robocent/components"
import Card from "@/components/elements/Card.vue"

@Component({
  components: {
    InputWithValidation,
    InputWithMaskAndValidation,
    InputWithCardValidation,
    ValidationObserver,
    Card
  }
})
export default class AddNewCardModal extends VueComponent {
  card: ICard | any = {}
  @Prop() orgId!: string

  async addCard() {
    try {
      this.loading = true

      const newCard = {
        ...this.card,
        exp: this.card?.exp?.replace(/\s/g, "")
      }

      const response = await BillingDao.addCard(this.orgId, newCard)
      this.$emit("added", response)
      this.$buefy.toast.open("Successfully added a card!")
      this.$parent.close()
    } catch (err) {
      console.log(err)
      this.$buefy.toast.open({
        message: "Failed adding a card.",
        type: "is-danger"
      })
    } finally {
      this.loading = false
    }
  }
}
