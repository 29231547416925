import { render, staticRenderFns } from "./OrganizationAutoPay.vue?vue&type=template&id=bff0a7b6&scoped=true&"
import script from "./OrganizationAutoPay.vue?vue&type=script&lang=ts&"
export * from "./OrganizationAutoPay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bff0a7b6",
  null
  
)

export default component.exports