import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { CampaignPricing, Script, SurveyOption } from "@robocent/components"
import { ScriptDao } from "@/services/dao/scriptDao"
import { EventBus } from "@/services/eventBus"
import { Prop, Ref, Watch } from "vue-property-decorator"
import { ValidationObserver } from "vee-validate"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"

@Component
export default class ScriptMixin extends VueComponent {
  @Prop() organizationId!: string
  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>
  script: Script = null!
  minOptionsNumber = 2
  defaultText = " Reply STOP to END"
  pricing: CampaignPricing | null = null

  mounted() {
    this.getPricing()
  }

  setInitialScript() {
    this.script = {
      title: "",
      text: `Hi {fname}! I’m texting you on behalf of ${this.$store.getters.lastOrg.name}. This message was sent by {agent_first_name}.`,
      survey: false,
      intro: true,
      mms: null,
      organizationId: this.organizationId,
      surveyOptions: [] as SurveyOption[],
      isDefaultScriptNeeded: true
    } as Script
  }

  async saveScript() {
    this.addDefaultText()
    this.validateScript()
    await this.request(
      async () => {
        delete this.script.isDefaultScriptNeeded
        const script = await ScriptDao.createScript(this.script)
        EventBus.getScripts$.next(script)
        this.$parent.close()
      },
      "Script was Added Successfully",
      "Error during saving the script"
    )
  }

  async editScript() {
    this.addDefaultText()
    this.validateScript()
    await this.request(
      async () => {
        delete this.script.isDefaultScriptNeeded
        await ScriptDao.updateScript(
          this.script.organizationId,
          this.script.id,
          this.script
        )
        EventBus.getScripts$.next(null)
        this.$parent.close()
      },
      "Script was Edited Successfully",
      "Error during saving the script"
    )
  }

  validateScript() {
    if (
      this.script.survey &&
      this.script.surveyOptions!.length < this.minOptionsNumber
    ) {
      return this.openFailedToast(
        `Please add at least ${this.minOptionsNumber} options to the Survey.`
      )
    }
  }

  addDefaultText() {
    this.script.text = this.script.text.trim()
    if (
      !this.script.isDefaultScriptNeeded ||
      !this.script.intro ||
      this.script.text.includes(this.defaultText)
    ) {
      return
    }

    this.script.text += this.defaultText
  }

  @Watch("script.survey")
  setSurveyOptions() {
    if (this.script.survey) {
      this.script.surveyOptions = [{ text: "Yes" }, { text: "No" }]
      return
    }
    this.script.surveyOptions = []
  }

  async getPricing() {
    await this.request(async () => {
      this.pricing = await SmsBillingDao.getPricing(this.organizationId)
    })
  }
}
