













































import Component from "vue-class-component"
import { InputWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import { Emit, Prop } from "vue-property-decorator"
import Vue from "vue"

@Component({
  components: {
    InputWithValidation,
    ValidationObserver
  }
})
export default class MarkPayoutsAsPaid extends Vue {
  @Prop() loading!: boolean
  check = ""

  @Emit()
  onConfirm() {
    return this.check
  }
}
