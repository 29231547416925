import { FindManyFilter } from "@/interfaces/findManyFilter"
import axios from "axios"
import { Feature } from "@/interfaces/feature/feature"
import { OrganizationFeature } from "@/interfaces/feature/organizationFeature"

export class FeatureDao {
  static async getAllRequests(params: FindManyFilter) {
    return (
      await axios.get(`text/feature-requests`, {
        params
      })
    ).data
  }

  static async getOrganizationFeatures(
    orgId: string
  ): Promise<OrganizationFeature[]> {
    return (await axios.get(`text/organizations/${orgId}/features`)).data
  }

  static async getOrganizationFeature(
    orgId: string,
    feature: Feature
  ): Promise<OrganizationFeature> {
    return (await axios.get(`text/organizations/${orgId}/features/${feature}`))
      .data
  }

  static async enableOrganizationFeature(
    orgId: string,
    feature: Feature
  ): Promise<OrganizationFeature> {
    return (
      await axios.put(`text/organizations/${orgId}/features/${feature}/enable`)
    ).data
  }

  static async disableOrganizationFeature(
    orgId: string,
    feature: Feature
  ): Promise<OrganizationFeature> {
    return (
      await axios.put(`text/organizations/${orgId}/features/${feature}/disable`)
    ).data
  }

  static async approveRequest(requestId: number) {
    return await axios.put(`text/feature-requests/${requestId}/approve`)
  }

  static async rejectRequest(requestId: number) {
    return await axios.put(`text/feature-requests/${requestId}/reject`)
  }
}
