






















































import { TextSchedulerUtilsMixin } from "@/mixins/campaign/TextSchedulerUtilsMixin"
import { TextSchedulerSteps } from "@/enums/textSchedulerSteps"
import { Agent, InvitedAgent, Organization } from "@robocent/components"
import Component from "vue-class-component"
import ChooseRow from "../elements/ChooseRow.vue"
import { Watch } from "vue-property-decorator"
import InviteAgentModal from "@/components/modals/InviteAgentModal.vue"
import TextCampaignActiveAgents from "@/components/scheduler/textElements/TextCampaignActiveAgents.vue"
import TextCampaignInvitedAgents from "@/components/scheduler/textElements/TextCampaignInvitedAgents.vue"
import TextCampaignDefaultAgents from "@/components/scheduler/textElements/TextCampaignDefaultAgents.vue"

@Component({
  components: {
    TextCampaignDefaultAgents,
    TextCampaignInvitedAgents,
    TextCampaignActiveAgents,
    ChooseRow
  }
})
export default class TextCampaignAgents extends TextSchedulerUtilsMixin {
  activeTab = 0

  nextStep() {
    this.next(TextSchedulerSteps.campaignPhoneNumbersInfo)
  }

  get selectedAgentsLength() {
    return (
      this.campaign.agents.length +
      this.campaign.invitedAgents.length +
      (this.campaign.defaultAgents! ? this.campaign.defaultAgents!.length : 0)
    )
  }

  @Watch("campaign.organization")
  watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      this.campaign.agents = []
      this.campaign.invitedAgents = []
    }
  }

  inviteAgentModal() {
    this.$buefy.modal.open({
      parent: this,
      component: InviteAgentModal,
      width: 400,
      props: {
        orgId: this.organizationId
      },
      events: {
        invited: this.onInvite
      }
    })
  }

  onInvite(agent: Agent | InvitedAgent) {
    if ((agent as Agent).userId) {
      this.campaign.agents = [...this.campaign.agents, agent]
      this.activeTab = 0
    } else {
      this.campaign.invitedAgents = [...this.campaign.invitedAgents, agent]
      this.activeTab = 1
    }
  }
}
