













import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Prop } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import { EventBus } from "@/services/eventBus"

@Component({
  components: { Card }
})
export default class UploadModal extends VueComponent {
  @Prop() orgId!: string
  @Prop() title!: string

  async onUploaded() {
    EventBus.getUploads$.next()
    this.$parent.close()
  }
}
