

























































































































import Component, { mixins } from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import CampaignOrganization from "@/components/scheduler/elements/CampaignOrganization.vue"
import { VoiceSchedulerUtilsMixin } from "@/mixins/campaign/VoiceSchedulerUtilsMixin"
import { Emit, Prop, Watch } from "vue-property-decorator"
import { VoiceSchedulerSteps } from "@/enums/voiceSchedulerSteps"
import CampaignName from "@/components/scheduler/elements/CampaignName.vue"
import VoiceCampaignDate from "@/components/scheduler/voiceElements/VoiceCampaignDate.vue"
import VoiceCampaignType from "@/components/scheduler/voiceElements/VoiceCampaignType.vue"
import CampaignPhoneLists from "@/components/scheduler/elements/CampaignPhoneLists.vue"
import VoiceCampaignAudios from "@/components/scheduler/voiceElements/VoiceCampaignAudios.vue"
import VoiceCampaignPhoneNumbers from "@/components/scheduler/voiceElements/VoiceCampaignPhoneNumbers.vue"
import CampaignCards from "@/components/scheduler/elements/CampaignCards.vue"
import VoiceCampaignDetails from "@/components/scheduler/voiceElements/VoiceCampaignDetails.vue"
import VoiceCampaignCost from "@/components/scheduler/voiceElements/VoiceCampaignCost.vue"
import { Customer, Organization } from "@robocent/components"
import { SmsBillingDao } from "@/services/dao/smsBillingDao"
import VoiceCampaignScheduleButton from "@/components/scheduler/voiceElements/VoiceCampaignScheduleButton.vue"
import { CampaignCostService } from "@/services/campaignCostService"
import { SchedulerService } from "@/services/schedulerService"
import VoiceCampaignTransferOptions from "@/components/scheduler/voiceElements/VoiceCampaignTransferOptions.vue"
import VoiceCampaignSurveyScript from "@/components/scheduler/voiceElements/VoiceCampaignSurveyScript.vue"
import CampaignDateMixin from "@/mixins/campaign/CampaignDateMixin"
import moment from "moment"
import UnderReviewMessage from "@/components/scheduler/voiceElements/UnderReviewMessage.vue"

@Component({
  components: {
    VoiceCampaignSurveyScript,
    UnderReviewMessage,
    VoiceCampaignTransferOptions,
    VoiceCampaignScheduleButton,
    VoiceCampaignCost,
    VoiceCampaignDetails,
    CampaignCards,
    VoiceCampaignPhoneNumbers,
    VoiceCampaignAudios,
    VoiceCampaignType,
    VoiceCampaignDate,
    ValidationObserver,
    CampaignOrganization,
    CampaignName,
    CampaignPhoneLists
  }
})
export default class VoiceSchedulerElements extends mixins(
  VoiceSchedulerUtilsMixin,
  CampaignDateMixin
) {
  customer: Customer | null = null
  campaignCostService = new CampaignCostService()
  @Prop() isSubmitting!: boolean

  get isLoading() {
    return this.isSubmitting || this.loading
  }

  get showScheduleButton() {
    return (
      this.filledSteps.includes(this.steps.campaignCost) ||
      (this.isCampaignUpdate &&
        this.filledSteps.includes(this.steps.campaignDetails))
    )
  }

  get filledSteps() {
    return this.$store.getters["voiceScheduler/steps"]
  }

  get step() {
    return this.$store.getters["voiceScheduler/step"]
  }

  created() {
    if (this.organizationId) {
      this.getCustomer()
    }
    SchedulerService.clear()
  }

  async getCustomer() {
    await this.request(async () => {
      this.setCustomer(await SmsBillingDao.getCustomer(this.organizationId))
    })
  }

  setCustomer(customer: Customer) {
    this.customer = customer
  }

  @Watch("step")
  goToStep(step: VoiceSchedulerSteps) {
    this.scrollToStep(step)
  }

  async onSave() {
    await this.request(async () => {
      if (!this.validateTime()) {
        this.openToast("Please select correct time")
        return this.scrollToStep(VoiceSchedulerSteps.campaignDate)
      }

      const rushMessage = await this.campaignCostService.checkRushAndGetMessage(
        this.campaign
      )
      if (rushMessage) {
        return this.confirmRush(rushMessage)
      }
      await this.onSubmit()
    })
  }

  validateTime() {
    const datetime = moment.utc(this.campaign.time).tz(this.campaign.timezone)
    const date = datetime.format("YYYY-MM-DD")
    const time = datetime.format("HH:mm")
    return this.validatePicker(date, time, this.campaign.timezone)
  }

  @Emit()
  onSubmit() {}

  confirmRush(message: string) {
    this.$buefy.dialog.confirm({
      ariaRole: "dialog",
      title: "Rush confirmation",
      message,
      confirmText: "Agree",
      type: "is-warning",
      hasIcon: true,
      onConfirm: () => this.onSubmit()
    })
  }

  @Watch("campaign.organization")
  async watchCampaignOrg(currentValue: Organization, prevValue: Organization) {
    if (currentValue?._id !== prevValue?._id) {
      await this.getCustomer()
    }
  }
}
