



























import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import { Emit, Prop, Ref } from "vue-property-decorator"

@Component
export default class CampaignAutoRefresh extends VueComponent {
  @Prop() updatedAt!: string
  leftTime = 60 * 1000
  @Ref("countdown") countdown: any

  @Emit()
  refresh() {
    this.countdown.startCountdown("restart")
  }
}
