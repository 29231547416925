

























import Component from "vue-class-component"
import ScriptMixin from "@/mixins/ScriptMixin"
import { ScriptDao } from "@/services/dao/scriptDao"
import { Prop } from "vue-property-decorator"
import Card from "@/components/elements/Card.vue"
import ScriptFormStepper from "@/components/scheduler/textElements/scriptStepper/ScriptFormStepper.vue"

@Component({
  components: {
    ScriptFormStepper,
    Card
  }
})
export default class EditScriptModal extends ScriptMixin {
  @Prop() scriptId!: number

  created() {
    this.getScript()
  }

  async getScript() {
    await this.request(async () => {
      this.script = await ScriptDao.getScript(
        this.organizationId,
        this.scriptId
      )
    })
  }

  async handleSave() {
    await this.editScript()
    this.$emit("onChange", this.script)
  }
}
