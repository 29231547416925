







import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import InviteAgentModal from "@/components/modals/InviteAgentModal.vue"
import { Prop } from "vue-property-decorator"

@Component
export default class AgentsEmptyMessage extends VueComponent {
  @Prop() orgId!: string

  inviteAgentModal() {
    this.$buefy.modal.open({
      parent: this,
      component: InviteAgentModal,
      width: 400,
      props: {
        orgId: this.orgId
      }
    })
  }
}
