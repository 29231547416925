











































































import Component from "vue-class-component"
import { ValidationObserver } from "vee-validate"
import { VueComponent } from "@/utils/VueComponent"
import { NumberInputWithValidation } from "@robocent/components"
import Card from "@/components/elements/Card.vue"
import { PartnerPortalDao } from "@/services/dao/partnerPortalDao"
import { organizationFeature } from "@/data/organizationFeature"
import OrganizationPartnerPortalUsers from "@/components/organization/OrganizationPartnerPortalUsers.vue"
import { PartnerType } from "@/enums/partnerPortal/partnerType"
import { OrganizationPartnerType } from "@/interfaces/partnerPortal/organizationPartnerType"
import { organizationPartnerType } from "@/data/organizationPartnerType"
import { OrganizationPartnerSettings } from "@/interfaces/partnerPortal/organizationPartnerSettings"
import _ from "lodash"
import { Feature } from "@/interfaces/feature/feature"

interface OrganizationPartnerSettingsFormatted {
  partnerId: string
  partnerTypes: {
    [PartnerType.commission]: OrganizationPartnerType[]
    [PartnerType.discount]: OrganizationPartnerType[]
  }
}

@Component({
  components: {
    OrganizationPartnerPortalUsers,
    Card,
    ValidationObserver,
    NumberInputWithValidation
  }
})
export default class OrganizationPartnerPortal extends VueComponent {
  partnerSettings: OrganizationPartnerSettingsFormatted | null = null
  partnerTypes = organizationPartnerType
  organizationFeature = organizationFeature
  features = Feature

  created() {
    this.getPartnerPortalSettings()
  }

  get organization() {
    return this.$store.getters.organization
  }

  get isPartnerSelected() {
    return this.partnerSettings?.partnerId
  }

  get label() {
    if (!this.isPartnerSelected) {
      return "Please select a partner."
    }
    return ""
  }

  onSelectPartner(partnerId = "") {
    this.partnerSettings!.partnerId = partnerId
  }

  onToggle(type: PartnerType, ind: number) {
    for (const key in this.partnerSettings?.partnerTypes) {
      if (key !== type) {
        this.partnerSettings!.partnerTypes[key as PartnerType][ind].enabled =
          false
      }
    }
  }

  async getPartnerPortalSettings() {
    await this.request(async () => {
      const partnerTypes =
        await PartnerPortalDao.getOrganizationPartnerSettings(
          this.$route.params.orgId
        )

      this.partnerSettings = {
        partnerTypes: _.groupBy(partnerTypes, "type"),
        partnerId: partnerTypes[0]?.partnerId
      } as OrganizationPartnerSettingsFormatted
    })
  }

  async savePartnerSettings() {
    const settings = this.prepareSettings()

    const ids = _.filter(settings.partnerTypes, (entry) => entry.id)

    if (ids.length) {
      await this.updatePartnerPortalSettings(settings)
    } else {
      await this.createPartnerPortalSettings(settings)
    }

    this.updateOrg()
    await this.getPartnerPortalSettings()
  }

  prepareSettings(): OrganizationPartnerSettings {
    let partnerTypes: OrganizationPartnerType[] = []
    for (const key in this.partnerSettings?.partnerTypes) {
      partnerTypes = [
        ...partnerTypes,
        ...this.partnerSettings!.partnerTypes[key as PartnerType]
      ] as OrganizationPartnerType[]
    }

    return {
      ...this.partnerSettings,
      partnerTypes
    } as OrganizationPartnerSettings
  }

  async createPartnerPortalSettings(settings: OrganizationPartnerSettings) {
    const preparedSettings = {
      ...settings,
      partnerTypes: this.getPreparedPartnerTypes(settings.partnerTypes)
    } as OrganizationPartnerSettings

    await this.request(async () => {
      await PartnerPortalDao.saveOrganizationPartnerSettings(
        this.$route.params.orgId,
        preparedSettings as OrganizationPartnerSettings
      )
    }, "Partner Settings were saved successfully.")
  }

  async updatePartnerPortalSettings(settings: OrganizationPartnerSettings) {
    await this.request(async () => {
      await PartnerPortalDao.updateOrganizationPartnerSettings(
        this.$route.params.orgId,
        settings as OrganizationPartnerSettings
      )
    }, "Partner Settings were updated successfully.")
  }

  getPreparedPartnerTypes(partnerTypes: OrganizationPartnerType[]) {
    return _.map(partnerTypes, (entry: OrganizationPartnerType) => ({
      type: entry.type,
      feature: entry.feature,
      rate: entry.rate,
      organizationId: this.$route.params.orgId,
      enabled: entry.enabled
    }))
  }

  updateOrg() {
    this.$store.commit("setOrganization", {
      ...this.organization,
      partnerId: this.partnerSettings?.partnerId
    })
  }
}
