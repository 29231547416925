export enum DatasetFields {
  gender = "sex",
  age = "age_ind",
  ethnicity = "ethnic_infer",
  partyCode = "party_code",
  votingHistory = "votingHistory",
  historyReqs = "historyReqs",
  county = "county_st",
  zipCode = "zip",
  municipality = "municipality",
  precinct = "precinct",
  congDist = "cong_dist"
}
