





























import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import JurisdictionRow from "@/voterData/components/jurisdiction/JurisdictionRow.vue"
import { Watch } from "vue-property-decorator"
import { JurisdictionType } from "@/voterData/interfaces/JurisdictionType"
import { JurisdictionTypes } from "@/voterData/enums/jurisdictionTypes"
import _ from "lodash"
import { FieldInfo } from "@/voterData/interfaces/FieldInfo"

@Component({
  components: {
    JurisdictionRow,
    StepTemplate
  }
})
export default class Jurisdiction extends VoterDataUtilsMixin {
  allTypes = [
    { name: "County", component: "county", slug: JurisdictionTypes.county },
    {
      name: "Zip Code",
      component: "zip-code",
      slug: JurisdictionTypes.zipCode
    }
  ]

  get jurisdictionTypes() {
    return this.$store.getters["voterData/locationInfo/jurisdictionTypes"]
  }
  set jurisdictionTypes(value: JurisdictionType[]) {
    this.$store.commit("voterData/locationInfo/updateJurisdictionTypes", value)
  }

  onAddNewType() {
    this.jurisdictionTypes = [
      ...this.jurisdictionTypes,
      {
        type: {
          name: "",
          component: "",
          slug: ""
        }
      }
    ]
  }

  @Watch("state")
  watchState(nextValue: string, prevValue: string) {
    if (!prevValue) {
      this.onAddNewType()
    }
  }

  @Watch("county")
  @Watch("zipCode")
  watchLocationChange(nextValue: FieldInfo[], prevValue: FieldInfo[]) {
    if (!_.isEqual(nextValue, prevValue)) {
      this.updateQuery()
    }
  }
}
