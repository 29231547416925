































import Component from "vue-class-component"
import { VueComponent } from "@/utils/VueComponent"
import Card from "@/components/elements/Card.vue"

@Component({
  components: { Card }
})
export default class SelfServiceAgreementModal extends VueComponent {
  onDecline() {
    this.$emit("onDecline")
    this.$parent.close()
  }
}
