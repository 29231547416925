









































































import Component from "vue-class-component"
import StepTemplate from "@/voterData/StepTemplate.vue"
import VoterDataUtilsMixin from "@/voterData/mixins/VoterDataUtilsMixin"
import {
  CardTable,
  CardTableItem,
  NumberInputWithValidation
} from "@robocent/components"
import { Prop, Watch } from "vue-property-decorator"
import { CountTypes } from "@/voterData/enums/countTypes"

@Component({
  components: {
    CardTable,
    NumberInputWithValidation,
    StepTemplate,
    CardTableItem
  }
})
export default class RecordsToPurchase extends VoterDataUtilsMixin {
  @Prop() countType!: { name: string; value: CountTypes }
  custom = false

  get count() {
    return this.$store.getters[`voterData/${this.countType.value}`]
  }

  get recordsAmount() {
    return this.$store.getters["voterData/submitRequestStep/recordsAmount"][
      this.countType.value
    ]
  }
  set recordsAmount(value: number) {
    this.$store.commit("voterData/submitRequestStep/updateRecordsAmount", {
      type: this.countType.value,
      value
    })
  }

  get isLoading() {
    return (
      this.$store.getters[`voterData/loading/${this.countType.value}`] ||
      this.$store.getters["voterData/loading"].query
    )
  }

  @Watch("count")
  watchCount() {
    this.setDefaultAmount()
  }

  setDefaultAmount() {
    this.recordsAmount = this.count
  }

  onEditClick() {
    if (this.isLoading) {
      return
    }
    this.custom = true
  }
}
