<template>
  <div class="modal-content" style="max-width: 400px">
    <div class="modal-card" style="max-width: 400px">
      <header class="modal-card-head">
        <p class="modal-card-title">Send Bulk SMS to {{ count }} Subscribers</p>
      </header>
      <section class="modal-card-body">
        <b-message v-if="smsCost" type="is-info">
          <strong>{{ smsCost.amount }}¢ per Subscriber with $10 minimum</strong>
        </b-message>

        <b-field label="Message">
          <b-input maxlength="300" type="textarea" v-model="message"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="$parent.close()">Close</button>
        <b-button
          class="button is-primary"
          :loading="loading"
          :disabled="!message"
          type="submit"
          @click="confirm"
        >
          Continue to Pay
        </b-button>
      </footer>
    </div>
    <stripe-checkout
      ref="checkoutRef"
      :pk="stripePublicApiKey"
      :session-id="sessionId"
    />
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe"
import { PhoneNumberDao } from "../services/dao/robocent/phoneNumberDao"
import { CheckoutDao } from "@/services/dao/robocent/checkoutDao"

export default {
  name: "SendBulkMessage",
  props: ["phone", "count"],
  components: { StripeCheckout },
  data() {
    return {
      stripePublicApiKey: "",
      orgId: this.$route.params.orgId,
      smsCost: null,
      message: "",
      loading: false,
      sessionId: ""
    }
  },
  async created() {
    await this.setStripePublicApiKey()
    await this.setSmsCost()
    await this.getCheckoutSessionId()
  },
  methods: {
    async getCheckoutSessionId() {
      const params = {
        organizationId: this.orgId,
        product: {
          name: `${this.count} Bulk SMS Messages`,
          amount: this.smsCost.amount,
          quantity: this.count
        },
        metadata: {
          message: this.message,
          type: "bulk_message",
          resourceType: "phoneNumber",
          resourceId: this.phone.uuid,
          redirectPath: `/organizations/${this.orgId}/phones/${this.phone.uuid}/messages`
        }
      }
      const { data } = await CheckoutDao.getCheckoutSession(params)
      this.sessionId = data?.id
    },
    async confirm() {
      this.toCheckout()
    },
    async toCheckout() {
      this.loading = true
      this.$refs.checkoutRef.redirectToCheckout()
      this.loading = false
    },

    async setStripePublicApiKey() {
      this.stripePublicApiKey = await CheckoutDao.getStripePublicKey()
    },
    async setSmsCost() {
      const { data } = await CheckoutDao.getSmsCost()
      this.smsCost = data.items
    },
    redirectToInvoices() {
      const redirectTo = `/organizations/${this.orgId}/billing`
      this.$router.push({ path: redirectTo })
    },
    sendNotification() {
      this.$buefy.dialog.prompt({
        message: "What's the Message?",
        inputAttrs: {
          placeholder: "e.g. Thank you for supporting us!",
          maxlength: 300
        },
        trapFocus: true,
        onConfirm: (value) =>
          PhoneNumberDao.sendNotify(this.orgId, this.phone.uuid, {
            message: value
          }).then(() => this.$buefy.toast.open("Notification Sent"))
      })
    }
  }
}
</script>
<style scoped>
.container {
  margin: 20px 20px 40px;
}
.container p {
  margin: 10px 0;
  font-size: 16px;
}
</style>
