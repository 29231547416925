






import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { VoiceCampaign } from "../../../interfaces/voiceCampaign/voiceCampaign"
import { VoiceCampaignDao } from "../../../services/dao/robocent/voiceCampaignDao"
import Vue from "vue"
import { PhoneNumberWithValidation } from "@robocent/components"
import { ValidationObserver } from "vee-validate"
import Card from "@/components/elements/Card.vue"
import TestCall from "@/components/elements/voiceCampaign/TestCall.vue"

@Component({
  components: { TestCall, Card, PhoneNumberWithValidation, ValidationObserver }
})
export default class TestVoiceCampaignModal extends Vue {
  @Prop() voiceCampaign!: VoiceCampaign
  phone = ""
  loading = false

  async submit(formattedPhone: string) {
    try {
      this.loading = true
      if (this.voiceCampaign.meta?.straticsId.length > 12) {
        await VoiceCampaignDao.outboundCampaignTestCall(
          this.voiceCampaign.meta.straticsId,
          formattedPhone
        )
      } else {
        await VoiceCampaignDao.campaignTestCall(
          this.voiceCampaign.meta?.straticsId,
          formattedPhone
        )
      }
      setTimeout(() => {
        this.loading = false
      }, 12000)
    } catch (e) {
      this.loading = false
      console.error(e)
    }
  }
}
